import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {IoStatsChart} from 'react-icons/io5';
import {themeColor,hoverEffect,use} from '../utils';
import Path from './assets/Path.png';
import Dashboardstoriesmetricstalent from './Dashboardstoriesmetricstalent';
import Dashboarddefectsmetricstalent from './Dashboarddefectsmetricstalent';
import DashboardGithublinesofcodeTalent from './DashboardGithublinesofcodeTalent';
import Dorametricsdashboardtalent from './Dorametricsdashboardtalent';
import DashboardTaskmetricsTalent from './DashboardTaskmetricsTalent';
import DashboardPRCountTalent from './DashboardPRCountTalent';
import Select from 'react-select'; // Import react-select
import TimeViewtalent from './TimeViewtalent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// import './styles.css';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

function Earnings({expanded}) {
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const [latestSprintVelocity, setLatestSprintVelocity] = useState(null);
  const intl = useIntl();
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: '', // Selected sprint name
    startDate: '', // Selected sprint start date
    endDate: '' // Selected sprint end date
  });
  const [podData, setPodData] = useState([]);
  const [selectedPod, setSelectedPod] = useState([]);  const[fetchMetrics,setFetchMetrics]=useState('');
  useEffect(() => {
    fetchSprintfilter();
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    if (!token) {
      return; // Exit early if there is no token
    }
  
    fetch(process.env.REACT_APP_TALENT_GETPODSFORTALENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ c_id: user.data[0].c_id || null }),
      signal: signal
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && Array.isArray(data.body)) {
          setPodData(data.body);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching data:', error);
        }
      });
  
    return () => {
      abortController.abort();
    };
  }, [token]);
  

  const fetchDataPod =()=>{
    if (!token) {
      return; // Exit early if there is no token
    }
    fetch(process.env.REACT_APP_TALENT_LATESTSVDASBOARDTALENT, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: token, 
        },
        
              body: JSON.stringify({
                c_id: user.data[0].c_id ,
          pod_id: selectedPod,
        }),
      })
        .then(response => response.json())
        .then(data => {
          // Parse the response body to JSON
          const responseBody = JSON.parse(data.body);
          // Extract and set the latest_sprint_velocity value
          setLatestSprintVelocity(responseBody.latest_sprint_velocity);
        })
        .catch(error => {
          console.error('Error fetching sprint velocity:', error);
        });
  }

  const fetchSprintfilter = () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    fetch(process.env.REACT_APP_TALENT_SPRINTFILTERTAENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ talent_id: user.data[0].c_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && data.body) {
          const parsedBody = JSON.parse(data.body);
          if (parsedBody.sprint_filter) {
            const sprintFilter = JSON.parse(parsedBody.sprint_filter);
            setSprintData(sprintFilter);
          }
        }
      })
      
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  
  const handlePodSelect = (selectedOptions) => {
    setSelectedPod
    (selectedOptions.map((option) => option.value));
  };
 
  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name,
  }));

  useEffect(() => {
    // Check if selectedPod is not empty
   
      fetchDataPod();
  
  }, [selectedPod,token]);

  const handleSprintSelect = (e) => {
    const selectedSprintName = e.target.value;
    const selectedSprintData = sprintData.find(sprint => sprint.sprint_name === selectedSprintName);
    setSelectedSprint({
      name: selectedSprintName,
      startDate: selectedSprintData ? selectedSprintData.sprint_start_date : '',
      endDate: selectedSprintData ? selectedSprintData.sprint_end_date : ''
    });
  };
   

  return (
  <YourProjects expanded={expanded}>

     <TitleText>  
     
     {intl.formatMessage({ id: 'podAnalytics' })}
      </TitleText>
      <Dropdowns>
      <Seletfield
        options={podOptions}
        isMulti
        value={podOptions.filter((option) => selectedPod.includes(option.value))}
        onChange={handlePodSelect}
        placeholder={intl.formatMessage({ id: 'selectPods' })}
        styles={{
          control: (provided) => ({
            ...provided,
      
            height:'3rem',
            overflow: 'auto'
          }),
        }}
      />

<Sprintselect value={selectedSprint.name} onChange={handleSprintSelect}>
  <option value="">Select a Sprint</option>
  {sprintData.map((sprint, index) => (
    <option key={index} value={sprint.sprint_name}>
      {sprint.sprint_name}
    </option>
  ))}
</Sprintselect>
</Dropdowns>
      <Border>
<Project style={{display:"block"}}>
  <Div>
    
<div>
         <Dashboardstoriesmetricstalent  podid={selectedPod}   selectedSprint={selectedSprint.name}  />
         </div>

         <div style={{marginLeft:"1rem"}}><Dashboarddefectsmetricstalent    podid={selectedPod}   selectedSprint={selectedSprint.name} /></div>
         
<div style={{marginLeft:"1rem"}}><DashboardGithublinesofcodeTalent  podid={selectedPod}   selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate} /></div>
<div style={{marginLeft:"1rem"}}><TimeViewtalent   podid={selectedPod}    selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/></div>  

     <div ><DashboardTaskmetricsTalent    podid={selectedPod}    selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/></div>
</Div>
</Project>
<DivDora >
  <div>
     
       
          {latestSprintVelocity !== null ? (<div style={{display:"flex",flexDirection:"row"}}>
          <span style={{ color: '#90E0EF',marginTop:"10px", }}>{intl.formatMessage({ id: 'latestSprintVelocity' })}</span>  <p style={{ color: '#90E0EF',marginTop:"10px" ,marginRight:"10px"}}>:{latestSprintVelocity}</p></div>
          ) : (
            <p>{intl.formatMessage({ id: 'noData' })}</p>
          )}
    
        </div>
        {/* <div>
        <p style={{ color: 'blue',marginTop:"10px",marginLeft:'1rem' }}>{intl.formatMessage({ id: 'doraMetrics' })}</p>
        <div >< Dorametricsdashboardtalent  initialPodIds={selectedPod} /></div>
        </div> */}
      </DivDora>

    </Border>
</YourProjects>
  
  );
} 

const DivDora=styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
 @media screen and (min-width:320px) and (max-width:1080px){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
`;
const Border=styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
background-color:white;
border-radius: 1rem ;
height:80%;
width:100%;
 padding:20px;
//  overflow-y:scroll;
 @media screen and (min-width:320px) and (max-width:1080px){
  // overflow-y:scroll;
  width:18rem;
 }
`;
const YourProjects=styled.div`
// border: 25px solid #90E0EF;
height:max-content;
background-color:#90E0EF;
border-radius: 1rem ;
padding:0.8rem;
display:flex;
flex-direction:column;

width: ${(props) => (props.expanded ? '47rem' : '51rem')}; 
box-shadow: 0px 4px 6px #00000029;
transition:0.45 ease-in-out;
&:hover:{
box-shadow:${hoverEffect};


 
}

@media screen and (min-width:320px) and (max-width:1080px){
    width:20rem;
    height:max-content:
    display:flex;
    margin:auto;
  
padding:0.8rem;
flex-direction:column;
justify-content:center;
align-items:center;
    margin-top:1rem;
    
   }
`;
const Seletfield=styled(Select)`
width: 60%;

margin: 0.5rem;

 @media screen and (min-width:320px) and (max-width:1080px){
  width: 90%;
 
 }
`;
const Div=styled.div`

display:flex;
flex-direction:row;


@media screen and (min-width:320px) and (max-width:1080px){
  
  
  display :flex;
  margin:auto;
   justify-content:center;
   flex-direction:column;
 align-items:center;
   }
`;

const TitleText = styled.h4`
height:8%;
color:white;
font-weight:600;

font-size:18px;
font-family:Poppins,Medium;
&:hover {
    
  color: #00B4D8; /* Change this to your desired text color on hover */
}
@media screen and (min-width:320px) and (max-width:1080px){
  
  
padding:1rem;

 }
`;

const Project=styled.div`
 display :flex;
 align-items: center;

 margin-bottom:0.3rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  display :flex;
 margin:auto;
  justify-content:center;
  
align-items:center;
   }
`;
const Dropdowns=styled.div`
 display :flex;
 align-items: center;
flex-direction:row;

 @media screen and (min-width:320px) and (max-width:1080px){
  display :flex;
 margin:auto;
  justify-content:center;
  flex-direction:column;
align-items:center;
   }
`;


const Sprintselect=styled.select`
textDecoration :none;
border: none;
borderRadius:none;
width:15em;
 @media screen and (min-width:320px) and (max-width:1080px){
  width:10em;
   }
`;

export default Earnings;