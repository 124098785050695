import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const DashboardGithublinesofcodeTalent = ({podid,selectedSprint,sprintStartDate,sprintEndDate}) => {
  const [metrics, setMetrics] = useState(null);
  const [talentUsers, setTalentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const chartRefs = useRef({});
  const [loading, setLoading] = useState(true); // Add loading state
  const isMounted = useRef(true);
  const chartId = useRef(uuidv4()); // Generate a unique ID for the chart canvas
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  const token = useAuthToken();


  useEffect(() => {
    Chart.register(...registerables);
   
    fetchMetrics();
  
    return () => {
      isMounted.current = false;
    };
  }, [selectedUser,podid,token,selectedSprint,sprintStartDate,sprintEndDate]);

  const fetchMetrics = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(
        process.env.REACT_APP_TALENT_CODEDASHBOARDTALENT,
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pod_id: podid || null, c_id: user.data[0].c_id, sprint_name: selectedSprint || '',
            sprint_start_date: sprintStartDate || '',
            sprint_end_date: sprintEndDate || '' }),
        }
      );
  
      if (response.status === 500) {
        console.error('Internal Server Error (500)');
        setLoading(false);
        return;
      }
  
      const data = await response.json();
      console.log('API Response Data:', data);
      if (data && data.body) {
        const { talent_users, all_repo_checkins } = data.body;
        console.log('Parsed Data:', talent_users);
  
        if (!talent_users || talent_users.length === 0) {
          console.error('No talent users data available');
          setLoading(false);
          setTalentUsers([]); // Reset talentUsers to an empty array
          setMetrics(null); // Reset metrics to null
          return;
        }
  
        setTalentUsers(talent_users);
  
        if (all_repo_checkins && all_repo_checkins.sprint_commit_lines !== undefined) {
          // Type 2 response
          const sprintCommitLines = talent_users.map(user => user.sprint_commit_lines);
          console.log('Sprint Commit Lines:', sprintCommitLines);
          setMetrics(sprintCommitLines);
        } else {
          // Type 1 response
          const linesChangedThisWeek = talent_users[0].total_lines_changed_this_week || talent_users[0].total_lines_changed_this_week === 0 ? talent_users[0].total_lines_changed_this_week : 0;
          const linesChangedLastWeek = talent_users[0].total_lines_changed_last_week || talent_users[0].total_lines_changed_last_week === 0 ? talent_users[0].total_lines_changed_last_week : 0;
          console.log('Metrics:', { linesChangedThisWeek, linesChangedLastWeek });
          setMetrics({ linesChangedThisWeek, linesChangedLastWeek });
        }
  
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setLoading(false);
    }
  };
  
  
  
  
 // Inside the second useEffect hook
useEffect(() => {
  if (metrics) {
    const { linesChangedThisWeek, linesChangedLastWeek } = metrics;
    if (selectedUser === '') {
      // If "All" is selected, display the total lines for all users
      const totalLinesChangedThisWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_this_week, 0);
      const totalLinesChangedLastWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_last_week, 0);
      displayChart([totalLinesChangedThisWeek, totalLinesChangedLastWeek], ['linesChangedThisWeek', 'linesChangedLastWeek']);

    } else {
      const userData = talentUsers.find((user) => user.github_user === selectedUser);
      if (userData) {
        console.log('Selected User Data:', userData);
        const userLinesChangedThisWeek = userData.total_lines_changed_this_week;
        const userLinesChangedLastWeek = userData.total_lines_changed_last_week;
        displayChart([userLinesChangedThisWeek, userLinesChangedLastWeek], ['linesChangedThisWeek', 'linesChangedLastWeek']);

      }
    }
  }
}, [metrics, selectedUser, talentUsers]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const displayChart = (sprintCommitLines) => {
    const existingChart = chartRefs.current[chartId.current];
  
    if (existingChart) {
      existingChart.destroy();
    }
  
    const ctx = document.getElementById(chartId.current);
  
    if (!ctx) {
      console.error('Canvas element not found. Chart ID:', chartId.current);
      console.log('Available chart IDs:', Object.keys(chartRefs.current));
      return;
    }
  
    console.log('Creating chart with ID:', chartId.current);
  
    if (talentUsers.length === 0) {
      // If no talent users are available, display a message instead of the chart
      ctx.innerHTML = '<p>No data available</p>';
      return;
    }
  
    let chartData = [];
    let chartLabels = [];
  
    talentUsers.forEach(user => {
      if ('total_lines_changed_this_week' in user && 'total_lines_changed_last_week' in user) {
        // Case 1: When total_lines_changed_this_week and total_lines_changed_last_week are present
        chartData.push(user.total_lines_changed_last_week, user.total_lines_changed_this_week);
        chartLabels.push('Lines Changed Last Week', 'Lines Changed This Week');
      } else if ('sprint_commit_lines' in user) {
        // Case 2: When sprint_commit_lines is present
        chartData.push(user.sprint_commit_lines);
        chartLabels.push('Sprint Commit Lines');
      }
    });
  
    chartRefs.current[chartId.current] = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: 'Metrics',
            data: chartData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)'
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.formattedValue;
              }
            }
          },
          legend: {
            display: false, // Remove legend
          }
        }
      }
      
      
      
      
    });
  };
  
  
  
  
  
  
  



  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
        <br />
    
        <div className="chart-wrapper" style={{marginTop:"20px", width:"135px", height:"135px"}}>
          {loading === false && talentUsers.length === 0 ? (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          ) : (
            <canvas id={chartId.current} ></canvas>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default DashboardGithublinesofcodeTalent;
