import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineMinus } from "react-icons/ai";
import { NavHashLink } from "react-router-hash-link";
import PartnerINviteTalent from "./PartnerINviteTalent";
import { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useAuthToken } from "../../TokenContext";
const PartnerInvite = () => {
  const [modal, setModal] = useState(false);
  const token = useAuthToken();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [removebutton, setRemovebutton] = useState(0);
  const [invite, setinvite] = useState([{ emails: "" }]);
  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...invite];
    list[index][name] = value;
    setinvite(list);
  };

  const handleremove = (index) => {
    if (invite.length == 1) {
      alert("there should be atleast one update field");
      setRemovebutton(1);
    } else {
      const list = [...invite];
      list.splice(index, 1);
      setinvite(list);
    }
  };

  const handleaddclick = () => {
    // setinputList([...inputList,{PodCandidate:'', update:'',Categories:''}]);
    if (invite.length >= 5) {
      alert("Max Limit is 5");
    } else {
      // console.log('data ;; ',[...invite,{emails:''}]);
      setinvite([...invite, { emails: "" }]);
    }
  };

  const handleSubmitinvite = (e) => {
    e.preventDefault();

    const requestOptionsinvite = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({ invite, id: user.data[0].id }),
    };
    // console.log('consoledata',invite)

    var arr = invite;

    var clean = arr.filter(
      (arr, index, self) =>
        index === self.findIndex((t) => t.emails === arr.emails)
    );

    // console.log('consoledata2',clean);
    if (invite.length !== clean.length) {
      alert("pod candidate name should be unique");
    } else {
      fetch(
        process.env.REACT_APP_CLIENT_EMAILINVITEMANAGERPARTNER,
        requestOptionsinvite
      )
        .then((response) => response.json())

        .then((json) => {
          console.log("newapi", json);
          if (json.status == 200) {
            alert("Invite Mail sent.");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (json.status == 400) {
            alert(json.data);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
    }
  };

  const isTalentLinkDisabled = true; // Set this to your condition

  return (
    <>
      {user.data[0].subscriptionID === null ||
      user.data[0].subscriptionID === "" ? (
        <>
          <Divpopup>
            <p>
              You don’t have an active subscription plan. Please click on{" "}
              <NavHashLink to="/subscriptions" smooth>
                "Subscribe"
              </NavHashLink>{" "}
              link to add a subscription.{" "}
            </p>{" "}
          </Divpopup>
        </>
      ) : (
        <Divpopup>
          <center>
            <Navdiv>
              <Cast2 to="/PartnerInvite" style={{ cursor: "pointer" }}>
                Manger Invite{" "}
              </Cast2>
              <span>
                {" "}
                <Cast to="/PartnerINviteTalent" style={{ cursor: "pointer" }}>
                  Talent Invite
                </Cast>
              </span>
            </Navdiv>
          </center>

          <TitleTextpopup>Enter E-Mail to Invite Pod Managers</TitleTextpopup>

          {invite.map((x, i) => {
            return (
              <CardContentpopup>
                <InputFieldpopup
                  type="email"
                  placeholder="Enter  E-Mail"
                  name="emails"
                  value={x.emails}
                  onChange={(e) => handleinputchange(e, i)}
                  required
                />
                <TextDiv>
                  {invite.length - 1 === i && (
                    <Buttonadd onClick={handleaddclick}>
                      <IoMdAdd />
                    </Buttonadd>
                  )}

                  {invite.length !== 0 && (
                    <ButtonRemove onClick={() => handleremove(i)}>
                      <AiOutlineMinus />
                    </ButtonRemove>
                  )}
                </TextDiv>
              </CardContentpopup>
            );
          })}
          <Buttonpopup onClick={handleSubmitinvite}>Invite</Buttonpopup>
        </Divpopup>
      )}
    </>
  );
};

export default PartnerInvite;
const Buttonadd = styled.button`
  tex-decoration: none;
  border: none;
  display: flex;
  margin-left: 10px;

  border-radius: 2px;
  font-size: 14px;
  font-family: Poppins, Medium;
  font-weight: 600;
  outline: none;
  width: 2rem;
  height: 1rem;
  justify-content: center;
  align-items: center;

  margin-top: 0.5rem;
  color: white;
  background-color: #00b4d8;
`;

const CSSstyle = css`
  color: black;
  background-color: #e4e4e4;
  &:hover {
    background-color: #e4e4e4;
  }
  text-decoration: none !important;
  border-radius: 2rem;
  padding: 0.5rem;
  margin-left: -3rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    padding-left: 2rem;
    padding-right: none;
  }
`;

const CSSstyle1 = css`
  color: white;
  box-shadow: 1px 3px 6px #00000029;
  background-color: #00b4d8;
  text-decoration: none !important;
  border-radius: 2rem;
  padding: 0.5rem;
  pointer-events: none;
  margin-left: -2rem;
  padding-left: 4rem;
  padding-right: 4rem;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
  }
`;

const Cast2 = styled(NavLink)`
  ${CSSstyle1}
`;

const Cast = styled(NavLink)`
  ${CSSstyle}
`;
const Navdiv = styled.div`
  margin-bottom: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
const navcss = css`
  color: black;
  background-color: #e4e4e4;
  shadow-color: 15px 15px white;
  text-decoration: none;
  border-radius: 2rem;
  &:hover {
    background-color: #e4e4e4;
  }
  padding: 0.6rem;

  padding-left: 4rem;
  padding-right: 3rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-left: 4rem;
  }
`;

const navcss1 = css`
  color: white;
  box-shadow: 1px 3px 6px #00000029;
  background-color: #00b4d8;
  text-decoration: none;
  border-radius: 2rem;
  padding: 0.6rem;
  pointer-events: none;
  margin-left: -18rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-left: -20rem;
  }
`;

const Nav2 = styled(NavLink)`
  ${navcss1}
`;

const Nav = styled(NavLink)`
  ${navcss}
`;
const ButtonRemove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  font-family: Poppins, Medium;
  font-weight: 600;
  outline: none;
  width: 2rem;
  height: 1rem;
  margin-top: 0.5rem;
  margin-left: 10px;
  color: white;
  background-color: #00b4d8;
`;
const ButtonRemovepopup = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  font-family: Poppins, Medium;
  font-weight: 600;
  outline: none;
  width: 2rem;
  height: 1.5rem;

  margin-left: auto;
  color: red;
`;
const Button2 = styled.button`
  font-family: Poppins, Medium;
  background-color: #00b4d8;
  color: white;
  font-size: 15px;
  border-radius: 50px;
  height: 30px;
  width: 5rem;
  margin-left: 2rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 10rem;
    height: max-height;
    margin: auto;
  }
`;
const Divpopup = styled.div`
  display: flex;

  jutify-content: center;
  align-items: center;
  flex-direction: column;

  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;

const CardContentpopup = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0.4rem;
  width: 30rem;
  padding: 1rem;

  height: max-content;
  border-radius: 10px;
  font-family: Poppins, Medium;
  background-color: #f2f4f5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    display: flex;

    jutify-content: center;
    flex-direction: column;
    margin: auto;
  }
`;

const Buttonpopup = styled.button`
  font-family: Poppins, Medium;
  background-color: #1dbf73;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  height: 54px;
  width: 18rem;
  border: none;
  margin-top: 2rem;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 12rem;
    height: max-height;
  }
`;
const InputFieldpopup = styled.input`
  margin-top: 1rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 20rem;
  height: 3.1rem;
  padding-left: 1.5rem;
  font-weight: 600;
  font-family: Poppins, Medium;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
  }
`;
const TitleTextpopup = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
