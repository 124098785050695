import React, { Component, useEffect, useState } from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import { login } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import PersonIcon from '@mui/icons-material/Person';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

import S3FileUpload from 'react-s3';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const area = [
  {
    value: 'Node', label: 'Node'
  },
  {
    value: 'Java', label: 'Java'
  },
  {
    value: 'React', label: 'React'
  },
  {
    value: 'Python', label: 'Python'
  },
  {
    value: 'AWS', label: 'AWS'
  },
  {
    value: 'C++', label: 'C++'
  }
]

//for country
const country = [
  {
    value: 'usa', label: 'USA'
  },
  {
    value: 'canada', label: 'Canada'
  },
  {
    value: 'india', label: 'India'
  }
]


const techStack = [
  {
    value: 'MEAN', label: 'MEAN'
  },
  {
    value: 'MERN', label: 'MERN'
  },
  {
    value: 'LAMP', label: 'LAMP'
  },
  {
    value: 'MEVN', label: 'MEVN'
  }
]
//new
const industry = [
  {
    value: ' Healthcare', label: ' Healthcare'
  },
  {
    value: 'IT & Software', label: 'IT & Software'
  },
  {
    value: ' Banking and Financial', label: ' Banking and Financial'
  },
  {
    value: 'Services', label: 'Services'
  }
]

const secondarea = [
  {
    value: 'Angular', label: 'Angular'
  },
  {
    value: 'Java', label: 'Java'
  },
  {
    value: 'React', label: 'React'
  },
  {
    value: 'Python', label: 'Python'
  },
  {
    value: 'AWS', label: 'AWS'
  },
  {
    value: 'C++', label: 'C++'
  }
]
const AddCandidate = () => {


  const token = useAuthToken();
  const intl = useIntl();


  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setState({ profileImg: reader.result })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };




  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [Uploading, setUploading] = useState(false)

  const [Image, setImage] = useState("");


  // console.log(user)

  const dispatch = useDispatch();

  const history = useHistory();

  var scrollTop = function () {
    window.scrollTo(0, 0);
  };

  const inputArr = [{
    degree: "",
    domain: "",
    institution: "",
    startDate: "",
    candidateID: user.data[0].c_id,
    endDate: ""
  }];

  const expArray = [{
    work_organization: "",
    work_position: "",
    workStartdate: "",
    workEnddate: "",
    present:0,
    candidateID: user.data[0].c_id,
  }];

  const certificationArray = [{
    cert_name: "",
    candidateID: user.data[0].c_id,
    startDate: "",
    endDate: "",
    certUrl: "",
    certificationInstitution: ""
  }];


  const [arrCert, setarrCert] = useState(certificationArray);

  const [arrexp, setArrexp] = useState(inputArr);
  const [Userexp, setUserexp] = useState(expArray);

  const addInput = () => {
    var joined = arrexp.concat(inputArr[0]);
   
    setArrexp(joined)
  };



  const addExp = () => {
    var joined = Userexp.concat(expArray[0]);
    setUserexp(joined)
  };


  const addCert = () => {
    var joined = arrCert.concat(certificationArray[0]);
    setarrCert(joined)
  };


  useEffect(() => {
    scrollTop();
    setUploading(false)



  }, [])


  const [State, setState] = useState({
    fname: user.data[0].firstName,
    github_repo_name :user.data[0].github_repo_name,
github_username:user.data[0].github_username,
jira_username:user.data[0].jira_username,
sonarqube_project_key:user.data[0].sonarqube_project_key,
    mname: user.data[0].middleName,
    lname: user.data[0].lastName,
    candidateemail: "",
    area: "",
    country: user.data[0].country,
    primaryskill: user.data[0].primaryskill,
    secondaryskill: user.data[0].secondaryskill,
    portfolio: user.data[0].portfolioUrl,
    linkedin: user.data[0].linkedinUrl,
    github: user.data[0].githubUrl,
    industry: user.data[0].industry,
    degree: user.data[0].degree,
    institution: user.data[0].institution,
    domain: user.data[0].domain,
    startingDate: user.data[0].startingDate,
    endingDate: user.data[0].endingDate,
    certification: user.data[0].certification,
    certificationIntitution: user.data[0].certificationInstitution,
    certificationUrl: user.data[0].certificationUrl,
    certification_startDate: user.data[0].certification_startDate,
    certification_endDate: user.data[0].certification_endDate,
    work_organization: user.data[0].work_organization,
    work_position: user.data[0].work_position,
    present: user.data[0].present,
    workStartdate: user.data[0].workStartdate,
    workEnddate: user.data[0].workEnddate,
    ratePerhour: user.data[0].ratePerhour,
    ratePermonth: user.data[0].ratePermonth,
    currency: user.data[0].currency,
    resetForm: "",
    about: user.data[0].about,
    workhours: "",
    resume: "",
    timezone: "",
    techStack: "",
    calendlyurl :"", 
    talent_code  :"", 
    availability: "",
    hoursperweek:'',
    profilePicture: "",
    region: "",
    profileImg:
      '/https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    errors: {
      blankfield: false,
      passwordmatch: false
    }
  })

  const clearErrorState = () => {
    setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });

  }


  const saveedd = () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {

      // https://si546iz1oe.execute-api.us-east-1.amazonaws.com/dev/addeducation
      const added = axios.post(process.env.REACT_APP_TALENT_ADDEDUCATION, { educationdata: arrexp, type: "EDU" });

     

    } catch (err) {
      alert(err);
    }



  }



  const saveExperience = () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {

      // https://si546iz1oe.execute-api.us-east-1.amazonaws.com/dev/addeducation
      const added = axios.post(process.env.REACT_APP_TALENT_ADDEDUCATION, { educationdata: Userexp, type: "EXP" },{headers:headers});

     
      

    } catch (err) {
      alert(err);
    }



  }



  const onFileUpload = e => {

    setUploading(true)

    const file = e.target.files[0];
    // console.log(file)
    S3FileUpload.uploadFile(file, config)
      .then(data => {

        setState((prevState) => {
          return { ...prevState, resume: data.key };
        });
        setUploading(false)
      })
      .catch(err => {
        console.log(err); setUploading(false)
      })

  }

  const onImageUpload = e => {

    const file = e.target.files[0];
    // console.log(file);
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    S3FileUpload.uploadFile(file, config)
      .then(data => {

        // console.log(data);


        setState((prevState) => {
          return { ...prevState, profilePicture: data.key };
        });
        setUploading(false)
      })
      .catch(err => {
        console.log(err); setUploading(false)
      })

    // setState((prevState) => {
    //   return { ...prevState, [e.target.id]: e.target.value };
    // });
  }



  const handleSubmit = async event => {
    event.preventDefault();
    // Form validation
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
    setUploading(true);

    const { fname, mname, lname, area, industry, country, techStack, calendlyurl, talent_code, about, linkedin, github, primaryskill, secondaryskill, workhours, chargePerhour, portfolio, institution, workEnddate, workStartdate, work_organization, present, work_position, certification, certificationIntitution, certificationUrl, certification_endDate, certification_startDate, degree, startingDate, endingDate, domain, resume, profilePicture, timezone, availability, hoursperweek, ratePerhour, ratePermonth, region, currency,github_repo_name,
  github_username, jira_username, sonarqube_project_key, } = State;

    const data = {
      id: user.data[0].c_id,
      firstname: fname, middlename: mname, lastname: lname, techStack: techStack, calendlyurl: calendlyurl, talent_code: talent_code, industry: industry, primaryskill: primaryskill, secondaryskill: secondaryskill, country: country, linkedinurl: linkedin, githuburl: github, workhours: workhours, chargePerhour: chargePerhour,
      portfoliourl: portfolio, institution: institution, about: about, workEnddate: workEnddate, workStartdate: workStartdate, work_organization: work_organization, present: present, work_position: work_position, certification: certification, certificationInstitution: certificationIntitution, certificationUrl: certificationUrl, certification_endDate: certification_endDate, certification_startDate: certification_startDate, degree: degree, startingDate: startingDate, endingDate: endingDate, domain: domain, profilePicture: profilePicture, resume: resume, timezone: timezone, availability: availability, hoursperweek: hoursperweek, ratePerhour: ratePerhour, ratePermonth: ratePermonth, region: region, currency: currency,
      github_repo_name :github_repo_name,
      github_username:github_username,
      jira_username:jira_username,
      sonarqube_project_key:sonarqube_project_key
    };

    try {
      await dispatch(updateProfilecandidate(data, headers));

      const promise1 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETEST, { educationdata: arrexp, type: "EDU" }, { headers: headers });
      const promise2 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETEST, { educationdata: Userexp, type: "EXP" }, { headers: headers });
      const promise3 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETEST, { educationdata: arrCert, type: "CERT" }, { headers: headers });

      await Promise.all([promise1, promise2, promise3]);

     
    } catch (error) {
      console.error("Error:", error);
      // Handle error if needed
      // You may also want to navigate to the candidate dashboard even if there's an error
        }
};



  const onInputChange = e => {
    console.log(e.target)
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }


  //   const onInputChangeEducation = e => {

  //     let inputs = arrexp.slice();

  //     for(let i in inputs){


  //       setArrexp(() => {
  //         return { ...inputs[i], [e.target.id]: e.target.value };
  //       });

  //     }
  // }

  const handleChange = (e, idx) => {
    let clone = [...arrexp];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    console.log('handlechage 33333 ; ', [...clone]);
    setArrexp([...clone])
  }

  const handleChangeExp = (e, idx, field) => {
    let clone = [...Userexp];
    let obj = clone[idx];
    if (field === 'present') {
      obj.present = e.target.checked ? 1 : 0;
      if (e.target.checked) {
        obj.workEnddate = ''; // Reset end date when present is checked
      }
    } else {
      obj[field] = e.target.value;
    }
    clone[idx] = obj;
    setUserexp(clone);
  }

  const handleChangeCert = (e, idx) => {
    let clone = [...arrCert];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setarrCert([...clone]);
  }



  const onInputChangeselect = e => {
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  console.log("daata", arrCert)
  console.log("daata1", expArray)
  console.log("daata2", inputArr)

  const { profileImg } = State
  return (

    <div className="container" style={{ "margin-top": "140px" }} id="main">

      <p style={{ fontWeight: "bold", fontSize: "26px" }}>{intl.formatMessage({ id: 'createProfileOpportunity' })}</p>


      <br></br>
      <br></br>
      <div className="row">

        <div className="col-lg-3" ></div>

        <div className="col-lg-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px" }}>


          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h1 className="headsmall">{intl.formatMessage({id:"createProfile"})}</h1>
          <br></br>

          <form onSubmit={handleSubmit}>
            {/* For First Name */}


            <center>

              {Image == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height: "100px", borderRadius: "50px", textAlign: "center" }}>

                <PersonIcon style={{ color: "#8EAEBC", marginLeft: "35px"}} />
                <input type="file" className="profileclass" onChange={onImageUpload} id="profilePicture" />


              </div>) : (<img src={Image} alt='' style={{ width: '100px', height: '100px', marginTop: "50px", borderRadius: "50px" }} id="profilePicture" ></img>)}

            </center>
            <br></br>

            <p style={{ color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "170px" }} >{intl.formatMessage({ id: 'basicInformation' })}</p>



            <div className="email">
              <label  className="Label">{intl.formatMessage({ id: 'firstName' })} : </label>
              <input
                className="input2"
                placeholder="Enter First Name*"
                type="text"
                id="fname"
                value={State.fname} onChange={onInputChange}
                required
              
              />
            </div>



            <div className="email">
            <label className="Label">{intl.formatMessage({ id: 'middleName' })} : </label>
              <input
                className="input2"
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={State.mname}
                onChange={onInputChange}
              
              />
            </div>

            <div className="email">
            <label className="Label">{intl.formatMessage({ id: 'lastName' })} : </label>
              <input
                className="input2"
                placeholder="Enter Last Name*"
                type="text"
                id="lname"
                value={State.lname}
                onChange={onInputChange}
                required
             
              />
            </div>

            {/* For Middle Name */}


            {/* For Email */}


            <div className="email" >
            <label  className="Label">{intl.formatMessage({ id: 'country' })}  : </label>
              <select
      //value={area}
      className="input2" 
      name="country"
      placeholder="What's your country"
      onChange={onInputChangeselect}       
      value={State.country}     
  
      required
      >
                <option  style={{color:'black'}} value='' defaultValue >Country</option>


{country.map((data) => (
<option> {data.value} </option>
))}

</select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'about' })} : </label>
            
              <textarea
                //value={area}
                type="text"
                className="about2"
                id="about"
                placeholder="About"
                onChange={onInputChange}
                value={State.about}
                
                required
              />
            </div>
            <br></br>
            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "160px" }} >Skills & Expertise</p>

            {/* For Primary Expertise */}
            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'primarySkill' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={area}
                className="input2"
                name="primaryskill"
                placeholder="Primary Expertise"
                isMulti="true"
                value={State.primaryskill}
                list="skill"
                required
               
              >

<option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Threejs</option>
<option  value='Tableau' style={{color:'black'}} >Tableau</option>
<option  value='Python' style={{color:'black'}} >Python</option>

<option  value='Quality Assurance' style={{color:'black'}} >Quality Assurance</option>
<option  value='IOS Development (Swift/Objective C)' style={{color:'black'}} >IOS Development (Swift/Objective C)</option>
<option  value='Android Development (Java/Kotlin)' style={{color:'black'}} >Android Development (Java/Kotlin)</option>
<option  value='Devops' style={{color:'black'}} >Devops</option>
<option  value='Cloud (AWS/Azure/GCP)' style={{color:'black'}} >Cloud (AWS/Azure/GCP)</option>



              </select>
            </div>
            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'secondarySkill' })}  : </label>
              <select
                onChange={onInputChangeselect}
                options={secondarea}
                className="input2"
                name="secondaryskill"
                placeholder="Secondary Expertise"
                value={State.secondaryskill}
                list="sskill"
               

                
              >
              <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>

              </select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'techStack' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={techStack}
                className="input2"
                name="techStack"
                placeholder="TechStack"
                value={State.techStack}
                list="sskill"
               

                
              >
                <option value='' defaultValue style={{ color: 'black' }} >techStack</option>

                <option value='MEAN'>MEAN</option>
                        <option value='MERN'>MERN</option>
                        <option value='LAMP'>LAMP</option>
                        <option value='MEVN'>MEVN</option>

              </select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'industry' })} : </label>
              <select
                //value={area}
                onChange={onInputChangeselect}
                options={industry}
                className="input2"
                name="industry"
                id="industry"
                placeholder="Industry"
                value={State.industry}
               
                
              >
                <option value='' defaultValue style={{ color: 'black' }} >Industry</option>


                {industry.map((data) => (

                  <option id="industry"> {data.value} </option>
                ))}
              </select>
            </div>
            <br></br>
            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

            <p style={{ marginLeft: "-60px", color: "#8EAEBC", fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

            <button onClick={addInput} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button>

     

            {arrexp.length > 0 ?

              arrexp.map((data, i) => {

                return <>

                  {/* Other Expertise */}
                  <div className="email">
                  <label  className="Label">{intl.formatMessage({ id: 'qualification' })} : </label>
                    <select
                      className="input2"
                      name="degree"
                      id="degree"
                      placeholder="Qualification"
                    
                      value={data[i]?.degree}
                      onChange={(e) =>
                        handleChange(e, i)}
                      required
                    >
                      <option value='' defaultValue style={{ color: 'black' }} >Qualification</option>
                      <option value='Bachelors' style={{ color: 'black' }} >Bachelors</option>
                      <option value='Masters' style={{ color: 'black' }} >Masters</option>
                      <option value='PHD' style={{ color: 'black' }} >PHD</option>

                    </select>

                  </div>
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'specialization' })} : </label>
                    <input
                      className="input2"
                      placeholder="Specialization"
                      type="text"
                  
                      value={data[i]?.domain}
                      onChange={(e) =>
                        handleChange(e, i)} id="domain"
                      
                    />
                  </div>
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'university' })}   : </label>
                    <input
                      className="input2"
                      placeholder="University"
                      type="text"
                      value={data[i]?.institution}
                      onChange={(e) =>
                        handleChange(e, i)}
                      id="institution"
                   
                      required
                    />
                  </div>




                  {/* Portfolio */}
                  <div className="date" >

                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })} </label>

                      <input
                        className="input3"
                        placeholder="Start date"
                        type="date"
                        value={data[i]?.startDate}
                        onChange={(e) =>
                          handleChange(e, i)}
                        id="startDate"
                        required
                      />
                    </div>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })}</label>

                      <input
                        className="input3"
                        placeholder="End date"
                        type="date"
                        value={data[i]?.endDate}
                        onChange={(e) =>
                          handleChange(e, i)}
                        id="endDate"
                        required
                      />
                    </div>
                  </div>
                  <br></br>
                </>
              }) : (<></>)}

            {/* <a className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" onClick={saveedd}>Save</a>   */}


            <p className="label2" style={{ marginLeft: "-60px", color: "#8EAEBC", fontSize: "18px", width: "229px" }}>Certifications</p>



            <button onClick={addCert} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button>

            <br></br>


            {arrCert.length > 0 ?

              arrCert.map((data, i) => {

                return <>

                  <div className="password" >
                  <label  className="Label">{intl.formatMessage({ id: 'certifications' })} : </label>
                    <input
                      className="input2"
                      placeholder="Certification"
                      type="text"
                      value={data[i]?.cert_name}
                      onChange={(e) =>
                        handleChangeCert(e, i)}
                      id="cert_name"
                  
                    />
                  </div>
                  <div className="password" >
                  <label  className="Label">{intl.formatMessage({ id: 'institute' })} : </label>
                    <input
                      className="input2"
                      placeholder="Institute"
                      type="text"
                      value={data[i]?.certificationInstitution}
                      onChange={(e) =>
                        handleChangeCert(e, i)}
                      id="certificationInstitution"
                   
                    />
                  </div>

                  {/* LinkedIn */}
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'certificationUrl' })} : </label>
                    <input
                      className="input2"
                      placeholder="Certification Url"
                      type="url"
                      value={data[i]?.certUrl}
                      onChange={(e) =>
                        handleChangeCert(e, i)}
                      id="certUrl"
                     
                    />
                  </div>

                  <div className="date" style={{ display: "flex" }}>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validFrom' })}</label>

                      <input
                        className="input3"
                        placeholder="Valid from"
                        type="date"
                        value={data[i]?.startDate}
                        onChange={(e) =>
                          handleChangeCert(e, i)}
                        id="startDate"
                      />
                    </div>
                    <div className="password" style={{ marginLeft: "20px" }}>
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validTill' })}l</label>

                      <input
                        className="input3"
                        placeholder="Valid till"
                        type="date"
                        value={data[i]?.endDate}
                        onChange={(e) =>
                          handleChangeCert(e, i)}
                        id="endDate"
                      />
                    </div>
                  </div>

                </>
              }) : (<></>)}


            <br></br>



            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "162px" }}>{intl.formatMessage({ id: 'workExperience' })}</p>

            <button onClick={addExp} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button>

            {Userexp.length > 0 ?

              Userexp.map((data, i) => {

                return <><div className="email" >
                  <label  className="Label">{intl.formatMessage({ id: 'companyName' })} : </label>
                  <input
                    className="input2"
                    placeholder="Company"
                    type="text"
                    value={data[i]?.work_organization}
                    onChange={(e) =>
                      handleChangeExp(e, i)}
                    id="work_organization"
                    required
                   
                  />
                </div>

                  {/* GitHub */}
                  <div className="password">
                  <label   className="Label">{intl.formatMessage({ id: 'positionName' })} : </label>
                    <input
                      className="input2"
                      placeholder="Position"
                      type="text"
                      value={data[i]?.work_position}
                      onChange={(e) =>
                        handleChangeExp(e, i)}
                      id="work_position"
                      required
                   
                    />
                  </div>


                  <div className="date" style={{ display: "flex" }}>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })}</label>

                      <input
                        className="input3"
                        placeholder="Start date"
                        type="date"
                        value={data[i]?.workStartdate}
                        onChange={(e) =>
                          handleChangeExp(e, i)}
                        id="workStartdate"
                        required
                      />
                    </div>
                    {data.present !== 1 && (
                    <div className="password" style={{ marginLeft: "20px" }}>
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })}</label>
                      
                      <input
                        className="input3"
                        placeholder="End date"
                        type="date"
                        value={data[i]?.workEnddate}
                        onChange={(e) =>
                          handleChangeExp(e, i)}
                        id="workEnddate"
                        required

                      />
                    </div>)}
                    <div className="checkbox" style={{ marginTop: "10px" }}>
  <label style={{ color: "grey", fontWeight: "bold" }}>
    <input
      type="checkbox"
      checked={data.present === 1}
      onChange={(e) => handleChangeExp(e, i, 'present')}
    />
    &nbsp;{intl.formatMessage({ id: 'present' })}
  </label>
</div>
                  </div>
                </>
              }) : (<></>)}

            {/* <a className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" onClick={saveExperience}>Save</a>   */}





            <br></br>
            <br></br>
            <br></br>
            <p style={{ marginRight: "321px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "170px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>

            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'portfolioUrl' })} : </label>
              <input
                className="input2"
                placeholder="Portfolio URL"
                type="url"
                value={State.portfolio}
                onChange={onInputChange}
                id="portfolio"
               

              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'linkedInUrl' })} : </label>
              <input
                className="input2"
                placeholder="LinkedIn Profile URL"
                type="url"
                value={State.linkedin}
                onChange={onInputChange}
                id="linkedin"
               
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'githubUrl' })} : </label>
              <input
                className="input2"
                placeholder="Github URL"
                type="url"
                value={State.github}
                onChange={onInputChange}
                id="github"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_repo_name' })} : </label>
              <input
                className="input2"
                placeholder="Github Repo Name"
                type="text"
                value={State.github_repo_name}
                onChange={onInputChange}
                id="github_repo_name"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_username' })} : </label>
              <input
                className="input2"
                placeholder="Github User Name"
                type="text"
                value={State.github_username}
                onChange={onInputChange}
                id="github_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'jira_username' })} : </label>
              <input
                className="input2"
                placeholder="Jira User Name"
                type="text"
                value={State.jira_username}
                onChange={onInputChange}
                id="jira_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'sonarqube_project_key' })} : </label>
              <input
                className="input2"
                placeholder="Sonarqube Projectkey"
                type="password"
                value={State.sonarqube_project_key}
                onChange={onInputChange}
                id="sonarqube_project_key"
                
              />
            </div>
            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'prefferedWorkHours' })} : </label>
              <select
                //value={area}
                onChange={onInputChangeselect}
                className="input2"
                name="workhours"
                placeholder="Preffered work hours"
                value={State.workhours}
                list="workhours"
                id="workhours"
               
                required
              >
                <option style={{ color: 'black' }} value="">Preffered work hours</option>

                <option>Morning Shift, 9am - 6pm </option>
                <option>Night Shift, 10pm - 7am </option>

              </select>

            </div>

            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'ratePerHour' })} : </label>
              <input
                className="input2"
                placeholder="Rate /hr"
                type="text"
                value={State.ratePerhour}
                onChange={onInputChange}
                id="ratePerhour"
               
               
              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'ratePerMonth' })} : </label>
              <input
                className="input2"
                placeholder="Rate /month"
                type="text"
                value={State.ratePermonth}
                onChange={onInputChange}
                id="ratePermonth"
                
              
              />
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'currency' })} : </label>
              <select
                onChange={onInputChangeselect}
                className="input2"
                name="currency"
                id="currency"
                placeholder="Currency"
                isMulti="true"
                value={State.currency}
                list="skill"
               
              >

                <option value="">Currency</option>
                <option style={{ color: 'black' }} >USD</option>
                <option style={{ color: 'black' }} >INR</option>
                <option style={{ color: 'black' }} >CRC</option>
                <option style={{ color: 'black' }} >Vietnamese Dong</option>
                <option style={{ color: 'black' }} >Colombian Pesos</option>

              </select>
            </div>


            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'availability' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={area}
                className="input2"
                name="availability"
                id="availability"
                placeholder="Availability"
                isMulti="true"
                value={State.availability}
                list="skill"
                
                required
              >

                <option style={{ color: 'black' }} value="">Availability</option>
                <option style={{ color: 'black' }} value='full time'>Full Time</option>
                <option style={{ color: 'black' }}  value='part time'>Part Time</option>


              </select>
              { State.availability == "part time" ? (<>

<div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'hoursPerWeek' })}</label>

<input
  className="input2"
  placeholder="Available date"
  type="num"
  value={State.hoursperweek}
  onChange={onInputChange}
  id="hoursperweek"
  required
/>
</div>


</>) : (<></>)}
              
            </div>

            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'timeZone' })} : </label>

              <select id='timezone' className="input2" name="timezone" onChange={onInputChangeselect} placeholder='Timezone' 
       value={State.timezone} required>
                <option style={{ color: 'black' }} value="">Time Zone</option>
                <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>
                </select>

            </div>
            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'region' })} : </label>
              <select id='region' className="input2" name="region" onChange={onInputChangeselect} placeholder='Region'  
  value={State.region} required>
                <option style={{ color: 'black' }} value="">Region</option>
                <option style={{ color: 'black' }} value='Asia Pacific'>Asia Pacific</option>
                <option style={{ color: 'black' }} value='Latin America'>Latin America</option>
                <option style={{ color: 'black' }} value='North America'>North America</option>
              </select>

            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'talentCode' })} : </label>
              <input
                className="input2"
                placeholder="Enter your Talent Code"
                type="text"
                 value={State.talent_code}
                onChange={onInputChange}
                id="talent_code"
                
              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'calendlyUrl' })} : </label>
              <input
                className="input2"
                placeholder="Enter calendlyurl"
                type="url"
                 value={State.calendlyurl}
                onChange={onInputChange}
                id="calendlyurl"
            
              />
            </div>

            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'resume' })} : </label>
              <input
                className="input2"
                placeholder="Upload Resume"
                accept=".doc,.docx,.pdf"

                type="file"
                onChange={onFileUpload}
                id="resume"
                required
             
              />
            </div>

            <div className="createAccount">
              {Uploading ? (<> <button className="button is-success" style={{ width: "50%", borderRadius: "10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" disabled>{intl.formatMessage({ id: 'creatingProfile' })}...</button></>) : (<> <button className="button is-success" style={{ width: "50%", borderRadius: "10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" >Create</button></>)}
            </div>

          </form>

        </div>

        <div className="col-lg-3" ></div>


      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>


  );
}

export default AddCandidate;
