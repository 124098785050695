import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const SprintVelocitiesChart = ({ initialPodIds }) => {
  const [sprintVelocities, setSprintVelocities] = useState([]);
  const[data,setData]=useState([])
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(async () => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    if (user.data[0].type === "stakeholder") {
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        const payload = {};
    
        // Check if pod_id is available, send only pod_id
       
          payload.pod_id = initialPodIds || [];
        
          // If pod_id is not available, check if org_code is available, send only org_code
          payload.org_code = user.data[0].client_code;
        
        const response = await axios.post(
          process.env.REACT_APP_STAKEHOLDER_SPRINTVELMETRICSSTAKEHOLDER,
         payload,
          { headers: headers }
        );
        const responseData = response.data.body;
        const transformedData = responseData.replace(/NaN/g, 'null');
        const data = JSON.parse(transformedData);
  
        const velocities = data.map((item) => {
          const velocity = parseFloat(item.sprint_velocities);
          return isNaN(velocity) ? null : velocity;
        });
        setSprintVelocities(velocities);
        setData(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      // If user type is not stakeholder, fetch data using the existing logic
      if (initialPodIds && initialPodIds.length > 0) {
        fetchDataPod(initialPodIds);
      } else {
        fetchData();
      }
    }
  }, [token, user.data[0].type, initialPodIds]);
  

  const fetchDataPod = async (selectedPodIds) => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_SPRINTVELOCITYMANAGERPODID,
        { pod_id: selectedPodIds || null },
        { headers: headers }
      );
      const responseData = response.data.body;
      const transformedData = responseData.replace(/NaN/g, 'null');
      const data = JSON.parse(transformedData);

      const velocities = data.map((item) => {
        const velocity = parseFloat(item.sprint_velocities);
        return isNaN(velocity) ? null : velocity;
      });
      setSprintVelocities(velocities);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_SPRINTVELOCITY,
        { manager_id: user.data[0].emp_id, org_code: user.data[0].client_code },
        { headers: headers }
      );
      const responseData = response.data.body;
      const transformedData = responseData.replace(/NaN/g, 'null');
      const data = JSON.parse(transformedData);

      const velocities = data.map((item) => {
        const velocity = parseFloat(item.sprint_velocities);
        return isNaN(velocity) ? null : velocity;
      });
      setSprintVelocities(velocities);
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Filter out null or NaN values from sprintVelocities
  const filteredSprintVelocities = sprintVelocities.filter((velocity) => velocity !== null);

  // Extracting relevant information for chart
  const sprintNames = data.map((item) => item.sprint_name);

  // Example colors, you can customize these
  const colors = [
    'rgba(75, 192, 192, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    // Add more colors as needed
  ];

  // Calculate the number of datasets based on the actual data
  const numberOfDatasets = sprintNames.length;

  const datasets = Array.from({ length: numberOfDatasets }, (_, index) => ({
    label: sprintNames[index],
    data: sprintVelocities.map((velocity, velocityIndex) => (velocityIndex === index ? velocity : null)),
    backgroundColor: colors[index % colors.length],
    borderColor: colors[index % colors.length],
    borderWidth: 1,
  }));
  

  const chartData = {
    labels: sprintNames,
    datasets: datasets,
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Sprint',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: 'Velocity',
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          height: '30rem',
          width: '20rem',
        }}
      >
        <div className="chart-wrapper" style={{ height: '25rem',
          width: '25rem',}}>
          <h2 className="chart-heading">{intl.formatMessage({ id: 'sprintVelocity' })}</h2>
          <br/>
          <br/>
          {filteredSprintVelocities.length > 0 ? (
            <Bar data={chartData} options={chartOptions} />
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}...</p>
          )}
        </div>
      </div>
    </div>
  );
};


SprintVelocitiesChart.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default SprintVelocitiesChart;
