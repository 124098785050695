import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";

const EditTalentSettingsForm = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  const [formData, setFormData] = useState({
    id: user.data[0].c_id,
    github_repo_name: '',
    github_username: '',
    jira_username: '',
    sonarqube_project_key: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          };
        const response = await axios.post(process.env.REACT_APP_TALENT_GETALENTSETTING, {
          candidateID: user.data[0].c_id},{headers:headers}
        );
        const data = response.data.body.data[0];
        setFormData({
          id: user.data[0].c_id,
          github_repo_name: data.github_repo_name || '',
          github_username: data.github_username || '',
          jira_username: data.jira_username || '',
          sonarqube_project_key: data.sonarqube_project_key || ''
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token,user.data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization' : token,
    };
    try {
      const response = await axios.put(process.env.REACT_APP_TALENT_EDITTALENTSETTINGS, formData, { headers });
      console.log('Response:', response.data);
      alert('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('Failed to update settings.');
    }
  };

  return (
    <Div>
      <form onSubmit={handleSubmit}>
        <TitleText>Settings</TitleText>
        <RecommendProject>
          <CardContent>
            <Postfield>
              <Label>GitHub Repo Name:</Label>
              <Input
                type="text"
                name="github_repo_name"
                value={formData.github_repo_name}
                onChange={handleChange}
                required
              />
            </Postfield>
            <Postfield>
              <Label>GitHub Username:</Label>
              <Input
                type="text"
                name="github_username"
                value={formData.github_username}
                onChange={handleChange}
                required
              />
            </Postfield>
            <Postfield>
              <Label>Jira Username:</Label>
              <Input
                type="text"
                name="jira_username"
                value={formData.jira_username}
                onChange={handleChange}
                required
              />
            </Postfield>
            <Postfield>
              <Label>SonarQube Project Key:</Label>
              <Input
                type="text"
                name="sonarqube_project_key"
                value={formData.sonarqube_project_key}
                onChange={handleChange}
                required
              />
            </Postfield>
            <Button type="submit">Submit</Button>
          </CardContent>
        </RecommendProject>
      </form>
    </Div>
  );
};

export default EditTalentSettingsForm;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
  font-family: Poppins, Medium;
  background-color: #1DBF73;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  height: 54px;
  width: 22rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 12rem;
    height: max-height;
  }
`;

const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;

const Div = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;

const RecommendProject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 100%;
    height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.4rem;
  width: 40rem;
  padding: 1rem;
  height: max-height;
  border-radius: 15px;
  font-family: Poppins, Medium;
  background-color: #F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin: auto;
  }
`;

const Postfield = styled.div`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const Label = styled.label`
  font-family: Poppins, Medium;
  font-weight: bold;
  font-size: 16px;
  color: black;
  width: 15rem;
`;

const Input = styled.input`
  font-family: Poppins, Medium;
  width: 22rem;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #B7B7B7;
  padding-left: 25px;
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 12rem;
    height: max-height;
  }
`;
