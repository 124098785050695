import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import axios from 'axios';


const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const myBucket = new AWS.S3(S3Config);



console.log("Hello")

function Recomendedproject() {


  const [CSVData, setCSVData] = useState([]);



  async function csvToJSON() {
    // get csv file and create stream

    let data = {
      "Bucket Name":"recomnd",
      "csv_fileName":"NewJobRecommendation2.csv",
      "Primary_Skill":"HTML,CSS",
      "Secondary_Skill":"PHP",
      "Time_Zone":"Indian Standrad Time"
      }

     const res = await axios.post("http://ec2-54-234-68-194.compute-1.amazonaws.com:8003/Skill", data);

    
      const params = {
        Bucket: 'recomnd',
        Key: res.data.matchFileUrl
      };
    
    

      myBucket.getSignedUrl("getObject", params, async (err, data) => {

     
        var commonConfig = { delimiter: "," };
  
        Papa.parse(data,
          {
            ...commonConfig,
            header: true,
            download: true,
            complete: (result) => {
              setCSVData(result.data);
            }
          }
        )
  
  
      });
  




  };





  useEffect(() => {
    csvToJSON();
  }, [])


  

  console.log(CSVData)


  return (
     <Browsecontainer>
  <div  >
    <Heading>
        <h3 style={{ fontSize: '1.625rem' ,fontFamily:"Poppins,Medium",fontWeight:'600' ,marginLeft:'5rem'}}>Recommended Projects</h3>
        </Heading>
        <br></br>
      <div  >  <Para> <p style={{ color: '#A2A2A2', fontFamily:'Poppins,Medium',fontSize: ' 1.125rem' }}>See your list of Recommended Project!</p>
      </Para>
      </div>
   
    <div>
    <Pega>
      <Pagination1>
        <div className='paginationcontent'>
  
<ol className='ol'>
          {
                    CSVData.length > 0 ?
                    CSVData.map((dev,index )=>{
                        return <li className='smg' key={index}><div  className='name'>  <p className='firstname'><a >React Developer</a></p> </div>
                          <div><p  className='name1'>{dev.Budget}</p></div><div className='skill'> <p className='about'>             Looking for creative designer and talented front-end & back-end developers to create branding materials for my new startup.
</p></div><div className='time'>
                          <p className='role'>{dev.Avablity}</p></div>
             </li>
                      }
                      )
                      : 'No data'
                  }
</ol>
        </div>
        </Pagination1>
        </Pega>
    </div>
  </div>
</Browsecontainer>
)

}

const Browsecontainer = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:100%;
display:flex;
align-items:center;
 justify-content:center;
flex-direction:column;
}
`;
const Main = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
margin:auto;
width:100%;
}
`;
const Table=styled.table`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
 justify-content:center;
flex-direction:column;
margin:auto;
width:20rem;
height:max-content;
}
`;
const Heading = styled.div`
margin-top: 9rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:70%;
margin-top:10rem;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Para =styled.p`
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:20rem;
margin:auto;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Singlecontainer = styled.tr`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
margin:auto;
width:20rem;

}
`;
const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;
const Input = styled.input`
@media screen and (min-width:320px) and (max-width:1080px){
border:none;
width:100%;
height:3rem;
align-items:center;
// display:flex;
// flex-direction:row;
}
`;
const SearchTag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
width:20rem;
margin:auto;
border-bottom:1px solid #A2A2A2;
}
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  margin:auto;
 width:20rem;
  border-bottom:1px solid #A2A2A2;
}
`;
const Clear = styled.p`
margin-top:20px;
width:6rem;
margin-left:31rem;
cursor: pointer;
font-weight:600;
font-size: 18px;
font-family:Poppins,Regular;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
 flex-direction:column;
 margin-left:auto;
}
`;
const Search = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
width:100%;
align-items:center;
 flex-direction:column;
 margin-bottom:20px;
}
`;
const Row1 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Row2 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Button = styled.button`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
 flex-direction:column;
}
`;
const Pagination1 = styled.div`
height: max-content;
margin-left: 5rem;
  width:83.5rem;
 
  background-color: white;

margin-top: 3rem;
 border:1px solid #EFEFEF;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:20rem;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
margin-top:3rem;

}
`;
const Pega = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
width:100%;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
}
`;
const Numbers = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
justify-content:center;
width:7rem;
}
`;
const Num = styled.ol`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
//   align-items:center;
// justify-content:center;
flex-direction:column;
marginLeft:-3rem;
width:70%;
}
`;
const Num1 = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  // display:flex;
  margin:auto;
width:100%;
}
`;
const Num2 = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  flex-direction:column;
  align-items:center;
justify-content:center;
width:100%;
}
`;
const Col = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: row;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;

export default Recomendedproject