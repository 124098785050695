import styled from 'styled-components';
import React  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useState } from 'react';
import ShowMore from 'react-show-more-button/dist/components/ShowMore';
import { NavHashLink } from 'react-router-hash-link';
// import PostedProReq from './PostedProReq';
import ViewPostedProReq from './ViewPostedProReq';
import { useDispatch, useSelector } from "react-redux";
import allergens from './allergens';
import OptionsOutsideSelect from '../OptionsOutsideSelect';
import Select from "react-select";
import PostReqPodCreatedSuccessfully from './PostReqPodCreatedSuccessfully';
import{FiSearch} from 'react-icons/fi';

import { useEffect } from "react";
import { useAuthToken } from "../TokenContext";
import axios from "axios";
const PostRequirementCand = () => {

  const token = useAuthToken();



const [position,setPosition]=useState("");
const [budget,setBudget]=useState("");
const [workhours,setWorkhours]=useState("");
const [timezone,setTimezone]=useState("");
const [getpoddetails, setGetpoddetails] = useState({});

  const [selected, setSelected] = useState([]);
  const [skills,setSkills]=useState([]);
  const { isLoggedIn, user } = useSelector(state => state.auth);

 
const [requirementdata,setRequirementData] = useState({
    
    position : "",
    skills :  [
      {
          "skill": ""
      }
  ], 
budget : " ",
workhours : "",
timezone : "",
candidateID : user.data[0].c_id,
    
   } );

   const handleSubmit = (e) => {
   e.preventDefault();

    var abc = {
    
      position : position,
      skills :  skills, 
      timezone :  timezone, 
  budget : budget,
  workhours : workhours,
  candidateID  : user.data[0].c_id,
  podID : getpoddetails.id
      
     }

    //  https://fb8bt2e9c5.execute-api.us-east-1.amazonaws.com/dev/candidate-postreq
    fetch(
     process.env.REACT_APP_TALENT_CANDIDATEPOSTREQ,
      {
        method: "POST",
         headers: {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        },
        body: JSON.stringify(abc),
      }
    ).then((data) => {
      if(data.status == 200){
        alert("Posted Requirement Successfully.")
         window.location.href = "/ViewPostedProReq"
        console.log('postreq',abc);
      }
    });
  };

  const onInputChange = (e) => {
setPosition(e.target.value);

    // console.log(e.target);
    // setRequirementData((prevState) => {
    //   return { ...prevState, [e.target.id]: e.target.value };
    // });
  };

  const onInputChangeworkhours =(e)=>{
    setWorkhours(e.target.value);
  }
  
  const onInputChangetimezone =(e)=>{
    setTimezone(e.target.value);
  }

  const onInputChangebudget = (e) => {
    setBudget(e.target.value);
    // console.log(e.target);
    // setRequirementData((prevState) => {
    //   return { ...prevState, budget: e.target.value };
    // });
  };

 
  const handleSelectChange = (values) => {


    setSelected(values);
    var testskill= [];
    
values.map(function(ext) {
  var c= {
    'skill':ext.label
  };
testskill.push(c)
})

setSkills(testskill);

    
  };

  const colourStyles = {
    control: (styles) => ({ ...styles,height:'50px',fontWeight:'600', paddingLeft:"12px"
  })
    
  };
  
  useEffect((token) => {


    const body = {
      "candidateID": user.data[0].c_id
    };
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };

    // https://xujexobo25.execute-api.us-east-1.amazonaws.com/dev/getcandidatepods
    axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEPODS, body, {headers:headers})

      .then((res) => {
      
        setGetpoddetails(res.data.data.podDetails)

      }


      )
      .catch((err) => {
        console.log("catch", err);
      })


  }, [token]);



  return (

   
    <Div >

      

<TitleText>Post Requirement</TitleText>
{/* <InputContainer>
          
          <Icon>
              <FiSearch/>
              </Icon>
              <Input type="text" placeholder="Search for projects" />
              </InputContainer> */}
<RecommendProject>

<form  onSubmit={handleSubmit}>

<CardContent>
<Postfield>
<Label>Position :</Label>
<InputField  placeholder='Required Position'
type='text'
id="position"
onChange={onInputChange}

required
/>

</Postfield>

<Postfield>
<Label>Skills :</Label>
                     <OptionsOutsideSelect
                     onChange={handleSelectChange}
                     isMulti
                     options={allergens}
                   placeholder='Skills'
                     value={selected}
                     styles={colourStyles}
                    //  value={requirementdata.skills}
                  

                   />
                 
                 </Postfield>
                 <Postfield>
                 <Label>Budget Range:</Label>
<SelectField 



onChange={onInputChangebudget}

required
placeholder="Budget Range"
>
  <option style={{ color: "black" }} value="" defaultValue>
    Budget Range
  </option>
  <option  value= '100k - 115k' style={{ color: "black" }}>100k - 115k</option>
  <option value='115k - 150k' style={{ color: "black" }}>115k - 150k</option>
 


    </SelectField>
    </Postfield>
    <Postfield>
    <Label>Preferred Work Hours:</Label>
    <SelectField 
    
  
id="workhours"
onChange={onInputChangeworkhours}

required
    >
    <option   value="" defaultValue >Preferred Work Hours</option>
    <option>Full Time</option>
    <option>Part Time</option>
    </SelectField>

    </Postfield>

    <Postfield>
    <Label>Time Zone :</Label>
    <SelectField 



onChange={onInputChangetimezone}

required
placeholder="timezone"
>
  <option style={{ color: "black" }} value="" defaultValue>
    Time Zone
  </option>
  <option style={{ color: "black" }} value='Indian Standard time' >Indian Standard time</option>
                    <option style={{ color: "black" }} value='Eastern standard time' >Eastern standard time</option>
                    <option style={{ color: "black" }} value='Central standard time'>Central standard time</option>
                    <option style={{ color: "black" }} value='Mountain standard time '>Mountain standard time </option>
                    <option style={{ color: "black" }} value='Pacific standard time'>Pacific standard time</option>


    </SelectField>

    </Postfield>
{/* <NavHashLink to = '/PostReqPodCreatedSuccessfully'> */}
<Button type='submit'>Post</Button>

{/* </NavHashLink> */}


</CardContent>
</form>

<SideCard>
{/* <H1>Recommended Pods</H1> */}
  {/* <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
          <Badge content="Design/" />

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent>
         <ViewAll>View All</ViewAll> */}

          
  <SideCardContent1>


          <Title>
          Your Posted Pod Requirements
            
          </Title>
          <View>
            
       <NavHashLink to = '/ViewPostedProReq'  smooth>
       
            View</NavHashLink> 
            
             </View>

          </SideCardContent1>
</SideCard>


</RecommendProject>

     </Div>
  )
}


const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;

const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
    width:80%;
    height:max-content;
    margin:0.4rem;
    
   }
`;


const TitleText=styled.h4`
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  margin-top:2rem;
 
}
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin-top:2rem;
 
  
 }
`;





const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const SelectField=styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;

const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:15rem;
}
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){

margin:auto;
margin-top:3rem;
  width:20rem;
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;

`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;

    width: 20rem;
  
 
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 20rem;
}
`;

const Label =styled.label`
font-family:Poppins,Medium;
font-weight: 600;
font-size:16px;
color :black;
width:10rem;
`;

const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;

export default PostRequirementCand