import styled from "styled-components";
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import { data, error, getJSON } from "jquery";
import { Route } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
// import EditPostRequirementCand from '../EditPostRequirementCand';
import { useAuthToken } from "../TokenContext";
const ViewPostedProReq = () => {
  const token = useAuthToken();

    const { isLoggedIn, user } = useSelector(state => state.auth);
    
    const[getdata,setGetData]=useState(
      
      []
  );

    
//         "candidateID": user.data[0].c_id
// position : "",
//     skills :  [
//       {
//           "skill": "Java"
//       }
//   ], 
// budget : " ",
// workhours : "",
    
//    "candidateID": user.data[0].c_id 

// });

    
// });
// fetch(
//     " https://qr8s86jsl7.execute-api.us-east-1.amazonaws.com/prod/getpostreq",
     
//      {
       
        
//        method: "GET",
     
//        body: JSON.stringify(getdata),
//      }
//    ).then(() => {
//      console.log();
//    });







     

   useEffect(() =>{

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
const body={candidateID: user.data[0].c_id} ;
   
// https://m7extvatu1.execute-api.us-east-1.amazonaws.com/dev/getpostreqdata
    axios.post(process.env.REACT_APP_TALENT_GETPOSTREQUIREMENTDATA,body,{headers:headers})

      .then((res)=>
      { 
        console.log("tulasi",res)
      setGetData(res.data.data);
      console.log("this is get data",getdata);
     
    }
    
      
   
        
      )
      .catch((err)=>{
        console.log("catch",err);
      })

  
   },[token]);
  

   
   
    
  
   const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  

  }


    
//   console.log(rep);

 

  return (
    <Div>
      <TitleText>Your Posted Pod Requirements</TitleText>
      <RecommendProject>
      <CardContent>
      {
        getdata.length > 0 ?
  getdata.map((item)=> 
   
 
      <Divseperator >
  
        

 
          <Row>
            <Detail>
              <InfoContainer>


  


 
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>{item.firstName}</InfoName>
                  <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
          {/* <Badge content="Design/" /> */}

          <Title>
            <Position>Required Position</Position> <span  style={{marginLeft:'3.5rem',marginRight:"2rem"}}>:</span>
            <Answer>{item.position } </Answer>
         
          </Title>
          <ProjectInfo>
         

          <Position1> Required Skills</Position1> <span style={{marginLeft:'3rem',marginRight:"2rem"}}>:</span>
            
            <Answer>{ item.req_skills.map((data) => { 
              return <span style={{margin:'5px'}}> {data.skill}<span>,</span> </span>
            })  }
            
             
            </Answer>
         
          
          </ProjectInfo>
          <Div1>
            <PriceContainer>
              <Price>
                {item.budget}
              </Price>
              <Badge content={item.workhours} clear />
              <Badge content={item.timezone} time />
            </PriceContainer>


            
            
           
                 
                 
       
            <ButtonEdit 
            
             
            >
 <NavHashLink to = {`/EditPostRequirementCand/${item.id}`}  smooth>
            
 <Badge content="Edit" glow1 />     </NavHashLink> 

             
            </ButtonEdit >
            
          </Div1>
         
         
          {/* {show ? <p> hello miss !</p> : null} */}
          </Divseperator>
               
            ) : (<p>No Post Requirements.</p>)} 
        </CardContent>
    
 
        <SideCard>
  <SideCardContent>
<Row1>
            <Detail1>
              <InfoContainer1>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info1>
                  <InfoName1>Thomas Martin</InfoName1>
                  <InfoUpdate1>10 days ago</InfoUpdate1>
                </Info1>
              </InfoContainer1>
            </Detail1>
          </Row1>
          {/* <Badge content="Design/" /> */}

          <Title1>
            Need a designer to form branding essentials for my bussiness

            
          </Title1>
          <ProjectInfo1>
          Required Position: UX/UI Designer
          </ProjectInfo1>

          </SideCardContent>
       

          
         <SideCardContent>
<Row1>
            <Detail1>
              <InfoContainer1>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info1>
                  <InfoName1>Thomas Martin</InfoName1>
                  <InfoUpdate1>10 days ago</InfoUpdate1>
                </Info1>
              </InfoContainer1>
            </Detail1>
          </Row1>
          {/* <Badge content="Design/" /> */}

          <Title1>
            Need a designer to form branding essentials for my bussiness

            
          </Title1>
          <ProjectInfo1>
          Required Position: UX/UI Designer
          </ProjectInfo1>


          </SideCardContent>

          <ViewAll>View All</ViewAll>
</SideCard>


      </RecommendProject>
    </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit =styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:9rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
`;
const Divseperator = styled.div`
border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`
  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;
border-bottom:1px solid pink;
box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:24rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;

display:flex;
flex-direction:column;
padding:3px;
margin:1rem;

border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1= styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;




export default ViewPostedProReq;
