import React ,{Component, useState, useEffect}from "react";
import '../css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
// import select from "react-select/src/select";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../Navbar";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer";
import PersonIcon from '@mui/icons-material/Person';
import star from '../assets/star.png';
import EditIcon from '@mui/icons-material/Edit';
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { podprovideredit } from "../action/auth";
import { useAuthToken } from "../../TokenContext";

// import { Margin } from '@mui/icons-material';
// import ManageProfile from '../pages2/ManageProfile';
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}


const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
   { 
    value: 'IT & Software', label: 'IT & Software'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]

const Providerprofile = () => {
  const token = useAuthToken();

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [Imagedata, setImagedata] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 


  console.log(user)

  const dispatch = useDispatch();

  const myBucket = new AWS.S3(S3Config);


  const history = useHistory();

  const [Uploading, setUploading] = useState(false)
  const [Edit, setEdit] = useState(false); 
  const [Image, setImage] = useState(""); 

 
  const [State, setState] = useState({
    id : user.data[0].id,
    firstName: user.data[0].firstName,
    lastName: user.data[0].lastName,
    email: user.data[0].email,
    candidateemail: user.data[0].emp_lastname,
    phone : user.data[0].phone,
    companyName:  user.data[0].companyName,
    region:  user.data[0].region,
    profilePicture : user.data[0].profilePicture,
    subscriptionID :user.data[0].subscriptionID, 
    priceID :user.data[0].priceID, 
    customerId:user.data[0].customerId,    

    })
  
    const onImageUpload = e => {

      const file = e.target.files[0];
  console.log("2#$#$#$#$#$$#$",file);

  if (file) {
    setImage(URL.createObjectURL(file));
    console.log("2#$#$#$#$#$$#$",URL.createObjectURL(file));

  }



      S3FileUpload.uploadFile(file, config)
      .then(data => {

        console.log(data);

         const location = data.key;

         console.log(location)
        setState((prevState) => {
         return { ...prevState, [e.target.id]: location };
      });
      setUploading(false)
    })    
    .catch(err => {
    
      console.log(err,"$%%%%%%%%%%%%%%%%%%%% : ",file,config);     setUploading(false)
    })
      
 
  }

  const clearErrorState = () => {
    setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  const handleSubmit = async event => {
    event.preventDefault();
    // Form validation
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    const { id, firstName, lastName, email, phone, region,profilePicture, companyName,subscriptionID,priceID,customerId} = State;

    const providerdata = {
        id : id, firstName : firstName,
        lastName: lastName, phone : phone, region : region, companyName : companyName,profilePicture : profilePicture,subscriptionID:subscriptionID,priceID:priceID,customerId:customerId
    }


    dispatch(podprovideredit(providerdata,headers)).then((err, datas)=>{

      alert("Details Successfully Updated");
      window.location.href = "/PartnerSubscription";
    });             
  };



  const onInputChange = e => {
    console.log(e.target)
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  const onInputChangeselect = e => {
    console.log(e.target.name)
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: user.data[0].profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
                setAuthImage(url);
  
              });
  }

  useEffect(() => {

    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
  
  }, [])
  
  


  const { profileImg} = State
    return (

     <div className="container">


      
    <br></br>
    <br></br>
      <div className="row">




      <div className="col-lg-3" >

      {/* <span style={{ marginLeft: '20px',  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' }} onClick={() => { setEdit(false) }}> <a 
          style={{  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' 
        }} href="/client-dashboard" >Dashboard</a>  >  Manage Profile  >   </span> */}

      </div>
 
      <div className="col-lg-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px" ,margin:'auto' }}>

      {Edit == false ? (

<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "50px", height : "50px" , borderRadius : "50px", backgroundColor: "#8EAEBC", float: "right"}}>
<EditIcon  style={{ color :  "white", marginLeft : "12px", marginTop: "12px" }} onClick={() => { setEdit(true) }} />

  </div>
): (<></>)}

<br></br>
<br></br>
<br></br>
<br></br>
<br></br> 

       {user.data[0].profile == 0 ? (  <h1 className="headsmall">Create profile</h1>  ) : ( <h1 className="headsmall">Manage profile</h1>)}


      

      


       { Edit == false ? (

<>

<center>


{   user.data[0].profilePicture == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
             
            <PersonIcon  style={{ color :  "#8EAEBC", textAlign :'center'}}/>


            </div>) : (  <img src={AuthImage}   style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img> )}
            
             <br></br>
             <p style={{ marginLeft : "-1px", color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.firstName + " " + State.lastName}</p>
             <p style={{ marginLeft : "-55px", width: "170px", fontSize: "16px" }}  ><a href="./Portfolio">Add Portfolio</a></p>

             <p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >{State.email}</p>
        
 
 
 
 
             </center>

       <p style={{ marginLeft : "-4px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "170px",float: "left" }} >Basic information</p>
<p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >Phone</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "18px", float: "left",marginLeft: "5px"  }} >{State.phone}</p>
</>) : ( <>
     
  <center>
      {   Image== "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
      
      <span>
      
             <img src={AuthImage} alt='' style={{width:'100px',height:'100px', borderRadius : "50px" }} id="profilePicture" ></img>
             <input type="file"  style={{marginLeft: '40px', width: '5rem', height: '3rem'}} className="profileclass" onChange={onImageUpload} id="profilePicture" />
             <EditIcon style={{color: 'white', width:'25px', height: '25px', marginLeft: '60px' ,marginTop: '-110px', backgroundColor: '#03045e', boxShadow: 'rgb(255, 255, 255) -15px -15px 25px', borderRadius: '50px' }} onChange={onImageUpload}
              />

</span>
              

             
             </div>) : (  <img src={Image} alt='' style={{width:'100px',height:'100px',marginTop:"30px", borderRadius : "50px" }} id="profilePicture" ></img> 
             )}
             </center>
     <br></br>
     
          <form onSubmit={handleSubmit}>
            {/* For First Name */}


            <div className="email">
              <input
                className="input2" 
                placeholder="Enter First Name"
                type="text"
                id="firstName"
value={State.firstName}  onChange={onInputChange}
required
              />
            </div>

             <div className="email">
              <input
                className="input2" 
                placeholder="Enter Last Name"
                type="text"
                id="lastName"
                value={State.lastName}
                onChange={onInputChange}
                required
              />
            </div>


            {/* For Middle Name */}
           

            {/* For Email */}

            
            <div className="email" >


          
                 <input
                
                  className="input2" 
                  type="tel"
                  id="phone"
                  placeholder={State.phone==0 ?'phone number':State.phone }
                
                 onChange={onInputChange}       
                 
                  value={State.phone}       
                  required   
                 
                  />

</div>
<div className="email" >
                 <input
                  //value={area}
                  className="input2" 
                  name="region"
                  placeholder="Region"
                  onChange={onInputChangeselect}       
                  value={State.region}       
                  required   
                 
                  />

</div>
<div className="email" >
                 <input
                  //value={area}
                  className="input2" 
                  name="companyName"
                  placeholder="Company Name"
                  onChange={onInputChangeselect}       
                  value={State.companyName}       
                  required   
                 
                  />


</div>

            <div className="createAccount">
              <button className="button is-success" style={{ width : "50%"}} type="submit"> {user.data[0].profile == 0 ? ( "Create") : ("Save")}</button>
            </div>
         
           </form>

           </>)}

</div>        

           <div className="col-lg-3"></div>


        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>


    );
  }

export default Providerprofile;
