import React, { useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";
import Modal from "react-modal";
import axios from "axios";
import './Popup.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

Modal.setAppElement("#root");

const ProjectPieChart = ({initialPodIds}) => {
  const [projectData, setProjectData] = useState([]); // Initialize as an empty array
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    if (user.data[0].type === "stakeholder") {
      fetchStakeholderData();
    } else {
      fetchNonStakeholderData();
    }
  }, [initialPodIds, token, user.data[0].type]);
  
  async function fetchStakeholderData() {
    if (!token) {
      console.log('Token is missing. API requests will not be made.');
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    };
  
    try {
      const payload = {};
  
      if (initialPodIds?.length > 0) {
        payload.pod_id = initialPodIds;
      } else if (user.data[0].client_code) {
        payload.org_code = user.data[0].client_code;
      }
  
      const response = await axios.post(process.env.REACT_APP_STAKEHOLDER_SONARRUNSMETRICSSTAKEHOLDER, payload, { headers });
  
      const responseData = response.data.body;
  
      if (!responseData) {
        console.error('Empty or invalid response data');
        return;
      }
  
      const data = JSON.parse(responseData);
  
      if (data && (Array.isArray(data) || typeof data === 'object')) {
        setProjectData(data);
      } else {
        console.error('Data is empty or not in the expected format');
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  }
  
  function fetchNonStakeholderData() {
    if (initialPodIds && initialPodIds.length > 0) {
      console.log('Calling fetchAdditionalApiForPodId');
      fetchAdditionalApiForPodId(initialPodIds);
    } else {
      fetchProjectDetails();
    }
  }
  

  const fetchAdditionalApiForPodId = async (selectedPodIds) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token, // You can add more headers as needed
    };
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await axios.post(process.env.REACT_APP_CLIENT_SONARRUNSMANAGERUSINGPODID, {
        pod_id: selectedPodIds ||null,
      },{headers:headers});

      const responseData = response.data.body;

      // Check if responseData is empty or null
      if (!responseData) {
        console.error('Empty or invalid response data');
        // Handle the case where the response is empty or invalid
        // You can set an error state or show a message to the user
        return;
      }

      const data = JSON.parse(responseData);

      // Check if data is an object (or an array, if expected) and contains valid data
      if (data && (Array.isArray(data) || typeof data === 'object')) {
        setProjectData(data);
      } else {
        console.error('Data is empty or not in the expected format');
        // Handle the case where the data is empty or not in the expected format
        // You can set an error state or show a message to the user
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
      // Handle other errors, e.g., show an error message or set an error state
    }
  };

  const fetchProjectDetails = async () => {
    const headers = {
     
      'Content-Type': 'application/json', 
      'Authorization': token,// You can add more headers as needed
    };
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETSONARPROJECTDETAILS,
        {
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code || null,
        },  {headers:headers}
      );
      const data = JSON.parse(response.data.body);
      setProjectData(data);
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  useEffect(() => {
    if (projectData) {
      renderChart();
    }
  }, [projectData]);

  const renderChart = () => {
    if (!projectData || !Array.isArray(projectData) || projectData.length === 0) {
      console.log('No data available');
      return;
    }
  
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    const ctx = canvasRef.current.getContext("2d");
  
    const repoLabels = projectData.map((project) => project.github_repo);
    const repoData = projectData.map(() => 1);
    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#FF5733",
      "#44A7EB",
      "#EFC656",
      // Add more colors as needed
    ];
    chartRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels: repoLabels,
        datasets: [
          {
            data: repoData,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false, // Set to false to hide the legend
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${context.label}`;
              },
            },
          },
        },
      },
    });
  
    // Add the event listener to the canvas element
    canvasRef.current.addEventListener("click", handleChartClick);
  };
  
  const handleChartClick = (event) => {
    console.log("Clicked on chart"); // Log to check if the click event is firing
    const chart = chartRef.current;

    if (chart) {
      const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
      console.log("Elements:", elements); // Log the elements array
      if (elements.length > 0) {
        const segment = elements[0];
        const datasetIndex = segment.datasetIndex;
        const index = segment.index;
        const validProjects = projectData.filter(project => project.github_repo);
        if (validProjects.length > 0) {
          const selectedRepoName = validProjects[datasetIndex].github_repo;
          console.log("Selected Repo:", selectedRepoName);
          setSelectedRepo(selectedRepoName);
          setModalIsOpen(true);
        } else {
          console.error("No valid project found for the clicked segment.");
        }
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedRepo(null);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'sonarqubeStats' })}</h2>
        <br />  <br/>
        <div className="chart-wrapper" style={{width:"150px",height:"150px",margin:"auto",textAlign:"center",marginLeft:"2rem"}}>
          {projectData && projectData.length>0 ?( <canvas ref={canvasRef} width="150" height="150"></canvas>):(<p>No data available</p>)}
         
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Details Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem'
          },
        }}
      >
        {selectedRepo && (
          <div>
            <h3>{intl.formatMessage({ id: 'detailsForGitHubRepo' })} {selectedRepo}</h3>
            <TableContainer component={Paper}>
              <Table component={Paper}
                sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
                aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: '#00B4D8',
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'white',
                      fontWeight: '600'
                    },
                  }}
                >
                 <TableCell align="right">{intl.formatMessage({ id: 'projectKey' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'ncloc' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'bugs' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'vulnerabilities' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'codeSmells' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'duplicatedLinesDensity' })}</TableCell>
         </TableHead>
                <TableBody>
                  {projectData && (
                    projectData.map((project, index) => (
                   
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                            '&:nth-child(even)': {
                              backgroundColor: '#90E0EF'
                            },
                            '&:nth-child(odd)': {
                              backgroundColor: '#caf0f8'
                            },
                            '& td, & th': {
                              fontFamily: 'Poppins,Medium',
                              fontSize: '15px',
                              color: 'black',
                            },
                          }}
                        >
                          <TableCell align="right">{project.project_key}</TableCell>
                          <TableCell align="right">{project.ncloc}</TableCell>
                          <TableCell align="right">{project.bugs}</TableCell>
                          <TableCell align="right">{project.vulnerabilities}</TableCell>
                          <TableCell align="right">{project.code_smells}</TableCell>
                          <TableCell align="right">{project.duplicated_lines_density}</TableCell>
                        </TableRow>
                      
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <button onClick={closeModal}> {intl.formatMessage({ id: 'done' })}</button>
          </div>
        )}
      </Modal>
    </div>
  );
};
ProjectPieChart.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default ProjectPieChart;