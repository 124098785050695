import React, { useEffect, useState, useRef } from "react";
import { Chart,registerables } from "chart.js";
import axios from "axios";
import { useAuthToken } from '../TokenContext';
import { useSelector } from 'react-redux';
import './time.css';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const TimeView = ({ initialPodIds,selectedSprint,sprintStartDate,sprintEndDate  }) => {
  const [timeData, setTimeData] = useState(null);
  const chartRef = useRef(null);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  useEffect(() => {
    Chart.register(...registerables);
    fetchData();
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [initialPodIds, token,,selectedSprint,sprintStartDate,sprintEndDate ]);

  const fetchData = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      let response;

      // Check if initialPodIds are provided
      if (user.data[0].type === "stakeholder") {
        const payload = {};
    
        // Check if pod_id is available, send only pod_id
        if (initialPodIds && initialPodIds.length > 0) {
          payload.pod_id = initialPodIds;
        } else if (user.data[0].client_code) {
          // If pod_id is not available, check if org_code is available, send only org_code
          payload.org_code = user.data[0].client_code;
        }
        payload.sprint_name = selectedSprint || '';
        payload.sprint_start_date = sprintStartDate || '';
        payload.sprint_sprint_end_datename = sprintEndDate || '';
        response = await axios.post(
        process.env.REACT_APP_STAKEHOLDER_JIRASHOWTIMEMETRICSSTAKEHOLDER,
          payload ,
          { headers: headers }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_CLIENT_JIRASHOWTIMEMANAGERID,
          { manager_id: user.data[0].emp_id, pod_id: initialPodIds||null ,sprint_name: selectedSprint || '',
          sprint_start_date:sprintStartDate||'',
          sprint_end_date: sprintEndDate ||''},
          { headers: headers }
        );
        console.log('time4',response)
      }

      const responseData = JSON.parse(response.data.body);
      setTimeData(responseData);
      console.log('time', responseData);
    } catch (error) {
      console.error("Error fetching time details:", error);
    }
  };

  useEffect(() => {
    if (timeData) {
      renderChart();
    }
  }, [timeData]);

  const renderChart = () => {
    if (!timeData || !timeData.body) {
      return;
    }
  
    // Use total_time_by_pods data by default
    let selectedData = timeData.body.total_time_by_pods;
  
    // Check if selectedData is defined
    if (!selectedData) {
      return;
    }
    // Check if initialPodIds are provided, and if so, find the selected pod data
    if (initialPodIds) {
      const selectedPod = timeData.body.jira_show_time_by_pod.find(
        (pod) => pod.pod_id.toString() === initialPodIds.toString()
      );
  
      // If a selected pod is found, use its data instead
      if (selectedPod) {
        selectedData = selectedPod;
      }
    }
  
    // Filter categories to include only Bug-Fixing, Deployment, Design, Development, Performance, Security
    const includedCategories = [
      'Bug-Fixing',
      'Deployment',
      'Design',
      'Development',
      'Performance',
      'Security',
    ];
  
    // Use optional chaining to handle possible null or undefined values
    const filteredData = Object.keys(selectedData || {})
      .filter((category) => includedCategories.includes(category))
      .reduce((obj, key) => {
        obj[key] = selectedData[key];
        return obj;
      }, {});
  
    // Format the data for the pie chart
    const labels = Object.keys(filteredData).map((category) => ({
      label: `${category}\n${convertMinutesToTime(filteredData[category])}`,
      value: parseFloat(filteredData[category]),
    }));
  
    const data = labels.map((labelObj) => labelObj.value);
  
    const ctx = document.getElementById("timeChart").getContext("2d");
  
    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#FF5733",
      "#44A7EB",
      "#EFC656",
      // Add more colors as needed
    ];
  
    // Clear the previous chart
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels.map((labelObj) => labelObj.label),
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
          labels: {
            display: false, // Do not show labels outside the pie chart
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index;
              const labelObj = labels[index];
              const formattedTime = convertMinutesToTime(labelObj.value);
              return `${labelObj.label}: ${formattedTime}`;
            },
          },
        },
      },
    });
  };
  
  

 

  const convertMinutesToTime = (timeString) => {

    if (!timeString || typeof timeString !== 'string') {
      return 'Invalid Time';
    }

    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Calculate the total minutes
    const totalMinutes = hours * 60 + minutes + seconds / 60;

    // Use String formatting to ensure leading zeros
    const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const formattedMinutes = String(Math.floor(totalMinutes % 60)).padStart(2, '0');

    return `${formattedHours}h${formattedMinutes}m`;
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'jiraTimeDetails' })}</h2>
        <div className="chart-wrapper" style={{ width: "130px", height: "130px", marginTop: "20px" }}>
          {timeData && timeData.body && Object.keys(timeData.body).length > 0 ? (
            <canvas id="timeChart" width="400" height="400"></canvas>
          ) : (
            <div>
              <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
};


TimeView.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default TimeView;
