import React, { Component, useState, useEffect } from "react";
import '../components/css/subscription.css';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../Dashboard/Navbar";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import EditIcon from '@mui/icons-material/Edit';
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import Sidebar from "./Sidebar";
import star from './assets/star.png';
import axios from "axios";
import '../components/css/payment.css';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import layers from './assets/layers.png';
import code from './assets/code.png';
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";

import monitor from './assets/monitor.png';
import Switch from "react-switch";
import { NavLink } from 'react-router-dom';
import visa from './assets/visa.png';
import subscripton from './Subscription';
import { fontFamily } from '@mui/system';
import { useAuthToken } from "../TokenContext";

function Subscription() {
  const { isLoggedIn, user } = useSelector(state => state.auth);
  
  const token = useAuthToken();

  const [Pricedetails, setPricedetails] = useState([]);
  const [Pricedetailspod, setPricedetailspod] = useState([]);
  const [Pricedetailslatam, setPricedetailslatam] = useState([]);
  const [Pricedetailspodlatam, setPricedetailspodlatam] = useState([]);
const[createcustomerId,setCreatecustomerId]=useState(null)



// Declare a global variable to store Customeriddb
let Customeriddb;

// Check if user has subscription data
if (user.subscriptiondata.length > 0) {
  // Check if customerId exists
  if (user.subscriptiondata[0].customerId) {
    Customeriddb = user.subscriptiondata[0].customerId;
  } else {
    // Handle the case where customerId is missing
    // console.error("customerId is missing");
    // You can also display an error message to the user
    // or redirect them to a different page
  }
} else {
  // Handle the case where SubscriptionDB is empty
  // console.error("No subscription data found");
  // You can also display a message to the user
  // or redirect them to a different page
}


  useEffect(() => {
    console.log("run")

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    console.log("token",token)
    // https://m1e8glzyqk.execute-api.us-east-1.amazonaws.com/dev/stripepricelist
    axios.post(process.env.REACT_APP_CLIENT_STRIPEPRICELIST,{},{headers:headers})

      .then((res) => {
       
        // setPricedetails(res.data.data.phase_1_admin) 
        // console.log('pricelist1',res.data.data.Asia_individuals )
        setPricedetailspod(res.data.data.phase_1_admin)

        // setPricedetailslatam(res.data.data.LATAM_individuals) 
        // setPricedetailspodlatam(res.data.data.LATAM_development) 
      }


      )
      .catch((err) => {
        console.log("catch", err);
      })
  }, [token]);
 
  const dispatch = useDispatch();


  const [PaymentData, setPaymentData] = useState({
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    name: "",
    postal_code: 222222,
    customerId: createcustomerId
  })

  console.log(PaymentData)

  const [PaymentID, setPaymentID] = useState("");
  const [Step, setStep] = useState("Chooseplan");
  const [Plantype, setPlantype] = useState("");
const[aPIresponse,setAPIresponse]=useState(null)

  const [PriceId, setPriceId] = useState({
    price: "",
    id: "",
    balance: "",
    type: '',
    plan: ''
  });

  const [Loading, setLoading] = useState(false);

  const [Cheked, setCheked] = useState(false);
  const [addcard, setAddcard] = useState(false);
  const [paymentvalue, setPaymentvalue] = useState('');
  const [customerId, setCustomerId] = useState(null);
  useEffect(() => {
    // This effect will run whenever customerId changes
    console.log('customerId has changed:', customerId);
  }, [customerId]);

  const prices = {
    bronze: { price: 4400, id: "" },
    silver: { price: 8400, id: "" },
    gold: { price: 12000, id: "" }
  }



  const asianprices = {
    bronze: { price: 2200, id: "price_1MqZp0CrTnlS95H4bQ3357t0" },
    silver: { price: 4000, id: "price_1MqZqBCrTnlS95H47EOcgjhQ" },
    gold: { price: 5760, id: "price_1MqZs5CrTnlS95H4mSK2KmMx" }
  }

  const usaprices = {
    bronze: { price: 12000, id: "" }
  }




  const podplan = {
    web: { price: 250, id: "price_1MqZwoCrTnlS95H4ASLh6NEO" },
    mobile: { price: 250, id: "price_1MqZxqCrTnlS95H4jITkFs4Y" },
    webmobile: { price: 250, id: "price_1MqZzFCrTnlS95H4ohqQLaZj" },
    saas: { price: 250, id: "price_1MFewhIFkSkYgDHSWEIHLquQ" }
  }

  console.log(PaymentID, PriceId)
console.log("name",user.data[0].customer_firstname)


useEffect(() => {
  // This code will run based on its dependencies or once after the initial render
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
  // Perform the action here before making the API call
  if (user.subscriptiondata.length === 0) {
    const data = {
      name: user.data[0].customer_firstname,
      email: user.data[0].email,
    };


    axios.post(process.env.REACT_APP_CREATECUSTOMER, data, {headers:headers})
      .then(response => {
        console.log('createcustomer response:', response);

        const responseData = JSON.parse(response.data.body);
        setCreatecustomerId(responseData.customer.id);

        console.log('createcustomerId set to:', responseData.customer.id); // Check if it's set correctly
      })
      .catch(error => {
        console.error('Error in create customer API:', error);
      });
  }

 

}, [user.subscriptiondata,token]); // Dependencies that trigger the effect when they change



const createPayment = async (e) => {
  e.preventDefault();
  setLoading(true);
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
  try {
    // let createcustomerId = null;

    
  
    
   
    if (PriceId.price === 0) {
      // Execute test-create-subscription API if PriceId is 0$
      const data = {
        price_id: PriceId.id,
        customerId:user.subscriptiondata.length > 0?(Customeriddb ):(createcustomerId)
        


      };

      try {
        const response = await axios.post(process.env.REACT_APP_TESTCREATESUBSCRIPTION, data, {headers:headers});
    
        console.log('createcustomer', response);
        


        const responseBody = JSON.parse(response.data.body);
        const subscriptionBody = JSON.parse(responseBody.subscription.body);
        const Start = subscriptionBody.subscription;
      
        console.log("current_period_start:", Start);
      

        // Update state with current_period_start
     

        // Set the entire API response in state if needed
        setAPIresponse(Start);
       
        // Set loading to false after the API call is complete
       
       console.log('apiresponse',response)
       
        setLoading(false);
        setStep("Confirm");
        // setAddcard(true);
        // setPaymentID(response.data.result.id);
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error in test-create-subscription API:', error);
      }
    
    } else {
      // Execute other APIs if PriceId is more than 0$
      const paymentData = {
        number: PaymentData.number,
        exp_month: PaymentData.exp_month,
        exp_year: PaymentData.exp_year,
        cvc: PaymentData.cvc,
        name: PaymentData.name,
        postal_code: PaymentData.zip,
        customerId:user.subscriptiondata.length > 0?(Customeriddb ):(createcustomerId)
      };
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };

      const paymentResponse = await axios.post(process.env.REACT_APP_CLIENT_CREATEPAYMENTMETHOD, paymentData, {headers:headers});

      console.log(paymentResponse);

      if (paymentResponse.data) {
        setLoading(false);
        setPaymentID(paymentResponse.data.result.id);
        setStep("Checkout");
        setAddcard(true);
      }
    }
  } catch (err) {
    console.log(err);
    alert("Some error occured! Try again.")
    setLoading(false)
  }
};

const createSubscription = async () => {

 
  try {
    setLoading(true);

    const data = {
      paymentMethodId: PaymentID,
      customerId:user.subscriptiondata.length > 0?(Customeriddb ):(createcustomerId),
      items: [{ price: PriceId.id }],
      subId: user.subscriptiondata.length > 0 ?user. subscriptiondata[0].subscriptionID : undefined,
    };

    console.log(data);
    console.log('res',aPIresponse)
    const headers = {
      Authorization : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };

    let subscriptionApi;
let response;

    // Use different APIs based on PriceId
    if (PriceId.price !== 0) {
      subscriptionApi = process.env.REACT_APP_CLIENT_CREATESUBSCRIPTION;
      response = await axios.post(subscriptionApi, data, headers);
    } else {
      // Use the API response from createPayment
      response = aPIresponse;
    }

  
    const subData = {
      Status: 'active',
      startDate: aPIresponse.current_period_start,
      endDate: aPIresponse.current_period_end,
      priceID: PriceId.id,
      subscriptionID: response.id,
      amount: PriceId.price,
      CandidateBalance: PriceId.balance,
      client_ID: user.data[0].customer_id,
      subId: user.subscriptiondata.length > 0 ?user. subscriptiondata[0].id : undefined,
      type: PriceId.type,
      region: user.data[0].customer_country,
      Product_Name: user.data[0].Product_Name,
      organizational_code: user.data[0].client_code,
      customerId:user.subscriptiondata.length > 0?(Customeriddb ):(createcustomerId)

    };

    console.log('responseive56', subData);
    console.log('responseive56', createcustomerId);
   
  
    // Use different APIs based on PriceId
    const subscriptionDbApi =
       process.env.REACT_APP_CLIENT_CREATESUBSCRIPTIONDB;

    // Execute the corresponding API
    const dbResponse = await axios.post(subscriptionDbApi, subData, {headers});

    console.log(dbResponse);

    dispatch(login(user.data[0].email, "customer"));
    setStep("Subscribed");
    setLoading(false);

  } catch (err) {
    console.log('testing',err)
    alert('You cannot update a subscription that is `canceled` or `incomplete_expired`.');
    setLoading(false);
  

  }
};


  console.log(PaymentID)

  const onInputChange = e => {

    setPaymentData((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });

  }
 
  const axios = require('axios');

const deletesubscription = () => {
  let data = JSON.stringify({
    "subId": user.subscriptiondata[0].subscriptionID
  });

  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    //https://jo3db8z8x4.execute-api.us-east-1.amazonaws.com/dev/cancel-subscription
    url: process.env.REACT_APP_CLIENT_CANCELSUBSCRIPTIONDB,
    headers: {
      Authorization : token,
      'Content-Type': 'application/json'
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));

      // Make the second API call immediately after the first one is successful
      const secondAPIData = JSON.stringify({
        "client_Id": user.data[0].customer_id
      });

      let secondAPIConfig = {
        method: 'put',
        maxBodyLength: Infinity,
        //https://dmz651neak.execute-api.us-east-1.amazonaws.com/dev/cancel-subscription-db
        url: process.env.REACT_APP_CLIENT_CANCELSUBSCRIPTIONDBSECOND,
        headers: {
          Authorization : token, // Replace with your actual token
          'Content-Type': 'application/json',
        },
        data: secondAPIData
      };

      axios.request(secondAPIConfig)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          alert(response.data);
          setTimeout(() => {
            window.location.reload();            
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

    // Call the function to start the process

    

// console.log('jut length',user.subscriptiondata[0].Status)


 


  return (

    <div className="container">

      {Step == "ChooseSubPlan" ? (
        <div>

          <subscription>

            <Heading>
              Subscription
            </Heading>

            <Heading1>
              Choose a plan that works best for you which makes your projects deliver fast!
            </Heading1>
            <Individualtag>
              <Data1>
                <P1 onClick={() => { setStep("Chooseplan") }}> TALENT ACCESS</P1>
                <P2 onClick={() => { setStep("ChooseSubPlan") }}>POD MANAGEMENT</P2>
              </Data1>
            </Individualtag>
            <Cards style={{ marginBottom: "20px" }}>

              <Cardlist>
                
              {Pricedetailspod&&
                  Pricedetailspod.length > 0 ?
                    Pricedetailspod.map((item) =>{
                      // Check if the Product_Name contains the keyword "Partner"
                      if (!item.Product_Name.toLowerCase().includes("partner")) {
                        return (
                      <Card1 >
                        <carding><img style={{ width: '50px', height: '50px' }} src={layers}></img></carding>
                        <h3 style={{ fontSize: '18px', padding: '5px' }}>{item.Product_Name}</h3>
                        <p style={{ fontSize: '14px', color: '#A2A2A2', textAlign: 'center', padding: '15px' }}>{item.Desc}</p>
                        <p style={{ fontSize: '20px', fontFamily: 'Poppins,Medium', color: '#00B4D8', fontWeight: "bold" }}>${item.Amount} <span style={{ fontSize: '13px', color: '#00B4D8', fontFamily: "Poppins,Medium" }}>/year</span></p>

                        {user.subscriptiondata.length > 0 && user.subscriptiondata[0].Status !=='inactive' && user.subscriptiondata[0].priceID != undefined ? user.subscriptiondata[0].priceID == item.Price_ID ? (
                          <>
                            <button style={{ border: 'none', fontWeight: "bold", borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} >Current Plan</button>
                            <button style={{ border: '2px solid red', borderRadius: "40px", width: '10rem', height: '2.5rem', backgroundColor: 'white' }} onClick={deletesubscription}>cancel subscription</button>
                          </>
                        ) : (<button style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} onClick={() => { setStep("Checkout"); setPriceId({ id: item.Price_ID, price: item.Amount, balance: 1, type: "Web App" }); }} >Choose Plan</button>
                        ) : (<><button style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} onClick={() => { setStep("Checkout"); setPriceId({ id: item.Price_ID, price: item.Amount, balance: 1, type: "Web App" }); }} >Choose Plan</button></>)}

</Card1>);
        } else {
          return null; // Skip rendering this item
        }
      })
       : (<p>No Plans Available.</p>)}

                {/* 
<Card1 >
                  <carding><img style={{ width: '50px', height: '50px' }} src={layers}></img></carding>
                  <h3 style={{ fontSize: '18px', padding: '5px' }}>plan 1</h3>
                  <p style={{ fontSize: '14px', color: '#A2A2A2', textAlign: 'center', padding: '15px' }}>Includes both frontend & backend web developers</p>
                  <p style={{ fontSize: '20px', fontFamily: 'Poppins,Medium', color: '#00B4D8', fontWeight: "bold" }}>$9898 <span style={{ fontSize: '13px', color: '#00B4D8', fontFamily: "Poppins,Medium" }}>/year</span></p>
                  <button style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} >Choose Plan</button>
                </Card1> 


                
                <Card1 >
                  <carding><img style={{ width: '50px', height: '50px' }} src={layers}></img></carding>
                  <h3 style={{ fontSize: '18px', padding: '5px' }}>Free Trail</h3>
                  <p style={{ fontSize: '14px', color: '#A2A2A2', textAlign: 'center', padding: '15px' }}>Includes both frontend & backend web developers</p>
                  <p style={{ fontSize: '20px', fontFamily: 'Poppins,Medium', color: '#00B4D8', fontWeight: "bold" }}>$0 <span style={{ fontSize: '13px', color: '#00B4D8', fontFamily: "Poppins,Medium" }}>/year</span></p>
                  <button style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} >Choose Plan</button>
                </Card1>  */}




              </Cardlist>


            </Cards>





            <br></br>
            <br></br>


          </subscription>



        </div>
      ) : Step == "Chooseplan" ? (
        <>

          <subscription>

            <Heading>
              Subscription
            </Heading>

            <Heading1>
              Choose a plan that works best for you which makes your projects deliver fast!
            </Heading1>
            <Individualtag>
              <Data1>
                <P3 onClick={() => { setStep("Chooseplan") }}> TALENT ACCESS</P3>
                <P4 onClick={() => { setStep("ChooseSubPlan") }}>POD MANAGEMENT</P4>
              </Data1>
            </Individualtag>
            <Cardstest style={{ marginBottom: "20px" }}>
              <h2>Coming Soon</h2>

            </Cardstest>




          </subscription>


        </>
      ) : Step == "Addcard" ? (

        <>




          <div className="row" >
            <div className="col-sm-3" >

              <div style={{ marginTop: '1rem', float: "left" }}>
                <span to='/Projectrequirements' style={{ marginLeft: '20px', color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' }} onClick={() => { setStep("Checkout"); }}>Billing</span>
                <span style={{ marginLeft: '25px' }}>  >  </span>
                <span style={{ marginLeft: '25px' }}>  Add Card   </span>

              </div>
            </div>

            <div className="col-sm-6">
              
              <form onSubmit={createPayment}>
                
  {PriceId.price > 0 && (
    <>
    <br></br>


<center>  <h3 className='register-header' >Add New Card</h3></center>

<br></br>
      <div className="field">
        <p className="control">
          <InputL
            className="payment-input"
            placeholder="Card Number"
            value={PaymentData.number}
            id="number"
            onChange={onInputChange}
            maxLength="16"
            required
          />
        </p>
      </div>

      <div className="field">
        <p className="control">
          <InputL
            className="payment-input"
            placeholder="Name"
            value={PaymentData.name}
            id="name"
            onChange={onInputChange}
            required
          />
        </p>
      </div>
      <div className="field">
        <p className="control">
          <InputL
            className="payment-input"
            placeholder="exp_month"
            value={PaymentData.exp_month}
            id="exp_month"
            onChange={onInputChange}
            minLength="2"
            maxLength="2"
            required
          />
        </p>
      </div>
      <div className="field">
        <p className="control">
          <InputL
            className="payment-input"
            placeholder="exp_year"
            value={PaymentData.exp_year}
            id="exp_year"
            onChange={onInputChange}
            minLength="4"
            maxLength="4"
            required
          />
        </p>
      </div>
      <div className="field">
        <p className="control">
          <InputL
            className="payment-input"
            placeholder="cvc"
            value={PaymentData.cvc}
            id="cvc"
            onChange={onInputChange}
            maxLength="3"
            required
          />
        </p>
      </div>
    </>
  )}

  <br></br>
{PriceId.price !==0? (<div className="field">
    <p className="control">
      <Buttonaddcard className="login" type="submit">
        {Loading ? ("Processing..." ): ("Add Card")}
      </Buttonaddcard>
    </p>
  </div>): (<div className="field">
    <p className="control">
      
      <Buttonaddcard className="login" type="submit">
       
        {Loading ? ("Processing..." ): ("Proceed")}
      </Buttonaddcard>
    </p>
  </div>) }
 
</form>

            </div>

            <div className="col-sm-3" ></div>


          </div>
          <br></br>

        </>


      ) : Step == "Checkout" ? (

        <>


          <Navi >

            <span to='/Projectrequirements' style={{ marginLeft: '20px', color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' }} onClick={() => { setStep("Chooseplan"); }}>Subscriptions</span>
            <span style={{ marginLeft: '20px' }}>  >  </span>
            <span style={{ marginLeft: '20px', color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>PaymentScreen</span>
          </Navi>


          <WholeCard>

            <WholeCard1 >
              <MediaCard >
                <h3 style={{ marginTop: "4rem", fontFamily: 'Poppins, Regular', fontSize: '22px', fontWeight: 'bold', paddingBottom: '1rem' }}>Billing Info</h3>
                <BillCard>
                  <DetailCard>
                    <ScreenView>
                      <img style={{ width: '80px', height: '80px', marginTop: '20px' }} src={monitor}></img>
                      <ScreenP >${PriceId.price}<span style={{ color: "#00B4D8", fontFamiliy: 'Poppins,Regular', fontSize: '22px' }}> /per year</span></ScreenP>
                    </ScreenView>

                  {  Pricedetailspod.map((item) =>
                     <BillHead>
                  <p style={{ borderBottom: '1px solid #A2A2A2', marginTop: '-40px', padding: '5px' }}> {item.Product_Name}</p>

                 
                      </BillHead>)}
                  </DetailCard>
                  <MoneyCard>
                    <MoneyLeftRow>
                      <tr style={{ marginRight: 'auto', marginLeft: '2rem' }}><td>Amount</td></tr>
                      <tr style={{ marginRight: 'auto', marginLeft: '2rem' }}><td >Tax</td></tr>
                      <tr style={{ marginRight: 'auto', marginLeft: '2rem' }}><td>Total</td></tr>
                    </MoneyLeftRow>
                    <MoneyRightRow>
                      <tr><td>${PriceId.price}</td></tr>
                      <tr><td>${PriceId.price}</td></tr>
                      <tr><td>${PriceId.price}</td></tr>
                    </MoneyRightRow>
                  </MoneyCard>

                  {/* <div style={{ marginLeft: '20px', marginTop: '10px' }}><h3 style={{ fontSize: '18px', fontFamily: 'Poppins,Regular', fontWeight: 'bold' }}>Enable auto renew <span style={{ marginLeft: '10rem' }}> </span></h3>
                    <p style={{ fontSize: '14px', color: '#A2A2A2', fontFamily: 'Poppins,Regular', fontWeight: "bold" }}>This option; if checked, will renew your subscription, if the current plan expires.</p>
                  </div> */}

                </BillCard>
              </MediaCard>

              <div style={{ marginLeft: '2rem', marginTop: "11rem" }}>
                        
                    {PriceId.price !==0?(
                <BillCard1>
                  <h3 style={{ fontFamily: 'Poppins, Regular', fontSize: '22px', fontWeight: 'bold', paddingBottom: '1rem' }}>Payment Methods</h3>
                 
                 
                  <Input1>

                    <input style={{ width: '1rem', height: '1rem' }} name='payment' type='radio' value='card' onClick={(e) => { console.log('checked data', e.target.checked); setCheked(e.target.checked); setPaymentvalue(e.target.value); }} checked={paymentvalue == 'card' ? Cheked : undefined} /> <span style={{ paddingLeft: '20px' }} >Credit/ Debit Card'</span>
                  </Input1>
           
                  {PaymentID == "" ? (<></>) : (<><Input1>{"************" + PaymentData.number.slice(12, 16)} </Input1>  <Input1>{PaymentData.name}</Input1></>)}




                  {!addcard ? (<>
                 
                    {paymentvalue == 'card' ? (<Button style={{ backgroundColor: "#1DBF73", color: "white", width: "210px", float: "left", marginTop: "20px" }} onClick={() => { setStep("Addcard"); }}> +Add Card</Button>) : (<></>)}
                  </>) : (<></>)}

                  <br></br>
                  <p></p>


                  <br></br>
                  <Button
  style={{ backgroundColor: "#1DBF73", color: "white", width: "210px", float: "left", marginTop: "20px" }}
  onClick={() => {
    if (PriceId.price !== 0) {
      alert("Please choose a payment method!");
    } else {
      setStep("Confirm");
    }
  }}
>
  Checkout
</Button>


                </BillCard1>):(
                  <BillCard>
                    If your are subscribing for a FREE plan please
                    <Button style={{ backgroundColor: "#1DBF73", color: "white", width: "210px", float: "left", marginTop: "20px" }} onClick={() => { setStep("Addcard"); }}>  Click Here!</Button> 
                    
                    </BillCard>
                )}

              </div>
           
              
          
            </WholeCard1>
          </WholeCard>

        </>) : Step == "Confirm" ? (
          <>
            <div style={{ marginTop: '1rem', float: "left" }}>
              <span style={{ marginLeft: '25px' }}>  >  </span>
              <span to='/Projectrequirements' style={{ marginLeft: '20px', color: 'grey' }} onClick={() => { setStep("Checkout"); }}>Billing</span>
            </div>
{PriceId.price !==0?(<Heading>
              Confirm Payment!
            </Heading>):(<Heading>
              Confirm Subscription!
            </Heading>)}
            
            <Heading1>
              <button style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }} onClick={createSubscription}>Confirm</button>
            </Heading1>
            {Loading ? (
              <>  <Heading1>
                Payment In-Progress...!
              </Heading1> </>
            ) : (<></>)}
          </>
        ) : Step == "Subscribed" ? (<>

          <div className="container "  >
            <div className="row" >
              <div className="col-sm-3"></div>

              <div className="col-sm-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, height: "500px" }}>
                <br></br>


                <center>  <h3 className='register-header' style={{ marginTop: "100px" }}>Payment Success!</h3>


                  <br></br>
                  <Heading1 style={{ color: "black", fontWeight: "bold" }}>
                    You have been subscribed to {PriceId.price == 100 ? ("Bronze- 1") : PriceId.price == 200 ? ("Silver-2") : PriceId.price == 300 ? ("Gold-3") : ("")} Developer Plan
                  </Heading1>
                </center>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="field">
                  <p className="control">
                    <Link to="/Clientinvite"> <BButton className="login" >
                      Invite Managers
                    </BButton>
                    </Link>
                  </p>
                </div>

              </div>
              <div className="col-sm-3" ></div>


            </div>
          </div>
        </>)
        : (<></>)}

      <br></br>
      <br></br>
      <br></br>
      <br></br>

    </div>

  )

}

export default Subscription;

const subscription = styled.div`



`;

const BButton = styled.button`

margin-top:20px;
width :20rem;
height:50px;

@media screen and (min-width:320px) and (max-width:1080px){
  width :10rem;
  height:60px;
  
 
}

`;


const Heading = styled.p`
font-size:26px;
font-family:Poppins,SemiBold;
margin-top:10rem;

font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){

  
  display:flex;
    align-items:center;
    justify-content:center;
}
`;
const Heading1 = styled.p`
font-size:18px;
font-family:Poppins,Regular;


color:#A2A2A2;
font-weight:400;
@media screen and (min-width:320px) and (max-width:1080px){
// margin:auto;

display:flex;
  align-items:center;
  justify-content:center;
}
`;

const Individualtag = styled.div`

@media screen and (min-width:320px) and (max-width:1080px){


  display:flex;
  font-size:4px;

  align-items:center;
  justify-content:center;
  // flex-direction:column;
}
`;

const P1 = styled.p`
background-color: #E4E4E4;
color:black;
margin-left:13rem;
padding:10px;
width:303px;
text-align:center;
border-radius:40px;
cursor : pointer;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  width:203px;
}
`;


const P2 = styled.p`
background-color: #00B4D8;
color:black;
padding:10px;
margin-left:-36rem;
width:303px;
text-align:center;
border-radius:40px;
 cursor : pointer;
 @media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  width:203px;
 }
`;

const P4 = styled.p`
background-color: #E4E4E4;
color:black;
padding:10px;
margin-left:-36rem;
width:303px;
text-align:center;
border-radius:40px;
 cursor : pointer;
 @media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  width:203px;
 }
`;

const P3 = styled.p`
background-color: #00B4D8;
color:black;
margin-left:13rem;
padding:10px;
width:303px;
text-align:center;
border-radius:40px;
cursor : pointer;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  width:203px;
}
`;
const Data1 = styled.p`

display:flex;
flex-direction:row;
font-size:18px;
font-family:Poppins,Regular;
margin-top:5rem;
margin-left:5rem;
margin-bottom:3rem;
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  display:flex;
  flex-direction:column;
width : 150px; 
font-size: 15px;
margin:auto;
margin-bottom:2rem;
 }




`;



const Cards = styled.div`
width: 75rem;
height:450px;
margin-bottom:1rem;
background-color: rgba(142, 174, 188,20%);
// background-color:#8EAEBC;

@media screen and (min-width:320px) and (max-width:1080px){
  width:24rem;

  height:max-content;
  display:flex;
// margin:auto;
// margin-left:20px;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;

const Cardstest = styled.div`
display:flex;
justify-content:center;
align-items:center;
margin:auto;

margin-bottom:1rem;

@media screen and (min-width:320px) and (max-width:1080px){
  width:24rem;

  height:max-content;
  display:flex;
// margin:auto;
// margin-left:20px;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;

const Cardlist = styled.div`

display:flex;

justify-content:center;
align-items:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin-bottom:1rem;
  align-items:center;
  justify-content:center;
  flex-direction:column;

 
`;
const Card1 = styled.div`
width: 230px;
margin-right:5rem;
height:350px;
background-color:white;
margin-top:5rem;
border-radius:20px;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
margin-left:1rem;
`;
const Card12 = styled.div`
width: 230px;
margin-right:5rem;
height:300px;
background-color:white;
margin-top:5rem;
border-radius:20px;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
margin-left:1rem;
`;


const carding = styled.div`

`;


const WholeCard = styled.div`


@media screen and (min-width:320px) and (max-width:1080px){
 
  
 margin:auto;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center;
}


`;

const WholeCard1 = styled.div`
display:flex;
flexDirection:row;

@media screen and (min-width:320px) and (max-width:1080px){
 
  
  display:flex;
    align-items:center;
    justify-content:center;
flex-direction:column;
}

`;


const BillCard = styled.div`
margin-bottom:5rem;
width:450px;
height:431px;
background-color: rgba(142, 174, 188 ,20%);
box-shadow: 2px 3px 5px #00000029;


@media screen and (min-width:320px) and (max-width:1080px){
  width:300px;
  height:431px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
margin:auto;
}

`;

const DetailCard = styled.div`
width:450px;
height:180px;
background-color: rgba(142, 174, 188 ,20%);
display:flex;
flex-direction:row;

@media screen and (min-width:320px) and (max-width:1080px){
  width:300px;
  height:180px;

}
`;



const ScreenView = styled.div`
width:225px;

height:180px;
background-color: rgba(142, 174, 188 ,20%);
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

@media screen and (min-width:320px) and (max-width:1080px){
  width:150px;
  height:180px;
  font-size:5px;

}
`;


const ScreenP = styled.p`
color: #00B4D8; 
font-familiy: Poppins,Regular;
font-size: 27px;
 margin-top: 30px;
 
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
}
`;

const BillHead = styled.div`
width:225px;
height:180px;
font-family:Poppins,Regular;
font-size:20px;
font-weight:600;
background-color: rgba(142, 174, 188 ,20%);
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
@media screen and (min-width:320px) and (max-width:1080px){
  width:150px;
  height:180px;
  font-size:15px;
  font-size:12px;
  display:flex;
 
justify-content:center;
align-items:center;
flex-direction:column;

}

`;

const MoneyCard = styled.div`
width:450px;
height:120px;
background-color:green;
display:flex;
@media screen and (min-width:320px) and (max-width:1080px){
  width:300px;
  height:120px;
  font-size:15px;
}

`;
const MoneyLeftRow = styled.div`
width:225px;
background-color:#F2F4F5;
height:120px;

display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

@media screen and (min-width:320px) and (max-width:1080px){
  width:250px;
  height:120px;
  font-size:15px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
`;

const MoneyRightRow = styled.div`
width:225px;
background-color:#F2F4F5;
height:120px;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
@media screen and (min-width:320px) and (max-width:1080px){
  width:250px;
  height:120px;
  font-size:15px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
`;



const BillCard1 = styled.div`
margin-left:5rem;
width:600px;
height:431px;
background-color: #F8F8F8;
margin-bottom:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:300px;
  height:431px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
 padding-top:5rem;
margin:auto;
}

`;

const DetailCard1 = styled.select`

width:500px;
height:5rem;
// height:180px;
// background-color: rgba(142, 174, 188 ,20%);
// display:flex



@media screen and (min-width:320px) and (max-width:1080px){
  width:250px;
  height:5rem;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

`;



const ScreenView1 = styled.div`
// width:225px;

// height:180px;
// background-color: rgba(142, 174, 188 ,20%);
// display:flex;
// justify-content:center;
// align-items:center;
// flex-direction:column;
`;

const Input = styled.input`

width:195px;
height:37px;
border:1px solid #A2A2A2;
border-radius:5px;
@media screen and (min-width:320px) and (max-width:1080px){
  width:150px;
  height:2rem;
  margin-top:1rem;
}
`;


const Input1 = styled.div`
width:600px;
border-radius:5px;
background-color:#F2F4F5;
height:3rem;
font-size:18px;
align-items:center;
display: flex;
padding-left:20px;
font-family:Poppins,Regular;
color:black;
font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){
  width:200px;
  height:4rem;
  font-size:10px;
}

`;



const BillHead1 = styled.div`
// width:225px;
// height:180px;
// font-family:Poppins,Regular;
// font-size:20px;
// font-weight:600;
// background-color: rgba(142, 174, 188 ,20%);
// display:flex;
// justify-content:center;
// align-items:center;
// flex-direction:column;

`;

const MoneyCard1 = styled.div`
margin-top:10px;
width:450px;
height:180px;
background-color:#F8F8F8;
display:flex;
flex-direction:row;
// justify-content:center;
align-items:center;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
flex-direction:column;
}


`;
const MoneyLeftRow1 = styled.div`
 width:200px;
//  border:2px solid #8EAEBC;
 border-radius:5px;
padding-left:10px;
 background-color:#F2F4F5;
 height:120px;
box-shadow: 1.5px 1.3px 1.5px #00000029;

@media screen and (min-width:320px) and (max-width:1080px){
 height:120px;
 width:200px;
}


`;

const MoneyRightRow1 = styled.div`
width:200px;
margin-left:10px;
background-color:#E5E5E5;
height:120px;
border-radius:5px;
border:2px solid #E5E5E5;

@media screen and (min-width:320px) and (max-width:1080px){
  height:120px;
  width:200px;
margin-top:1rem; 
}
`;

const MediaCard = styled.div`
margin-top: 4rem;
margin-left: 2rem;

@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
display:flex;
flex-direction:column;

}
`;


const Navi = styled.div`
 margin-top: 1rem;
 margin-left:-55rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
 
  }

`;

const Buttonaddcard = styled.button`
display:flex;
align-items:center;
justify-content :center;
  width: 420px;
  height: 50px;
  margin-Top:40px;
  font-family:Poppins, Medium;
  font-weight:600;
  top: 869px;
    left: 757px;
    width: 407px;
    height: 54px;

    border-radius: 10px;
    opacity: 1;
background-color: #1DBF73;
color: white;
border: 0px;
box-shadow: -15px -15px 25px #FFFFFF;
border-radius: 10px;
@media screen and (min-width:320px) and (max-width:1080px){
 
  width: 208px;
  height: 54px;
 

}
`;

const InputL = styled.input`
display:flex;
align-items:center;
justify-content :center;
  width: 420px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;

  border:1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;

  margin:auto;
@media screen and (min-width:320px) and (max-width:1080px){
 font-size :12px;
  width: 215px;
  height: 54px;
 

}
`; 