import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { cardShadow, hoverEffect, themeColor } from "../utils";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { CheckBox } from '@mui/icons-material';
// Add this import statement
import Checkbox from '@mui/material/Checkbox';
import { useIntl } from 'react-intl';

import { AiOutlineSortAscending } from 'react-icons/ai';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Grid } from '@mui/material';
import { useAuthToken } from "../TokenContext";
const TaskList = ({ tasks, markTaskComplete, onSort, sortOrder, podNameFilter, setPodNameFilter }) => {
  const [statusFilter, setStatusFilter] = useState('all');
  const filteredTasks = tasks.filter((task) => {
    const isStatusMatch = statusFilter === 'all' || task.status === statusFilter;
    const isPodNameMatch = podNameFilter === 'all' || task.project_key.toLowerCase() === podNameFilter;
    return isStatusMatch && isPodNameMatch;
  });
  const intl = useIntl();

  return (
    <Div>
      <RecommendProject>
      <Grid container justifyContent="center">
 <Grid item xs={12} md={14} lg={15}>
        <CardContent>
         
            <>
              <TableContainer component={Paper} >
                <Table component={Paper} rea sx={{ maxWidth: 1200, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
                    <TableRow>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        <SelectField id="podNameFilter" value={podNameFilter} onChange={(e) => setPodNameFilter(e.target.value)}>
                          <option value="all">{intl.formatMessage({ id: 'podName' })}</option>
                          {Array.from(new Set(tasks.map((task) => task.project_key))).map((podName) => (
                            <option key={podName} value={podName.toLowerCase()}>{podName}</option>
                          ))}
                        </SelectField>
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podTask' })}</TableCell>

                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'description' })}</TableCell>
                      <TableCell align="right" onClick={onSort} sx={{ fontWeight: 'bold', cursor: 'pointer' }}>{intl.formatMessage({ id: 'dueDate' })} {sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        <SelectField id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                          <option value="all">{intl.formatMessage({ id: 'all' })}</option>
                          <option  value="To Do">{intl.formatMessage({ id: 'toDo' })}</option>
          <option  value="In Progress">{intl.formatMessage({ id: 'inProgress' })}</option>
          <option  value="Done">{intl.formatMessage({ id: 'Done' })}</option>
          
                        </SelectField>

                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'blocker' })}</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'markComplete' })}</TableCell>
                    </TableRow>
                  </TableHead>
                  {filteredTasks.length === 0 ? (
            <>{intl.formatMessage({ id: 'noPodTasks' })}</>
          ) : (
                  <TableBody>
                    {filteredTasks.map((task) => (
                      <TableRow
                        key={task.pod_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                          '&:nth-child(even)': {
                            backgroundColor: '#90E0EF'
                          },
                          '&:nth-child(odd)': {
                            backgroundColor: '#caf0f8'
                          },
                          '& td, & th': {
                            fontFamily: 'Poppins,Medium',
                            fontSize: '15px',
                            color: 'black',
                          },
                        }}
                      >
                        <TableCell align="right">{task.project_key}</TableCell>
                        <TableCell align="right">{task.pod_task_name}</TableCell>
                        
                        <TableCell align="right">{task.description}</TableCell>
                        <TableCell align="right">{moment(task.due_date).format('MM-DD-YYYY')}</TableCell>
                        <TableCell align="right">{task.status}</TableCell>
                        <TableCell align="right">
      {task.blockercheck === 1 ? (
        // Render a checked checkbox and disable it
        <CheckBox checked disabled />
      ) : (
        // Render an unchecked checkbox that can be checked
        <></>
      )}
    </TableCell>
    <TableCell align="right">
  {task.status === 'done' ? (
    // Render a checked checkbox and disable it
    <Checkbox checked disabled />
  ) : (
    // Render an unchecked checkbox that can be checked
    <Checkbox
      onChange={() => markTaskComplete(task.pod_task_id, task.jira_item, task.pod_id, task.candidate_id, task.pod_task_name)}
    />
  )}
</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  )}
                </Table>
              </TableContainer>
            </>
          
        </CardContent>
        </Grid>
</Grid>
      </RecommendProject>
    </Div>
  );
};

const TalentViewTask = () => {
  const [tasks, setTasks] = useState([]);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [podNameFilter, setPodNameFilter] = useState('all');
  const token = useAuthToken();

  const intl = useIntl();

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchTasks();
  }, [token]);

  const fetchTasks = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(process.env.REACT_APP_TALENT_CLIENTVIEWMYTASK, { candidate_Id: user.data[0].c_id },{headers:headers});
      setTasks(response.data.data);
        // Sort tasks by due date in descending order
        const sortedByDueDate = [...response.data.data].sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
        setSortedTasks(sortedByDueDate);
  
      console.log('skkk', response.data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  // Inside TalentViewTask component
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
  // Sort tasks only when the component is initially loaded
  if (isLoading) {
    const sorted = [...tasks].sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setSortedTasks(sorted);
    setIsLoading(false);
  }
}, [tasks, sortOrder, isLoading]);


  const markTaskComplete = async (taskId,jiraItem,podId,candidateId,taskname) => {
    try {
      const payload = {
        pod_task_id: taskId,
        jira_item: jiraItem,
        pod_id: podId,
        candidateID: candidateId,
        status:'done',
        pod_task_name:taskname,
      };
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
      console.log('payloas',payload)
      await axios.post(process.env.REACT_APP_TALENT_UPDATEPODTASKSTATUS, payload, {headers:headers});
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.pod_task_id === taskId ? { ...task, isComplete: true } : task
        )
      );
       // Show an alert when the task is successfully marked as completed
    alert('Task marked as completed successfully!');
    fetchTasks();
      // window.location.reload(); 
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };

  const handleSort = () => {
    const sorted = [...sortedTasks].sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setSortedTasks(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Div>
      <h2>{intl.formatMessage({ id: 'taskManager' })}</h2>
      
      {tasks.length > 0 ? (
        <TaskList
        tasks={sortedTasks}
        markTaskComplete={markTaskComplete}
        onSort={handleSort}
        sortOrder={sortOrder}
        podNameFilter={podNameFilter}
        setPodNameFilter={setPodNameFilter}
        />
      ) : (
        <CardContent>
          <p>{intl.formatMessage({ id: 'noPodTasks' })}</p>
        </CardContent>
      )}
    </Div>
  );
};

export default TalentViewTask;

const Div = styled.div`
  // display: flex;
  display: inline;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin:auto;
  margin-top: 6rem;
 
  

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 24rem;
  }
`;

const CardContent = styled.div`
  margin: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-family: Poppins, Medium;
  // width: 65rem;
  height: max-height;
  padding: 1rem;
  background-color: #f2f4f5;
  box-shadow: ${cardShadow};
  transition: 0.45 ease-in-out;

  &:hover {
    box-shadow: ${hoverEffect};
  }

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    //width: 20rem;
    margin: auto;
  }
`;

const RecommendProject = styled.div`
  display: flex;
  jutify-content: center;
  flex-direction: row;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 100%;
    height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

const Button1 = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;
  width: 8rem;
  height: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: white;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #00B4D8;
  box-shadow: 1px 3px 6px #00000029;
  width: 8rem;
  height: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: white;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
  }
`;

const SelectField = styled.select`
 
  border-radius: 5px;
  width: 9.5rem;
  height: 3rem;
  font-weight: 600;
  color: white;
  font-family: Poppins, Medium;
background-color:#00B4D8;
border:none;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    margin: auto;
  }
`;
