import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const BarGraphstories = ({initialPodIds,selectedSprint,sprintStartDate,sprintEndDate}) => {
  const [metrics, setMetrics] = useState(null);
  const chartId = useRef(uuidv4());
  const chartRef = useRef(null); // Reference to the chart instance
  const { user } = useSelector((state) => state.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const intl = useIntl();

  const token = useAuthToken();
  
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [token, user.data[0].type, initialPodIds,selectedSprint,sprintStartDate,sprintEndDate]);
  
  const fetchData = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    if (user.data[0].type === "stakeholder") {
      await fetchStakeholderMetrics();
    } else {
      fetchClientMetrics();
    }
  };
  
  const fetchStakeholderMetrics = async () => {
    try {
      const payload = {};
  
     
        payload.pod_id = initialPodIds || [];
        payload.org_code = user.data[0].client_code;
      
      payload.sprint_name = selectedSprint || '';
      payload.sprint_start_date = sprintStartDate || '';
      payload.sprint_sprint_end_datename = sprintEndDate || '';

      const response = await fetch(process.env.REACT_APP_STAKEHOLDER_CODEDASHBOARDSTAKEHOLDER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      console.log('API Response:', data);
  
      if (data?.body) {
        const responseJSON = data.body;
        setMetrics(responseJSON);
        displayChart(responseJSON);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };
  
  const fetchClientMetrics = async () => {
    Chart.register(...registerables);
  
    if (initialPodIds && initialPodIds.length > 0) {
      fetchMetricsPod();
    } else {
      fetchMetrics();
    }
  };
  
  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_CODEDASHBOARDMANAGER,
        {
          method: 'POST',
          headers: {
            Authorization: token, 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            sprint_name: selectedSprint || '',
    sprint_start_date:sprintStartDate||'',
    sprint_end_date: sprintEndDate ||''

          }),
        }
      );

      const data = await response.json();
      console.log('API Response:', data);

      if (data?.body) {
        const responseJSON = data.body;
        setMetrics(responseJSON);
        displayChart(responseJSON);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };

  const fetchMetricsPod = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GITHUBCODEDASHMANGEPODID,
        {
          method: 'POST',
          headers: {
            Authorization: token, 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pod_id: initialPodIds|| null, sprint_name: selectedSprint || '',
          sprint_start_date:sprintStartDate||'',
          sprint_end_date: sprintEndDate ||''
       }),
        }
      );

      const data = await response.json();
      console.log('API Response:', data);

      if (data?.body) {
        const responseJSON = data.body;
        setMetrics(responseJSON);
        displayChart(responseJSON);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };

  const displayChart = (data) => {
    const ctx = document.getElementById(chartId.current);
  
    if (!ctx) {
      console.error(`Element with ID ${chartId.current} not found.`);
      return;
    }
  
    if (chartRef.current) {
      chartRef.current.destroy(); // Destroy the previous chart instance
    }
  
    if (data?.all_repo_checkins) {
      const { current_week, previous_week, sprint_commit_lines } = data.all_repo_checkins;
  
      if (current_week === undefined && previous_week === undefined && sprint_commit_lines === undefined) {
        // No meaningful data present, don't display the chart and show "No data available" message
        ctx.style.display = 'none';
        document.getElementById('no-data-message').style.display = 'block';
      } else {
        // Check if any meaningful data is present before creating and displaying the chart
        let chartData = [];
        let labels = [];
  
        if (current_week !== undefined && previous_week !== undefined) {
          chartData = [previous_week, current_week];
          labels = ['Previous Week', 'Current Week'];
        } else if (sprint_commit_lines !== undefined) {
          chartData = [sprint_commit_lines];
          labels = ['Sprint Commit Lines'];
        }
  
        chartRef.current = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Code',
                data: chartData,
                backgroundColor: ['seagreen', 'red'],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              annotation: {},
              legend: {
                display: false,
              },
            },
            layout: {
              padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              },
            },
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                label: function (tooltipItem, chartData) {
                  const dataIndex = tooltipItem.dataIndex;
                  const datasetIndex = tooltipItem.datasetIndex;
                  const value = chartData.datasets[datasetIndex].data[dataIndex];
                  const label = chartData.labels[dataIndex];
                  return `${label}: ${value}`;
                },
              },
            },
            onClick: (event, elements) => {
              if (elements.length > 0) {
                openModal();
              }
            },
          },
        });
  
        // Display the chart and hide the "No data available" message
        ctx.style.display = 'block';
        document.getElementById('no-data-message').style.display = 'none';
      }
    } else {
      // Data is not present or empty, show "No data available" message
      ctx.style.display = 'none';
      document.getElementById('no-data-message').style.display = 'block';
    }
  };
  
  
  
  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
      
        <div className="chart-wrapper" style={{marginTop:"10px"}}>
          <canvas id={chartId.current} width="150" height="150"></canvas>
          <p id="no-data-message" style={{ display: 'none' }}>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        </div>
      </div>

      
    </div>
  );
};
BarGraphstories.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default BarGraphstories;