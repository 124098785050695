import React, { useState } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import axios from 'axios';
import '../css/register.css';
import { Link, useHistory } from "react-router-dom";
import { register } from '../action/auth';
import { useDispatch, useSelector } from "react-redux";
import talentlabel from '../assets/talentlabel.png';
import clientlabel from "../assets/clientlabel.png";
import candidatelabel from "../assets/candidatelabel.png";
import pod from "../assets/customer.png";
import styled from 'styled-components';
import partner from '../assets/partner.png';
import customer from '../assets/customer.png'

import AWS from 'aws-sdk';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const Register = () => {
  const dispatch = useDispatch();
  const [Confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [User, setUser] = useState({
    username: "",
    email: "",
    code:'',
    password: "",
    usertype: "",
    authCode: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  });

  const clearErrorState = () => {
    setUser(prevState => ({
      ...prevState,
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    }));
  };


  const [isRegistered, setIsRegistered] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    const { username, code, email, usertype, password, confirmpassword } = User;
  
    if (usertype === '') {
      alert("Please select a user type!");
      setLoading(false);
      return;
    } else if (code === '') {
      alert("Please select a Talent Code!");
      setLoading(false);
      return;
    } else if (email === '') {
      alert("Please enter an email!");
      setLoading(false);
      return;
    } else if (!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      alert("Please enter a valid email address!");
      setLoading(false);
      return;
    } else if (password === '') {
      alert("Please enter a password!");
      setLoading(false);
      return;
    } else if (confirmpassword === '') {
      alert("Please enter the password confirmation!");
      setLoading(false);
      return;
    } else if (password !== confirmpassword) {
      alert("Passwords do not match!");
      setLoading(false);
      return;
    }
  
    try {
      const usertype =
        User.usertype === "candidate"
          ? 1
          : User.usertype === "podprovider"
          ? 2
          : User.usertype === "manager"
          ? 0
          : User.usertype === "stakeholder"
          ? 4
          : 3;
  
      const registrationResponse = await axios.post(process.env.REACT_APP_CANDIDATESIGNUP, {
        usertype,
        email: User.email,
        code: User.code,
      });
  
      // console.log("Registration response:", registrationResponse);
      // console.log("Registration response data:", registrationResponse ? registrationResponse.data : "N/A");
  
      // if (registrationResponse.status === 200) {
      //   const responseData = registrationResponse.data; // Get the response data
  
        // Add this console log to check the condition
        //console.log("Check condition:", responseData === 'Candidate Registred!');
  
        if (registrationResponse.data.status == 200 ) { // Compare with 'Candidate Registred!' string
          // console.log('res', registrationResponse.data);
          alert('Registered Successfully!');
try {
        const signUpResponse = await Auth.signUp({
          username,
          password,
          code,
          attributes: {
            email,
          }
        });
        setConfirm(true);
        setLoading(true)
        console.log(signUpResponse);
      } catch (error) {
        setLoading(false);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setUser(prevState => ({
          ...prevState,
          errors: {
            cognito: err
          }
        }));
      }
          // setLoading(false);
         
        } if(registrationResponse.data.status == 400)  {
          alert("Invalid invite code");
          setLoading(false);
          setConfirm(false);
          
        }
      // } else {
      //   alert("Invalid invite code");
      //   setLoading(false);
      //   setConfirm(false);
      //   console.log('false', registrationResponse.data);
      //   // alert("Registration failed. Server returned an error or no response.");
      // }
    } catch (error) {
      console.error("Error during registration:", error);
      setLoading(false);
      alert("Registration failed. An error occurred during the registration process. Please try again later.");
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          cognito: err,
        },
      }));
    }
  };

  

  const confirmSignUp = async (event) => {
    event.preventDefault();
  
    try {
      const confirm = await Auth.confirmSignUp(User.username, User.authCode);
  
      const usertype =
        User.usertype === "candidate"
          ? 1
          : User.usertype === "podprovider"
          ? 2
          : User.usertype === "manager"
          ? 0
          :User.usertype === "stakeholder"
          ?4
          : 3;
  
      if (confirm === "SUCCESS") {
        // dispatch(register(usertype, User.email, User.code))
      
          

            alert("Your account has been successfully verified!");
             history.push("/login");
        
          
      }
    } catch (error) {
      console.log(error);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setUser((prevState) => ({
        ...prevState,
        errors: {
          cognito: err,
        },
      }));
    }
    
  };
  
 

  const onInputChange = e => {
    clearErrorState();
    setUser(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }));

    document.getElementById(e.target.id).classList.remove("is-danger");
  };

  return (
    <div className="container">
      <FormErrors formerrors={User.errors} />

      {Confirm === false ? (
        <MAN2>
          <h1 className='register-header'>Register</h1>

          {loading === true ? (<p>Loading...</p>) : (<p></p>)}

          <form onSubmit={handleSubmit}>
            <Field1>
              <SelectL
                id="usertype"
                className="select-type"
                value={User.usertype}
                onChange={onInputChange}
              >
                <option value="" disabled hidden>Select User Type</option>
                <option value="candidate">Talent</option>
                <option value="podprovider">Partner</option>
                <option value="manager">Manager</option>
                <option value="stakeholder">Stake Holder</option>
                <option value="customer"> Admin</option>
              </SelectL>
            </Field1>
            <div className="field">
              <p className="control">
                <InputL
                  className='input2'
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter Username"
                  value={User.username}
                  onChange={onInputChange}
                />
              </p>
            </div>

            <div className="field">
              <p className="control">
                <InputL
                  className='input2'
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter E-mail"
                  value={User.email}
                  onChange={onInputChange}
                />
              </p>
            </div>

            <div className="field">
              <p className="control">
                <InputL
                  className='input2'
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  value={User.password}
                  onChange={onInputChange}
                />
              </p>
            </div>

            <div className="field">
              <p className="control">
                <InputL
                  className='input2'
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm Password"
                  value={User.confirmpassword}
                  onChange={onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <InputL
                  className='input2'
                  type="code"
                  id="code"
                  placeholder="Code"
                  value={User.code}
                  onChange={onInputChange}
                />
              </p>
            </div>

            <div className="field">
              <p className="control">
                <Button>Register</Button>
              </p>
            </div>

            <div className="field">
              <p className="control">
                Already have an account? <Link to="/login"><b className='login-label'>Login</b></Link>
              </p>
            </div>
          </form>
        </MAN2>
      ) : (
        <div>
          <form onSubmit={confirmSignUp}>
            <h1 className='register-header'>Welcome!</h1>
            <br></br>
            <br></br>
            <b>You have successfully registered with Globl.ai</b>
            <br></br>
            <br></br>
            <b>We've sent you an email. Please enter the code to verify your account</b>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="field">
              <p className="control">
              <input
            type="number"
            id="authCode"
            aria-describedby="userNameHelp"
            placeholder="Enter Confirmation Code"
            value={User.authCode}
            onChange={onInputChange} // Update the onChange handler
          />
              </p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="field">
              <p className="control">
                <button className="login" style={{ "width": "400px", height: "50px", marginTop: "20px", fontFamily: "Poppins,Medium", fontWeight: '600', boxShadow: '15px 15px 25px #00000029' }}>
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      )}

      <br></br>
      <br></br>
    </div>
  );
}

export default Register;

const MAN2 = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #D8D8D8;
  border-radius: 16px;
  opacity: 1;
  padding: 30px;
  width: 650px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    over-flow: hidden;
  }
`;

const Field1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin margin:auto;
    justify-content: center;
    align-items: center;
  }
`;

const InputL = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    font-size: 12px;
    width: 215px;
    height: 54px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;
  margin-Top: 40px;
  font-family: Poppins, Medium;
  font-weight: 600;
  top: 869px;
  left: 757px;
  width: 407px;
  height: 54px;
  border-radius: 10px;
  opacity: 1;
  background-color: #1DBF73;
  color: white;
  border: 0px;
  box-shadow: -15px -15px 25px #FFFFFF;
  border-radius: 10px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 208px;
    height: 54px;
  }
`;
const SelectL = styled.select`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    font-size: 12px;
    width: 215px;
    height: 54px;
  }
`;