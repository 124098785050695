import { green } from "@mui/material/colors";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { postpodproject, postproject } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Select from "react-select";                                                                     
import profile from './assets/profile_img.png';
import { event } from 'jquery';
import styled from 'styled-components';
import { style } from '@mui/system';
import star from './assets/star.png';
import { Podcasts } from "@mui/icons-material";
// import './BrowseTalent.css';
import CloseIcon from '@mui/icons-material/Close';
import allergens from './allergens';
import PersonIcon from '@mui/icons-material/Person';

import CreatePodSkillspage from "./CreatePodSkillspage";
import PodCreatedSuccessFullyCan from './PodCreatedSuccessFullyCan';
import OptionsOutsideSelect from "../OptionsOutsideSelect";
import { Media } from "react-bootstrap";
import S3FileUpload from 'react-s3';
import moment from 'moment';
import { useAuthToken } from "../TokenContext";

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)

const Createpod = (props) => {

  
  const token = useAuthToken();



const [userData, setUserData] = useState([]);
const [userSearchData, setUserSearchData] = useState([]);
const [name, setName] = useState('');
const [profession, setProfession] = useState('');
const [inputValue, setInputValue] = useState('');
const [job, setJob] = useState('');
const [data, SetData] = useState([]);
const [Team, SetTeam] = useState([]);
const [Candidatetoadd, SetCandidatetoadd] = useState('');
const [Addcandidatescreen, setAddcandidatescreen] = useState(false);
const [Getcandidates, setGetcandidates] = useState([]);
const [s3img,setS3img] = useState('');

const [Image, setImage] = useState(""); 
const [Uploading, setUploading] = useState(false)

const [currentPage, setcurrentPage] = useState(1);
const [itemsPerPage, setitemsPerPage] = useState(3);
const [pageNumberLimit, setpageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
const [value,setValue]=useState('default');
const[mis,setMis]=useState('');
const [bdg,setBdg]=useState('');
const[mr,setMr]=useState('');
const [pri,setPri]=useState('');
const[sec,setSec]=useState('');
const[tz,setTz]=useState('');
const[Title,setTitle]=useState('');
const[avail,setAvail]=useState('');
const [pref,setPref]=useState('');
const[rep,setRep]=useState([]);
const[Candidatedata,setCandidatedata]=useState([]);
const [len,setLen]=useState(0);
const handleClick = (event) => {
  setcurrentPage(Number(event.target.id));
}
const available=(val) =>{
  setAvail(val.target.value);
}

const jobtitle=(val) =>{
  setTitle(val.target.value);
}
const resetAvailableField =() => {
  setAvail('');
}
const resetTitleField =() => {
  setAvail('');
}
const preferfield=(val) =>{
  setPref(val.target.value);
}
const resetPreferField =() => {
  setPref('');
}
const handleUserInput = (val) => {
  setMr(val.target.value);
}
const resetInputField = () => {
  setMr('');
}
const handleJob = (val) => {
  setMis(val.target.value);
}
const resetJobField = () => {
  setMis('');
}
const budgetfield =(val) =>{
setBdg(val.target.value);
}
const resetBudgetField = () => {
setBdg('');
}
const primaryfield =(val) =>{
// alert(val.target.value);
setPri(val.target.value);
}
const resetPrimaryField = () => {
setPri('');
}
const secondaryfield =(val) =>{
setSec(val.target.value);
}
const resetSecondaryField = () => {
setSec('');
}
const timezonefield =(val) =>{
setTz(val.target.value);
}
const resetTimezoneField = () => {
setTz('');
}
const handleChange = (e) => {
setValue(e.target.value);
};
const pages = [];
for (let i = 1; i <= Math.ceil(rep.length / itemsPerPage); i++) {
  pages.push(i);
}
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = rep.slice(indexOfFirstItem, indexOfLastItem);
const renderPageNumbers = pages.map(number => {
  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
    return (
      <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
    );
  } else {
    return null;
  }
});

const { isLoggedIn, user } = useSelector(state => state.auth);
const dispatch = useDispatch();

const [Loading, setLoading] = useState(false);

var scrollTop = () => {
  window.scrollTo(0, 0);
};


useEffect( async (token) => {

  try {
    const data = {
      clientID : user.data[0].emp_id 
    }

   // console.log(data)

    // https://yhoi3rq78b.execute-api.us-east-1.amazonaws.com/prod/getteammembers
    const response = await axios.post(process.env.REACT_APP_CLIENT_GETTEAMMEMBERS, data);

    //console.log(response)
    SetTeam(response.data.data);     

    
    const getpodbody = {
      "candidateID": user.data[0].c_id
    };
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // https://xujexobo25.execute-api.us-east-1.amazonaws.com/dev/getcandidatepods
    let getpod = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEPODS, getpodbody, {headers:headers});

        
    setGetcandidates(getpod.data.data.candidates)



  } catch (error) {
    console.log(error);
  }



}, [token])

const onImageUpload = e => {

  const file = e.target.files[0];
console.log(file);
if (file) {
setImage(URL.createObjectURL(file));
}
  S3FileUpload.uploadFile(file, config)
  .then(data => {

    
    let s3url = data.location
  setS3img(s3url);


   
    setPoddata((prevState) => {
     return { ...prevState, picture: data.key };
  });
  setUploading(false)
})    
.catch(err => { console.log(err);     setUploading(false)
})
  
// setState((prevState) => {
//   return { ...prevState, [e.target.id]: e.target.value };
// });
}



function filter(c_id) {

return Poddata.candidatesid.map((data) => {
    
    return data.id == c_id;
    
    });

};

const [podName,setPodName]=useState('');
const [podDescription,setPodDescription]=useState('');
const [podtype,setPodtype]=useState('');
const [techStack,setTechStack]=useState('');

const [podsize,setPodsize]=useState('');

const [skills,setSkills]=useState([]);
const [budget1,setBudget1]=useState('');
const [countrypod,setCountrypod]=useState('');
const [timezonepod,setTimezonepod]=useState('');
const [multiskill,setMultiskill]=useState([]);


const [selected, setSelected] = useState([]);
const handleSelectChange = (values) => {
  setSelected(values);
};
const [Poddata,setPoddata]=useState({
podName: '' ,
skills: skills,
budget: '',
country:'',
podDescription:'',
timezone:'',
canID : user.data[0].c_id,
candidatesid : [],
podsize :'' ,
podtype : '',
techStack : "",
picture : s3img,

});


const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white",height:'50px',border:"2px solid #B7B7B7", borderRadius: '10px',

  paddingLeft:'20px',
  color:'grey',
  fontWeight:'600'

 
})

};




   const onInputChangepodName =(e)=>{
    setPodName(e.target.value);
  }
  const onInputChangepoddescription =(e)=>{
    setPodDescription(e.target.value);
  }
  const onInputChangepodsize =(e)=>{
    setPodtype(e.target.value);
  }
  const onInputChangepodtype =(e)=>{
    setPodsize(e.target.value);
  }
  const onInputChangetechstack =(e)=>{
    setTechStack(e.target.value);
  }

  
  const onInputChangetimezonepod =(e)=>{
    setTimezonepod(e.target.value);
  }
  const onInputChangecountrypod =(e)=>{
    setCountrypod(e.target.value);
  }
  const handleSelectChangeskills = (values) => {


    setMultiskill(values);
    var pushskill= [];
    
values.map(function(ext) {
  var c= {
    'label':ext.label
  };
pushskill.push(c)
})

setPoddata((prevState) => {
  return { ...prevState, skills: pushskill };
});

    // console.log(values,multiskill,'@@@@@@@@@@@@@@@@@@@');
  };


  const onInputChangebudget1 =(e)=>{
    setBudget1(e.target.value);
  }




const addCandidate = async (c_id, name) => {



    Poddata.candidatesid.push({ id : c_id, name : name });

    setAddcandidatescreen(false);
  
  }



  



// const [techStack,setTechStack]=useState('');
// const [budget,setBudget]=useState('');
// const [podName,setPodName]=useState('');

const handleSubmitOptions = (e) => {
  e.preventDefault();
  
  if(Poddata.candidatesid.length > 0){

   
    // https://ml22ab9tfl.execute-api.us-east-1.amazonaws.com/dev/createpod
fetch(process.env.REACT_APP_TALENT_CREATEPOD,{
  method:'POST',
  headers: { 'Content-Type': 'application/json','Authorization' : token, },
  body:JSON.stringify(Poddata)
}).then(()=>{
alert("Pod is Created Successfully.")
 window.location.href = '/FindPods'
});

}else{
  alert("Please add the candidate to the pod!")
}

}

const onInputChange = e => {  
  
    setPoddata((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  

  const onInputChangebudget = e => {
   
    setPoddata((prevState) => {
      return { ...prevState, budget: e.target.value };
    });
  }




  // const onInputChangeselect = e => {
  //   console.log(e.target.value)
  //   setState((prevState) => {
  //     return { ...prevState, [e.target.name]: e.target.value };
  //   });
  // }






const renderData = (rep) => {
  return (
    <Pega>
    <Pagination2>
      <div className='paginationcontent'>
        <Num>
          <ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                Prev
              </button>
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > Next</button>
            </li>
            </Num2>
          </ol>
<Num1>
          <p className='length'> {Candidatedata.length} Candidates</p>   <br></br>
          </Num1>
        </Num>
<ol className='ol'>
        {
                  rep && rep.length > 0 ?
                    rep.map((dev,index )=>{
                      return <li className='smg' key={index}><div  className='name'>   <img style={{ width: '50px', height: '50px', borderRadius: "50px" }} src={dev.profilePicture}></img> <p className='firstname'>{dev.firstName}</p> </div> <div className='name2'><img  style={{width:'13px' ,height:'13px'}} src={star}></img>4.5<p className='place'>{dev.country}</p> </div> <div><p  className='name1'>{dev.work_position}</p></div><div className='skill'> <p className='about'>{dev.about}</p></div><div className='time'><p className='role'>{dev.primaryskill}</p> <p className='role'>{dev.secondaryskill}</p> </div>
                      <Col><p className='time1'>{dev.timezone}</p><p className='role'>{dev.availability}</p> <p className='role'>{dev.workhours}</p> {dev.currentStatus == "Yes" ? (<p className='role' style={{ color: "green" }}>Currently Available</p>) : (<><p className='role' style={{ color: "red" }}>Currently Unavailable</p> <p className='role' style={{ color: "red" }}>Available Date : { moment(dev.availabledate).format("YYYY-MM-DD")}</p></>)}<p className='budget1'>
                          
                        { filter(dev.c_id).includes(true) ? (
<><p className='budget1'>Already added to your pod.</p></>   ) : ( <>                        
<button className='sub' onClick={() => {


if(Poddata.country == dev.country){

  var then = new Date(dev.availabledate);
  var now = new Date();

  const msBetweenDates = Math.abs(then.getTime() - now.getTime());

  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

  

  if(dev.currentStatus == "no"){
    if(then > now) {

      if(daysBetweenDates < 30){
               addCandidate(dev.c_id);   
     }else{
       alert("Sorry, currenlty candidate is not available to add to team!"); 
     }  
   } else{
     alert("Sorry, candidate is not available to add to team!"); 
   }   
  }
  if (dev.c_id, dev.firstName){
    addCandidate(dev.c_id, dev.firstName);   
  }
}else{
  alert("The candidate you are adding is not in your region!"); 
  // console.log('786', dev.country)
}


}}>Add to Pod </button></>    )}</p></Col></li>
                    }
                    )
                    : 'No data'
                }
</ol>
      </div>
      </Pagination2>
      </Pega>
  )
}
useEffect(() => {
  per();
  setLen(rep.length);
 

}, [])
const handleNextbtn = () => {
  setcurrentPage(currentPage + 1);
  if (currentPage + 1 > maxPageNumberLimit) {
    setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  }
};
const handlePrevbtn = () => {
  setcurrentPage(currentPage - 1);
  if ((currentPage - 1) % pageNumberLimit == 0) {
    setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  }
};
const per=() =>{
  const kk= (mis!= 'default')? mis: '';
  const tk=(mr!= 'default')? mr: '';
  const bd=(bdg!= 'default')? bdg: '';
  const ps= (pri != 'default')? pri: '';
  const ss=(sec  != 'default')? sec: '';
  const tmz=(tz  != 'default')? tz: '';
  const avb=(avail != 'default')? avail: '';
  const Prefered=(pref != 'default')? pref: '';

  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json',  },
    body: JSON.stringify({
      "project_location" : kk,
      "project_name": Title,
      // "skills" : "",
      "budget" : bd,
      "primaryskill":ps,
      "secondaryskill":ss,
      "timezone":tmz,
      "availability":avb,
     "workhours":Prefered,
     "about": mr
  })
  
};

// https://q35udmt5od.execute-api.us-east-1.amazonaws.com/dev/project_search_by_location
fetch(process.env.REACT_APP_CLIENT_PROJECTSEARCHBYLOCATION,requestOptions)
.then((response) => response.json())
.then((json) => { setRep(json.data);
   setCandidatedata(json.data)  });

}
function getData (val){
setMis(val.target.value);

}
function hey (val){
setMr(val.target.value);

}
function budget(val){
  setBdg(val.target.value);
  
}
function priskill(val){
  setPri(val.target.value);

}
function secskill(val){
  setSec(val.target.value);
  
}
function tmzone(val){
  setTz(val.target.value);
  
}
function Availability(val){
  setAvail(val.target.value);
  
}
function preferworkhours(val){
  setPref(val.target.value);

}


const [selectedOption, setSelectedOption] = useState(null);
const handlechange = e => {
setSelectedOption(e);
}
const handlePageClick = (pdata) => {

}





const [selectedone, setSelectedone] = useState([]);
const handleSelectChangeone = (values) => {
setSelectedone(values);
};
const countryoptions = [
{ value: "0", label: "India", abbreviation: "Ind" },
{ value: "14", label: "USA", abbreviation: "US" },
{ value: "15", label: "Canada", abbreviation: "Can" }
];
const searchskillsoptions = [
{ value: "0", label: "Software Developer" },
{ value: "14", label: "Software Engineer" },
{ value: "15", label: "Computer Software" }
];

return (

<div>
   { Addcandidatescreen == false ? (<>
   
       <CreatePodScreen>
    
    <Headingtext1>Create your own pod and browse for projects</Headingtext1>

    <Container1 >
   
      <p
        style={{
          color: "#8EAEBC",
          marginTop: "2rem",
          marginBottom: "5rem",
          textAlign: "center",
          fontWeight: "600",
          fontSize: "40px",
        }}
      >
        Create Pod
      </p>

      {Getcandidates.length > 0 ? (<>

      <br></br>

      <p style={{ textAlign : "center"  }}>You already created the pod! </p>
</>
) : (
<>
   
      <form  onSubmit={handleSubmitOptions}>
<center>
      {   Image == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
             
             <PersonIcon  style={{ color :  "#8EAEBC", marginLeft : "33px"}}/>
             <input type="file" className="profileclass" onChange={onImageUpload} id="profilePicture" />
 
 
             </div>) : (  <img src={Image} alt='' style={{width:'100px',height:'100px',marginTop:"30px", borderRadius : "50px" }} id="profilePicture" ></img> )}</center>
             <br></br>

        <Postfield>
          <Label>Pod Name :</Label>
          <Input
            type="text"
            id="podName"
            onChange={onInputChange}
            placeholder="Enter Pod Name"
            value={Poddata.podName}
          
        //  onChange={}
            required
          />
        </Postfield>

        <Postfield>
        <Label>Pod Description :</Label>
          <Input
            type="text"
            id="podDescription"
            onChange={onInputChange}
            placeholder="Enter Pod Description"
            value={Poddata.podDescription}
          
        //  onChange={}
            required
          />
        </Postfield>

        <Postfield>
        <Label>Pod Type :</Label>
          <Select2
            onChange={onInputChange}
            id="podtype"
              placeholder="Pod Type"
              value={Poddata.podtype}

              >
            <option style={{ color: "black" }} value="" defaultValue>
         Pod Type
            </option>
                        <option value='Saas'>Saas</option>
                        <option value='Web app'>Web app</option>
                        <option value='Web app'>Mobile app</option>

          </Select2>
        </Postfield>

        <Postfield>
        <Label>Tech Stack :</Label>
          <Select2
            onChange={onInputChange}
            id="techStack"
              placeholder="Pod Tech Stack"
              value={Poddata.techStack}

              >
            <option style={{ color: "black" }} value="" defaultValue>
            Pod Tech Stack
            </option>
                        <option value='MEAN'>MEAN</option>
                        <option value='MERN'>MERN</option>
                        <option value='LAMP'>LAMP</option>
                        <option value='MEVN'>MEVN</option>

          </Select2>
        </Postfield>

        <Postfield>
        <Label>Pod Size :</Label>
          <Select2
            onChange={onInputChange}
            id="podsize"
              placeholder="Pod Size"
              value={Poddata.podsize}

              >
            <option style={{ color: "black" }} value="" defaultValue>
         Pod Size
            </option>
                        <option value='2'>2</option>
                        <option value='5'>5</option>
                        <option value='7'>7</option>

          </Select2>
        </Postfield>
     
        
        <Postfield>
        <Label>Country :</Label>
          <Select2
         
            onChange={onInputChange}
            id="country"
              placeholder="Country"
              value={Poddata.country}
          >
            <option style={{ color: "black" }} value="" defaultValue>
         Country
            </option>
            <option value='USA'>USA</option>
                        <option value='India'>India</option>
                        <option value='Canada'>Canada</option>
          </Select2>
        </Postfield>
        <Postfield>
        <Label>Time Zone :</Label>
        <Select2
         
         onChange={onInputChange}
         id="timezone"
         value={Poddata.timezone}
           placeholder="Time Zone"
       >
         <option style={{ color: "black" }} value="" defaultValue>
      Time Zone
         </option>
         <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>

       </Select2>
     </Postfield>
    
       

        <Postfield>
        <Label>Skills :</Label>
        <CreatePodSkillspage
         styles={colourStyles}
                     onChange={handleSelectChangeskills}
                     isMulti
                     options={allergens}
                   placeholder='Skills'
                     value={multiskill}
                    
                
                />
        </Postfield>



        <br></br>
        <Postfield1>
       <Span >
          Talents
          </Span>
          {/* <Postfield1> */}
{Poddata.candidatesid.length >= Poddata.podsize ? (
<Button2
            type="submit"  onClick={() => { setAddcandidatescreen(true) }} 
            disabled>
            Add + 
          </Button2>       
) : (<Button2
    type="submit"  onClick={() => { setAddcandidatescreen(true) }} 
  >
    Add + 
  </Button2>   )}
        
        </Postfield1>

        { Poddata.candidatesid.map((data, i) => {

return (

        <Postfield>
              <Select3>
            
            <p style={{ margin : "20px" }}>{data.name}</p>

            <p  style={{marginLeft:'auto' ,marginTop:'1rem',backgroundColor:"red",borderRadius:"50%",color:'white',cursor:'pointer'  }} onClick={() => {  Poddata.candidatesid.splice(i, i + 1); setPoddata({ candidatesid : Poddata.candidatesid }) } }><CloseIcon/></p>
            </Select3>

                                </Postfield>
   )})}


       
<center>
{/* <NavLink to='/PodCreatedSuccessFullyCan'> */}
<br></br>
  <br></br>
          <Button
            type="submit"
          >
            Create
          </Button>
          <br></br>
  <br></br>
          {/* </NavLink> */}
          </center>
      </form>

      </>)}

    </Container1>
  </CreatePodScreen>
  <br></br>
  <br></br>
</>) : (<>



<Browsecontainer>
  <div  >
    <Heading>
        <h3 style={{ fontSize: '1.625rem' ,marginLeft:'5rem'}}>Talent-Pod Lead-Talent Search</h3>
        </Heading>
        <br></br>
      <div  >  <Para> <p style={{ color: '#A2A2A2', fontSize: ' 1.125rem' }}>Find the best freelancers or talent pods to get your projects delivered at a faster pace!</p>
      </Para>
      </div>
      <br></br>
        <br></br>


    <Main>
      {/* <div className='center'> */}
        <Table>
          <div className='singlecontainer'>
            <Singlecontainer>
                  <td className='mis'>
                  <SearchTag>
                    <SearchOutlinedIcon style={{marginTop:'15px',marginLeft: '20px',margin:'auto',color:'#7E7E7E'}} />
                    <input     className='jobsearch' type='text' placeholder='Keyword' value={mr} onChange={((val)=>setMr(val.target.value),handleUserInput)}    />
                    </SearchTag>
                  </td>
                  {/* <td className='mis1' >
                  <Selecttag>
                    <select className='select'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                      <option value='default'>Location</option>
                      <option value='usa'>USA</option>
                      <option value='india'>India</option>
                      <option value='canada'>Canada</option>
                    </select>
                    </Selecttag>
                  </td> */}
               <td >  <Clear   onClick={() => { resetInputField(); resetJobField(); resetBudgetField(); resetPrimaryField(); resetSecondaryField(); resetTimezoneField();  resetPreferField(); resetAvailableField(); resetTitleField(); } }
                  >Clear</Clear>  </td>
                <Search>
                  <td  ><button style={{ marginTop:'10px',width: "10rem", height: '2.6rem', color: 'white', border: '1px solid  #1DBF73', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73', boxShadow: '1px 6px 9px #00000029',fontFamily:'Poppins,Regular',fontWeight:"500"}} onClick={() => per()}>Search</button></td>
                </Search>
            </Singlecontainer>
          </div>
          {/* <div className='main'> */}
         <Row>
              {/* <tr> */}
              <Row1>
                  <td><select className='options'  value={pri} onChange={((val)=> setPri(val.target.value),primaryfield )} >
                    <option value='default' >Primary Skill</option>
                    <option value='html'> HTML/CSS</option>
                    <option  value='JavaScript'>JavaScript/jQuery</option>
                    <option value='Adobe'>Adobe Illustrator</option>
                    <option value='React'>ReactJS</option>
                    <option value='AngularJS'>AngularJS</option>
                    <option value='Vue'>Vue</option>
                    <option value='Next'>Next</option>
                    <option value='Typescript'> Typescript</option>
                    <option  value='Gatsby'>Gatsby</option>

<option  value='ThreeJs'>Three.js</option>
                    <option value='NodeJs'>Node.js</option>
                    <option  value='Java'>Java</option>
                    </select></td>
                    <td><select className='options'  value={sec} onChange={((val)=>setSec(val.target.value), secondaryfield )} >
                      <option value='default' >Secondary Skill </option>
                      <option  value='Docker' >     Docker</option>
      <option value='PHP'  >PHP</option>
      <option   value='SQL'   >SQL</option>
      <option  value='Git'  >Git</option>
      <option value='Perl'  >Perl</option>
      <option  value='JavaScript'   >JavaScript</option>
      <option  value='Kotlin'   >Kotlin</option>
      <option value='Azure'  >Azure</option>
      <option   value='AWS'   >AWS</option>
      <option   value='Java'   >Java</option>
      <option   value='c++'   >c++</option>
                      </select></td>
                    </Row1>
                    <Row2>
                      <td >
<select className='options'   value={avail} onChange={((val)=>setAvail(val.target.value),available)}>
<option  value='default'>Availability</option>
<option value='parttime'>Part Time</option>
<option  value='fulltime' >Full Time</option>
</select>
</td>
<td><select className='options'   value={tz} onChange={((val)=> setTz(val.target.value) , timezonefield )}>
                    <option value='default' >Time Zone</option>
                    <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>
                    </select></td>
</Row2>
<Row2>
<td >
<select className='options'   value={pref} onChange={((val)=>setPref(val.target.value),preferfield)}>
<option  value='default' >Prefered Work Hours</option>
<option value='Morning Shift 9am-6pm'>Morning Shift 9am-6pm</option>
<option  value='Night Shift 10pm - 7am'>Night Shift 10pm - 7am</option>
</select>
</td>
<td >
<select className='options'   value={Title} onChange={((val)=>setTitle(val.target.value),jobtitle)}>

<option  value='default' >Job title</option>
<option value='Software Engineer'>Software Engineer</option>
<option  value='Full Stack Developer'>Full Stack Developer</option>
<option  value='Java Developer'>Java Developer</option>

</select>
</td>

</Row2>
<tr>
<td >
<select className='options'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                      <option value='default'>Location</option>
                      <option value='usa'>USA</option>
                      <option value='india'>India</option>
                      <option value='canada'>Canada</option>
                    </select>
</td>
</tr>
<tr></tr>
              {/* </tr> */}
            <tr> <td >
                  <button  className='optionsaplly' style={{  width: "10rem", height: '2rem', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73',  boxShadow: '1px 6px 9px #00000029',fontFamily:'Poppins,Regular',fontWeight:"500"}} onClick={() => per()}>Apply Filters</button>
                  </td></tr>
                  </Row>
          {/* </div> */}
        </Table>
    </Main>
    <div>
      {renderData(currentItems) }
    </div>
  </div>
</Browsecontainer>

</>)}
</div>

)
}

export default Createpod;


const CreatePodScreen = styled.div`
  display: flex;
  flex-direction: column
  ;
  font-family:Poppins,Medium;
  margin-left:330px;
  justify-content: center;
  align-items: center;
  // margin: auto;
  margin-top:5rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    margin: auto;
    }
`;
const Container1=styled.div`
font-family:Poppins,Medium;
    width: 60rem;
    height: max-height;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:22rem;
        height:max-height;
        }
`;
const Select2=styled.select`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;

const Select3=styled.p`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
display:flex;
// flex-direction:row;
justify-content:center;
align-items:center;

border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Multi=styled.div`
font-family:Poppins,Medium;
// width: 22rem;
// height:50px;
// border-radius: 10px;
// border: 2px solid #B7B7B7;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Button=styled.button`
font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:22rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;


const Button2=styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        margin-left:10rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
      width:5rem;
      height: 30px;
      margin-left:2rem;
        }
`;
const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;

const Label =styled.label`
font-family:Poppins,Medium;
font-weight: bold;
font-size:18px;
color :black;
width:10rem;
`;
const Postfield1=styled.span`
font-family:Poppins,Medium;
color: #8EAEBC;
font-weight: bold;
 font-size:18px;
display:flex;
justify-content:center;
align-items:center;
margin-left:5rem;
padding:10px
    @media screen and (min-width:320px) and (max-width:1080px){
       
        }
`;

const Input=styled.input`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Headingtext1=styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    padding-left:20px;

    margin-top: 5rem;
    }
`;

const Browsecontainer = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:100%;
display:flex;
align-items:center;
 justify-content:center;
flex-direction:column;
}
`;
const Main = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
margin:auto;
width:100%;
}
`;
const Table=styled.table`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
 justify-content:center;
flex-direction:column;
margin:auto;
width:20rem;
height:max-content;
}
`;
const Heading = styled.div`
margin-top: 9rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:70%;
margin-top:10rem;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Para =styled.p`
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:20rem;
margin:auto;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Singlecontainer = styled.tr`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
margin:auto;
width:20rem;

}
`;
const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;

const SearchTag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
width:20rem;
margin:auto;
border-bottom:1px solid #A2A2A2;
}
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  margin:auto;
 width:20rem;
  border-bottom:1px solid #A2A2A2;
}
`;
const Clear = styled.p`
margin-top:20px;
width:6rem;
margin-left:31rem;
cursor: pointer;
font-weight:600;
font-size: 18px;
font-family:Poppins,Regular;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
 flex-direction:column;
 margin-left:auto;
}
`;
const Search = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
width:100%;
align-items:center;
 flex-direction:column;
 margin-bottom:20px;
}
`;
const Row1 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Row2 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
// const Button = styled.button`
// @media screen and (min-width:320px) and (max-width:1080px){
//   display:flex;
// margin:auto;
//  flex-direction:column;
// }
// `;
const Pagination2 = styled.div`

height: max-content;
margin-left: 5rem;
  width:83.5rem;
 
  background-color: white;

margin-top: 3rem;
 border:1px solid #EFEFEF;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:20rem;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
margin-top:3rem;

}
`;
const Pega = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
width:100%;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
}
`;
const Numbers = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
justify-content:center;
width:7rem;
}
`;
const Num = styled.ol`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
//   align-items:center;
// justify-content:center;
flex-direction:column;
marginLeft:-3rem;
width:70%;
}
`;
const Num1 = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  // display:flex;
  margin:auto;
width:100%;
}
`;
const Num2 = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  flex-direction:column;
  align-items:center;
justify-content:center;
width:100%;
}
`;
const Col = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: row;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;

const Span = styled.span`
margin-left:-5.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  margin-left:-4rem;
}
`;




