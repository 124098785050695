import React, { useState } from 'react'
import styled from 'styled-components'
import profile from './assets/profile_img.png';
import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';
import userEvent from '@testing-library/user-event';
import { useDispatch, useSelector } from "react-redux";
import { data } from 'jquery';
import FindPodsDetails from './FindPodsDetails'
import { NavHashLink } from 'react-router-hash-link';
import star from './assets/star.png';
import Createpod from './Createpod';
import AWS from 'aws-sdk';
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const FindPods = () => {
  const token = useAuthToken();
  const intl = useIntl();

  const [Subscription, setSubscription] = useState({})
  const [subscrictionfetch, setSubscriptionfetch] = useState([]);
  const [loading, setLoading] = useState(true);
  const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  }
  console.log('cong', process.env.REACT_APP_BUCKETNAME)
  const S3Config = {
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  }

  useEffect(() => {

    console.log('mew', user.data[0].client_code)



    const body = {
      client_code: user.data[0].talent_code

    }
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };


    // https://x68qdu82jd.execute-api.us-east-1.amazonaws.com/dev/fetchSubscriptiondata
    axios.post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, {headers:headers})
      .then((res) => {

        setSubscription(res.data.data);
        setSubscriptionfetch(res.data.data[0].find_pods);

      })
      .catch((err) => {
        console.log("catch", err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false once the API call is completed.
      });
  }, [token]);


  const myBucket = new AWS.S3(S3Config);

  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(2);
  const [AuthImage, setAuthImage] = useState([]);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [getData, setGetData] = useState([])
  const [Getcandidates, setGetcandidates] = useState([])


  const [requirement, setRequirement] = useState([]);


  const email = user.data[0].email

  const portfolioUrl = user.data[0].portfolioUrl

  const firstName = user.data[0].firstName

  const lastName = user.data[0].lastName

  // const skilldata = (data) => {

  //     var mainskill = JSON.parse(data.skills)
  //     console.log("1112423435345345 :::: ",mainskill)
  //     return(mainskill);

  // }

  let applypod = (id) => {
    const headers = {
      'Authorization': token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  
    let body = {
      podID: id,
      candidateID: user.data[0].c_id,
      status_data: "approved",
      pod_requested:1,
    };
  
    // https://tsddt5g2u5.execute-api.us-east-1.amazonaws.com/dev/Joinpod
    axios.post(process.env.REACT_APP_TALENT_JOINPOD, body, { headers: headers })
      .then((res) => {
        console.log("response", res);
      })
      .catch((error) => {
        console.error("Error applying to pod:", error);
      });
  }
  
       
  
  
    
      
  useEffect(async () => {

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {

      const body = {
        // "country": user.data[0].country,
        // "timezone": user.data[0].timezone,
        "primary_skills": user.data[0].primaryskill,
        "secondary_skills": user.data[0].secondaryskill,
        "Client_Code":user.data[0].talent_code,
        // "techStack": user.data[0].techStack

      }



      // https://qvtr0dpm63.execute-api.us-east-1.amazonaws.com/dev/findpods
      let findpod = await axios.post(process.env.REACT_APP_TALENT_FINDPODS, body, {headers:headers});



      setGetData(findpod.data.data);
      const URL_EXPIRATION_TIME = 900;

      const params = {
        Bucket: process.env.REACT_APP_BUCKETNAME,
        Key: findpod.data.data[0].picture,
        Expires: URL_EXPIRATION_TIME,
      }

      console.log('params', params)

      myBucket.getSignedUrl('getObject', params, (err, url) => {

        console.log(err, url)
        setAuthImage(url);

      });

      const getpodbody = {
        "candidateID": user.data[0].c_id
      };
      

      // https://xujexobo25.execute-api.us-east-1.amazonaws.com/dev/getcandidatepods
      let getpod = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEPODS, getpodbody,{headers:headers});

      setGetcandidates(getpod.data.data.candidates)



    } catch (err) {

      console.log(err)
    }




  }, [token]);


  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  }


  const pages = [];
  for (let i = 1; i <= Math.ceil(getData.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);

    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };








  const renderData = (getData) => {
    return (
      <>

        {loading ? (
          <div>{intl.formatMessage({ id: 'loading' })}...</div> // Show a loading indicator while waiting for the API call to complete.
        ) : Subscription === "There Are No subscriction data." || subscrictionfetch === "False" ? (
          <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", margin: 'auto', marginTop: "8rem" }}>
            {intl.formatMessage({ id: 'noSubscription' })}
          </div>
        ) : (
          <div>



            <Headingtext1> {intl.formatMessage({ id: 'findBestPod' })}</Headingtext1>

            <ol className='pageNumbers' >
              <Num2>
                <li>
                  <button onClick={handlePrevbtn}
                    disabled={currentPage == pages[0] ? true : false} >
                    {intl.formatMessage({ id: 'prev' })}
                  </button>
                </li>
                <Numbers>{renderPageNumbers} </Numbers>
                <li>
                  <button onClick={handleNextbtn}
                    disabled={currentPage == pages[pages.length - 1] ? true : false}
                  > {intl.formatMessage({ id: 'next' })}</button>
                </li>
              </Num2>
            </ol>

            <OL1 style={{ display: 'flex', flexDirection: 'row' }}>

              {

                getData.length > 0 ? getData.map((data, i) => {

                  return <Div  >

                    <li  >

                      <div className='name'>

                        {data.picture == null || data.picture == "" ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{ width: 50, height: 50, borderRadius: "50px" }}
                          ></img>
                        )


                          : (
                            <img style={{ width: '50px', height: '50px' }} src={'https://globldatauat.s3.amazonaws.com/' + data.picture}></img>
                          )}



                        <p className='firstname'> {data.rating} </p> </div>

                      <div className='name2'> <tr className='TR'><td className='TD'  >{intl.formatMessage({ id: 'podName' })}</td> <td>:</td><TD1 > {data.podName} </TD1></tr></div>


                      <div className='name2'> <tr className='TR' ><td className='TD'  >{intl.formatMessage({ id: 'email' })}</td> <td>:</td><TD1 > {data.email}</TD1></tr></div>

                      {/*                                     
               <div className='name2'> <tr  className='TR'><td className='TD'  >Clients</td> <td>:</td><TD1 > hg</TD1></tr></div> */}


                      <div className='name2'>
                        <tr className='TR'><td className='TD' >{intl.formatMessage({ id: 'portfolio' })}</td> <td>:</td><TD1 > {data.portfolioUrl}</TD1></tr></div>


                      <div className='name2'> <tr className='TR'><td className='TD' >{intl.formatMessage({ id: 'podSize' })}</td> <td>:</td><TD1  > {data.podsize}  </TD1></tr></div>

                      <div className='name2'> <tr className='TR'><td className='TD' >{intl.formatMessage({ id: 'spotsLeft' })}</td> <td>:</td><TD1  > {data.spot_left}  </TD1></tr></div>

                      <br></br>



                      {/* <tr className='TR' ><td className='TD'  >Candidate Requirements - </td> </tr> */}

                      {/* { 
                        data.requrment.map((hepline)=>{
return <div> <div className='name2'> <tr  className='TR'><td className='TD' >Position</td> : {hepline.position} </tr></div>
<div className='name2'> <tr  className='TR'><td className='TD' >Skills</td>:{ hepline.reqskill.map((sk) => { return   <span style={{margin:'5px'}}>{sk.skill}<span>,</span> </span> }) }</tr></div>
</div>})} 
                        */}




                      {/* <NavHashLink to={`/poddetail/${data.id}`} smooth> */}

                      <DetailsButton onClick={() => applypod(data.id)}>{intl.formatMessage({ id: 'joinPod' })}  </DetailsButton>

                      {/* </NavHashLink> */}



                    </li>
                  </Div>





                }) : (<><br></br><p style={{ textAlign: "center", fontWeight: "bold" }}>{intl.formatMessage({ id: 'noMatchingPods' })}. </p></>)

              }

            </OL1>

          </div>)
        }
      </>
    )
  }



  return (<Return>
    {loading ? (
      <div>{intl.formatMessage({ id: 'loading' })}...</div> // Show a loading indicator while waiting for the API call to complete.
    ) : Subscription === "There Are No subscriction data." || subscrictionfetch === "False" ? (
      <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", margin: 'auto', marginTop: "8rem" }}>
        {intl.formatMessage({ id: 'noSubscription' })}
      </div>
    ) : (

      <Return>
        {renderData(currentItems)}
      </Return>)}
  </Return>


  )
}



export default FindPods;

const OL1 = styled.ol`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;

align-items:center;
justify-content:center;


}
`;

const Return = styled.div`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;

align-items:center;
justify-content:center;


}
`;

const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;
margin-left:4rem;
height:50rem;
width:29rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
 
  margin:auto;
  width:20rem;
 height:max-height;
margin-top:5rem;
}
`;

const Cards = styled.div`
display:flex;
flex-direction:row;

@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;

  flex-direction:column;
  align-items:center;
  margin:auto;
  justify-content:center;
 
}
`;


const TD1 = styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
}
`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;


const DetailsButton = styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
      
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:4rem;
        margin-bottom:2rem;
        
        `;
const CreateButton = styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:auto;
        padding:5px;
      width:10rem;
      height:3rem;
      font-size:18px;
      font-weight:600;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:4rem;
        margin-bottom:2rem;
        
        `;


const Headingtext1 = styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-top: 7rem;
    }
`;