import React, { Component, useState, useEffect } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';
import '../css/register.css';
import { Redirect, useHistory  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

const ChangePassword = () =>  {

  const [state, setState] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  })

 const clearErrorState = () => {
    setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  const history = useHistory();

 
  const { isLoggedIn, user } = useSelector(state => state.auth);


 const handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    const error = Validate(event, state);
    if (error) {
      setState({
        errors: { ...state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      await Auth.changePassword(
        user,
        state.oldpassword,
        state.newpassword
      );
      window.alert("Password changed successfully.");
      history.push("/");
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      setState({
        ...state,
        errors: {
        cognito: err}

      });
      console.log(err);
    }
  }

  const onInputChange = e => {
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });

    document.getElementById(e.target.id).classList.remove("is-danger");
  }


    return (
        <div className="container" style={{ marginTop : "100px"}}>
                  { isLoggedIn == true ? (
    <h1 className='register-header'>Reset Password</h1> ) : ( <h1 className='register-header'>Reset Password</h1>)}
          <br></br>
          <br></br>

          <div className='row'>
            <div className='col-sm-9'>
          <FormErrors formerrors={state.errors} />

          <form onSubmit={handleSubmit} >
            <div className="field">
              <p className="control">
                <input 
                  className="input32" 
                  type="password"
                  id="oldpassword"
                  placeholder="Old password"
                  value={state.oldpassword}
                  onChange={onInputChange}
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                   
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
            </div>
            <br></br>
            <div className="field">
              <p className="control">
                <input
                  className="input2"
                  type="password"
                  id="newpassword"
                  placeholder="New password"
                  value={state.newpassword}
                  onChange={onInputChange}
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                   
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
            </div>
            <br></br>
            <div className="field">
              <p className="control">
                <input
                  className="input2"
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={state.confirmpassword}
                  onChange={onInputChange}
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                   
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
            </div>
            <br></br>
            <div className="field">
              <p className="control">
                <button className="button is-success" style={{ "width": "400px" }}>
                  Change password
                </button>
              </p>
            </div>
          </form>

          </div>
          </div>
        </div>
    );
  
}

export default ChangePassword;