import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {cardShadow,hoverEffect} from '../utils';
import styled from 'styled-components';
import { useAuthToken } from "../TokenContext";
import { Checkbox } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import makeAnimated from 'react-select/animated';
import Select from "react-select";

const animatedComponents = makeAnimated();
const TaskForm = (props) => {

  const [taskDescription, setTaskDescription] = useState('');
  const[podtaskname,setPodtaskname]=useState('')
  const [taskDueDate, setTaskDueDate] = useState('');
  const [taskOwner, setTaskOwner] = useState(1);
  const [categories, setCategories] = useState('');
  const [owners, setOwners] = useState([]);
  const [status, setStatus] = useState('');
  const [isBlockerChecked, setIsBlockerChecked] = useState(false);
  const location = useLocation();
  const [slackUsers, setSlackUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const token = useAuthToken();
  const [formError, setFormError] = useState('');
  const intl = useIntl();

  const { podid: idFromLocationState } = location.state || {};
  const { podid: idFromProps } = props;

  const podid = idFromLocationState || idFromProps;

  const { podname: nameFromLocationState } = location.state || {};
  const { podname: nameFromProps } = props;

  const podname = nameFromLocationState || nameFromProps;
  const [userStories, setUserStories] = useState([]);

  const [selectedUserStories, setSelectedUserStories] = useState([]);

  const handleMultiSelectChange = (selectedOptions) => {
    setSelectedUserStories(selectedOptions);
  };
    
  useEffect(() => {
    // Fetch names and user IDs when the component mounts
    fetchSlackUsers();
  }, [token]);

  const fetchSlackUsers = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(process.env.REACT_APP_CLIENT_SLACKUSERSFETCH, {
        token: 'xoxb-5660967945602-6190432472723-OLtJ8JxryETavv8SrNQRRiAI',
      },{headers:headers});
      setSlackUsers(response.data.body.users);
    } catch (error) {
      console.error('Error fetching Slack users:', error);
    }
  };
  const handleClose = () => {
    // Add any logic here to close the popup.
    // You can call the onClose function passed as a prop.
    props.onClose();
  };
 
  useEffect(() => {
    fetchOwners();
  }, [token]);
 
  const fetchOwners = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(process.env.REACT_APP_CLIENT_CLIENTFETCHPODMEMBERS,{pod_Id:podid},{headers:headers});
      setOwners(response.data.data);
      console.log(' fetching owners:', owners);
     
    } catch (error) {
      console.error('Error fetching owners:', error);
    }
  };
  useEffect(() => {
    // Check if taskOwner is selected
    if (taskOwner) {
        // Make API call only if taskOwner is selected
        fetch(process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pod_id: podid || null,
                c_id: taskOwner, // Send task owner ID to c_id
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.statusCode === 200 && data.body) {
                const parsedBody = JSON.parse(data.body);
                if (Array.isArray(parsedBody.jiraIssues)) {
                    setUserStories(parsedBody.jiraIssues);
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    }
}, [podid, token, taskOwner]); // Trigger when podid, token, or taskOwner changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Validate that a user is selected
    // if (!selectedUserId) {
    //   alert('Please select a user from the dropdown.');
    //   return;
    // }
  
    // Validate that a task name is provided
    if (!podtaskname) {
      alert('Please enter a task name.');
      return;
    }
  
    try {
      // Make a notification API call with the selected user ID
      const notificationResponse = await axios.post(
        process.env.REACT_APP_CLIENT_SLACKINDIVIDUALTASKNOTIFICATION,
        {
          token: 'xoxb-5660967945602-6190432472723-OLtJ8JxryETavv8SrNQRRiAI',
          user_id: selectedUserId,
          task_name: podtaskname,
        },{headers:headers}
      );
  
      console.log('Notification API Response:', notificationResponse.data);
  
      // Make the pod task creation API call
      const podTaskResponse = await axios.post(process.env.REACT_APP_CLIENT_CLIENTCREATEPODTASK, {
        project_key: podname,
        pod_task_name: podtaskname,
        description: taskDescription,
        due_date: taskDueDate,
        candidate_id: parseInt(taskOwner),
        issuetype: 'story',
        summary: '',
        category: categories,
        status: status,
        pod_id: podid,
        issue_key: selectedUserStories.map((story) => story.value),
        blockercheck: isBlockerChecked ? 1 : 0,
      },{headers:headers});
  
      console.log('Form submitted successfully');
      console.log('Pod Task API Response:', podTaskResponse.data);
  
      // Clear form fields and states
      setTaskDescription('');
      setTaskDueDate('');
      setTaskOwner('');
      setPodtaskname('');
      setCategories('');
      setStatus('');
      setFormError('');
  
      alert('Posted To Talent');
      window.location.reload();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const handleUserSelect = (userId) => {
    // Update the selected user ID when a user is selected from the dropdown
    setSelectedUserId(userId);
  };
  
const id=2;
  
  return (
    <Div style={{marginTop:'12rem'}}>
      <h2>{intl.formatMessage({ id: 'taskForm' })}</h2>
      <button style={{display:"flex", marginLeft:"auto"}}onClick={handleClose}>Close</button> {/* Close button */}
      {formError && <p className="error">{formError}</p>}
      <CardContent>

      <form onSubmit={handleSubmit}>
      <CardContent1>
      <Postfield>
        <Label  htmlFor="taskDueDate">{intl.formatMessage({ id: 'podTaskName' })}</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="text"
          id="podtaskname"
          name="podtaskname"
          placeholder='podtaskname'
      maxLength={50}
          value={podtaskname}
          onChange={(e) => setPodtaskname(e.target.value)}
          required
        />
</Postfield>
        <Postfield>
        <Label  
        htmlFor="taskDescription">{intl.formatMessage({ id: 'podTaskDescription' })} </Label>
        <p style={{fontWeight:'600'}}>:</p>
        <TextArea
          type="text"
          id="taskDescription"
          name="taskDescription"
          placeholder='taskDescription'
          maxLength={100}
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
          required
        />
</Postfield>
<Postfield>
        <Label  htmlFor="taskDueDate">{intl.formatMessage({ id: 'podTaskDueDate' })}</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <InputField
          type="date"
          id="taskDueDate"
          name="taskDueDate"
          placeholder='taskDueDate'
      
          value={taskDueDate}
          onChange={(e) => setTaskDueDate(e.target.value)}
          required
        />
</Postfield>
<Postfield>
        <Label  htmlFor="taskOwner">{intl.formatMessage({ id: 'podTaskOwner' })}</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <SelectField
          id="taskOwner"
          name="taskOwner"
          placeholder='taskOwner'
          value={taskOwner}
          onChange={(e) => setTaskOwner(e.target.value)}
          required
        >
          <option  value="">Select an owner</option>
          
          {owners.map((owner) => {
           return <option key={owner.c_id} value={owner.c_id}>
              {owner.firstName}
            </option>}
          )}
        </SelectField>
        
        </Postfield>
        <Postfield>
        <Label  htmlFor="status">{intl.formatMessage({ id: 'podTaskStatus' })}</Label>
        <p style={{fontWeight:'600'}}>:</p>
        <SelectField
          id="status"
          name="status"
          placeholder='status'
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          required
        >
          <option  value="" defaultValue>Select a status</option>
          <option  value="To Do">To Do</option>
          <option  value="In Progress">In Progress</option>
          <option  value="Done">Done</option>
          
       
            
        </SelectField>
        </Postfield>
        <Postfield>
      
        <Label   htmlFor="categories">{intl.formatMessage({ id: 'podCategories' })}  </Label>


        <p style={{fontWeight:'600'}}>:</p>


        <SelectField
          id="categories"
          name="categories"
          placeholder='categories'
          value={categories}
          onChange={(e) => setCategories(e.target.value)}
          required
        >
          <option  value="">categories</option>
        
            <option  value='Design'>Design</option>
            <option  value='Coding'>Coding</option>
            <option  value='Testing'>Testing</option>
            <option  value='Documentation'>Documentation</option>
         
        </SelectField>
       
</Postfield>
<Postfield>
        <Label  
        htmlFor="jiraItem">{intl.formatMessage({ id: 'jiraItem' })}</Label>
        <p style={{fontWeight:'600'}}>:</p>
       <Select
              components={animatedComponents}
              isMulti
              value={selectedUserStories}
              onChange={handleMultiSelectChange}
              options={userStories.map((story) => ({
                label: `${story.issue_key} - ${story.issue_summary}`,
                value: story.issue_key,
              }))}
              required
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '20rem',
                  height:'3rem',
                  overflow:'auto',
                  marginLeft:"1rem"
                }),
              }}
            />

</Postfield>
<Postfield>
              <Label htmlFor="notificationRecipient">{intl.formatMessage({ id: 'notificationRecipient' })}</Label>
              <p style={{ fontWeight: '600' }}>:</p>
              <SelectField
                id="notificationRecipient"
                name="notificationRecipient"
                placeholder="notificationRecipient"
                value={selectedUserId}
                onChange={(e) => handleUserSelect(e.target.value)}
                
              >
                <option value="">Select a recipient</option>
                {slackUsers && slackUsers.length>0&& slackUsers.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.name}
                  </option>
                ))}
              </SelectField>
            </Postfield>
<Postfield>
    <span style={{color:"black",fontWeight:"600"}} htmlFor="blockerCheck">&#x2611; {intl.formatMessage({ id: 'checkBoxBlocker' })}</span>
    {/* <p style={{ fontWeight: '600' }}>:</p> */}
    <Checkbox
        type="checkbox"
        id="blockerCheck"
        checked={isBlockerChecked}
        onChange={(e) => setIsBlockerChecked(e.target.checked)}
    />
</Postfield>

        <Button type="submit">{intl.formatMessage({ id: 'submit' })}</Button>
        </CardContent1>
      </form>
      </CardContent>
    </Div>
  );
};

export default TaskForm
const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:auto;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;

  }
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:15px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 

  
 }
`;

const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;
const CardContent1=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }


`;




const TextArea=styled.textarea`

color:grey;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;
const InputField=styled.input`

color:grey;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;


const SelectField=styled.select`

border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
margin-left:1rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;
const Textarea = styled.textarea`
display:flex;
color:grey;
justify-content:center;
align-items:center;
margin:auto;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding:10px;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;

  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;
const Label =styled.label`
font-family:Poppins,Medium;
font-weight: 600;
font-size:16px;
color :grey;
width:14rem;
`;