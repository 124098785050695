import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import PopupDetailviewmetrics from './PopupDetailviewmetrics';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Modal from 'react-modal';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const Detailstoriesmetricstalent = ({initialPodIds,selectedSprint}) => {
  const [metrics, setMetrics] = useState([]);
  const [open_stories, setOpenStories] = useState([]);
  const [closed_stories, setClosedStories] = useState([]);
  const { user } = useSelector(state => state.auth);
  const chartRefs = useRef({});
  const [popupData, setPopupData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedReporter, setSelectedReporter] = useState("");
  const [issues, setIssues] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(async () => {
   
      Chart.register(...registerables);
      fetchMetrics();
    
  }, [token, user.data[0].type, initialPodIds,selectedSprint]);

  const fetchMetrics = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(process.env.REACT_APP_TALENT_JIRASTORIESMETRICSTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          c_id: user.data[0].c_id ,
          pod_id: initialPodIds||[],
          sprint_name: selectedSprint || '',

        }),
      });
  
      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      setTableData([...parsedData.open_stories, ...parsedData.closed_stories]);
  
      if (Array.isArray(parsedData.reporters)) {
        setMetrics(parsedData.reporters);
        setOpenStories(parsedData.open_stories);
        setClosedStories(parsedData.closed_stories);
        setIssues({
          open_stories: parsedData.open_stories,
          closed_stories: parsedData.closed_stories,
        });
      } else {
        console.log('Metrics data is empty or not in the expected format');
        setMetrics([]);
        setOpenStories([]);
        setClosedStories([]);
        setIssues([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
      setOpenStories([]);
      setClosedStories([]);
      setIssues([]);
    }
  };

  // Function to handle changes in selected reporter
  const handleReporterChange = (e) => {
    setSelectedReporter(e.target.value);
  };

  

  
 


 
// Function to generate pie chart data based on selected sprint and reporter
// Function to generate pie chart data based on selected sprint and reporter
// Function to generate pie chart data based on selected sprint and reporter
const generatePieData = () => {
  const totalOpenStories = open_stories.length;
  const totalClosedStories = closed_stories.length;
  return [totalOpenStories, totalClosedStories];
};

// Update chart data when metrics or selected reporter changes
useEffect(() => {
  if (metrics.length > 0) {
    if (selectedReporter) {
      const selectedReporterData = metrics.find((item) => item.Reporter === selectedReporter);
      if (selectedReporterData) {
        const chartData = [selectedReporterData.open_user_stories, selectedReporterData.completed_user_stories];
        displayChart('completedChart', 'Stories', chartData);
      } else {
        displayChart('completedChart', 'Stories', [0, 0]);
      }
    } else {
      const chartData = generatePieData();
      displayChart('completedChart', 'Stories', chartData);
    }
  } else {
    displayChart('completedChart', 'Stories', [0, 0]);
  }
}, [metrics, selectedReporter, open_stories, closed_stories]);

// Form pie chart by default when the component mounts with no filters applied
useEffect(() => {
  if (!selectedReporter) {
    const chartData = generatePieData();
    displayChart('completedChart', 'Stories', chartData);
  }
}, [open_stories, closed_stories]);

  // Function to display pie chart
  const displayChart = (chartId, chartLabel, chartData) => {
    const ctx = document.getElementById(chartId);

    if (!ctx) {
      return;
    }

    if (chartRefs.current[chartId]) {
      chartRefs.current[chartId].destroy();
    }

    chartRefs.current[chartId] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Open Stories', 'Completed Stories'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: ['lightgreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
        onClick: () => {
          openModal();
        },
      },
    });
  };

  // Function to handle click on pie chart
  const handlePieChartClick = (event) => {
    const chartInstance = chartRefs.current['completedChart'];

    if (chartInstance) {
      const clickedDataset = chartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

      if (clickedDataset && clickedDataset.length > 0) {
        const datasetIndex = clickedDataset[0].datasetIndex;
        const selectedLabel = chartInstance.data.labels[datasetIndex];

        if (selectedLabel === 'All Reporters') {
          const totalStoriesData = generatePieData();

          setPopupData({
            Reporter: 'All Reporters',
            open_user_stories: totalStoriesData[1],
            completed_user_stories: totalStoriesData[0],
            open_stories: open_stories,
            closed_stories: closed_stories,
          });
          setIsPopupVisible(true);

          setIsTableOpen(true);
        } else {
          const reporterData = metrics.find((item) => item.Reporter === selectedLabel);

          if (reporterData) {
            const { Reporter } = reporterData;

            const reporterOpenStories = open_stories.filter(story => story.Reporter === Reporter);
            const reporterClosedStories = closed_stories.filter(story => story.Reporter === Reporter );

            setPopupData({
              Reporter,
              open_user_stories: reporterData.open_user_stories,
              completed_user_stories: reporterData.completed_user_stories,
              open_stories: reporterOpenStories,
              closed_stories: reporterClosedStories,
            });
            setIsPopupVisible(true);

            setTableData([...reporterOpenStories, ...reporterClosedStories]);
            setIsTableOpen(true);
          } else {
            console.log('Reporter Data not found for selectedLabel:', selectedLabel);
          }
        }
      }
    }
  };

  // Function to open modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'stories' })}</h2>
        <br />
        <select   
          value={selectedReporter}
          onChange={(e) => setSelectedReporter(e.target.value)}
          style={{ width: '12rem' }}
        >
          <option value="">{intl.formatMessage({id:'allReporters'})}</option>
          {metrics.map(item => (
            <option key={item.Reporter} value={item.Reporter}>
              {item.Reporter}
            </option>
          ))}
        </select>
     <br/>
      <div className="chart-wrapper" style={{ width: "145px", height: "145px", margin: "auto" }}>
        {metrics.length > 0 ? (
          <canvas
            key="completedChart"
            id="completedChart"
        
            onClick={(event) => handlePieChartClick(event)}
          ></canvas>
        ) : (
          <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        )}
      </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PR Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600',
                },
              }}
            >
              <TableCell align="right">{intl.formatMessage({ id: 'reporter' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueKey' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueSummary' })}</TableCell>
            </TableHead>
            <TableBody>
              {tableData.map((reporter, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF',
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: '#caf0f8',
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'black',
                    },
                  }}
                >
                  <TableCell align="right">{reporter.Reporter}</TableCell>
                  <TableCell align="right">{reporter.issue_key}</TableCell>
                  <TableCell align="right">{reporter.issue_summary}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

Detailstoriesmetricstalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default Detailstoriesmetricstalent;
