import React, {useRef, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import { useIntl } from 'react-intl';

import { useAuthToken } from "../TokenContext";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Checkbox,
} from "@mui/material";
const { DropdownIndicator, MultiValue } = components;

const Podactivityviewalltalent = (props) => {
  const [editableData, setEditableData] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const [isLoading, setIsLoading] = useState(true);
  const Podid = props.Podid;
  const [userStories, setUserStories] = useState([]);
  const [selectedJiraIssues, setSelectedJiraIssues] = useState([]);
  const [jiraIssues, setJiraIssues] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const selectedJiraIssuesRef = useRef([]);
  const [editingJiraIssues, setEditingJiraIssues] = useState([]);
  const intl = useIntl();

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: token,
      };
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODACTIVITYDASHBORDTALENT,
        {
          talent_id: user.data[0].c_id,
          status: "view_all",
        },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        console.error("Error fetching data:", response.statusText);
        return;
      }
      const data = response.data.data.data;
      if (!data || Object.keys(data).length === 0) {
        console.log("No data available.");
        return;
      }
      setEditableData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleEditClick = async (row) => {
    setEditableData((prevData) =>
      prevData.map((item) =>
        item.id === row.id ? { ...item, editable: true } : item
      )
    );

    try {
      await fetchJiraIssues();

      const preFilledJiraIssues = Array.isArray(row.jira_issue)
        ? row.jira_issue
        : row.jira_issue.split(',');

      const preCheckedIssueKeys = jiraIssues
        .filter((issue) => preFilledJiraIssues.includes(issue.issue_key))
        .map((issue) => issue.issue_key);

      setEditingJiraIssues(preCheckedIssueKeys);
    } catch (error) {
      console.error('Error handling edit click:', error);
    }
  };

  const handleCheckboxChange = (issueKey) => {
    setEditingJiraIssues((prevSelected) => {
      const isSelected = prevSelected.includes(issueKey);
      if (isSelected) {
        return prevSelected.filter((value) => value !== issueKey);
      } else {
        return [...prevSelected, issueKey];
      }
    });
  };

  const handleSaveClick = async (row) => {
    console.log("Selected Jira Issues:", editingJiraIssues);

    try {
      const updatedData = {
        poddata: [
          {
            today_focus: row.today_focus,
            blockers: row.blockers,
            loom_url: row.loom_url,
            accomplishments: row.accomplishments,
            categories: row.categories,
            jira_issue: editingJiraIssues,
            id: row.id,
          },
        ],
      };
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT,
        updatedData,
        { headers: headers }
      );

      if (response.status === 200) {
        setEditableData((prevData) =>
          prevData.map((item) =>
            item.id === row.id
              ? { ...item, ...updatedData.poddata[0], editable: false }
              : item
          )
        );

        alert("Edited Details successfully.");
        fetchData();
      } else {
        alert("Error updating data. Please try again later.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again later.");
    }
  };

  const handleFieldChange = (row, field, value) => {
    if (field === "jira_issue") {
      selectedJiraIssuesRef.current = value;
    }

    setEditableData((prevData) =>
      prevData.map((item) =>
        item.id === row.id ? { ...item, [field]: value } : item
      )
    );
  };

  const fetchJiraIssues = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pod_id: Podid || null,
            c_id: user.data[0].c_id,
          }),
        }
      );
      const data = await response.json();
      if (data.statusCode === 200 && data.body) {
        const parsedBody = JSON.parse(data.body);
        if (Array.isArray(parsedBody.jiraIssues)) {
          setJiraIssues(parsedBody.jiraIssues);
        }
      }
    } catch (error) {
      console.error("Error fetching Jira Issues:", error);
    }
  };

  useEffect(() => {
    if (!initialized) {
      fetchJiraIssues();
      setInitialized(true);
    }
  }, [initialized, fetchJiraIssues, Podid, user.data, token]);

  useEffect(() => {
    const preFilledJiraIssues = editableData
      .filter(
        (row) =>
          Array.isArray(row.jira_issue) && row.jira_issue.length > 0
      )
      .map((row) => row.jira_issue)
      .flat();
    setSelectedJiraIssues(preFilledJiraIssues);
  }, [editableData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    const isSelected = selectedCreatedBy.includes(value);

    setSelectedCreatedBy((prevSelected) =>
      isSelected
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const filteredData =
    selectedCreatedBy.length === 0
      ? editableData.filter(
          (data) => data.created_by === user.data[0].c_id
        )
      : editableData.filter((data) =>
          selectedCreatedBy.includes(data.pod_candidates)
        );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    handleClose();
  };

  const issueKeys = jiraIssues.map((issue) => issue.issue_key);

  const transcribeVideo = async (loomUrl) => {
    try {
      const transcriptionApiUrl =  process.env.REACT_APP_TALENT_CLOUDFRONTAPI;
      const filename = loomUrl.split('/').pop();
      const response = await axios.post(
        transcriptionApiUrl,
        { url: filename },
        { headers: { "Content-Type": "application/json" } }
      );
      return response.data;
    } catch (error) {
      console.error("Error transcribing video:", error);
      return null;
    }
  };

  const handleTranscribeClick = async (loomUrl, id) => {
    try {
      const filename = loomUrl.split('/').pop(); // Extract filename from the loom_url
      const cloudFrontUrl =   process.env.REACT_APP_CLOUDFRONT ; // CloudFront URL
      const urlWithCloudFront = cloudFrontUrl + filename; // Append CloudFront URL to filename
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": urlWithCloudFront } // Properly formatted payload with CloudFront URL + filename
      );
  
      console.log('Transcription Response:', response.data); // Log the entire response data for inspection
  
      const signedUrl = response.data.signedUrl; // Access the signedUrl property
  
      // Ensure that the response contains the signedUrl
      if (!signedUrl) {
        console.error('Signed URL not found in transcription response:', response.data);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }
  
      // Update the corresponding item in editableData with the signed URL
      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
  
      console.log('Updated Data:', updatedData); // Log updatedData for debugging
  
      setEditableData(updatedData);
  
      console.log('Transcription Data:', signedUrl);
      alert('Video transcription completed successfully.');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };
  
  
  
  const handleViewAndTranscribe = async (loomUrl, id) => {
    try {
      // Split the URL at '?' to extract the filename
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
  
      // Construct the CloudFront URL
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
  
      // Combine the CloudFront URL with the filename
      const fullUrl = cloudFrontUrl + filename;
  
      // Make a request to get the signed URL for transcription
      const response = await axios.post(
       process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );
  
      // Parse the response body as JSON
      const responseBody = JSON.parse(response.data.body);
  
      // Extract the signed URL from the parsed response body
      const signedUrl = responseBody.signedUrl;
  
      // Ensure that the signed URL is found in the transcription response
      if (!signedUrl) {
        console.error('Signed URL not found in transcription response:', responseBody);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }
  
      // Update the corresponding item in editableData with the signed URL
      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
      setEditableData(updatedData);
  
      // Open the video for viewing in a new tab using the full signed URL
      window.open(signedUrl, '_blank');
  
      // console.log('Transcription Data:', signedUrl);
      // alert('Video transcription completed successfully.');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };
  
  
  

  return (
    <Div>
      <TitleText> {intl.formatMessage({ id: 'yourPostedPodActivity' })}</TitleText>
      <Button
        style={{ marginBottom: "1rem" }}
        onClick={handleClick}
        variant="outlined"
      >
        {selectedCreatedBy.length === 0
          ? intl.formatMessage({ id: 'selectPodTeamMember' })
          : selectedCreatedBy.map((item) => (
              <Chip
                key={item}
                label={item}
                color="primary"
                style={{ marginRight: 5 }}
              />
            ))}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {[...new Set(editableData.map((data) => data.pod_candidates))].map(
          (name) => (
            <MenuItem
              key={name}
              onClick={() => handleMenuItemClick(name)}
              selected={selectedCreatedBy.includes(name)}
            >
              {selectedCreatedBy.includes(name) && (
                <Chip
                  label={name}
                  color="primary"
                  style={{ marginRight: 5 }}
                />
              )}
              {name}
            </MenuItem>
          )
        )}
        <MenuItem onClick={handleDoneClick}>{intl.formatMessage({ id: 'Done' })}</MenuItem>
      </Menu>

      <RecommendProject>
        <TableContainer component={Paper}>
          <Table
            component={Paper}
            sx={{
              fontFamily: "Poppins,Medium",
              borderRadius: "10px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#00B4D8",
                "& td, & th": {
                  fontFamily: "Poppins,Medium",
                  fontSize: "15px",
                  color: "white",
                  fontWeight: "600",
                },
              }}
            >
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'postedDate' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'jiraIssue' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'videoUrl' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'todaysFocus' })} </TableCell>
                <TableCell>{intl.formatMessage({ id: 'accomplishments' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'blocker' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'edit' })}</TableCell>
              </TableRow>
            </TableHead>
            {filteredData && filteredData.length === 0 && (
              <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
            )}
            {filteredData && filteredData.length > 0 ? (
              <TableBody>
                {filteredData.map((row) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                      },
                      "&:nth-child(even)": {
                        backgroundColor: "#90E0EF",
                      },
                      "&:nth-child(odd)": {
                        backgroundColor: "#caf0f8",
                      },
                      "& td, & th": {
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                        color: "black",
                      },
                    }}
                    key={row.id}
                    className={row.editable ? "highlighted-row" : ""}
                  >
                    <TableCell>
                      {moment(row.createdAt).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {row.editable ? (
                        <Select
                          multiple
                          value={editingJiraIssues}
                          onChange={(e) => {
                            const selectedValues = e.target.value.filter(
                              (value) => value !== "undefined" && value !== null
                            );
                            setEditingJiraIssues(selectedValues);
                          }}
                          renderValue={(selected) =>
                            Array.isArray(selected) ? selected.join(", ") : selected
                          }
                        >
                          {issueKeys.map((issueKey) => (
                            <MenuItem key={issueKey} value={issueKey}>
                              <Checkbox
                                checked={editingJiraIssues.includes(issueKey)}
                                onChange={() => handleCheckboxChange(issueKey)}
                              />
                              {issueKey}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        row.jira_issue
                      )}
                    </TableCell>

                    <TableCell>
  {row.editable ? (
    <TextField
      fullWidth
      value={row.loom_url}
      onChange={(e) =>
        handleFieldChange(
          row,
          "loom_url",
          e.target.value
        )
      }
    />
  ) : (
    row.loom_url ? (
      <Button onClick={() => handleViewAndTranscribe(row.loom_url, row.id)}>
      {intl.formatMessage({ id: "viewVideo" })}
      </Button>
    ) : (
      <span>No video available</span>
    )
  )}
</TableCell>



                    <TableCell>
                      {row.editable ? (
                        <Textarea
                          fullWidth
                          value={row.today_focus}
                          onChange={(e) =>
                            handleFieldChange(
                              row,
                              "today_focus",
                              e.target.value.slice(0, 200)
                            )
                          }
                        />
                      ) : (
                        row.today_focus
                      )}
                    </TableCell>
                    <TableCell>
                      {row.editable ? (
                        <Textarea
                          fullWidth
                          value={row.accomplishments}
                          onChange={(e) =>
                            handleFieldChange(
                              row,
                              "accomplishments",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        row.accomplishments
                      )}
                    </TableCell>
                    <TableCell>
                      {row.editable ? (
                        <Textarea
                          fullWidth
                          value={row.blockers}
                          onChange={(e) =>
                            handleFieldChange(
                              row,
                              "blockers",
                              e.target.value.slice(0, 100)
                            )
                          }
                        />
                      ) : (
                        row.blockers
                      )}
                    </TableCell>
                    {row.posted_to_client === 0 ? (
                      <TableCell>
                        {row.editable ? (
                          <Button
                            onClick={() => handleSaveClick(row)}
                            disabled={!row.today_focus && !row.blockers}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button onClick={() => handleEditClick(row)}>Edit</Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Button disabled onClick={() => handleEditClick(row)}>
                          Edit
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <p>No data available.</p>
            )}
          </Table>
        </TableContainer>
      </RecommendProject>
    </Div>
  );
};







export default Podactivityviewalltalent;
const Div = styled.div`
  display: inline;
  justify-content: center;
  align-items: center;
  
  width:80vw;
 
  overflow-y:auto;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    // width: 20rem;
  }
`;
const Textarea = styled.textarea`



tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const ButtonEdit = styled.button`
  text-decoration: none !important;
  border-radius: 10px;
  background-color: #00b4d8;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top: 2rem;
  }
`;

const Buttondelete = styled.button`
  text-decoration: none !important;
  border-radius: 10px;
  background-color: red;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top: 2rem;
  }
`;
const Buttonpost = styled.button`
  text-decoration: none !important;
  border-radius: 10px;
  background-color: seagreen;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top: 2rem;
  }
`;
const RecommendProject = styled.div`
  display: flex;
  width:80vw;
  jutify-content: center;
  flex-direction: row;
  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    // width: 100%;
    height: max-content;
    margin: auto;

    display: flex;

    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 4rem;
  }
`;

const Position1 = styled.p`
  font-weight: 600;
  width: 15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 4rem;
  }
`;

const Answer = styled.p`
  font-weight: 600;
  width: 15rem;
  overflow-wrap: anywhere;
  border-radius: 10px;

  font-family: Poppins, Medium;
  //   background-color:#1DBF73;
  //   padding:1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const CardContent = styled.div`
  margin: 0.4rem;
  width: 45rem;
  padding: 1rem;
  height: 34rem;
  overflow-y: scroll;

  border-radius: 10px;
  font-family: Poppins, Medium;
  background-color: #f2f4f5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin: auto;
  }
`;
const Divseperator = styled.div`
  border-bottom: 1px solid #cecece;
  padding: 1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 100%;
    height: 100%;
    margin: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`
  font-weight: 600;
  display: flex;
  flex-direction: row;
`;
const ProjectInfo = styled.h6`
  font-weight: 600;
  display: flex;
  flex-direction: row;
`;

const SideCard = styled.div`
  margin-left: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  font-family: Poppins, Medium;
  width: 20rem;
  height: 34rem;

  padding: 1rem;
  background-color: #f2f4f5;

  box-shadow: ${cardShadow};
  transition: 0.45 ease-in-out;
  &:hover {
    box-shadow: ${hoverEffect};
  }
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    height: 38rem;
    margin: auto;
    margin-top: 2rem;
  }
`;

const SideCardContent = styled.div`
  background-color: #90e0ef;
  width: 17rem;

  display: flex;
  flex-direction: column;
  padding: 3px;
  margin: 1rem;

  border-radius: 20px;
`;

const SideCardContent1 = styled.div`
  background-color: #90e0ef;
  width: 17rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  height: 10rem;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1 = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
  padding: 5px;
  margin: 3px;
  background-color: white;
  border-radius: 5px;
  font-size: 13px;
  color: black;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ViewAll = styled.button`
  width: 5rem;
  font-weight: 600;
  height: 2rem;
  margin-left: auto;
  margin-top: 1rem;
  border: 2px solid #1dbf73;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
  width: 8rem;
  font-weight: 600;
  height: 2rem;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #00000029;
`;
// const Button=styled.button`
// margin-top:1rem;
// border:none;
// border-radius: 10px;
// background-color:#1DBF73;
// box-shadow: 1px 3px 6px #00000029;
// width:12rem;
// height:2rem;
// font-size:15px;
// font-weight:600;
// color:white;
// @media screen and (min-width:320px) and (max-width:1080px){

//   margin-bottom:1rem;

// }

// `;

const InputContainer = styled.div`
  display: flex;
  border-radius: 10px;
  justify-content: center;
  margin-left: auto;
  width: 25rem;
  margin-top: -2rem;
  margin-bottom: 1rem;
  border: 1px solid #4e8fae;
  box-shadow: 3px 6px 9px #00000005;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20rem;
  }
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
  justify-content: center;
  height: 3rem;
  width: 25rem;
  border: 0px solid #4e8fae;
  background-color: #f2f3f4;
  font-family: Poppins, Medium;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  &:focus {
    border: none;
    outline: none;
  }
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
  }
`;
