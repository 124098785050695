import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory, useParams } from "react-router-dom";
import Navbar from "../Dashboard/Navbar";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import EditIcon from '@mui/icons-material/Edit';
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import Sidebar from "./Sidebar";
import star from './assets/star.png';
import styled from 'styled-components'
import {AiOutlineStar,AiFillStar} from 'react-icons/ai';
import { Circles } from 'react-loader-spinner'
import AWS from 'aws-sdk';
import defaultimg from './assets/defaultimge.png';
import S3FileUpload from 'react-s3';
import { useIntl } from 'react-intl';
import moment from "moment";
import { Buffer } from "buffer";
import { useAuthToken } from "../TokenContext";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import PortfolioList from "./PortfolioList";
import { useLocation } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Button } from "react-bootstrap";
import { Breadcrumbs } from '@mui/material';

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;



const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]



const Candidatedetailsbyid = () => {
  const token = useAuthToken();

  const location = useLocation();

  const { c_id } = location.state;

  const [Edit, setEdit] = useState(false); 
  const [Image, setImage] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 


  const [Uploading, setUploading] = useState(false)
  const [Object, setObject] = useState([])
  const [Resume, setResume] = useState(""); 

  const [Assesment, setAssesment] = useState(""); 
  const intl = useIntl();

  const { isLoggedIn, user } = useSelector(state => state.auth);

 

  const dispatch = useDispatch();

   const [arrexp, setArrexp] = useState({
    experience : [],
    education : [],
    certification: []
  });
  const[pod,setPod]=useState([]);
  const handleChangeExp = (e,idx) => {
    let clone = [...arrexp.experience];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ experience : [...clone]})
   }
   const handleChange = (e,idx) => {
    let clone = [...arrexp.education];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setArrexp({ education: [...clone]})
   }


  const history = useHistory();

  const myBucket = new AWS.S3(S3Config);

  const [State, setState] = useState({

 ratePerhour: "initialValue",
 firstName: "initialValue",
 lastName: "initialValue",
 country:"initialValue",
 about: "initialValue",
 primaryskill: "initialValue", 
        secondaryskill: "initialValue", 
        industry:"initialValue",
        techStack:"initialValue",

        linkedin: "initialValue",
        githubUrl: "initialValue",
        calendlyurl:"initialValue",
        github_repo_name: "initialValue",
        github_username: "initialValue",
        jira_username:"initialValue",
        sonarqube_project_key: "initialValue",
        talent_code: "initialValue",
 hoursperweek: "initialValue",
 availability: "initialValue",
  timezone:  "initialValue",
work_authorization:  "initialValue",
 ratePermonth:  "initialValue",
          currency:  "initialValue",
          workhours:  "initialValue",

  })

  const [editMode, setEditMode] = useState(false);


    const handleEditClick = () => {
      setEditMode(true);
  };

  const handleSaveClick = () => {
      setEditMode(false);
      handleApiCall();
    };

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];


  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);

    // console.log(count)
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  }



  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: State.profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {

                console.log(err, url)
  
                setAuthImage(url);
  
              });
  }

  
 
 

  useEffect( async () => {
 
    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
  

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };

    try{
  
      // https://583amcrdw0.execute-api.us-east-1.amazonaws.com/dev/getcandidatebyid
      const addeddata = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEBYID, { id : c_id, review : "yes" },{headers:headers});


      // https://svty7iwcsb.execute-api.us-east-1.amazonaws.com/dev/getcandidatedata
      const added = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEDATA, { id :c_id },{headers:headers});
      setPod(added.data.data.podExperience)


      //console.log(addeddata)

  
      setArrexp({
        experience : added.data.data.exp,
        education : added.data.data.education,
        certification : added.data.data.certification
      })
   
  
      setState(addeddata.data.data[0])
   
      setObject(addeddata.data.data[0].reviews == null ? [] : addeddata.data.data[0].reviews)

     // console.log(State)

    }catch(err){
      console.log(err)
    }

  }, [token])


  //console.log(AuthImage)

  useEffect(() => {
    // Ensure State.resume is populated before generating the URL
    if (State.resume) {
      generateAndSetSignedUrl();
    }
    if (State.coding_assessment_url) {
    generateAndSetSignedUrlassessment()}

}, [State.resume,State.coding_assessment_url]); // Run this effect whenever State.resume changes

// Function to generate pre-signed URL for resume
// function generatePreSignedPutUrl11Resume() {
//     const URL_EXPIRATION_TIME = 900;

//     const params = {
//         Bucket: "globldatauat",
//         Key: State.resume,
//         Expires: URL_EXPIRATION_TIME,
//     };

//     myBucket.getSignedUrl('getObject', params, (err, url) => {
//         if (err) {
//             console.error("Error generating signed URL:", err);
//             return;
//         }

//         console.log("Generated URL:", url);
//         setResume(url);
//     });
// }


async function generateAndSetSignedUrl() {
  try {
      // Define the request parameters
      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': token,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              url: process.env.REACT_APP_CLOUDFRONT +State.resume
          })
      };

      // Make the API call
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
      
      // Parse the response as JSON
      const data = await response.json();

      // Extract the signed URL from the response data
      const signedUrl = JSON.parse(data.body).signedUrl;

      console.log("Generated CloudFront signed URL:", signedUrl);

      // Use the signed URL as needed
      setResume(signedUrl);
  } catch (error) {
      console.error("Error generating signed URL:", error);
  }
}

async function generateAndSetSignedUrlassessment() {
  try {
      // Define the request parameters
      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': token,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              url: process.env.REACT_APP_CLOUDFRONT +State.coding_assessment_url
          })
      };

      // Make the API call
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
      
      // Parse the response as JSON
      const data = await response.json();

      // Extract the signed URL from the response data
      const signedUrl = JSON.parse(data.body).signedUrl;

      console.log("Generated CloudFront signed URL:", signedUrl);

      // Use the signed URL as needed
      setAssesment(signedUrl);

  } catch (error) {
      console.error("Error generating signed URL:", error);
  }
}



const handleInputChange = (field, value) => {
  setState(prevState => ({
      ...prevState,
      [field]: value
  }));
};

const handleApiCall = async () => {
  try {
      // Create a new payload by excluding ratePerhour and modifying its value
      const payload = {
        id:c_id,
          firstname: State.firstName,
          middlename: State.middleName,
          lastname: State.lastName,
          candidateemail: State.candidateemail,
          area: State.area,
          github_repo_name: State.github_repo_name,
          github_username: State.github_username,
          jira_username: State.jira_username,
          sonarqube_project_key: State.sonarqube_project_key,
          country: State.country,
          primaryskill: State.primaryskill,
          portfolio: State.portfolio,
          linkedin: State.linkedin,
          githuburl: State.githubUrl,
          industry: State.industry,
          degree: State.degree,
          institution: State.institution,
          domain: State.domain,
          startingDate: State.startingDate,
          endingDate: State.endingDate,
          certification: State.certification,
          certificationIntitution: State.certificationIntitution,
          certificationUrl: State.certificationUrl,
          certification_startDate: State.certification_startDate,
          certification_endDate: State.certification_endDate,
          work_organization: State.work_organization,
          work_position: State.work_position,
          present: State.present,
          workStartdate: State.workStartdate,
          workEnddate: State.workEnddate,
          secondaryskill: State.secondaryskill,
          resetForm: State.resetForm,
          ratePerhour: State.ratePerhour, // Modified value
          ratePermonth: State.ratePermonth,
          currency: State.currency,
          workhours: State.workhours,
          about: State.about,
          techStack: State.techStack,
          email: State.email,
          resume: State.resume,
          coding_assessment_url: State.coding_assessment_url,
          work_authorization: State.work_authorization,
          timezone: State.timezone,
          availability: State.availability,
          hoursperweek: State.hoursperweek,
          currentStatus: State.currentStatus,
          availabledate: State.availabledate,
          profilePicture: State.profilePicture,
          calendlyurl: State.calendlyurl,
          talent_code: State.talent_code,
          profileImg: '/https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          errors: {
              blankfield: false,
              passwordmatch: false
          }
      };

      // Make the API call with the payload
      const response = await fetch(process.env.REACT_APP_TALENT_EDITCANDIDATE, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              // Add any necessary authorization headers
          },
          body: JSON.stringify(payload)
      });

      if (!response.ok) {
          throw new Error('Failed to send data to the API');
      }

      console.log('Data sent successfully');
      // Optionally, handle any success feedback or additional logic here
  } catch (error) {
      console.error('Error sending data to the API:', error);
      // Optionally, handle errors or provide feedback to the user
  }
};





  const { profileImg} = State
    return (

     <div className="container" style={{ "margin-top": "140px" }}>
      <Breadcrumbs aria-label="breadcrumb" style={{float:"left"}}>
  <Link to="/browse-talent" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Find Talent</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Talent Details</span>
</Breadcrumbs>
    <center>
    {State.email!=undefined ?
    <div className="col-lg-7" style={{   border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    padding: '20px', opacity: 1 ,margin:'auto' ,marginTop:"2rem"}}>

{user.data[0].manager_type === "PartnerClient" && (
  <div style={{display:"flex",margin:"auto",float:"right"}} >
    {/* Edit button */}
    {!editMode && (
      <FaEdit onClick={handleEditClick} style={{ cursor: 'pointer' }} />
    )}

    {/* Save button */}
    {editMode && (
      <Button onClick={handleSaveClick}>Save</Button>
    )}
  </div>
)}


      <div className="row" style={{display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection:"column"}}>
<br></br>
    
 
        <div  >

       
<center>
          
{State.profilePicture==null || State.profilePicture==''? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: "100px", height: "100px", borderRadius : "50px"  }}
></img>
)


  :(
    <img  src={'https://globldatauat.s3.amazonaws.com/'+ State.profilePicture}   style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img> 
     
) }
 
            <br></br>
            <br></br>
            <div style={{ display: 'flex', alignItems: 'center' }}>
           
            {editMode ? (
                <div style={{display:"flex",flexDirection:"column"}}>
                    <input
                        type="text"
                        className="input2"
                        placeholder="FirstName"
                        value={State.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                    <input
                        type="text"
                        className="input2"
                        placeholder="LastName"
                        value={State.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                  
                </div>
            ) : (
                <>
                    <p style={{ marginLeft: "-1px", color: "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>{State.firstName + " " + State.lastName}</p>
                    
              
                </>
            )}
         
        </div>            </center>
            <br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>
<p style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }} >{intl.formatMessage({ id: 'basicInformation' })}</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'email' })}</p>


<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.email}</p>


<p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'country' })}</p>


            {editMode ? (
                <>
                    <select
                        value={State.country}
                        className="input2"
                        onChange={(e) => handleInputChange('country', e.target.value)}
                    >
                        <option value="india">India</option>
                        <option value="usa">USA</option>
                        <option value="canada">Canada</option>
                    </select>
                   
                </>
            ) : (
                <>
                    <p style={{ marginLeft: "5px", color: "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left" }}>{State.country}</p>
                </>
            )}
        

{State.country ==='usa'?<><p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >Work Authorization</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.work_authorization}  
</p></>
:(<></>)}

<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'about' })}</p>

{editMode ? (
                <>
                    <textarea
                        value={State.about}
                        className="input2"
                        onChange={(e) => handleInputChange('about', e.target.value)}

                    />
                    
                </>
            ) : (
                <>
                    <p style={{ marginLeft: "5px", color: "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left" }}>{State.about}</p>
                </>
            )}

<br></br>
</div>
<br></br>
<br></br>
<div style={{ border: "2px solid #E7E7E7", boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto", display: 'flex', flexDirection: "column" }}>
  <p style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "160px" }}>{intl.formatMessage({ id: 'portfolio' })}</p>
  <br />
  <div style={{ flex: 1 }}> {/* Wrapper for encapsulating PortfolioList */}
    <PortfolioListWrapper>
      <PortfolioList candidateid={State.c_id} />
    </PortfolioListWrapper>
  </div>
</div>


<br></br>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>

            <p style={{ display:"flex",justifyContent:"center",alignItems:"center",margin:"auto" ,color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px",  }} >{intl.formatMessage({ id: 'skillsAndExpertise' })} </p>
            <br></br>

            {/* For Primary Expertise */}
            <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'skills' })} </p>

            {editMode ? (
                <>
                    <select
                      className="input2"
                        value={State.primaryskill}
                        onChange={(e) => handleInputChange('primaryskill', e.target.value)}

                        placeholder="Primary Skill*"
                    >
                        <option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Three.js</option>
<option  value='Tableau' style={{color:'black'}} >Tableau</option>
<option  value='Python' style={{color:'black'}} >Python</option>

<option  value='Quality Assurance' style={{color:'black'}} >Quality Assurance</option>
<option  value='IOS Development (Swift/Objective C)' style={{color:'black'}} >IOS Development (Swift/Objective C)</option>
<option  value='Android Development (Java/Kotlin)' style={{color:'black'}} >Android Development (Java/Kotlin)</option>
<option  value='Devops' style={{color:'black'}} >Devops</option>
<option  value='Cloud (AWS/Azure/GCP)' style={{color:'black'}} >Cloud (AWS/Azure/GCP)</option>

                    </select>
                    <select
                      className="input2"
                        value={State.secondaryskill}
                        onChange={(e) => handleInputChange('secondaryskill', e.target.value)}

                        placeholder="Secondary Skills*"
                    >
                      <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>


                    </select>
         
                </>
            ) : (
                <>
                    <p style={{ color: "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}>{State.primaryskill}, {State.secondaryskill}</p>
                </>
            )}

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'industry' })}</p>


{editMode ? (
                <>
                    <select
                        value={State.industry}
                        className="input2"
                        onChange={(e) => handleInputChange('industry', e.target.value)}

                    >
                      <option  style={{color:'black'}} value='' defaultValue >Industry</option>


                        <option value="Healthcare">Healthcare</option>
                        <option value="IT & Software">IT & Software</option>
                        <option value="Banking and Financial">Banking and Financial</option>
                        <option value="Services">Services</option>

                    </select>
                
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{State.industry}</p>
</>)}
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'techStack' })}</p>

{editMode ? (
                <>
                    <select
                     className="input2"
                        value={State.techStack}
                        onChange={(e) => handleInputChange('techStack', e.target.value)}

                    >
                      <option  style={{color:'black'}} value='' defaultValue >Industry</option>


                        <option value="MEAN">MEAN</option>
                        <option value="MERN">MERN</option>
                        <option value="LAMP">LAMP</option>
                        <option value="MEVN">MEVN</option>

                    </select>
                  
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }} >{State.techStack}</p>
</>)}
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center",}}>


<br></br>
            <p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

        
            <p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

            <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Degree</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Institution</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.education.length > 0 ? arrexp.education.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.degree}</TableCell>
              <TableCell>{data.institution}</TableCell>
              <TableCell>{data.startDate}</TableCell>
              <TableCell>{data.endDate}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No education data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center", }}>

<p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'certifications' })}  </p>

<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Certification Name</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Institution</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.certification.length > 0 ? arrexp.certification.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.cert_name}</TableCell>
              <TableCell>{data.certificationInstitution}</TableCell>
              <TableCell>{moment(data.startDate).format("YYYY-MM-DD")} </TableCell>
               <TableCell> {moment(data.endDate).format("YYYY-MM-DD")}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No certification data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  

<br></br>
</div>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center", }}>

<br></br>
<p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'workExperience' })} </p>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Position</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Organization</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.experience.length > 0 ? arrexp.experience.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.work_position}</TableCell>
              <TableCell>{data.work_organization}</TableCell>
              <TableCell>{data.workStartdate} </TableCell>
              {data.present === 0 ?
  <TableCell>{data.workEnddate}</TableCell>
  :
  <TableCell>
    <span style={{ color: 'blue', marginRight: '0.3rem' }}>•</span>
    Present
  </TableCell>
}


            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No experience data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  



<br></br>
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",margin:'auto' }}>

<br></br>
<p style={{color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'podExperience' })}</p>
{ pod.length > 0 ? pod.map((data) => {
       return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.podName}</p>


</div>
}) : ( <>{intl.formatMessage({ id: 'noExperience' })}</>)}




<br></br>
</div>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",}}>

<p style={{ color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>

<br></br>
{/* <p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'github' })}</p>
{editMode ? (
                <>
<input
                        type="url"
                        className="input2"
                        value={State.githubUrl}
                        placeholder="GithubUrl"
                        onChange={(e) => handleInputChange('githubUrl', e.target.value)}
                    />
                    
                </>
              ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.githubUrl}</p>
</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Github RepoName' })}</p>
{editMode ? (
                <>
<input
                        type="text"
                        className="input2"
                        placeholder="Github RepoName"
                        value={State.github_repo_name}
                        onChange={(e) => handleInputChange('github_repo_name', e.target.value)}
                    />
              
                </>
              ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.github_repo_name}</p>
</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Github UserName' })}</p>
{editMode ? (
                <>
<input
                        type="text"
                        className="input2"
                        placeholder="Github UserName"
                        value={State.github_username}
                        onChange={(e) => handleInputChange('github_username', e.target.value)}
                    />
                    
                </>
              ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.github_username}</p>
</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'JIra UserName' })}</p>
{editMode ? (
                <>
<input
                        type="text"
                    
                        className="input2"
                        placeholder="Jira UserName"
                        value={State.jira_username}
                        onChange={(e) => handleInputChange('jira_username', e.target.value)}
                    />
                    
                </>
              ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.jira_username}</p>
</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Sonarqube Projectkey' })}</p>
{editMode ? (
                <>
<input
                        type="password"
                        className="input2"
                        placeholder="Sonarqube Project Key"
                        value={State.sonarqube_project_key}
                        onChange={(e) => handleInputChange('sonarqube_project_key', e.target.value)}
                    />
                  
                </>
              ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.sonarqube_project_key}</p>
</>)} */}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'linkedIn' })}</p>

{editMode ? (
                <>
                    <input
                        type="url"
                        className="input2"
                        placeholder="LinkedIn"
                        value={State.linkedin}
                        onChange={(e) => handleInputChange('linkedin', e.target.value)}
                    />
                    
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.linkedin}</p>
</>)}
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'preferredWorkHours' })}</p>

{editMode ? (
                <>
                     <select
                        value={State.workhours}
                        className="input2"
                        
                        onChange={(e) => handleInputChange('workhours', e.target.value)}
                    >
                          <option  style={{color:'black'}} value="">Preffered Work Hours</option>

<option>Morning Shift, 9am - 6pm </option>
<option>Evening Shift, 10pm - 7am </option>
                    </select>
                  
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.workhours}</p>

</>)}
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'ratePerHour' })}</p>

{editMode ? (
                <>
                    <input
                        type="text"
                        className="input2"
                        placeholder="Rate /hr"
                        value={State.ratePerhour}
                        onChange={(e) => handleInputChange('ratePerhour', e.target.value)}
                    />
                    {/* Add more input fields for other editable fields */}
                 
                </>
            ) : (
                <>
                {user.data[0].manager_type === "PartnerClient" ? (
                   <p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.ratePerhour}</p>
                ):( <p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.totalrate}</p>
              )}
                </>
            )}
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"   }} >Coding Assesment</p>

{Assesment ? (

<a href={Assesment}  target="_blank" style={{ color :  "#A6C6D6.", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >View Assesment</a>
):(<p>No Assesment available</p>)}


<br></br>

<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'calendlyUrl' })}</p>
<br></br>
{editMode ? (
                <>
                    <input
                        type="url"
                        className="input2"
                        placeholder="Calendly URL"
                        value={State.calendlyurl}
                        onChange={(e) => handleInputChange('calendlyurl', e.target.value)}
                    />
                    {/* Add more input fields for other editable fields */}
                 
                </>
            ) : (
                <>
<p style={{ marginLeft : "-43px", color :  "grey", width: "170px", fontSize: "16px"  }} >{State.calendlyurl}</p>
</>)}

{/*<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'talentCode' })}</p>
 {editMode ? (
                <>
                    <input
                        type="text"
                        className="input2"
                        placeholder="Talent Code"
                        value={State.talent_code}
                        onChange={(e) => handleInputChange('talent_code', e.target.value)}
                    />
                   
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.talent_code}</p>
</>)} */}
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'currency' })}</p>

{editMode ? (
                <>
                     <select
                        value={State.currency}
                        className="input2"
                        
                        onChange={(e) => handleInputChange('currency', e.target.value)}
                    >
                          <option  value="">Currency</option>
        <option  style={{color:'black'}} >USD</option> 
        <option  style={{color:'black'}} >INR</option>
        <option  style={{color:'black'}} >CRC</option>    
        <option  style={{color:'black'}} >Vietnamese Dong</option>
        <option  style={{color:'black'}} >Colombian Pesos</option>    

                    </select>
                  
                </>
            ) : (
                <>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.currency}</p>
</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"   }} >{intl.formatMessage({ id: 'resume' })}</p>

{Resume ? (
<a href={Resume} target="_blank" style={{ color :  "#A6C6D6", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >Resume Link</a>

):(<p>No resume available</p>)}

<br></br>
<br></br>

</div>
<br></br>



<Div2>
<TitleText>Reviews</TitleText>
<RecommendProject>
<CardContent>
{ 

Object.length > 0 ? 

Object.map((item)=> {

return <Divseperator >


<Row>
            <Detail>
              <InfoContainer>


  
              <img
                  src={item.profilePicture}
                  alt=""
                  style={{ width: 50, height: 50, borderRadius : "50px" }}
                ></img>

                <Info>
                  <InfoName>Client</InfoName>
                  <InfoUpdate> {timeSince(new Date(item.r_createdAt))} </InfoUpdate>

                </Info>
              </InfoContainer>


            </Detail>
          </Row>
      

   
    <ProjectInfo>

    {Array(Number(item.rating)).fill().map((_,index)=>(
   
     <AiFillStar style={{ color: "orange", width : "18px", height : "18px"  }}></AiFillStar>  

    ))}
    <br></br><br></br>


    <Position1> {item.review}</Position1> 
   
   
    
    </ProjectInfo>
    <Div1>
    


      
      
     
           
           
 
      <ButtonEdit 
      
       
      >
{/* <NavHashLink to = {`/EditPostRequirementCand/${item.id}`}  smooth>
      
<Badge content="Edit" glow1 />     </NavHashLink>  */}

       
      </ButtonEdit>
      
    </Div1>
   
   
    {/* {show ? <p> hello miss !</p> : null} */}
    </Divseperator>
         
     }) : (<>No  Reviews</>) 

    }   

  </CardContent>
 


</RecommendProject>
</Div2>


</div>


        <div className="col-lg-3"></div>


        </div>

        <br></br>
<br></br>
<br></br>
</div>
: <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
<Circles
height="50"
width="50"
color="black"
ariaLabel="circles-loading"

wrapperStyle={{}}
wrapperClass=""
visible={true}
/> </div>  }
</center>

        </div>
 


    );
  }

export default Candidatedetailsbyid;


const OL1 = styled.ol`
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;
margin-top:10rem;
align-items:center;
justify-content:center;


}
`;

const Return = styled.ol`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;
    margin-top:5rem;
    

}
`;

const TD1 =styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;  font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 8rem;
    overflow-wrap: anywhere;
}
`;
const TD2 =styled.td`

width: 12rem;
overflow-wrap: anywhere;
padding-top:10px;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;

const TD3=styled.td`

width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-size:14px;
font-family: Poppins,medium;

@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;



const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;

width:59rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;

    width:20rem;

}
`;




const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;

        const DetailsButton=styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
        margin-top:3rem;
        margin-bottom:2rem;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

      
        
        `;

        const Headingtext1=styled.h3`
        font-family:Poppins,Medium;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        margin-top: 5rem;
        font-weight: 600;
        font-size: 1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
            display:flex;
            justify-content:center;
            align-items:center;
            margin:auto;
            margin-top: 7rem;
            }
        `;


        const Div2 = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit =styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:17rem;
    overflow-wrap: anywhere;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    
  margin: auto;
  }
`;
const Divseperator = styled.div`
border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  font-weight:600;
  font-family:Poppins, Medium;
`;

const Price2 = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  margin-left:10px;
  font-weight:600;
  font-family:Poppins, Medium;
`;
const Price1 = styled.div`
  display:flex;
  flex-direction:row;
  margin-top:10px;
`;


const ABOUT = styled.p`
color: black;

font-family:Poppins, Medium;
padding: 3px;

font-weight:600;
`;
const Certificatetab = styled.div`
display:flex;
flex-direction:column;
margin-top:10px;
`;


const CertificateName = styled.p`
color: black;

font-family:Poppins, Medium;

 
  font-weight:600;
`;


const CertificateNameinsti = styled.p`
color: black;
font-family:Poppins, Medium;
font-weight:600;
font-size:12px;
margin-top:-5px;

 
  font-weight:600;

`;





const Title = styled.h6`
  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;

`;

const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins, Medium;
width:20rem;
height:38rem;

padding: 1rem;
background-color: #F2F4F5;

transition:0.45 ease-in-out;
&:hover {

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:22rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent=styled.div`

width:17rem;
height:12.66rem;
border-bottom:1px solid #CECECE;
display:flex;
flex-direction:column;
padding:3px;



`;
const PortfolioListWrapper = styled.div`
  flex: 1;
  /* Add any additional styles for the wrapper as needed */
`;
