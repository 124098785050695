import React from "react";

function FormErrors(props) {
  if ( props.formerrors && (props.formerrors.blankfield || props.formerrors.passwordmatch)) {
    return (
      <div className="error container help" style={{ height: "30px", color: "red" }}>
        <div className="row justify-content-center">
          {props.formerrors.passwordmatch
            ? "Password value does not match confirm password value"
            : ""}
        </div>
        <div className="row justify-content-center help is-danger">
          {props.formerrors.blankfield ? "All fields are required" : ""}
        </div>
      </div>
    );
  } else if (props.apierrors) {
    return (
      <div className="error container help is-danger" style={{ height: "40px",color: "red"  }}>
        <div className="row justify-content-center">{props.apierrors}</div>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito) {
    return (
      <div className="error container help" style={{ height: "50px",color: "red"  }}>
        <div className="row justify-content-center">
          {props.formerrors.cognito.message}
        
        </div>
      </div>
    );
    console.log ('eroors',props.formerrors.cognito.message)
  } else {
    return <div />;
  }
}

export default FormErrors;