import React,{useState,useEffect} from 'react'
import styled from 'styled-components';
import Avatar from "../assets/profile_img.png";
import {cardShadow,hoverEffect,themeColor} from '../../utils';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BarGraph from '../BarGraph';
import SprintVelocityChart from '../SprintVelocityChart ';
import BarGraphstories from '../BarGraphstories';
import DashboardTaskmetricsManager from '../DashboardTaskmetricsManager';
import TimeView from '../TimeView';
import DetailPRcountmanager from '../DetailPRcountmanager';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { NavHashLink } from 'react-router-hash-link';
import Select from 'react-select'; // Import react-select
import { useAuthToken  } from '../../TokenContext';
import Dorametricsdashboard from '../Dorametricsdashboard'
import { useIntl } from 'react-intl';
function Projects({expanded}) {
  console.log('Expanded:', expanded); // Add this line

  const [selectedPods, setSelectedPods] = useState([]);
  const intl = useIntl();


  const token = useAuthToken();

 

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const [latestSprintVelocity, setLatestSprintVelocity] = useState(null);
    const [podData, setPodData] = useState([]);
    const [selectedPod, setSelectedPod] = useState('');
    const[fetchMetrics,setFetchMetrics]=useState('');
    const [sprintData, setSprintData] = useState([]);
    const [selectedSprint, setSelectedSprint] = useState({
      name: '', // Selected sprint name
      startDate: '', // Selected sprint start date
      endDate: '' // Selected sprint end date
    });
   
   
    useEffect(() => {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      if (user.data[0].type === 'stakeholder') {
        fetch(process.env.REACT_APP_STAKEHOLDER_SPRINTFILTERSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ manager_id: user.data[0].emp_id, org_code: user.data[0].client_code }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200 && data.body) {
              const parsedBody = JSON.parse(data.body);
              if (Array.isArray(parsedBody.sprint_filter)) {
                setSprintData(parsedBody.sprint_filter);
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching data from stakeholder API:', error);
          });
      } else {
        fetch(process.env.REACT_APP_CLIENT_SPRINTFILTERMANAGER, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ manager_id: user.data[0].emp_id, org_code: user.data[0].client_code }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200 && data.body) {
              const parsedBody = JSON.parse(data.body);
              if (Array.isArray(parsedBody.sprint_filter)) {
                setSprintData(parsedBody.sprint_filter);
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [token]);
  
   
   
    useEffect(() => {
      if (user.data[0].type === "stakeholder") {
         if (!token) {
            console.log('Token is missing. API requests will not be made.');
            return;
          }
        // If user type is stakeholder, make a request to the specified API_URL
        fetch(process.env.REACT_APP_STAKEHOLDER_GETPODSFORSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ org_code: user.data[0].client_code }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200 && Array.isArray(data.body)) {
              setPodData(data.body);
            }
          })
          .catch((error) => {
            console.error('Error fetching data from stakeholder API:', error);
          });
      } else {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
      fetch(process.env.REACT_APP_CLIENT_GETPODSFORMANAGER, {
        method: 'POST',
        headers: {
          Authorization: token, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emp_id: user.data[0].emp_id}),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && Array.isArray(data.body)) {
            setPodData(data.body);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      }
    }, [token]);
  

   
    
    useEffect(async () => {
      if (user?.data[0]?.type === "stakeholder") {
        try {
          if (!token) {
            console.log('Token is missing. API requests will not be made.');
            return;
          }
          // If user type is stakeholder, make a request to the specified API_URL for the latest SV dashboard
          const response = await fetch(process.env.REACT_APP_STAKEHOLDER_LATESTSVDASHBOARDSTAKEHOLDER, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify({
              org_code: user.data[0].client_code,
              pod_id: selectedPods,
            }),
          });
    
          // Parse the response body to JSON
          const data = await response.json();
    
          // Check if the response is successful and has the expected body structure
          if (data?.body && typeof data.body === 'string') {
            const responseBody = JSON.parse(data.body);
            // Extract and set the latest_sprint_velocity value
            setLatestSprintVelocity(responseBody.latest_sprint_velocity);
          } else {
            // Handle the case where the response does not have the expected structure
            console.error('Unexpected response format:', data);
          }
        } catch (error) {
          console.error('Error fetching sprint velocity:', error);
        }
      } else {
        // If user type is not stakeholder, fetch data using the existing logic
        if (selectedPods.length > 0) {
          fetchDataPod();
        } else {
          fetchData();
        }
      }
    }, [token, user.data[0].type, selectedPods]);
    

const fetchDataPod =()=>{
  if (!token) {
    console.log('Token is missing. API requests will not be made.');
    return;
  }
  fetch(process.env.REACT_APP_CLIENT_LATESTSPRINTVELOCITYMANGERPODID, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token, 
      },
            body: JSON.stringify({
        pod_id: selectedPods,
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Parse the response body to JSON
        const responseBody = JSON.parse(data.body);
        // Extract and set the latest_sprint_velocity value
        setLatestSprintVelocity(responseBody.latest_sprint_velocity);
      })
      .catch(error => {
        console.error('Error fetching sprint velocity:', error);
      });
}
const fetchData =()=>{
  if (!token) {
    console.log('Token is missing. API requests will not be made.');
    return;
  }
  fetch(process.env.REACT_APP_CLIENT_LATESTSPRINTVELOCITY, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: token, 
    },
    body: JSON.stringify({
      manager_id: user.data[0].emp_id,
      org_code: user.data[0].client_code,
    }),
  })
    .then(response => response.json())
    .then(data => {
      // Parse the response body to JSON
      const responseBody = JSON.parse(data.body);
      // Extract and set the latest_sprint_velocity value
      setLatestSprintVelocity(responseBody.latest_sprint_velocity);
    })
    .catch(error => {
      console.error('Error fetching sprint velocity:', error);
    });
}

const podOptions = podData.map((pod) => ({
  value: pod.pod_id,
  label: pod.pod_name,
}));

const handlePodSelect = (selectedOptions) => {
  setSelectedPods(selectedOptions.map((option) => option.value));
};


const handleSprintSelect = (e) => {
  const selectedSprintName = e.target.value;
  const selectedSprintData = sprintData.find(sprint => sprint.sprint_name === selectedSprintName);
  setSelectedSprint({
    name: selectedSprintName,
    startDate: selectedSprintData ? selectedSprintData.sprint_start_date : '',
    endDate: selectedSprintData ? selectedSprintData.sprint_end_date : ''
  });
};
 
 
return (
  <YourProjects expanded={expanded}>
    <NavHashLink to={`/PodMetricsmanager`} smooth>
      <TitleText>     {intl.formatMessage({ id: 'podAnalytics' })}
</TitleText>
    </NavHashLink>
<Dropdowns>
    <Seletfield
        options={podOptions}
        isMulti
        value={podOptions.filter((option) => selectedPods.includes(option.value))}
        onChange={handlePodSelect}
        placeholder={intl.formatMessage({ id: 'selectPods' })}
        styles={{
          control: (provided) => ({
            ...provided,
           
            height:'3rem',
            overflow: 'auto'
          }),
        }}
      />

<Sprintselect value={selectedSprint.name} onChange={handleSprintSelect}>
  <option value="">Select a Sprint</option>
  {sprintData.map((sprint, index) => (
    <option key={index} value={sprint.sprint_name}>
      {sprint.sprint_name}
    </option>
  ))}
</Sprintselect>
      </Dropdowns>
    <Border>
      <Project style={{ display: "block" }}>
        <Div>
          <div style={{ width: '21rem' }}>
            <BarGraph initialPodIds ={selectedPods}  selectedSprint={selectedSprint.name} /> {/* Pass selectedPods as an array */}
          </div>
          <div style={{ width: '10.5rem' }}>
            <TimeView initialPodIds={selectedPods}  selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}  />
          </div>
          <div style={{ width: '10.5rem' }}>
            <BarGraphstories initialPodIds={selectedPods}  selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate} /> {/* Pass selectedPods as an array */}
          </div>
          <div style={{ width: '10.5rem' }}>
          <DashboardTaskmetricsManager  initialPodIds={selectedPods}  selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/>
          </div>
        </Div>
      </Project>
      <DivDora >
      <div>
       
          {latestSprintVelocity !== null ? (<div style={{display:"flex",flexDirection:"row"}}>
          <span style={{ color: '#90E0EF',marginTop:"10px", }}> {intl.formatMessage({ id: 'latestSprintVelocity' })}</span>   <p style={{ color: '#90E0EF',marginTop:"10px" ,marginRight:"10px"}}>:{latestSprintVelocity}</p>
            </div>) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        
      </div>
      {/* <div>
      <p style={{  color: 'blue',marginTop:"10px",marginLeft:'1rem'  }}>{intl.formatMessage({ id: 'doraMetrics' })}</p>
        <div >< Dorametricsdashboard  initialPodIds={selectedPods} /></div>
        </div> */}
        </DivDora>
    </Border>
  </YourProjects>
);
}
const DivDora=styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
 @media screen and (min-width:320px) and (max-width:1080px){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
`;
const Seletfield=styled(Select)`
width: 60%;

margin: 0.5rem;

 @media screen and (min-width:320px) and (max-width:1080px){
  width: 40vw;
 
 }
`;
const Border=styled.div`

background-color:white;
border-radius: 1rem ;
height: 80%;
 padding:20px;
//  overflow-y:scroll;
 @media screen and (min-width:320px) and (max-width:1080px){
  // overflow-y:scroll;
  width:18rem;
 }
`;
const YourProjects=styled.div`
// border: 25px solid #90E0EF;
height: fit-content;
background-color:#0077b6  ;
border-radius: 1rem ;
padding:0.8rem;
display:flex;
flex-direction:column;

 width: ${(props) => (props.expanded ? '51rem' : '51rem')}; 
box-shadow: 0px 4px 6px #00000029;
transition:0.45 ease-in-out;
&:hover:{
box-shadow:${hoverEffect};


 
}

@media screen and (min-width:320px) and (max-width:1080px){
    width:20rem;
    height:max-content:
    display:flex;
    flex-direction:column;
justify-content:center;
align-items:center;
    margin:auto;
  
padding:0.8rem;

    margin-top:1rem;
    
   }
`;

const Div=styled.div`

display:flex;
flex-direction:row;


@media screen and (min-width:320px) and (max-width:1080px){
  
  
  display :flex;
  margin:auto;
   justify-content:center;
   flex-direction:column;
 align-items:center;
   }
`;

const TitleText = styled.h4`
height:10%;
color:white;
font-weight:600;

font-size:18px;
font-family:Poppins,Medium;
&:hover {
    
  color: #00B4D8; /* Change this to your desired text color on hover */
}
@media screen and (min-width:320px) and (max-width:1080px){
  
  
padding:1rem;

 }
`;

const Project=styled.div`
 display :flex;
 align-items: center;

 margin-bottom:0.3rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  display :flex;
 margin:auto;
  justify-content:center;
  
align-items:center;
   }
`;


const Dropdowns=styled.div`
 display :flex;
 align-items: center;
flex-direction:row;

 @media screen and (min-width:320px) and (max-width:1080px){
  display :flex;
 margin:auto;
  justify-content:center;
  flex-direction:column;
align-items:center;
   }
`;


const Sprintselect=styled.select`
textDecoration :none;
border: none;
borderRadius:none;
width:15em;
 @media screen and (min-width:320px) and (max-width:1080px){
  width:10em;
   }
`;


export default Projects