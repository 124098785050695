import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Badge from './Badge';

import {cardShadow,hoverEffect,themeColor} from '../../utils';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {VscDebugBreakpointConditional} from 'react-icons/vsc'
import {IoIosNotificationsOutline} from 'react-icons/io'
import { useDispatch, useSelector } from "react-redux";
import { icons } from 'react-icons';

import { useAuthToken } from "../../TokenContext";

import { useIntl } from 'react-intl';



function ClientProjectInfo({expanded}) {
const[details,setDetails]=useState([])
const[blockers,setBlockers]=useState([]);
const [isLoading, setIsLoading] = useState(true);
const [errorMessage, setErrorMessage] = useState('');
const { isLoggedIn, user } = useSelector(state => state.auth);
const intl = useIntl();

const token = useAuthToken();
const Blockers = async () => {
  if (!token) {
    console.log('Token is missing. API requests will not be made.');
    return;
  }
  const  headers ={
    'Authorization': token,
    'Content-Type': 'application/json',
  }

console.log("type?",user.data[0].type)
  if (user.data[0].type === "stakeholder") {
    try {
      //https://6ooukwlqwk.execute-api.us-east-1.amazonaws.com/dev/fetchLatestBlockers
      const response = await axios.post(process.env.REACT_APP_STAKEHOLDER_FTCHLATESTBLOCKERSSTAKEHOLDER,{"client_code": user.data[0].client_code },{headers:headers});
      console.log('blockers',response)
     
      setBlockers(response.data.data); // Assuming that data.body contains an array of gig options
      setIsLoading(false);
    } catch (error) {
      console.error(error);
           setErrorMessage("Failed to fetch data. Please try again later.");
          setIsLoading(false);
    }
  }else{

  try {
    //https://6ooukwlqwk.execute-api.us-east-1.amazonaws.com/dev/fetchLatestBlockers
    const response = await axios.post(process.env.REACT_APP_CLIENT_FETCHLATESTBLOCKERS,{manager_id:user.data[0].emp_id},{headers:headers});
    console.log('blockers',response)
   
    setBlockers(response.data.data); // Assuming that data.body contains an array of gig options
    setIsLoading(false);
  } catch (error) {
    console.error(error);
         setErrorMessage("Failed to fetch data. Please try again later.");
        setIsLoading(false);
  }
}
};


  const notificationdetails = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    const  headers ={
      'Authorization': token,
      'Content-Type': 'application/json',
    }
    try {
      const response = await axios.post(process.env.REACT_APP_NOTIFICATIONLIMIT,{user_id:user.data[0].emp_id},{headers:headers});
      console.log('dteails',response)
     
      setDetails(response.data.data); // Assuming that data.body contains an array of gig options
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
 
    notificationdetails();
  Blockers();
  }, [token])




  return <InfoCard  expanded={expanded}>

<SPAN>
     {/* <TitleText>Notifications</TitleText> */}
     <TitleText> {intl.formatMessage({ id: 'notifications' })}</TitleText>
     
                </SPAN>
     <Notcard>
 
     { details &&  details.length >0?details.map((data)=>
 
    <Card >
      <CardContent>
      
        <Row>
       
        
          <InfoContainer>
            {/* <Title>David Triggin </Title> */}
            <SubTitle><IoIosNotificationsOutline  style={{width:"1.5rem",height:"2rem"}}/> {data.message}  </SubTitle>
          </InfoContainer>
          
        </Row>
        
      </CardContent>
    </Card>):(<Card ><CardContent>  <Row>
       
        
       <InfoContainer>

         <SubTitle> <IoIosNotificationsOutline  style={{width:"1.5rem",height:"2rem"}}/>{intl.formatMessage({ id: 'noNotifications' })}</SubTitle></InfoContainer></Row></CardContent></Card>)
 }
    
    
    </Notcard> 


    <RecommendProject>
  <br></br>
  <TitleText1>{intl.formatMessage({ id: 'blocker' })}</TitleText1>
  <br></br>
  <Notcard>
    { !Array.isArray(blockers) || blockers.length === 0 ? (
      <Card
        
      >
        <CardContent>
          <Row>
            {/* <Title>David Triggin </Title> */}
            <InfoContainer>
              <SubTitle>
                <VscDebugBreakpointConditional
                  style={{
                    width: '1.5rem',
                    height: '3rem',
                    color: 'red',
                  }}
                />
               {intl.formatMessage({ id: 'noBlockers' })}
              </SubTitle>
            </InfoContainer>
          </Row>
        </CardContent>
      </Card>
    ) : (
     <>
        {blockers.map((data) => (
           <Card1
         
         >
          <CardContent >
            <Row   >
              <Title style={{ fontSize: '16px', fontWeight: '600' }}>
                {data.Candidate_Name}
              </Title>
              <InfoContainer>
              <SubTitle>
                <VscDebugBreakpointConditional style={{ color: 'red' }} />
                {data.description}
              </SubTitle>
              </InfoContainer>
            </Row>
          </CardContent>
          </Card1>
        ))}
      </>
    )}
  </Notcard>
</RecommendProject>

    
  </InfoCard>
}


const Viewall=styled.div`
text-align:center;
color: black;
float:right;
cursor:pointer;

border:2px solid green;
width:75px;
height:25px;
font-size:15px;
align-self:center;
`;
const InfoCard = styled.div`
height:360%!important;

width: ${(props) => (props.expanded ? '19%' : '21%')}; 

margin-left:35px;
border-radius:1rem;
background-color:#e5f8fc;
flex-direction: column;
align-items: center;
gap: 1rem;
box-shadow: 0px 4px 6px #00000029;
transition:0.4s ease-in-out;
&:hover{
  box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
 margin-right: 300px
 display: flex;

margin:auto;
align-item:center;
justify-content:center;
 }
`;

const TitleText=styled.h6`
margin-top:10px;
margin-left:10px;
font-size:18px;
font-weight:600;
font-family:Poppins,Medium;
padding-bottom:3px;
color:#03045e ;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px;
  padding: 2px;
  max-height: 30%; /* Set a maximum height */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
  border-bottom: 1px solid #00000029;
  width: 41.5%;
  margin-bottom: 2%;
  margin-top: -10px;
  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 19rem;
    display: flex;
    margin: auto;
    align-items: center; /* Fix the typo: change align-item to align-items */
    justify-content: center;
  }
`;

const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px;
  padding: 2px;
  height: 4.5rem; /* Set a maximum height */
  overflow-y: auto; /* Add vertical scroll if content exceeds max height */
  border-bottom: 1px solid #00000029;
  width: 41.5%;
  margin-bottom: 2%;
  margin-top: -10px;
  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 19rem;
    display: flex;
    margin: auto;
    align-items: center; /* Fix the typo: change align-item to align-items */
    justify-content: center;
  }
`;

const CardContent = styled.div`

margin-left:10px;
// padding: 0.7rem 1rem 0.3rem 1rem;
`;
const Row = styled.div`
display: flex;
flex-direction:column;
margin:auto;

align-item:center;
justify-content:center;

${({justify})=>
justify &&

`justify-content:space-around;
width:90%;
margin-left:6rem;
margin-bottom:0rem;

`}
`;

const Notcard=styled.div`
justify-content:center;
align-items:center;
flex-direction:column;

height:40%;
width:45rem;
margin-top:10px;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width:19rem;
 
 display: flex;

margin:auto;
align-item:center;
justify-content:center;
 }

`;

const InfoContainer = styled.div`
display:flex;

margin-right:auto;

overflow:hidden;
`;
const Title = styled.h6`
color:black;
`;
const SubTitle = styled.p`

align-item:center;
justify-content:center;
margin:auto;
color:black;
font-size:12px;
font-weight:600;
font-family:Poppins;

font-weight:Poppins;
`;

const RecommendProject=styled.div`
border-bottom-right-radius:1rem;
border-bottom-left-radius:1rem;

height:60%;
width:100%;
// margin-top:2rem;
background-color: #caf0f8  ;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:40%;
   }
`;

const Row1 = styled.div`
display: flex;

align-item:center;
margin-bottom:0.2rem;
${({justify})=>
justify &&

`justify-content:space-around;
width:90%;

margin-left:6rem;
margin-bottom:0rem;
`}
`;
const TitleText1=styled.h6`
margin-left:10px;
font-size:18px;
font-family:Poppins,Medium;
font-weight:600;
color:#03045e;
`;
const CardContent1=styled.div`
margin:0.4rem;

`;
const Detail1=styled.div`
display:flex;
justify-content:space-around;
 aligh-items:center;
margin-left:5px;
@media screen and (min-width:320px) and (max-width:1080px){
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    gap:0.2rem;
    margin-bottom:1rem;
   }
`;
const InfoContainer1=styled.div`
display:flex;
aligh-items:center;

`;

const Info1=styled.div`
margin-left:1rem;
`;
const InfoName1=styled.h6`
font-weight:500;
font-size:18px;

`;
const InfoUpdate1=styled.h6`
font-weight:300;

`;
const PriceContainer1=styled.div`
display: flex;
align-items:center;
 justify-content:space-between;
font-size:12px;
 padding:0.1rem;
 
// border-radius:1rem;
height:85%;
width:63%;
background-color:${themeColor};

@media screen and (min-width:320px) and (max-width:1080px){
    flex-dirction:column;
    gap:0.4rem;
    
   }
`;
const Price1=styled.div`

`;
const Title1=styled.h6`
font-weight:00;

`;
const SPAN=styled.div`
padding:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
 
 }
`;
const ProjectInfo1=styled.p`
padding:0
margin:0;
font-size:0.7rem;
color:#3b3b3b;
<font-weight:4></font-weight:4>00;
margin-bottom:0.5rem
`;

export default ClientProjectInfo