import React, {useEffect} from 'react'
import styled from 'styled-components';
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import AWS from 'aws-sdk';
const csv = require('csvtojson');

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const myBucket = new AWS.S3(S3Config);


const params = {
    Bucket: 'recomnd',
    Key: 'NewJobRecommendation1output_2022-11-28 12:14:17.320131.csv'
  };





function ProjectRecommendation() {

  async function csvToJSON() {
    // get csv file and create stream
    const stream = myBucket.getObject(params).createReadStream();
    // convert csv file (stream) to JSON format data
    const json = await csv().fromStream(stream);
    console.log("Data", json);
  };

  useEffect(() => {
    csvToJSON();
  }, [])
  

    return (
        <RecommendProject>
    
            <TitleText>Recommended Projects</TitleText>
    
            <CardContent>
                
              <Row>
              <Detail>
                    <InfoContainer>
                    <h5 style={{ textAlign : "center"  }}>Coming Soon</h5>
    
                    <img src={Avatar} alt='' style={{width:50}}></img>
                        <Info>
                            <InfoName>Thomas Martin</InfoName>
                            <InfoUpdate>Updated 10m ago</InfoUpdate>
                        </Info>
                        
                    </InfoContainer>
                    </Detail>
                    </Row>
                    {/* <Badge content="Design/" /> */}
               
    
                <Title>
                    Need a designer to form branding essentials for my bussiness
                </Title>
                <ProjectInfo>
                Looking for creative designer and talented front-end & back-end developers to create branding materials for my new startup.
                </ProjectInfo>
                <PriceContainer>
                    <Price>$80-100 / hour</Price>
                    <Badge content='Full Time' clean />
                </PriceContainer>
            </CardContent>
            
            <Row justify >
    
    <Badge content="View All" glow />
            
            </Row>
        </RecommendProject>
      )
    }
    
    const RecommendProject=styled.div`
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem;
    
     height:200%;
    padding: 1rem;
    background-color: white;
    width:18rem;
    box-shadow:${cardShadow};
    transition:0.45 ease-in-out;
    &:hover {
    box-shadow:${hoverEffect};
    }
    
    @media screen and (min-width:320px) and (max-width:1080px){
        width:80%;
        height:max-content;
        margin:0.4rem;
       }
    `;
    
    const Row = styled.div`
    display: flex;
    
    align-item:center;
    margin-bottom:0.8rem;
    ${({justify})=>
    justify &&
    
    `justify-content:space-around;
    width:90%;
    
    margin-left:6rem;
    margin-bottom:0rem;
    `}
    `;
    const TitleText=styled.h4``;
    const CardContent=styled.div`
    margin:0.4rem;
    
    `;
    const Detail=styled.div`
    display:flex;
    justify-content:space-around;
     aligh-items:center;
     
    @media screen and (min-width:320px) and (max-width:1080px){
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        gap:0.2rem;
        margin-bottom:1rem;
       }
    `;
    const InfoContainer=styled.div`
    display:flex;
    aligh-items:center;
    
    `;
    
    const Info=styled.div``;
    const InfoName=styled.h3`
    font-weight:500;
    
    `;
    const InfoUpdate=styled.h5`
    font-weight:300;
    
    `;
    const PriceContainer=styled.div`
    display: flex;
    align-items:center;
     justify-content:space-between;
    
     padding:0.1rem;
     
    // border-radius:1rem;
    height:100%;
    width:100%;
    background-color:${themeColor};
    
    @media screen and (min-width:320px) and (max-width:1080px){
        flex-dirction:column;
        gap:0.4rem;
        
       }
    `;
    const Price=styled.div``;
    const Title=styled.h5`
    font-weight:00;
    
    `;
    const ProjectInfo=styled.p`
    padding:0
    margin:0;
    font-size:0.7rem;
    color:#3b3b3b;
    
    margin-bottom:0.5rem
    `;

export default ProjectRecommendation
