import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const DetailviewGithublinesofcode = ({ initialPodIds ,selectedSprint,sprintStartDate,sprintEndDate}) => {
  const [metrics, setMetrics] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const chartId = useRef(uuidv4());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const chartRef = useRef(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const token = useAuthToken();
  const intl = useIntl();

  const openModal = () => {
    console.log('Opening modal');
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchData = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    try {
      let newData;
  
      if (user.data[0].type === 'stakeholder') {
        newData = await fetchStakeholderData();
      } else {
        Chart.register(...registerables);
        newData = await fetchNonStakeholderData();
      }
  
      setMetrics(newData);
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };
  
  const fetchStakeholderData = async () => {
    try {
    const payload = {};
  
      payload.pod_id = initialPodIds ||[];
    
      payload.org_code = user.data[0].client_code;
    
    payload.sprint_name = selectedSprint || '';
    payload.sprint_start_date = sprintStartDate || '';
    payload.sprint_sprint_end_datename = sprintEndDate || '';
    const response = await fetch(process.env.REACT_APP_STAKEHOLDER_GITHUBCODEMETRICSSTAKEHOLDER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(payload),
    });
  
    const data = await response.json();
  
    if (data && typeof data.body === 'object' && !Array.isArray(data.body)) {
      return data.body;
    } else if (data?.body) {
      const responseJSON = JSON.parse(data.body);

      if (responseJSON?.talent_users) {
        return responseJSON;
      } else if (responseJSON?.all_repo_checkins) {
        // Adjust the response structure to match the existing type1 structure
        return {
          talent_users: responseJSON.talent_users.map(user => ({
            github_user: user.github_user,
            total_lines_changed_last_week: user.sprint_commit_lines,
            total_lines_changed_this_week: 0, // Assuming this data is not available in type2
          })),
          all_repo_checkins: {
            current_week: responseJSON.all_repo_checkins.sprint_commit_lines,
            previous_week: 0, // Assuming this data is not available in type2
          }
        };
      } else {
        console.error('Invalid response format:', responseJSON);
      }
    } else {
      console.error('Empty response body:', data);
    }
  } catch (error) {
    console.log('Error fetching metrics:', error);
  }
};
  const fetchNonStakeholderData = async () => {
    let newData;
    if (initialPodIds?.length > 0) {
      newData = await fetchMetricsPod(initialPodIds);
    } else {
      newData = await fetchMetrics();
    }
    return newData;
  };
  
  const fetchMetricsPod = async (selectedPodIds) => {
    try {
      const apiEndpoint = process.env.REACT_APP_CLIENT_GITHUBCODEMETRICSMANAGEPODID;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          pod_id: selectedPodIds || null,
          sprint_name: selectedSprint || '',
          sprint_start_date:sprintStartDate||'',
          sprint_end_date: sprintEndDate ||''
       
        }),
      });
  
      const data = await response.json();
  
      if (data && typeof data.body === 'object' && !Array.isArray(data.body)) {
        return data.body;
      } else if (data?.body) {
        const responseJSON = JSON.parse(data.body);
  
        if (responseJSON?.talent_users) {
          return responseJSON;
        } else if (responseJSON?.all_repo_checkins) {
          // Adjust the response structure to match the existing type1 structure
          return {
            talent_users: responseJSON.talent_users.map(user => ({
              github_user: user.github_user,
              total_lines_changed_last_week: user.sprint_commit_lines,
              total_lines_changed_this_week: 0, // Assuming this data is not available in type2
            })),
            all_repo_checkins: {
              current_week: responseJSON.all_repo_checkins.sprint_commit_lines,
              previous_week: 0, // Assuming this data is not available in type2
            }
          };
        } else {
          console.error('Invalid response format:', responseJSON);
        }
      } else {
        console.error('Empty response body:', data);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };
  

  const fetchMetrics = async () => {
    try {
      const apiEndpoint = process.env.REACT_APP_CLIENT_GITHUBCODEMETRICSMANAGER;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          sprint_name: selectedSprint || '',
          sprint_start_date:sprintStartDate||'',
          sprint_end_date: sprintEndDate ||''
        }),
      });

      const data = await response.json();
  
      if (data && typeof data.body === 'object' && !Array.isArray(data.body)) {
        return data.body;
      } else if (data?.body) {
        const responseJSON = JSON.parse(data.body);
  
        if (responseJSON?.talent_users) {
          return responseJSON;
        } else if (responseJSON?.all_repo_checkins) {
          // Adjust the response structure to match the existing type1 structure
          return {
            talent_users: responseJSON.talent_users.map(user => ({
              github_user: user.github_user,
              total_lines_changed_last_week: user.sprint_commit_lines,
              total_lines_changed_this_week: 0, // Assuming this data is not available in type2
            })),
            all_repo_checkins: {
              current_week: responseJSON.all_repo_checkins.sprint_commit_lines,
              previous_week: 0, // Assuming this data is not available in type2
            }
          };
        } else {
          console.error('Invalid response format:', responseJSON);
        }
      } else {
        console.error('Empty response body:', data);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };
  

  const displayChart = () => {
    // Check if there's an existing chart instance and destroy it
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    // Get the canvas element using chartId.current
    const canvasElement = document.getElementById(chartId.current);
  
    // Check if the canvas element is found
    if (!canvasElement) {
      console.error(`Canvas element with id ${chartId.current} not found.`);
      return;
    }
  
    // Get the 2D rendering context
    const ctx = canvasElement.getContext('2d');
  
    // Check if the context is obtained successfully
    if (!ctx) {
      console.error('Failed to obtain canvas context.');
      return;
    }
  
    console.log('Initializing Chart...');
  
    let chartData, chartLabels;
  
    if (!selectedDropdownValue) {
      // If no reporter is selected, display data from all_repo_checkins
      if (metrics?.all_repo_checkins) {
        // Adjust chartData and chartLabels based on the API response type
        if ('previous_week' in metrics.all_repo_checkins) {
          chartData = [metrics.all_repo_checkins.previous_week, metrics.all_repo_checkins.current_week];
          chartLabels = ['Previous Week', 'Current Week'];
        } else if ('sprint_commit_lines' in metrics.all_repo_checkins) {
          chartData = [metrics.all_repo_checkins.sprint_commit_lines, 0];
          chartLabels = ['Sprint Commit Lines', ''];
        }
      } else {
        console.error('all_repo_checkins data not available.');
        return;
      }
    } else {
      const talentUser = metrics?.talent_users.find((user) => user.github_user === selectedDropdownValue);
  
      if (talentUser) {
        // Adjust chartData and chartLabels based on the API response type
        if ('total_lines_changed_this_week' in talentUser) {
          chartData = [talentUser.total_lines_changed_last_week, talentUser.total_lines_changed_this_week];
          chartLabels = ['Lines Changed Last Week', 'Lines Changed This Week'];
        } else if ('sprint_commit_lines' in talentUser) {
          chartData = [talentUser.sprint_commit_lines, 0];
          chartLabels = ['Sprint Commit Lines', ''];
        }
      } else {
        console.error('Selected user not found:', selectedDropdownValue);
        chartData = [0, 0];
        chartLabels = ['Lines Changed Last Week', 'Lines Changed This Week'];
      }
    }
  
    // Create a new Chart instance
    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: 'Code',
            data: chartData,
            backgroundColor: ['seagreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        tooltips: {
          enabled: false,
        },
        onClick: () => {
          openModal();
        },
        hover: {
          onHover: function (event, elements) {
            const chart = event.target;
            chart.options.plugins.annotation.labels = {
              render: 'label',
              position: 'outside',
              fontSize: 12,
              fontStyle: 'bold',
            };
            chart.update();
          },
        },
      },
    });
  };
  
  
  

  useEffect(() => {
    fetchData();
  }, [token, user.data[0].type, initialPodIds,,selectedSprint,sprintStartDate,sprintEndDate]);

  useEffect(() => {
    if (metrics) {
      displayChart();
    }
  }, [metrics,token, selectedDropdownValue, modalIsOpen]);

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
        <br/>
       
      
            <select
              style={{ width: '12rem' }}
              value={selectedDropdownValue}
              onChange={(e) => setSelectedDropdownValue(e.target.value)}
            >
              <option value="">{intl.formatMessage({id:'allReporters'})}</option>
              {metrics && metrics.talent_users && metrics.talent_users.length > 0 && metrics.talent_users.map((user) => (
    <option key={user.github_user} value={user.github_user}>
        {user.github_user}
    </option>
))}

            </select>
         
       
        
       <div className="chart-wrapper" style={{ width: "140px", height: "150px", margin: "auto", textAlign: "center", marginLeft: "2rem",marginTop:"30px" }}>
  {(metrics && (
    (selectedDropdownValue && metrics.talent_users && metrics.talent_users.length > 0 && metrics.talent_users.some(user => user.github_user === selectedDropdownValue &&
      (user.total_lines_changed_last_week !== 0 || user.total_lines_changed_this_week !== 0)
    )) ||
    (!selectedDropdownValue && metrics.all_repo_checkins && (metrics.all_repo_checkins.previous_week !== 0 || metrics.all_repo_checkins.current_week !== 0))
  )) ? (
    <canvas id={chartId.current} width="150" height="150"></canvas>
  ) : (
    <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
  )}
</div>


      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="GitHub Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>{intl.formatMessage({ id: 'githubSummary' })}</h6>
        <TableContainer component={Paper}>
          <Table component={Paper}
            sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
            aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600'
                },
              }}
            >
                 <TableCell align="right">{intl.formatMessage({ id: 'githubUser' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedLastWeek' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedThisWeek' })}</TableCell>
            </TableHead>
            <TableBody>
  {metrics?.talent_users && metrics.talent_users.map((project, index) => (
    <TableRow
      key={index}
      sx={{
        '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
        '&:nth-child(even)': {
          backgroundColor: '#90E0EF'
        },
        '&:nth-child(odd)': {
          backgroundColor: '#caf0f8'
        },
        '& td, & th': {
          fontFamily: 'Poppins,Medium',
          fontSize: '15px',
          color: 'black',
        },
      }}
    >
      <TableCell align="right">{project.github_user}</TableCell>
      <TableCell align="right">{project.total_lines_changed_last_week}</TableCell>
      <TableCell align="right">{project.total_lines_changed_this_week}</TableCell>
    </TableRow>
  ))}
</TableBody>

          </Table>
        </TableContainer>
        <div>
          {metrics?.all_repo_checkins && (
            <>
              <h6 >{intl.formatMessage({ id: 'allRepoCheckins' })}</h6>
              <p> {intl.formatMessage({ id: 'currentWeek' })}  : {metrics.all_repo_checkins.current_week}</p>
              <p> {intl.formatMessage({ id: 'previousWeek' })} : {metrics.all_repo_checkins.previous_week}</p>
            </>)}
        </div>
        <button onClick={closeModal}>{intl.formatMessage({ id: 'done' })}</button>
      </Modal>
    </div>
  );
};
DetailviewGithublinesofcode.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default DetailviewGithublinesofcode;
