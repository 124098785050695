import styled from "styled-components";
import React from "react";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import { useEffect, useRef } from "react";
import {  useSelector } from "react-redux";
import axios from "axios";
import { NavHashLink } from "react-router-hash-link";
import { useParams } from "react-router-dom";
import defaultimg from "./assets/defaultimge.png";
import {  FaSortUp, FaSortDown } from 'react-icons/fa'; // Replace with the appropriate icons from React Icons
import IconButton from "@mui/material/IconButton";
import PropTypes from 'prop-types';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Collapse } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { GrFormEdit } from "react-icons/gr";
import "react-date-range/dist/styles.css";

import { DateRangePicker } from "react-date-range";
import { useAuthToken } from "../TokenContext";
import ViewTimeSubmit from "./ViewTimeSubmit";
import { useIntl } from 'react-intl';

import { FormControl, InputLabel, Select } from "@mui/material";

const PodActivitystakeholderviewall = () => {
  const token = useAuthToken();
  const intl = useIntl();

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [getpoddetails, setGetpoddetails] = useState([]);
  const [getcandidates, setGetcandidates] = useState([]);
  const [getactivitiy, setGetactivitiy] = useState([]);
  const [activities, SetActivities] = useState([]);
  const [allactivities, SetAllactivities] = useState([]);
  const [getfilteractivitiy, setFilterGetactivitiy] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const start_date = useRef(new Date());
  const end_date = useRef(new Date());
  const [selectedPod, setSelectedPod] = useState("");
  const [podskill, setPodskill] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [optionValue, setOptionValue] = useState(0);
  const params = useParams();
  const [selectedIds, setSelectedIds] = useState([]);
  const [objects, setObjects] = useState([]);

  const [activeTab, setActiveTab] = useState("post"); // Default to 'post' tab

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10); // Initial value, you can set it based on your requirement
  const [sortByPostedDate, setSortByPostedDate] = useState('desc');
  const [selectedPodTeamMember, setSelectedPodTeamMember] = useState(null);
 // Filtered data based on selected team member
const filteredRecords = getfilteractivitiy.filter(item => selectedPodTeamMember === null || item.firstName === selectedPodTeamMember);

// Update pagination related states based on filtered data
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const records = filteredRecords.slice(firstIndex, lastIndex);
const totalNonEmptyPages = Math.ceil(filteredRecords.length / recordsPerPage);
const totalPages = Math.max(1, totalNonEmptyPages);

// Adjust currentPage if it exceeds totalPages due to filtering
if (currentPage > totalPages) {
  setCurrentPage(totalPages);
}

// Adjust handleChangePage function to handle filtered data

  const pagesToShow = 20; // Set the number of pages to show in the pagination bar
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  const npages = Math.ceil(getfilteractivitiy.length / recordsPerPage);
  const numbers = [...Array(npages + 1).keys()].slice(1);
  const [error, setError] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [podTeamMembers, setPodTeamMembers] = useState([]);
  
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const options = ["None", "date"];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setOptionValue(event.target.value);
    setAnchorEl(null);
  };
  const filterFunction = (e) => {
    setCurrentPage(1);

   let local_filter_date = getactivitiy;
    let filter_output = local_filter_date.filter(
      (item) =>
        start_date.current <=
          new Date(moment(item.createdAt).format("MM-DD-YYYY")) &&
        end_date.current >=
          new Date(moment(item.createdAt).format("MM-DD-YYYY"))
    );

    // Sort filter_output based on createdAt in descending order
    filter_output.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    
    setFilterGetactivitiy(filter_output);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const body = {
          "client_code": user.data[0].client_code,
          "stakeholder_id": user.data[0].stakeholder_id,
          status: "view_all",
        };

        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETPODACTIVITYSTAKEHOLDER,
          body,
          { headers: headers }
        );

        setError(response);

        if (response.data !== undefined && response.data.data !== undefined) {
          if (response.data.data.activities !== undefined) {
            // Sort activities by createdAt in descending order
            const sortedActivities = response.data.data.activities.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );

            setGetactivitiy(sortedActivities);
            setFilterGetactivitiy(sortedActivities);
          } else {
            setGetactivitiy([]);
            setFilterGetactivitiy([]);
          }

          SetActivities(response.data.data.activities);
          SetAllactivities(response.data.data.activities);

          if (response.data.data.candidates !== undefined) {
            setGetcandidates(response.data.data.candidates);
          } else {
            setGetcandidates([]);
          }

          if (response.data.data.podDetails !== undefined) {
            setGetpoddetails(response.data.data.podDetails);
            if (response.data.data.podDetails.skills !== undefined) {
              setPodskill(JSON.parse(response.data.data.podDetails.skills));
            } else {
              setPodskill([]);
            }
          } else {
            setGetpoddetails([]);
          }
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchData();
  }, [token, user.data]);

  
  const toggleSortByPostedDate = () => {
    const sortedData = records.slice().sort((a, b) => {
      // Sorting logic for posted date field
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortByPostedDate === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortByPostedDate(sortByPostedDate === 'asc' ? 'desc' : 'asc');
    setFilterGetactivitiy(sortedData);
  };
  
 
  
  
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleSubmit = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one data item.");
      return; // Prevent further execution of the function
    }

    const ids = selectedIds.map((id) => id); // Copy selectedIds array

    const payload = {
      poddata: [{ id: ids, manager_id: user.data[0].emp_id }],
      manager_id: user.data[0].emp_id,
    };

    fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        alert("Data Approved successfully.");
        // Optionally, you can perform further actions after successful submission.
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  
  const handleSelect = (date) => {
    let filtered = allactivities.filter((getfilteractivitiy) => {
      let activitiesDate = new Date(getfilteractivitiy["createdAt"]);
      return (
        activitiesDate >= date.selection.startDate &&
        activitiesDate <= date.selection.endDate
      );
    });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setFilterGetactivitiy(filtered);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const datePickerStyles = {
    backgroundColor: "#caf0f8", // Change the background color here
  };

  const rangeColors = ["#00B4D8"];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };
  const SortableHeader = ({ onClick, sortOrder, children }) => ( <button
  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: 'none', background: 'none' }}
  onClick={onClick}
  role="button"
  tabIndex={0} // This makes it focusable
  onKeyDown={(event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  }}
>
  <span>{children}</span>
  {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
</button>
);
// Prop validation
SortableHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  children: PropTypes.node.isRequired,
};
  useEffect(() => {
    // Extract all unique team members associated with the current pod from all records
    const allPodTeamMembers = new Set(getfilteractivitiy.map(item => item.firstName));
    setPodTeamMembers(Array.from(allPodTeamMembers));
}, [getfilteractivitiy]);

 
  

  
  return (
    <Div>
      <TitleText>{intl.formatMessage({ id: 'podActivity' })}</TitleText>

      <RecommendProject>
        {!getpoddetails && Object.keys(getpoddetails).length == 0 ? (
          <>
            <p>{intl.formatMessage({ id: 'noPodActivity' })}</p> <br></br>{" "}
            <a href="/client-createpod">
              <button className="btn btn-primary">{intl.formatMessage({ id: 'createPod' })}</button>{" "}
            </a>
          </>
        ) : (
          getpoddetails.map((result) => (
            <>
              <CardContent>
                <div>
                  <Row
                    className="btn"
                    onClick={() => {
                      setOpen(!open);
                      if (selectedPod !== result.podName) {
                        setSelectedPod(result.podName);
                        let local_filter_podid = getactivitiy;
                        let filter_output = local_filter_podid.filter(
                          (item) => item.pod_id === result.id
                        );
                        setFilterGetactivitiy(filter_output);
                      } else {
                        setSelectedPod("");
                      }
                    }}
                    onKeyDown={(e) => {
                      // Check if the user pressed the Enter key
                      if (e.key === "Enter") {
                        setOpen(!open);
                        if (selectedPod !== result.podName) {
                          setSelectedPod(result.podName);
                          let local_filter_podid = getactivitiy;
                          let filter_output = local_filter_podid.filter(
                            (item) => item.pod_id === result.id
                          );
                          setFilterGetactivitiy(filter_output);
                        } else {
                          setSelectedPod("");
                        }
                      }
                    }}
                    tabIndex={0} // This makes the element focusable
                  >
                    <Detail>
                      <InfoContainer>
                        <img
                          src={Avatar}
                          alt=""
                          style={{ width: "auto" }}
                        ></img>
                        <InfoPod>
                          <InfoName>{result.podName}</InfoName>

                          {/* <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate> */}
                        </InfoPod>
                      </InfoContainer>
                    </Detail>
                  </Row>
                  {/* </Button> */}

                  <Collapse in={selectedPod == result.podName ? true : false}>
                    <div>
                      <Poddetails>
                        <PodDiv>
                          <Title>
                            <Position>{intl.formatMessage({ id: 'podSize' })}</Position>{" "}
                            <span
                              style={{
                                marginLeft: "2.5rem",
                                marginRight: "1rem",
                              }}
                            >
                              :
                            </span>
                            <Answer>{result.podsize} </Answer>
                          </Title>

                          <Title>
                            <Position>{intl.formatMessage({ id: 'skills' })}</Position>{" "}
                            <span
                              style={{
                                marginLeft: "2.5rem",
                                marginRight: "1rem",
                              }}
                            >
                              :
                            </span>
                            <Answer>
                              {" "}
                              {JSON.parse(result.skills).map((eryt) => {
                                return (
                                  <span style={{ margin: "5px" }}>
                                    {" "}
                                    {eryt.skill}
                                    <span></span>{" "}
                                  </span>
                                );
                              })}
                            </Answer>
                          </Title>

                          <br></br>
                          <br></br>
                          <br></br>
                        </PodDiv>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Candidatesdetails>
                            <p>{intl.formatMessage({ id: 'Candidates' })}</p>
                            <br></br>

                            <div>
                              {getcandidates.length > 0
                                ? getcandidates.map((data) => (
                                    <>
                                      {data.podID == result.id && (
                                        <Title style={{ marging: "10px" }}>
                                          {data.profilePicture == "" ||
                                          data.profilePicture == null ? (
                                            <img
                                              src={defaultimg}
                                              alt=""
                                              style={{
                                                width: 40,
                                                height: 40,
                                                borderRadius: "50px",
                                              }}
                                            ></img>
                                          ) : (
                                            <img
                                              src={
                                                "https://globldatauat.s3.amazonaws.com/" +
                                                data.profilePicture
                                              }
                                              alt=""
                                              style={{
                                                width: 40,
                                                height: 40,
                                                borderRadius: "50px",
                                              }}
                                            ></img>
                                          )}
                                          <Colum>
                                            <PositionCand1>
                                              {data.firstName}{" "}
                                              <span
                                                style={{ paddingLeft: "2px" }}
                                              >
                                                {data.lastName}
                                              </span>
                                            </PositionCand1>
                                          </Colum>
                                        </Title>
                                      )}
                                    </>
                                  ))
                                : intl.formatMessage({ id: 'noCandidatesAddedToPod' }) }
                            </div>
                          </Candidatesdetails>
                        </div>
                      </Poddetails>
                    </div>
                  </Collapse>
                </div>
              </CardContent>

              <Collapse in={selectedPod == result.podName ? true : false}>
                <TitleText></TitleText>
                {tableView && selectedPod == result.podName && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{ float: "right" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={opens}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  {options.map((option, i) => (
                    <MenuItem
                      key={option}
                      value={i}
                      selected={option === "None"}
                      onClick={handleClose}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
                {optionValue == 1 && (
                  <>
                    <input
                      type="date"
                      name="start_date"
                      onChange={(e) => {
                        let value = new Date(e.target.value);
                        start_date.current = value;
                        filterFunction();
                      }}
                    />
                    <input
                      type="date"
                      name="end_date"
                      onChange={(e) => {
                        let value = new Date(e.target.value);
                        end_date.current = value;
                        filterFunction();
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setOptionValue(0);
                        setFilterGetactivitiy(getactivitiy);
                        setCurrentPage(1);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )}
                <TabsContainer>
                  <TabButton
                    onClick={() => handleTabClick("post")}
                    active={activeTab === "post"}
                  >
                   {intl.formatMessage({ id: 'viewPodActivities' })}
                  </TabButton>
                  <TabButton
                    onClick={() => handleTabClick("view")}
                    active={activeTab === "view"}
                  >
                  {intl.formatMessage({ id: 'viewTimeEntries' })}
                  </TabButton>
                </TabsContainer>
                {activeTab === "post" ? (
                  <>
                 <Buttonexp onClick={toggleExpand}>
  {intl.formatMessage({ id: 'dateRangeFilter' })}
</Buttonexp>

                    {isExpanded && (
                      <div>
                        {" "}
                        <DateRangePicker
                          style={datePickerStyles}
                          rangeColors={rangeColors}
                          showMonthAndYearPickers
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                        />
                      </div>
                    )}

                    <div style={{ width: "1000px" }}>
                      {tableView && selectedPod == result.podName && (
                        <TableContainer
                          component={Paper}
                          sx={{ marginTop: "2rem" }}
                        >
                          <Table
                            component={Paper}
                            sx={{
                              maxWidth: 700,
                              backgroundColor: "#90E0EF",
                              fontFamily: "Poppins,Medium",
                              borderRadius: "10px",
                            }}
                            aria-label="simple table"
                          >
                            {" "}
                            <TableHead
                              sx={{
                                backgroundColor: "#00B4D8",
                                "& td, & th": {
                                  fontFamily: "Poppins,Medium",
                                  fontSize: "15px",
                                  color: "white",
                                  fontWeight: "600",
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell align="right">{intl.formatMessage({ id: 'select' })}</TableCell>

                                <TableCell align="right">
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  <span>{intl.formatMessage({ id: 'PodTeamMember' })}</span>
  <Select
    value={selectedPodTeamMember}
    onChange={(event) => setSelectedPodTeamMember(event.target.value)}
    style={{ marginLeft: '8px' }}
  >
    <MenuItem value={null}>All Team Members</MenuItem>
    {podTeamMembers.map((member, index) => (
      <MenuItem key={member} value={member}>{member}</MenuItem>
    ))}
  </Select>
</div>
</TableCell>

                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                 {intl.formatMessage({ id: 'jiraIssue' })}
                                </TableCell>
                                {/* <TableCell align="right"sx={{fontWeight:'bold'}}>Category</TableCell> */}
                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {intl.formatMessage({ id: 'todaysFocus' })}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {intl.formatMessage({ id: 'accomplishments' })}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                   {intl.formatMessage({ id: 'blocker' })}
                                </TableCell>
                                <TableCell align="right">
      <SortableHeader onClick={toggleSortByPostedDate} sortOrder={sortByPostedDate}>
        {intl.formatMessage({ id: 'postedDate' })}
      </SortableHeader>
    </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {intl.formatMessage({ id: 'videoUrl' })}
                                </TableCell>

                                <TableCell align="right">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {intl.formatMessage({ id: 'status' })}
   
  </div>
</TableCell>

                                <TableCell
                                  align="right"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {intl.formatMessage({ id: 'edit' })}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                           
                            {records.length > 0 ? (
  records.map((item, i) => (
    <>
    <TableBody key={item}>
      {(selectedPodTeamMember === null || item.firstName === selectedPodTeamMember) && (
       
                                      <TableRow
                                        key={i}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                            fontFamily: "Poppins,Medium",
                                            fontSize: "15px",
                                          },
                                          "&:nth-child(even)": {
                                            backgroundColor: "#90E0EF",
                                          },
                                          "&:nth-child(odd)": {
                                            backgroundColor:
                                              "#caf0f8",
                                          },
                                          "& td, & th": {
                                            fontFamily: "Poppins,Medium",
                                            fontSize: "15px",
                                            color: "black",
                                          },
                                        }}
                                      >
                                        {item.posted_to_client == 1 ? (
                                          <TableCell>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              disabled
                                              checked={selectedIds.includes(
                                                item.id
                                              )}
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event,
                                                  item.id
                                                )
                                              }
                                            />
                                          </TableCell>
                                        ) : (
                                          <TableCell>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              checked={selectedIds.includes(
                                                item.id
                                              )}
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event,
                                                  item.id
                                                )
                                              }
                                            />
                                          </TableCell>
                                        )}
{item.firstName === null ? (
                                          <TableCell align="right">
                                            null{" "}
                                          </TableCell>
                                        ) : (
                                          <TableCell align="right">
                                            {item.firstName}{" "}
                                          </TableCell>
                                        )}
                                        {item.jira_issue === null ? (
                                          <TableCell align="right">
                                            null{" "}
                                          </TableCell>
                                        ) : (
                                          <TableCell align="right">
                                            {item.jira_issue}{" "}
                                          </TableCell>
                                        )}
                                        {/* <TableCell align="right">{item.categories} </TableCell> */}
                                        <TableCell align="right">
                                          {" "}
                                          {item.today_focus}
                                        </TableCell>

                                        <TableCell align="right">
                                          {" "}
                                          {item.accomplishments}
                                        </TableCell>
                                        <TableCell align="right">
                                          {" "}
                                          {item.blockers}
                                        </TableCell>

                                        <TableCell
                                          align="right"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setTableView(false);
                                            let value = new Date(
                                              moment(item.createdAt).format(
                                                "MM-DD-YYYY"
                                              )
                                            );

                                            start_date.current = value;
                                            end_date.current = value;
                                            filterFunction();
                                          }}
                                          onDoubleClick={() => {
                                            setOptionValue(0);
                                            setFilterGetactivitiy(getactivitiy);
                                            setCurrentPage(1);
                                          }}
                                        >
                                          {" "}
                                          {moment(item.createdAt).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </TableCell>
                                        <TableCell align="right">
  {item.loom_url ? (
    <a href={item.loom_url} target="_blank" rel="noopener noreferrer">
      {intl.formatMessage({ id: 'viewVideo' })}
    </a>
  ) : (
    // Message when no video is available
    <p>{intl.formatMessage({ id: 'noVideoAvailable' })}</p>
  )}
</TableCell>

                                        {item.posted_to_client == 1 ? (
                                          <TableCell align="right">
                                           
                                            {intl.formatMessage({ id: 'approved' })}
                                          </TableCell>
                                        ) : (
                                          <TableCell align="right">
                                      
                                            {intl.formatMessage({ id: 'pending' })}
                                          </TableCell>
                                        )}
                                        {item.posted_to_client == 1 ? (
                                          <TableCell align="right">
                                            {" "}
                                            <ButtonEdit disabled>
                                              <GrFormEdit
                                                style={{ color: "white" }}
                                              />{" "}
                                            </ButtonEdit>{" "}
                                          </TableCell>
                                        ) : (
                                          <TableCell align="right">
                                            {" "}
                                            <NavHashLink
                                              to={{
                                                pathname:
                                                  "/Edittalentpodactivitybyid",
                                                state: {
                                                  Id: item.id,
                                                },
                                              }}
                                              smooth
                                            >
                                              {" "}
                                              <ButtonEdit>
                                                <GrFormEdit
                                                  style={{ color: "white" }}
                                                />{" "}
                                              </ButtonEdit>{" "}
                                            </NavHashLink>{" "}
                                          </TableCell>
                                        )}
                                      </TableRow>
                                      )}</TableBody>
                                 
                                </>
                              ))
                            ) : (
                              <>{intl.formatMessage({ id: 'noReviews' })} </>
                            )}
                          </Table>
                          <Button onClick={handleSubmit}>{intl.formatMessage({ id: 'approve' })}</Button>
                        </TableContainer>
                      )}
                    </div>

                    {tableView && selectedPod == result.podName && (
                      <div style={{ textAlign: "center" }}>
                        <ul>
                          <li style={{ display: "inline-block", margin: 4 }}>
                            <p
                              style={{
                                cursor: "pointer",
                                border: "none",
                                textDecoration: "none",
                              }}
                              onClick={() => handlePrevPage()}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handlePrevPage();
                                }
                              }}
                              disabled={currentPage === 1}
                            >
                              {intl.formatMessage({ id: 'prev' })}
                            </p>
                          </li>

                          {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((number) => (
  <button
    key={number}
    onClick={() => handleChangePage(number)}
    style={{
      fontWeight: currentPage === number ? 'bold' : 'normal',
      color: currentPage === number ? '#90E0EF' : 'black',
    }}
  >
    {number}
  </button>
))}
                          <li style={{ display: "inline-block", margin: 4 }}>
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={handleNextPage}
                              onKeyDown={(e) => {
                                // Check if the user pressed the Enter key
                                if (e.key === "Enter") {
                                  handleNextPage();
                                }
                              }}
                              tabIndex={0} // This makes the element focusable
                              role="button" // Add a role attribute for better accessibility
                              disabled={currentPage === totalPages}
                            >
                              {intl.formatMessage({ id: 'next' })}
                            </p>
                          </li>

                          <FormControl style={{marginTop:'1rem'}}>
                            <InputLabel id="items-per-page-label">
                            {intl.formatMessage({ id: 'rowsPerPage' })}
                            </InputLabel>
                            <Select
                              labelId="items-per-page-label"
                              id="items-per-page"
                              value={recordsPerPage}
                              onChange={(e) =>
                                setRecordsPerPage(parseInt(e.target.value))
                              }
                            >
                              <MenuItem value="" disabled>
                                {" "}
                                {intl.formatMessage({ id: 'selectItemsPerPage' })}
                              </MenuItem>

                              <MenuItem value={10}>10 Rows</MenuItem>
                              <MenuItem value={20}>20 Rows</MenuItem>
                              <MenuItem value={30}>30 Rows</MenuItem>
                              <MenuItem value={40}>40 Rows</MenuItem>
                              <MenuItem value={50}>50 Rows</MenuItem>
                            </Select>
                          </FormControl>
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <ViewTimeSubmit Podid={result.id} />
                )}
                {!tableView && selectedPod == result.podName && (
                  <IconButton
                    style={{ float: "right" }}
                    onClick={() => {
                      setOptionValue(0);
                      setFilterGetactivitiy(getactivitiy);
                      setCurrentPage(1);
                      setTableView(true);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                {!tableView &&
                  selectedPod == result.podName &&
                  records.map((item, i) => (
                    <>
                      {result.id == item.pod_id && (
                        <Divseperator>
                          <Row>
                            <Detail>
                              <InfoContainer>
                                <img
                                  src={defaultimg}
                                  alt=""
                                  style={{ width: 50, height: 50 }}
                                ></img>

                                <Info>
                                  <InfoName>
                                    {item.firstName} {item.lastName}{" "}
                                    <span
                                      style={{
                                        marginLeft: "1.5rem",
                                        fontSize: "13px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        width="10px"
                                        height="10px"
                                        src={""}
                                      ></img>{" "}
                                      {item.categories}{" "}
                                    </span>
                                  </InfoName>
                                  <InfoUpdate>
                                    <span style={{ marginRight: 10 }}>
                                      {moment(item.createdAt).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </span>{" "}
                                    {item.today_focus}{" "}
                                  </InfoUpdate>
                                </Info>
                              </InfoContainer>
                            </Detail>
                          </Row>
                          <ProjectInfo>
                            <Position1> {""} </Position1>
                          </ProjectInfo>
                          <Div1>
                            <ButtonEdit></ButtonEdit>
                          </Div1>
                        </Divseperator>
                      )}
                    </>
                  ))}
              </Collapse>

              {selectedPod == result.podName && <hr />}
            </>
          ))
        )}

        {/* </Divseperator> */}
      </RecommendProject>
    </Div>
  );
};

const Div = styled.div`
  display: flex;

  jutify-content: center;
  align-items: center;

  flex-direction: column;
  margin: auto;
  margin-top: 6rem;

  // margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit = styled.button`
  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top: 2rem;
  }
`;
const RecommendProject = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: column;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 100%;
    height: max-content;
    margin: auto;

    display: flex;

    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;

const Position = styled.p`
  width: 5rem;
  font-size: 15px;
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 4rem;
  }
`;

const Position1 = styled.p`
  font-weight: 600;
  width: 9rem;
  font-size: 15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 4rem;
  }
`;

const Answer = styled.p`
  font-weight: 600;
  width: 15rem;
  font-size: 15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const Answer1 = styled.p`
  font-weight: 600;
  width: 16rem;
  font-size: 15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding: 1rem;

  height: max-height;

  border-radius: 15px;
  font-family: Poppins, Medium;
  background-color: #f2f4f5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin: auto;
  }
`;
const Divseperator = styled.div`
  // border-bottom:1px solid #CECECE;
  padding: 1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableInfoContainer = styled.div`
  display: block;
  align-items: left;
  justify-content: left;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const TableInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 0px;
`;
const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;

const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`
  font-weight: 600;
  display: flex;
  flex-direction: row;
`;
const ProjectInfo = styled.h6`
  font-weight: 600;
  display: flex;
  flex-direction: row;
`;

const SideCard = styled.div`
  margin-left: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  font-family: Poppins, Medium;
  width: 20rem;
  height: 34rem;

  padding: 1rem;
  background-color: #f2f4f5;

  box-shadow: ${cardShadow};
  transition: 0.45 ease-in-out;
  &:hover {
    box-shadow: ${hoverEffect};
  }
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;

    margin: auto;
    margin-top: 2rem;
  }
`;

const SideCardContent = styled.div`
  background-color: #90e0ef;
  width: 17rem;

  display: flex;
  flex-direction: column;
  padding: 3px;
  margin: 1rem;

  border-radius: 20px;
`;

const SideCardContent1 = styled.div`
  background-color: #90e0ef;
  width: 17rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  height: 10rem;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1 = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
  padding: 5px;
  margin: 3px;
  background-color: white;
  border-radius: 5px;
  font-size: 13px;
  color: black;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ViewAll = styled.button`
  width: 5rem;
  font-weight: 600;
  height: 2rem;
  margin-left: auto;
  margin-top: 1rem;
  border: 2px solid #1dbf73;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
  width: 8rem;
  font-weight: 600;
  height: 2rem;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #00000029;
`;

const Poddetails = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const PodDiv = styled.div``;

const Candidatesdetails = styled.div`
  height: 12rem;
  padding: 5px;
  width: 14rem;
  border-radius: 15px;
  height: 20rem;
  background-color: rgb(166, 194, 206, 30%);
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 2rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Colum = styled.div`
  margin-left: 10px;
`;

const PositionCand = styled.p`
  font-weight: 600;
  width: max-content;
  width: 11rem;
  font-size: 15px;
  margin-top: -1rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
  }
`;

const PositionCand1 = styled.p`
  font-weight: 600;
  width: 11rem;
  font-size: 15px;
  margin-top: 5px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
  }
`;

const H1 = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1.2rem;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;
  width: 10rem;
  height: 2rem;
  font-size: 15px;
  font-weight: 600;
  color: white;
`;
const Buttonexp = styled.button`
  margin-top: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #00b4d8;
  box-shadow: 1px 3px 6px #00000029;
  width: 12rem;
  height: 2rem;
  font-size: 15px;
  font-weight: 600;
  color: white;
`;

const InputContainer = styled.div`
  display: flex;
  border-radius: 10px;
  justify-content: center;
  margin-left: auto;
  width: 25rem;
  margin-top: -2rem;
  margin-bottom: 1rem;
  border: 1px solid #4e8fae;
  box-shadow: 3px 6px 9px #00000005;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 18rem;
  }
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
  justify-content: center;
  height: 3rem;
  width: 25rem;
  border: 0px solid #4e8fae;
  background-color: #f2f3f4;
  font-family: Poppins, Medium;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  &:focus {
    border: none;
    outline: none;
  }
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;
const ManageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left:15rem;
  padding: 5px;
  width: 10rem;
  height: 2rem;
  font-size: 16px;
  font-weight: 600;
  color: white;

  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;
`;

const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? "lightblue" : "white")};
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  outline: none;
`;

export default PodActivitystakeholderviewall;
