import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect ,useState} from 'react';
import axios from 'axios';
import Badge from "./Badge";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { NavHashLink } from 'react-router-hash-link';
import { CheckBox } from '@mui/icons-material';
import { useToastContainer } from 'react-toastify';
import AddPodactivityLead from './AddPodactivityLead';
import Pods from './Pods';
import { Calendar, DateRangePicker } from 'react-date-range';
import {GrFormEdit} from 'react-icons/gr';
import {RiDeleteBin6Line} from 'react-icons/ri';
import { FaSort } from 'react-icons/fa';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { IoAdd } from 'react-icons/io5';
import moment from 'moment';
import './stylereact.css';

import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const ViewPodTasksManagerbyid = (props) => {

  const token = useAuthToken();
  const intl = useIntl();


  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [paginationdate, setPaginationdate] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [id, setId] = useState('');
  const { isLoggedIn, user } = useSelector(state => state.auth);
const[getpodactivitydata,setGetpodactivitydata]=useState([]);
const[status,setStatus]=useState('');
const [sorting, setSorting] = useState({
  column: '', // Default sorting column
  order: 'asc', // Default sorting order
});
const location = useLocation();
const { Id } = location.state
const  {podname}= location.state
let path = window.location.pathname;
let path1 = path && path.split(',')

let c_id =  path1 && path1.length > 0 && path1[0].split('/') 
console.log('c_id',c_id)
let c = c_id && c_id.length > 0 && c_id[2]
console.log('path1',path1[1]);
console.log('c',c)
console.log('idyiu',path1[1]);


const podid=props.podid
console.log('propssss',podid);
useEffect(() => {
  const headers = {
    'Authorization': token,
    'Content-Type': 'application/json',
  };

  const body = {
    pod_Id: Id,
  };

  axios.post(process.env.REACT_APP_CLIENT_CLIENTVIEWMYPODTASK, body, { headers: headers })
    .then((res) => {
      const sortedData = res.data.data.sort((a, b) => {
        return new Date(b.due_date) - new Date(a.due_date);
      });

      setGetpodactivitydata(sortedData);
    })
    .catch((err) => {
      console.log("catch", err);
    });
}, [token, Id]);


const filterData = (data, selectedStatus) => {
  if (selectedStatus) {
    return data.filter((item) => item.status === selectedStatus);
  }
  return data;
};


const handleClick = (event) => {
  setcurrentPage(Number(event.target.id));
}


const pages = [];
for (let i = 1; i <= Math.ceil(getpodactivitydata.length / itemsPerPage); i++) {
  pages.push(i);
}
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;

const currentItems = getpodactivitydata.slice(indexOfFirstItem, indexOfLastItem);
const renderPageNumbers = pages.map(number => {
  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
   
    return (
      <div>
      <li key={number} id={number} onClick={handleClick} 
      className={currentPage == number ? 'active' : null}>{number}</li>
   
      </div>
      );
  } else {
    return null;
  }
});



const handleNextbtn = () => {
  setcurrentPage(currentPage + 1);
  if (currentPage + 1 > maxPageNumberLimit) {
    setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  }
};
const handlePrevbtn = () => {
  setcurrentPage(currentPage - 1);
  if ((currentPage - 1) % pageNumberLimit == 0) {
    setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  }
  
};
var todaysDate = new Date();



const handleSort = (column) => {
  setSorting((prevSorting) => {
    if (prevSorting.column === column) {
      // Toggle the sort order if the same column is clicked again
      return { ...prevSorting, order: prevSorting.order === 'asc' ? 'desc' : 'asc' };
    }
    // Set the new column and default sort order
    return { column, order: 'asc' };
  });
};


const sortedData = getpodactivitydata.sort((a, b) => {
  if (sorting.order === 'asc') {
    return a[sorting.column] > b[sorting.column] ? 1 : -1;
  } else {
    return a[sorting.column] < b[sorting.column] ? 1 : -1;
  }
});

const inlineStyles = {
  asc: {
    transform: 'rotate(180deg)'
  },
  desc: {
    transform: 'rotate(0deg)'
  }
};



const renderdata = (sortedData) => {
  return (
    
  <Div >
    
    <TitleText> {intl.formatMessage({ id: 'podTasks' })}</TitleText>
<ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                {intl.formatMessage({ id: 'prev' })}
              </button>
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > {intl.formatMessage({ id: 'next' })}</button>
            </li>
          </Num2>
        </ol>

  { getpodactivitydata.length == 0 ? (<CardContent  style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'auto'}}> {intl.formatMessage({ id: 'noPodTasks' })}...
 
  </CardContent>) : (<>
      
       <TableContainer  component={Paper}>
     
    <div>

      
      
      <Table 
      
      component={Paper} sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}} aria-label="simple table">
    
      
    
        <TableHead  sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}} >
        

          <TableRow >
          {/* <TableCell  align="right" onClick={() => handleSort('due_date')}>
          <Span >Sort </Span>  
          {sorting.column === 'due_date' && (
         <AiOutlineSortAscending className={sorting.order === 'asc' ? 'asc' : 'desc'} />
       )}
      
     </TableCell> */}
          
            <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podName' })}</TableCell>
            <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'podTask' })}</TableCell>
            <TableCell align="right" sx={{fontWeight:'bold'}}> 
            <SelectField
      value={selectedStatus}
      onChange={(e) => setSelectedStatus(e.target.value)}
    >
      <option value="">All Status</option>
      <option value="completed">Completed</option>
      <option value="Pending">Pending</option>
      {/* Add more options for other status values */}
    </SelectField>
    </TableCell>
            <TableCell align="right" sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'PodTeamMember' })}</TableCell> 
            <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'categories' })}</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'description' })}</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>Jira Item</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'blocker' })}</TableCell>
            <TableCell align="right" onClick={() => handleSort('due_date')}  sx={{fontWeight:'bold',cursor:"pointer"}}>{intl.formatMessage({ id: 'dueDate' })} <span style={{fontSize:"10px",marginLeft:'1px'}}><FaSort/></span>
            {sorting.column === 'due_date' && (
         <AiOutlineSortAscending className={sorting.order === 'asc' ? 'asc' : 'desc'} />
       )}
            </TableCell>
           
          </TableRow>
        </TableHead>
       
        <TableBody     >
        {filterData(sortedData, selectedStatus).map((x1, i) => (
       
            <TableRow
            
              key={""}
              sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
               '&:nth-child(even)': {
                backgroundColor: '#90E0EF'
              },
              '&:nth-child(odd)': {
                backgroundColor: '#caf0f8'
              },
              '& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'black',
              },
             }}
            >
              
              
              <TableCell   align="right" >{podname}</TableCell>
              <TableCell   align="right" >{x1.pod_task_name}</TableCell>
              <TableCell align="right">{x1.status}</TableCell>
              <TableCell align="right">{x1.Candidate_Name}</TableCell>
              <TableCell align="right">{x1.category}</TableCell>
              <TableCell align="right">{x1.description}</TableCell>
              <TableCell align="right">{x1.jira_item}</TableCell>
              <TableCell align="right">
      {x1.blockercheck === 1 ? (
        // Render a checked checkbox and disable it
        <CheckBox checked disabled />
      ) : (
        // Render an unchecked checkbox that can be checked
        <></>
      )}
    </TableCell>
              <TableCell align="right">{ moment(x1.due_date).format("MM-DD-YYYY")}</TableCell>
         
           
            </TableRow>
            
            ))}
        </TableBody>

    
      </Table>
      
      
      </div> 
      
     
    </TableContainer>
    </>)}
    </Div>
    
  )
}
return (<div>

  {renderdata(currentItems)}
  </div>
 )

}



export default ViewPodTasksManagerbyid


const Div =styled.div`
margin-left: 5rem;
margin-top: 10rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
  width: 20rem;
  }

`;
const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const ButtonEdit =styled.button`

  text-decoration: none  !important;

  background:none;
 
 
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const ButtonAdd =styled.button`
display: flex;
    flex-direction: row;
justify-content: center;
align-items: center;

    margin: auto;
  text-decoration: none  !important;
  border-radius: 10px;
 font-weight:600;
 background:none;
  color: #00B4D8;
  border: none  !important;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Para =styled.p`
width: max-width;
float: right;
padding: 5px;
color: white;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin: auto;
  }

`;
const Button=styled.button`
display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
    margin: auto;
margin-top:1rem;

border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:5rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;

@media screen and (min-width:320px) and (max-width:1080px){
  width:5rem;
 
  
 }
`;
const Buttondelete =styled.button`

  text-decoration: none  !important;
 
  background:none  !important;

  color: black;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const Buttonpost =styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:seagreen;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
        const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;
        const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Span=styled.span`
cursor:pointer;
`;

const SelectField = styled.select`
 
  border-radius: 5px;
  width: 9.5rem;
  height: 3rem;
  font-weight: 600;
  color: white;
  font-family: Poppins, Medium;
background-color:#00B4D8;
border:none;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    margin: auto;
  }
`;