import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import EditIcon from '@mui/icons-material/Edit';
import { updateProfilecandidate } from "./action/auth";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import moment from "moment";
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import LanguageSelector from '../LanguageSelector'
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'usa', label: 'USA'
   },
 { 
   value: 'canada', label: 'Canada'
  },
 { 
   value: 'india', label: 'India'
  }
]

const work_authorization=[
 { value: 'US Citizen', label: 'US Citizen'},
{  value: 'Permanent Resident', label: 'Permanent Resident'
}  ,
{value: 'EAD', label: 'EAD'
},
{value: 'OPT-EAD', label: 'OPT-EAD'},
{  value: 'H4-EAD', label: 'H4-EAD'},
 { value: 'H1B', label: 'H1B'},
  {value: 'TN', label: 'TN'},
 { value: 'Not Applicable (NA)', label: 'Not Applicable (NA)'},
  


  
  
  
 
  
  
  
  
  
  
  
  

]
const techStack = [
  {
    value: 'MEAN', label: 'MEAN'
  },
  {
    value: 'MERN', label: 'MERN'
  },
  {
    value: 'LAMP', label: 'LAMP'
  },
  {
    value: 'MEVN', label: 'MEVN'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
   {
    value: 'IT & Software', label: 'IT & Software'
  },
 { 
   value: 'Banking and Financial', label: 'Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]



const Updateprofile = ({ currentLocale, changeLocale }) => {
const Re = []
const Re1 = []
const Re2 = []
const token = useAuthToken();


  const [Edit, setEdit] = useState(false); 
  const [Image, setImage] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 

const[mister,setMister]=useState();

  const [Uploading, setUploading] = useState(false)
  const [S3Object, setS3Object] = useState([])
  const [Resume, setResume] = useState(""); 
  const [Assesment, setAssesment] = useState(""); 
const[pod,setPod]=useState([]);

const intl = useIntl();


  const { isLoggedIn, user } = useSelector(state => state.auth);

  console.log('usercconsole',user)

  const dispatch = useDispatch();

  const inputArr = [{
    degree: "",
    domain: "",
    institution:"",
    startDate : "",
    candidateID : user.data[0].c_id,
    endDate : "",
    id  : ""
  }];
  const expArray = [{
    work_organization: "",
    work_position: "",
    workStartdate:"",
    workEnddate : "",
    present:0,
    candidateID : user.data[0].c_id,
    id  : ""
  }];
  
  const certificationArray = [{
    cert_name:"",
    candidateID: user.data[0].c_id, 
    startDate: "",
    endDate: "", 
    certUrl :"", 
    certificationInstitution: "",
    id  : ""
   }];
  

  const [arrexp1, setArrexp1] = useState(inputArr);
  const [arrCert, setArrCert] = useState(certificationArray);
  const [Userexp, setUserexp] = useState(expArray);


  const[testing,setTesting]=useState('');

   const [arrexp, setArrexp] = useState({
    experience : [],
    education : [],
    certification : []
  });


 
  const addInput = (event) => {
    event.preventDefault(); // Prevent form submission
  
    var joined = arrexp1.concat(inputArr[0]);
    console.log('addinputdat 33333 ; ', joined);
    setArrexp1(joined)
  };



  const addExp = () => {
    var joined = Userexp.concat(expArray[0]);
    setUserexp(joined)
  };


  const addCert = () => {
    var joined = arrCert.concat(certificationArray[0]);
setArrCert(joined)
   
  };
  

  const handleChangeExp = (e, idx, field) => {
    let clone = [...Userexp];
    let obj = clone[idx];
    if (field === 'present') {
      obj.present = e.target.checked ? 1 : 0;
      if (e.target.checked) {
        obj.workEnddate = ''; // Reset end date when present is checked
      }
    } else {
      obj[field] = e.target.value;
    }
    clone[idx] = obj;
    setUserexp(clone);
  }
  

   const handleChangeCert = (e,idx) => {
    let clone = [...arrCert];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    console.log('handlechageCert 33333 ; ',[...clone]);
    setArrCert([...clone]);
   }

   const handleChange = (e,idx) => {
   
    let clone = [...arrexp1];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    console.log('handlechage 333338 ; ',[...clone]);
    setArrexp1([...clone])
   }


  const history = useHistory(); 

  const myBucket = new AWS.S3(S3Config);

  const [State, setState] = useState({
  fname: user.data[0].firstName,
  mname: user.data[0].middleName,
  lname: user.data[0].lastName,
  candidateemail: "",
  area: "", 
  github_repo_name :user.data[0].github_repo_name,
  github_username:user.data[0].github_username,
  jira_username:user.data[0].jira_username,
  sonarqube_project_key:user.data[0].sonarqube_project_key,
  country: user.data[0].country,
  primaryskill: user.data[0].primaryskill,
  portfolio: user.data[0].portfolioUrl,
  linkedin:user.data[0].linkedinUrl,
  github:user.data[0].githubUrl,
  industry: user.data[0].industry,
  degree: user.data[0].degree,
  institution: user.data[0].institution,
  domain: user.data[0].domain,
  startingDate: user.data[0].startingDate,
  endingDate: user.data[0].endingDate,
  certification: user.data[0].certification,
  certificationIntitution: user.data[0].certificationInstitution,
  certificationUrl: user.data[0].certificationUrl,
  certification_startDate: user.data[0].certification_startDate,
  certification_endDate: user.data[0].certification_endDate,
  work_organization: user.data[0].work_organization,
  work_position: user.data[0].work_position,
  present: user.data[0].present,

  
  workStartdate: user.data[0].workStartdate,
  workEnddate: user.data[0].workEnddate,
  secondaryskill: user.data[0].secondaryskill,
  resetForm: "",
  ratePerhour: user.data[0].ratePerhour, 
  ratePermonth: user.data[0].ratePermonth, 
  currency: user.data[0].currency, 
  workhours: user.data[0].workhours,
  about : user.data[0].about,
  techStack: user.data[0].techStack,
  email : user.data[0].email,
  resume : user.data[0].resume,
  coding_assessment_url:user.data[0].coding_assessment_url, 
  work_authorization : user.data[0].work_authorization,
  timezone : user.data[0].timezone,
  availability: user.data[0].availability,
  hoursperweek:user.data[0].hoursperweek,
  currentStatus : user.data[0].currentStatus,
  availabledate : user.data[0].availabledate,
  profilePicture : user.data[0].profilePicture,
  calendlyurl :user.data[0].calendlyurl,
  talent_code  :user.data[0].talent_code, 
  profileImg:
	'/https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    errors: {
      blankfield: false,
      passwordmatch: false
    }
  })



  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  var candidateImage;


  const clearErrorState = () => {
    setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

//   const onFileUpload = e => {

//     setUploading(true)

//     const file = e.target.files[0];
// console.log(file,'2@@@@@@@@@@@@@@@@@@@@@@@@@@@')
//     S3FileUpload.uploadFile(file, config)
//     .then(data => {

//       const location = `https://globldatauat.s3.amazonaws.com/${data.key}`;

//       setState((prevState) => {
//        return { ...prevState, [e.target.id]: location };
//     });
//     setUploading(false)
//     })    
//     .catch(err => { console.log(err);    
//        setUploading(false)
//     })
    
//   }


const onFileUpload = e => {

  setUploading(true)

  const file = e.target.files[0];
  // console.log(file)
  S3FileUpload.uploadFile(file, config)
    .then(data => {

      setState((prevState) => {
        return { ...prevState, resume: data.key };
      });
      setUploading(false)
    })
    .catch(err => {
      console.log(err); setUploading(false)
    })

}
const onFileUploadassesment = e => {

  setUploading(true)

  const file = e.target.files[0];
  // console.log(file)
  S3FileUpload.uploadFile(file, config)
    .then(data => {

      setState((prevState) => {
        return { ...prevState, coding_assessment_url: data.key };
      });
      setUploading(false)
    })
    .catch(err => {
      console.log(err); setUploading(false)
    })

}

    const onImageUpload = e => {

      const file = e.target.files[0];
  console.log("2#$#$#$#$#$$#$",file);

  if (file) {
    setImage(URL.createObjectURL(file));
    console.log("2#$#$#$#$#$$#$",URL.createObjectURL(file));

  }



      S3FileUpload.uploadFile(file, config)
      .then(data => {

        console.log(data);

         const location = data.key;

         console.log(location)
        setState((prevState) => {
         return { ...prevState, [e.target.id]: location };
      });
      setUploading(false)
    })    
    .catch(err => {
    
      console.log(err,"$%%%%%%%%%%%%%%%%%%%% : ",file,config);     setUploading(false)
    })
      
 
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const headers = {
      'Authorization': token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  
    const {
      fname, mname, lname, techStack, industry, country, about, linkedin, github, primaryskill, secondaryskill,
      workhours, chargePerhour, portfolio, institution, workEnddate, workStartdate, work_organization, work_position,
      present,
      certification, certificationIntitution, certificationUrl, certification_endDate, certification_startDate, degree,
      startingDate, endingDate, domain, resume,coding_assessment_url, work_authorization, profilePicture, timezone, calendlyurl, talent_code, availability, hoursperweek,
      currentStatus, availabledate, currency, ratePerhour, ratePermonth,github_repo_name, github_username, jira_username, sonarqube_project_key,
    } = State;
  
    const data = {
      id: user.data[0].c_id,
      firstname: fname, middlename: mname, lastname: lname, industry: industry, techStack: techStack, primaryskill: primaryskill,
      secondaryskill: secondaryskill, country: country, linkedinurl: linkedin, githuburl: github, workhours: workhours,
      chargePerhour: chargePerhour, portfoliourl: portfolio, institution: institution, about: about, workEnddate: workEnddate,
      workStartdate: workStartdate, work_organization: work_organization, work_position: work_position,present:present, certification: certification,
      certificationInstitution: certificationIntitution, certificationUrl: certificationUrl, certification_endDate: certification_endDate,
      certification_startDate: certification_startDate, degree: degree, startingDate: startingDate, endingDate: endingDate, domain: domain,
      profilePicture: profilePicture, resume: resume,coding_assessment_url:coding_assessment_url, work_authorization:work_authorization, timezone: timezone, calendlyurl: calendlyurl, talent_code: talent_code,
      availability: availability, hoursperweek: hoursperweek, currentStatus: currentStatus, availabledate: availabledate,
      ratePerhour: ratePerhour, ratePermonth: ratePermonth, currency: currency, github_repo_name :github_repo_name,
      github_username:github_username,
      jira_username:jira_username,
      sonarqube_project_key:sonarqube_project_key
    };
  
    try {
      const response = await dispatch(updateProfilecandidate(data, headers));
    
      await axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: arrexp1, type: "EDU" }, { headers: headers });
  
   
      await axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: Userexp, type: "EXP" }, { headers: headers });
  
      await axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: arrCert, type: "CERT" }, { headers: headers });
  
      dispatch(login(user.data[0].email, "candidate"));
      alert("Candidate Details Successfully Updated");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
      alert("Failed to update candidate details.");
    }
  };
  


  const onInputChange = e => {
    console.log(e)
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  const onInputChangeselect = e => {
    console.log(e.target.value)
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }


  
  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: process.env.REACT_APP_BUCKETNAME,
                    Key: user.data[0].profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
                setAuthImage(url);
  
              });
  }
  
  console.log('manger',user.data[0].profile)
  // function generatePreSignedPutUrl11Resume() {
   
  //   const URL_EXPIRATION_TIME = 900;
  
  //                 const params = {
  //                   Bucket: "globldatauat",
  //                   Key: user.data[0].resume,
  //                   Expires: URL_EXPIRATION_TIME,
  //                 }
                 
  //              myBucket.getSignedUrl('getObject', params, (err, url) => {

  //               console.log("url",err, url)
  
  //               setResume(url);
  
  //             });
  // }




  async function generateAndSetSignedUrl() {
    try {
        // Define the request parameters
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: process.env.REACT_APP_CLOUDFRONT +State.resume
            })
        };
  
        // Make the API call
        const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
        
        // Parse the response as JSON
        const data = await response.json();
  
        // Extract the signed URL from the response data
        const signedUrl = JSON.parse(data.body).signedUrl;
  
        console.log("Generated CloudFront signed URL:", signedUrl);
  
        // Use the signed URL as needed
        setResume(signedUrl);
    } catch (error) {
        console.error("Error generating signed URL:", error);
    }
  }

  async function generateAndSetSignedUrlassessment() {
    try {
        // Define the request parameters
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': token,

                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: process.env.REACT_APP_CLOUDFRONT +State.coding_assessment_url
            })
        };
  
        // Make the API call
        const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
        
        // Parse the response as JSON
        const data = await response.json();
  
        // Extract the signed URL from the response data
        const signedUrl = JSON.parse(data.body).signedUrl;
  
        console.log("Generated CloudFront signed URL:", signedUrl);
  
        // Use the signed URL as needed
        setAssesment(signedUrl);
    } catch (error) {
        console.error("Error generating signed URL:", error);
    }
  }

  
  useEffect( async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
    generateAndSetSignedUrlassessment();
    generateAndSetSignedUrl();

    try{
  
      // https://svty7iwcsb.execute-api.us-east-1.amazonaws.com/dev/getcandidatedata
      const added = await axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEDATA, { id : user.data[0].c_id }, {headers:headers});

      setPod(added.data.data.podExperience)

      console.log(added)

      added.data.data.education.map((data)=>{

        Re.push({
          degree: data.degree,
          domain: data.domain,
          institution:data.institution,
          startDate : data.startDate,
          candidateID : data.candidateID,
          endDate : data.endDate,
          id  : data.id
        })

      })

      added.data.data.exp.map((data)=>{

        Re1.push({
          work_organization: data.work_organization,
          work_position: data.work_position,
          present:data.present,
          workStartdate:data.workStartdate,
          workEnddate : data.workEnddate,
          candidateID : data.candidateID,
          id  : data.id
        })

      })
      
      added.data.data.certification.map((data)=>{
        console.log('@@@@@@@####$$$ consil ::', Re,Re1,moment(data.startDate).format("YYYY-MM-DD"))
        Re2.push({
          cert_name : data.cert_name,
          candidateID: data.candidateID, 
          startDate:moment(data.startDate).format("YYYY-MM-DD"),
          endDate: moment(data.endDate).format("YYYY-MM-DD"), 
          certUrl : data.certUrl, 
          certificationInstitution: data.certificationInstitution,
          id  : data.id
         })

      })


      console.log('@@@@@@@####$$$ ::', Re,Re1,Re2)
      setArrexp1(Re)
      setUserexp(Re1)
      setArrCert(Re2)
    



  
      setArrexp({
        experience : added.data.data.exp,
        education : added.data.data.education,
        certification : added.data.data.certification

      })
   

    }catch(err){
      console.log(err)
    }

  }, [token])


  const deleteExp = async (idx) => {
    // Get the entry to be deleted
    const deletedEntry = Userexp[idx];

    // Clone the array and remove the entry at the specified index
    let clone = [...Userexp];
    clone.splice(idx, 1);
    setUserexp([...clone]);

    try {
        const response = await fetch(process.env.REACT_APP_TALENT_DELETECANDIDATEEXP, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify({
                educationdata: [deletedEntry], // Send only the deleted entry
                type: 'EXP'
            })
        });

        if (response.ok) {
            console.log('Data successfully deleted');
        } else {
            console.error('Failed to delete data');
        }
    } catch (error) {
        console.error('Error deleting data:', error);
    }
};


  


  

    return (

     <div className="container" style={{ "margin-top": "140px" }}>


      <div className="row" >
<br></br>
        <div className="col-lg-3" >

        <span style={{ marginLeft: '20px',  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' }} onClick={() => { setEdit(false) }}> <a 
          style={{  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' 
        }} href="/candidate-dashboard" > {intl.formatMessage({ id: 'dashboard' })}</a>  {">"}   {intl.formatMessage({ id: 'manageProfile' })}  {">"}   </span>


        </div>
     
        <div className="col-lg-7" style={{      border: '2px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        padding: '20px', opacity: 1 }}>

<br></br><br></br>
            
        {Edit == false ? (
  <h1 className="headsmall">{intl.formatMessage({ id: 'manageProfileHere' })}</h1>
  
        ):(user.data[0].profile == 0 ? (  <h1 className="headsmall">Create profile</h1>  ) :(  <h1 className="headsmall"  style={{ "margin-top": "40px" }}>{intl.formatMessage({ id: 'updateProfileHere' })}</h1>)
        )}


          <br></br>
          <br></br>



          <form onSubmit={handleSubmit}>
            {/* For First Name */}


{Edit == false ? (

            <div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "50px", height : "50px" , borderRadius : "50px", backgroundColor: "#8EAEBC", float: "right", boxShadow: "-15px -15px 25px #FFFFFF"}}>
            <EditIcon  style={{ color :  "white", marginLeft : "12px", marginTop: "12px" }} onClick={() => { setEdit(true) }} />

              </div>
): (<></>)}

<br></br>
<br></br>
<br></br>

    

{Edit == false ? (
<>
<center>
          
{   user.data[0].profilePicture == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
             
            <PersonIcon  style={{display : 'flex',alignItems:'center',margin:'auto', color :  "#8EAEBC", textAlign :'center'}}/>


            </div>) : (  
            
            AuthImage==null ||AuthImage== ""? (
              <img
src={defaultimg}
alt=""
style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}
></img>
) :(
            <img src={AuthImage}   style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img>
            
            ) 
            
            )}
            
            <br></br>
            <br></br>
            <p style={{display: "flex",justifyContent: "center",  color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.fname + " " + State.lname}</p>
            <p style={{display: "flex",justifyContent: "center",width: "170px", fontSize: "16px" }} ><a href="./Portfolio">{intl.formatMessage({ id: 'addPortfolio' })}</a></p>
           
            </center>
            <br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>
<p style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }} >{intl.formatMessage({ id: 'basicInformation' })}</p>
<div style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }}> <LanguageSelector currentLocale={currentLocale} changeLocale={changeLocale} />
</div>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'email' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.email}</p>
<p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'country' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.country}  
</p>
{State.country ==='usa'?<><p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >Work Authorization</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.work_authorization}  
</p></>
:(<></>)}


<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'about' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >{State.about}</p>

<br></br>
</div>


<br></br>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>

            <p style={{ display:"flex",justifyContent:"center",alignItems:"center",margin:"auto" ,color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px",  }} >{intl.formatMessage({ id: 'skillsAndExpertise' })} </p>
            <br></br>

            {/* For Primary Expertise */}
            <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'skills' })} </p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{State.primaryskill}, {State.secondaryskill} </p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'industry' })}</p>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }} >{State.industry}</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'techStack' })}</p>
<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }} >{State.techStack}</p>
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center",}}>


<br></br>
            <p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

        
            <p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

            <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Degree</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Institution</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.education.length > 0 ? arrexp.education.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.degree}</TableCell>
              <TableCell>{data.institution}</TableCell>
              <TableCell>{data.startDate}</TableCell>
              <TableCell>{data.endDate}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No education data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  
</div>


<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column", justifyContent:"center",alignItems:"center", }}>

<p  style={{ color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'certifications' })}  </p>

<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Certification Name</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Institution</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.certification.length > 0 ? arrexp.certification.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.cert_name}</TableCell>
              <TableCell>{data.certificationInstitution}</TableCell>
              <TableCell>{moment(data.startDate).format("YYYY-MM-DD")} </TableCell>
               <TableCell> {moment(data.endDate).format("YYYY-MM-DD")}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No certification data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  

<br></br>
</div>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center", }}>

<br></br>
<p style={{  color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'workExperience' })} </p>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Position</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Organization</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>End Date</TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {arrexp.experience.length > 0 ? arrexp.experience.map((data, index) => (
            <TableRow key={index}>
              <TableCell>{data.work_position}</TableCell>
              <TableCell>{data.work_organization}</TableCell>
              <TableCell>{data.workStartdate} </TableCell>
              {data.present === 0 ?
  <TableCell>{data.workEnddate}</TableCell>
  :
  <TableCell>
    <span style={{ color: 'blue', marginRight: '0.3rem' }}>•</span>
    Present
  </TableCell>
}


            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No experience data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  



<br></br>
</div>

<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",margin:'auto' }}>

<br></br>
<p style={{color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'podExperience' })}</p>
{ pod.length > 0 ? pod.map((data) => {
       return <div style={{ margin : "40px" }}> <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{data.podName}</p>


</div>
}) : ( <>{intl.formatMessage({ id: 'noExperience' })}</>)}




<br></br>
</div>
<br></br>
<div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",}}>

<p style={{ color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'portfolio' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.portfolio}</p>
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'github' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.github}</p>

<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Github RepoName' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.github_repo_name}</p>

<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Github UserName' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.github_username}</p>

<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'JIra UserName' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.jira_username}</p>

<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'Sonarqube Projectkey' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.sonarqube_project_key}</p>

<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'linkedIn' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.linkedin}</p>
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'preferredWorkHours' })}</p>


<p style={{ color :  "grey", maxWidth: "100%", height: "auto",  fontSize: "16px" }}  >{State.workhours}</p>
<br></br>
{user.data[0].talent_type !== "PARTNER" && (<>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'ratePerHour' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.ratePerhour}</p>

</>)}
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"   }} >Coding Assesment</p>


<a href={Assesment} target="_blank" style={{ color :  "#A6C6D6", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >View Assesment</a>


<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'calendlyUrl' })}</p>

<br></br>
<p style={{ marginLeft : "-43px", color :  "grey", width: "170px", fontSize: "16px"  }} >{State.calendlyurl}</p>

<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'talentCode' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.talent_code}</p>
<br></br>
<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'currency' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px" }}  >{State.currency}</p>
<br></br>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"   }} >{intl.formatMessage({ id: 'resume' })}</p>

<a href={Resume} target="_blank" style={{ color :  "#A6C6D6", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'resumeLink' })}</a>

<br></br>
<br></br>
</div>
<br></br>


  </>) : (<>

    <center>
                    {Image == "" ? (
                    <div  style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height: "100px", borderRadius: "50px", textAlign: "center" }}>
                    
                        {AuthImage == null ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{ width: '100px', height: '100px', borderRadius: "50px",textAlign: "center" }}
                          ></img>
                        )


                          : (
                          
                            <img src={AuthImage} alt='' style={{ width: '100px', height: '100px',textAlign: "center", borderRadius: "50px" }} id="profilePicture" ></img>

                          )}

<span>
                        <input type="file" style={{ marginLeft: '40px', width: '5rem', height: '3rem', borderRadius: "50px"  }} className="profileclass" onChange={onImageUpload} id="profilePicture" />
                        <EditIcon style={{color: 'white', width:'25px', height: '25px', marginLeft:"-4rem",marginTop:"-1rem", backgroundColor: '#03045e', boxShadow: 'rgb(255, 255, 255) -15px -15px 25px', borderRadius: '50px' }} onChange={onImageUpload}
              />
                      </span>
                      {/* <span style={{marginTop:"-10px",marginLeft:"20px"}}> hhgjh</span> */}

                    </div>) : (<img src={Image} alt='' style={{ width: '100px', height: '100px', marginTop: "30px", borderRadius: "50px" }} id="profilePicture" ></img>)}</center>
            
             <br></br>
            <p style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px" }} >  {intl.formatMessage({ id: 'basicInformation' })}</p>
            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>


<div className="email">
   <label  className="Label"> {intl.formatMessage({ id: 'firstName' })} : </label>
  <input            
    className="input2" 
    placeholder="Enter First Name*"
    type="text"
    id="fname"
  
value={State.fname}  onChange={onInputChange}  
  />
</div>

<div className="email">
       <label className="Label"> {intl.formatMessage({ id: 'middleName' })} : </label>
  <input
    className="input2" 
    placeholder="Enter Middle Name"
    type="text"
    id="mname"
    value={State.mname}
    onChange={onInputChange}

  />
</div>

    <div className="email">
      <label className="Label"> {intl.formatMessage({ id: 'lastName' })}: </label>
  <input
    className="input2" 
    placeholder="Enter Last Name*"
    type="text"
    id="lname"
    value={State.lname}
    onChange={onInputChange}
 
    required
  />
</div>


{/* For Middle Name */}




{/* For Email */}


<div className="email" >
     <label  className="Label">{intl.formatMessage({ id: 'country' })}  : </label>
  <select
      //value={area}
      className="input2" 
      name="country"
      placeholder="What's your country*"
      value={State.country}   
      onChange={onInputChangeselect}       
        
  
      >
        <option  style={{color:'black'}} value='' defaultValue >Country</option>

{country.map((data) => (
<option> {data.label} </option>
))}

</select>
</div>



{State.country === "usa" && (
  <div className="email">
    <label className="Label">Work Authorization</label>
    <select
      className="input2"
      placeholder="Work Authorization"
      value={State.work_authorization}
      onChange={onInputChangeselect}
      id="work_authorization"
      name="work_authorization" 
      required
    >
        <option  style={{color:'black'}} value='' defaultValue >Work Authorization</option>
      {work_authorization.map((data) => (
        
        <option key={data.value}>{data.value}</option>
      ))}
    </select>
  </div>
)}


<div className="email" >
     <label  className="Label">{intl.formatMessage({ id: 'techStack' })} : </label>
  <select
      //value={area}
      className="input2" 
      name="techStack"
      placeholder="Tech Stack*"
      onChange={onInputChangeselect}       
      value={State.techStack}     
      
      >
        <option  style={{color:'black'}} value='' defaultValue >TechStack</option>

{techStack.map((data) => (
<option> {data.value} </option>
))}

</select>
</div>

<div className="email">
  
     <label  className="Label">{intl.formatMessage({ id: 'about' })} : </label>
  <textarea
      //value={area}
      type="text"
      className="input2"
      id="about"
      placeholder="About*"
      onChange={onInputChange}
      value={State.about}
    
      maxLength={250}
      />
</div>
</div>

<br></br>
<p style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "160px" }} >{intl.formatMessage({ id: 'skillsAndExpertise' })}</p>
<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

{/* For Primary Expertise */}
<div className="email"> 
            <label   className="Label">{intl.formatMessage({ id: 'primarySkill' })} : </label>
  <select
    onChange={onInputChangeselect}
    className="input2"
    name="primaryskill"
    placeholder="Primary Skill*"
    value={State.primaryskill} 
 
required
    >

<option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Three.js</option>
<option  value='Tableau' style={{color:'black'}} >Tableau</option>
<option  value='Python' style={{color:'black'}} >Python</option>

<option  value='Quality Assurance' style={{color:'black'}} >Quality Assurance</option>
<option  value='IOS Development (Swift/Objective C)' style={{color:'black'}} >IOS Development (Swift/Objective C)</option>
<option  value='Android Development (Java/Kotlin)' style={{color:'black'}} >Android Development (Java/Kotlin)</option>
<option  value='Devops' style={{color:'black'}} >Devops</option>
<option  value='Cloud (AWS/Azure/GCP)' style={{color:'black'}} >Cloud (AWS/Azure/GCP)</option>





        </select>

</div>
<div className="email"> 
            <label  className="Label">{intl.formatMessage({ id: 'secondarySkill' })} : </label>
  <select
    onChange={onInputChangeselect}
    className="input2"
    name="secondaryskill"
    placeholder="Secondary Skills*"
    value={State.secondaryskill} 

    
    >

<option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>


        </select>

</div>

<div className="email">
      <label  className="Label">{intl.formatMessage({ id: 'industry' })} : </label>
              <select
                  //value={area}
                  onChange={onInputChangeselect}
                  className="input2"
                  name="industry"
                  id="industry"
                  placeholder="Industry"
                  value={State.industry}
                  list="industry"
                
                  >
      <option  style={{color:'black'}} value='' defaultValue >Industry</option>


 {industry.map((data) => (
<option id="industry"> {data.value} </option>
))}

</select>
            </div> 

            </div> 
<br></br>
<p style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>
<p  style={{ marginLeft: "-60px", color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>


<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

{arrexp1.length == 5 ? (<></>) : (<button onClick={addInput} className="add">{intl.formatMessage({ id: 'add' })} +</button>
)}

{ arrexp1.length > 0 ?

arrexp1.map((data, i) => {




return <><br></br>
{/* Other Expertise */}
<div className="password"> 
 <label  className="Label">{intl.formatMessage({ id: 'qualification' })} : </label>
<select
className="input2"
name="degree"
id="degree"
placeholder="Qualification"

value={data.degree}
onChange={(e) => 
handleChange(e,i)} 
//value={data.degree}
// onChange={(e) => 
//   handleChange(e,i)} 
// onChange={(e) =>
//   setUser({...user, user_name: e.target.value });
// }
>
<option value='' defaultValue style={{color:'black'}} >Qualification</option>
<option value='Bachelors' style={{color:'black'}} >Bachelors</option>
<option value='Masters' style={{color:'black'}} >Masters</option>
<option value='PHD' style={{color:'black'}} >PHD</option>

</select>

</div>
<div className="password">
        <label  className="Label">{intl.formatMessage({ id: 'specialization' })} : </label>
<input             
className="input2"
placeholder="Specialization"
type="text"

//value={data.domain}
value={data.domain}
    onChange={(e) => 
      handleChange(e,i)} 
  id="domain"
/>
</div>
<div className="password">
        <label  className="Label">{intl.formatMessage({ id: 'university' })}  : </label>
<input             
className="input2"
placeholder="University"
type="text"
//value={data.institution}
value={data.institution}
    onChange={(e) => 
      handleChange(e,i)} 
id="institution"

/>
</div>




{/* Portfolio */}
  <div className="date" style={{ display: "flex" }}>

 <div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })} </label>

<input
className="input3"
placeholder="Start date"
type="date"
//value={data.startDate}
value={data.startDate}
    onChange={(e) => 
      handleChange(e,i)} 

id="startDate"
/>
</div>
<div className="password" style={{ marginLeft: "20px" }}>
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })} </label>

<input             
className="input3"
placeholder="End date"
type="date"
//value={data.endDate}
value={data.endDate}
onChange={(e) => 
  handleChange(e,i)}

id="endDate"
/>
</div>
</div>
<br></br>
</>  }) : (<></>)}

{/* <a className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" onClick={saveedd}>Save</a>   */}

</div>
<br></br>
<p  style={{ marginLeft: "-60px", color :  "#8EAEBC" , fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'certifications' })} </p>
<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

{arrCert.length == 5 ? (<></>) : (<a onClick={addCert}className="add">{intl.formatMessage({ id: 'add' })} +</a>)}




 {arrCert.length >0 ?

arrCert.map((data, i) => {


return  <>
<div className="password" >
                    <label  className="Label">{intl.formatMessage({ id: 'certifications' })} : </label>
 <input             
   className="input2"
   placeholder="Certification"
   type="text"
   value={data.cert_name}
   onChange={(e) => 
     handleChangeCert(e,i)}
   id="cert_name"
   
 />
</div>
<div className="password" >
                  <label  className="Label">{intl.formatMessage({ id: 'institute' })} : </label>
  <input             
                className="input2"
                placeholder="Institute"
                type="text"
                value={data.certificationInstitution}
                onChange={(e) => 
                  handleChangeCert(e,i)} 
                id="certificationInstitution"
              />
</div>

{/* LinkedIn */}
<div className="password">
   <label  className="Label">{intl.formatMessage({ id: 'certificationUrl' })} : </label>
 <input
   className="input2"
   placeholder="Certification Url"
   type="url"
   value={data.certUrl}
                onChange={(e) => 
                  handleChangeCert(e,i)}  
   id="certUrl"
  
 />
</div>

<div className="date" style={{ display : "flex" }}>
 <div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validFrom' })}</label>

 <input
   className="input3"
   placeholder="Valid from"
   type="date"

   
   value={data.startDate}
                onChange={(e) => 
                  handleChangeCert(e,i)} 
   id="startDate"

 />
</div>
<div className="password" style={{ marginLeft: "20px" }} >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validTill' })}</label>

 <input             
   className="input3"
   placeholder="Valid till"
   type="date"
   value={data.endDate}
                onChange={(e) => 
                  handleChangeCert(e,i)} 
   id="endDate"
 />
</div>
</div>
</>  }) : (<></>)}
</div>
<br></br>

<p  style={{ marginRight: "325px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "162px" }}>{intl.formatMessage({ id: 'workExperience' })}</p>

{/* <a onClick={addExp} style={{ fontWeight : "bold", color : "#8EAEBC", marginLeft: "450px"}}>Add +</a> */}
<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

{Userexp.length == 5 ? (<></>) : (<a onClick={addExp} className="add">{intl.formatMessage({ id: 'add' })} +</a>)}




{Userexp.length > 0 ? Userexp.map((data, i) => (
        <div key={i}>
          <div className="email">
            <label className="Label">{intl.formatMessage({ id: 'companyName' })}:</label>
            <input
              className="input2"
              placeholder="Company"
              type="text"
              value={data.work_organization}
              onChange={(e) => handleChangeExp(e, i, 'work_organization')}
              id="work_organization"
            />
          </div>

          <div className="password">
            <label className="Label">{intl.formatMessage({ id: 'positionName' })}:</label>
            <input
              className="input2"
              placeholder="Position"
              type="text"
              value={data.work_position}
              onChange={(e) => handleChangeExp(e, i, 'work_position')}
              id="work_position"
            />
          </div>

          <div className="date" style={{ display: 'flex' }}>
            <div className="password">
              <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })}</label>
              <input
                className="input3"
                placeholder="Start date"
                type="date"
                value={data.workStartdate}
                onChange={(e) => handleChangeExp(e, i, 'workStartdate')}
                id="workStartdate"
              />
            </div>
            {data.present !== 1 && (
            <div className="password" style={{ marginLeft: "20px" }}>
              
  <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })}</label>
  
    <input
      className="input3"
      placeholder="End date"
      type="date"
      value={data.workEnddate}
      onChange={(e) => handleChangeExp(e, i, 'workEnddate')}
      id="workEnddate"
    />
 
</div> )}
<div className="checkbox" style={{ marginTop: "10px" }}>
  <label style={{ color: "grey", fontWeight: "bold" }}>
    <input
      type="checkbox"
      checked={data.present === 1}
      onChange={(e) => handleChangeExp(e, i, 'present')}
    />
    &nbsp;{intl.formatMessage({ id: 'present' })}
  </label>
</div>

          </div>
          <button style={{textDecoration:'none',backgroundColor:"white",color:"red",border:"2px solid #E7E7E7",borderRadius:"2px"}}onClick={() => deleteExp(i)}>Remove</button>
        </div>
      )) : (<></>)}
   
</div>
<br/>
<p style={{ marginRight: "321px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "170px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>
<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

<div className="password">
      <label  className="Label">{intl.formatMessage({ id: 'portfolioUrl' })} : </label>
  <input
    className="input2"
    placeholder="Portfolio URL"
    type="url"
    value={State.portfolio}
    onChange={onInputChange}
    id="portfolio"

  />
</div>
<div className="password">
  <label   className="Label">{intl.formatMessage({ id: 'linkedInUrl' })} : </label>
  <input
    className="input2"
    placeholder="LinkedIn Profile URL"
    type="url"
    value={State.linkedin}
    onChange={onInputChange}
    id="linkedin"

  />
</div>
<div className="password">
   <label  className="Label">{intl.formatMessage({ id: 'githubUrl' })} : </label>
  <input
    className="input2"
    placeholder="Github URL"
    type="url"
    value={State.github}
    onChange={onInputChange}
    id="github"
   
  />
</div>

<div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_repo_name' })} : </label>
              <input
                className="input2"
                placeholder="Github Repo Name"
                type="text"
                value={State.github_repo_name}
                onChange={onInputChange}
                id="github_repo_name"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_username' })} : </label>
              <input
                className="input2"
                placeholder="Github User Name"
                type="text"
                value={State.github_username}
                onChange={onInputChange}
                id="github_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'jira_username' })} : </label>
              <input
                className="input2"
                placeholder="Jira User Name"
                type="text"
                value={State.jira_username}
                onChange={onInputChange}
                id="jira_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'sonarqube_project_key' })} : </label>
              <input
                className="input2"
                placeholder="Sonarqube Projectkey"
                type="password"
                value={State.sonarqube_project_key}
                onChange={onInputChange}
                id="sonarqube_project_key"
                
              />
            </div>
<div className="email">
   <label   className="Label">{intl.formatMessage({ id: 'prefferedWorkHours' })} : </label>
  <select
      //value={area}
      onChange={onInputChangeselect}
      className="input2"
      name="workhours"
      placeholder="Preffered work hours"
      value={State.workhours}
      list="workhours"
      id="workhours"
  
      >
      <option  style={{color:'black'}} value="">Preffered Work Hours</option>

        <option>Morning Shift, 9am - 6pm </option>
<option>Evening Shift, 10pm - 7am </option>

</select>
        
</div> 

{user.data[0].talent_type !== "PARTNER" && (
  <div className="password">
    <label className="Label">{intl.formatMessage({ id: 'ratePerHour' })} : </label>
    <input
      className="input2"
      placeholder="Rate /hr"
      type="text"
      value={State.ratePerhour}
      onChange={onInputChange}
      id="ratePerhour"
    />
  </div>
)}

{/* <div className="password">
  <label   className="Label">{intl.formatMessage({ id: 'ratePerMonth' })} : </label>
  <input
    className="input2"
    placeholder="Rate /month"
    type="text"
    value={State.ratePermonth}
    onChange={onInputChange}
    id="ratePermonth"  
    
  
  />
</div> */}
<div className="password">
       <label   className="Label">{intl.formatMessage({ id: 'calendlyurl' })} : </label>
  <input
    className="input2"
    placeholder="Enter calendlyurl"
    type="url"
    value={State.calendlyurl}
    onChange={onInputChange}
    id="calendlyurl"  

  />
</div>

<div className="password">
     <label  className="Label">{intl.formatMessage({ id: 'talentCode' })} : </label>
  <input
    className="input2"
    placeholder="Enter talent code"
    type="text"
    value={State.talent_code}
    onChange={onInputChange}
    id="talent_code"  
  
  />
</div>

<div className="email">
   <label  className="Label">{intl.formatMessage({ id: 'currency' })} : </label>
              <select 
                onChange={onInputChangeselect}
                className="input2"
                name="currency"
                id="currency"
                placeholder="Currency"
                isMulti="true"
                value={State.currency} 
                list="skill"
               
                required
                >
                  
      <option  value="">Currency</option>
        <option  style={{color:'black'}} >USD</option> 
        <option  style={{color:'black'}} >INR</option>
        <option  style={{color:'black'}} >CRC</option>    
        <option  style={{color:'black'}} >Vietnamese Dong</option>
        <option  style={{color:'black'}} >Colombian Pesos</option>    

                    </select>
            </div>


            <div className="email">
               <label  className="Label">{intl.formatMessage({ id: 'availability' })} : </label>
              <select 
                onChange={onInputChangeselect}
                options={area}
                className="input2"
                name="availability"
                id="availability"
                placeholder="Availability"
                isMulti="true"
                value={State.availability} 
                list="skill"
              
                required
                >
                  
      <option  style={{color:'black'}} value="">Availability</option>
        <option  style={{color:'black'}} value='full time'>Full Time</option> 
        <option  style={{color:'black'}} value='part time'>Part Time</option>
    

                    </select>
            </div>

            { State.availability == "part time" ? (<>

<div className="email" >
<label className="Label">{intl.formatMessage({ id: 'hoursPerWeek' })}</label>

<input
  className="input2"
  placeholder="Hours per Week"
  type="num"
  value={State.hoursperweek}
  onChange={onInputChange}
  id="hoursperweek"
  required
/>
</div>
</>) : (<></>)}

            <div className="email">
  <label   className="Label">{intl.formatMessage({ id: 'timeZone' })} : </label>
            <select  id='timezone' className="input2" name="timezone" onChange={onInputChangeselect} placeholder='Timezone'    
 value={State.timezone}>
        <option  style={{color:'black'}}  value="">Time Zone</option>
        <option  style={{color:'black'}} >Indian standard time</option>
        <option  style={{color:'black'}} >Eastern standard time</option>
        <option  style={{color:'black'}} >Central standard time</option>
        <option  style={{color:'black'}} >Mountain standard time</option>
        <option  style={{color:'black'}} >Pacific standard time</option>
    
        </select>

</div>


<div className="email">
  <label   className="Label">{intl.formatMessage({ id: 'currentlyAvailable' })} : </label>
<select  id='currentStatus' className="input2" name="currentStatus" onChange={onInputChangeselect} placeholder='Currently Available'               
 value={State.currentStatus} required>
<option  style={{color:'black'}}  value="">Currently Available</option>
<option  style={{color:'black'}} >Yes</option>
<option  style={{color:'black'}} >No</option>

</select>

</div>

{ State.currentStatus == "No" ? (<>

  <div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'availableDate' })}</label>

  <input
    className="input2"
    placeholder="Available date"
    type="date"
    value={State.availabledate}
    onChange={onInputChange}
    id="availabledate"
    required
  />
</div>


</>) : (<></>)}


<div className="password">
  <label  className="Label">Coding Assesment : </label>
  <input
    className="input2"
    placeholder="Upload Assesment pdf/docx"
    type="file"
    onChange={onFileUploadassesment}
    id="coding_assessment_url"
    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
 
  />
</div>
<div className="password">
  <label  className="Label">{intl.formatMessage({ id: 'resume' })} : </label>
  <input
    className="input2"
    placeholder="Upload Resume"
    type="file"
    onChange={onFileUpload}
    id="resume"
    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
 
  />
</div>


</div>


</>

)}


            {Edit == true ? (user.data[0].profile == 1 ?(


            <div className="createAccount">
           { Uploading ? (<> <button className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" disabled>   {intl.formatMessage({ id: 'update' })}</button></>) : (<> <button className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" >
            
            
             {intl.formatMessage({ id: 'update' })}</button></>)}  
            </div>):(<div className="createAccount">
           { Uploading ? (<> <button className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" disabled>   {intl.formatMessage({ id: 'createprofile' })}</button></>) : (<> <button className="button is-success"  style={{ width : "50%", borderRadius:"10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" >
            
            
             {intl.formatMessage({ id: 'createprofile' })}</button></>)}  
            </div>)
         
     
            ): (<></>)}
           </form>











        </div>
        <div className="col-lg-3"></div>

        {/* <p className="payment-title">Credit Card</p>
        <div>
          <input
            className="payment-input"
            placeholder="Card Number"
            value={PaymentData.number}
            id="number"
            onChange={onInputChange}
          />
        </div>
        <div>
          <input
            className="payment-input"
            placeholder="Name"
            value={PaymentData.name}
            id="name"
            onChange={onInputChange}
          />
        </div>
        <div className="payment-input-section">
          <div>
            <input
              className="payment-input"
              placeholder="exp_month"
              value={PaymentData.exp_month}
              id="exp_month"
              onChange={onInputChange}
            />
          </div>
          <div>
            <input
              className="payment-input"
              placeholder="exp_year"
              value={PaymentData.exp_year}
              id="exp_year"
              onChange={onInputChange}
            />
          </div>
         
          <div>
            <input
              className="payment-input"
              placeholder="cvc"
              value={PaymentData.cvc}
              id="cvc"
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className="payment-button-section">
          <Button variant="contained" style={{ backgroundColor : "#8EAEBC", color : "cornsilk" }}   onClick={createPayment}>
            Checkout
          </Button>
        </div> */}

        
        </div>
        <br></br>
<br></br>
      </div>


    );
  }

  Updateprofile.propTypes = {
    changeLocale: PropTypes.func.isRequired,
    currentLocale: PropTypes.string.isRequired,
  };
export default Updateprofile;

