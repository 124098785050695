import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {IoStatsChart} from 'react-icons/io5';
import {themeColor,hoverEffect} from '../utils';
import Path from './assets/Path.png';
import { useSelector } from 'react-redux';
import DetailGithublinesofcodeTalent from './DetailGithublinesofcodeTalent';
import DetailPRCountTalent from './DetailPRCountTalent';

import DetailbitbucketPRcountTalent from './DetailbitbucketPRcountTalent';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Detailstoriesmetricstalent from './Detailstoriesmetricstalent';
import Detaildefectsmetricstalent from './Detaildefectsmetricstalent';
import ProjectPieCharttalent from './ProjectPieCharttalent';
import CloudCosttalent from './CloudCosttalent';
import Githublinesofcodebydaywisetalent from './Githublinesofcodebydaywisetalent';
import SprintVelocitiesCharttalent from './SprintVelocitiesCharttalent';
import Dorametircslinegraphtalent from './Dorametircslinegraphtalent';
import CicdPiechartmanagerdetailvivewtalent from './CicdPiechartmanagerdetailvivewtalent';
import TimeDetailViewtalent from './TimeDetailViewtalent';
import DetailsTaskmetricsTalent from './DetailsTaskmetricsTalent';
// import './styles.css';
import './edit.css';
import { useAuthToken } from "../TokenContext";
import Select from 'react-select'; // Import react-select
import { useIntl } from 'react-intl';
import DetailBitbucketcodeTalent from './DetailBitbucketcodeTalent';
import Bitbucketcodebydaywisetalent from './Bitbucketcodebydaywisetalent';

function PodMetricsTalentDetailview() {
  const [podData, setPodData] = useState([]);
  const [selectedPod, setSelectedPod] = useState('');
  const[fetchMetrics,setFetchMetrics]=useState('');
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: '', // Selected sprint name
    startDate: '', // Selected sprint start date
    endDate: '' // Selected sprint end date
  });
  useEffect(() => {
    if (!token) {
      return; // Exit early if there is no token
    }
    fetchSprintfilter();
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    fetch(process.env.REACT_APP_TALENT_GETPODSFORMETRICSTALENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token, 
      },
      body: JSON.stringify({ c_id: user.data[0].c_id || null }),
      signal: signal
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && Array.isArray(data.body)) {
          setPodData(data.body);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching data:', error);
        }
      });
  
    return () => {
      abortController.abort();
    };
  }, [token, user.data]);
  
  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name,
  }));
  
  const handlePodSelect = (selectedOptions) => {
    setSelectedPod(selectedOptions.map((option) => option.value));
  };
  const fetchSprintfilter =()=>{
    if (!token) {
      return; // Exit early if there is no token
    }
    fetch(process.env.REACT_APP_TALENT_SPRINTFILTERTAENT, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: token, 
        },
        
        body: JSON.stringify({ talent_id: user.data[0].c_id,  }),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && data.body) {
          const parsedBody = JSON.parse(data.body);
          if (parsedBody.sprint_filter) {
            const sprintFilter = JSON.parse(parsedBody.sprint_filter);
            setSprintData(sprintFilter);
          }
        }
      })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  
    const handleSprintSelect = (e) => {
      const selectedSprintName = e.target.value;
      const selectedSprintData = sprintData.find(sprint => sprint.sprint_name === selectedSprintName);
      setSelectedSprint({
        name: selectedSprintName,
        startDate: selectedSprintData ? selectedSprintData.sprint_start_date : '',
        endDate: selectedSprintData ? selectedSprintData.sprint_end_date : ''
      });
    };
     
  return (
    <div style={{display:'flex',justifyContent:"center",alignItems:'center',margin:'auto',flexDirection:'column'}}>
<div style={{display:'inline',justifyContent:"center",alignItems:'center',margin:'auto',  flexDirection:'column',marginTop:'10rem'}}>
     <TitleText>{intl.formatMessage({id:'podAnalytics'})}</TitleText>
     </div>
  <YourProjects>
  
  <Dropdowns>
<Seletfield
  options={podOptions}
  isMulti
  value={podOptions.filter((option) => selectedPod.includes(option.value))}
  onChange={handlePodSelect}
  placeholder={intl.formatMessage({id:'selectPods'})}
  styles={{
    control: (provided) => ({
      ...provided,
    
      height:'3rem',
      overflow: 'auto'
    }),
  }}
  
/>
<Sprintselect value={selectedSprint.name} onChange={handleSprintSelect}>
  <option value="">Select a Sprint</option>
  {sprintData.map((sprint, index) => (
    <option key={index} value={sprint.sprint_name}>
      {sprint.sprint_name}
    </option>
  ))}
</Sprintselect>
</Dropdowns>
      <Border>
<Project >
<div>
         <Detailstoriesmetricstalent initialPodIds={selectedPod}  selectedSprint={selectedSprint.name}/>
         </div>

         <div><Detaildefectsmetricstalent initialPodIds={selectedPod}   selectedSprint={selectedSprint.name}/></div>
{/*          
<div><DetailGithublinesofcodeTalent initialPodIds={selectedPod}   selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/></div> */}
     <div><DetailBitbucketcodeTalent initialPodIds={selectedPod}  selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/>  </div>
<div><TimeDetailViewtalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/></div> 

<div><DetailsTaskmetricsTalent  initialPodIds={selectedPod} selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/></div>

</Project>
    </Border>
</YourProjects>
  <YourProjects2>
  <Border1>
<Project >

  <div  >
    <CicdPiechartmanagerdetailvivewtalent initialPodIds={selectedPod}/>
  </div>
  <div><ProjectPieCharttalent initialPodIds={selectedPod}/></div>
  <div><CloudCosttalent initialPodIds={selectedPod}/></div>
  {/* <div><DetailPRCountTalent   initialPodIds={selectedPod}/></div> */}
  <div><DetailbitbucketPRcountTalent   initialPodIds={selectedPod}/></div>
{/* <div><DetailBitbucketcodeTalent initialPodIds={selectedPod}  selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate}/>  </div> */}
  </Project>
  </Border1>
  </YourProjects2>
  
  <YourProjects2>
  <Border1>
  <Project >
  {/* <div >
    <Githublinesofcodebydaywisetalent initialPodIds={selectedPod}/>
  </div> */}
  <div >
    <Bitbucketcodebydaywisetalent initialPodIds={selectedPod}/>
  </div>
  <div >
    <SprintVelocitiesCharttalent initialPodIds={selectedPod}/>
  </div>
</Project>


  
  </Border1>
  </YourProjects2>
  <YourProjects2>
    <Border1>
    <h6 style={{display:'flex',justifyContent:"center",alignItems:"center"}}>{intl.formatMessage({id:'doraMetrics'})}</h6>
<Project >

    <div >
     {/* <DoraMetricsModal/>
    </div>

    <div style={{marginLeft:'2rem'}}> */}
     
      <Dorametircslinegraphtalent/>
    </div>
    </Project>
    
    </Border1>
    </YourProjects2>
  </div>
  
  );
} 


const Seletfield=styled(Select)`
width: 40vw;

margin: 0.5rem;

 @media screen and (min-width:320px) and (max-width:1080px){
  width: 40vw;
 
 }
`;
const Border=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin:auto;
width:100%;
background-color:white;
border-radius: 1rem ;
height:20rem;
 padding:20px;
//  overflow-y:scroll;
 @media screen and (min-width:320px) and (max-width:1080px){
  overflow-y:scroll;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:18rem;
 }
`;


const Border1=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin:auto;
width:100%;
background-color:white;
border-radius: 1rem ;
height:90%;
 padding:20px;
//  overflow-y:scroll;
 @media screen and (min-width:320px) and (max-width:1080px){
  overflow-y:scroll;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:18rem;
 }
`;

const YourProjects = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  margin:auto;
  width: 80vw;
  margin-top: 2rem;
  height: max-height;
  background-color:#0077b6;
  border-radius: 1rem;
  padding: 0.8rem;
  box-shadow: 0px 4px 6px #00000029;
  transition: 0.45 ease-in-out;

 
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    height: max-content;
    display:flex;
    flex-direction:column;
justify-content:center;
align-items:center;
    margin:auto;
  
    margin-top: 1rem;

  }
`;
const Div=styled.div`

display:flex;
flex-direction:row;


@media screen and (min-width:320px) and (max-width:1080px){
  
  
    display:flex;
flex-direction:column;

   }
`;
const YourProjects2 = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items:center;
margin:auto;
width: 80vw;
margin-top: 2rem;
height: max-height;
background-color:#0077b6;
border-radius: 1rem;
padding: 0.8rem;
  box-shadow: 0px 4px 6px #00000029;
  transition: 0.45 ease-in-out;

 
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    height: max-content;
    display: flex;
    flex-direction: column;
    display:flex;
    flex-direction:column;
justify-content:center;
align-items:center;
    margin:auto;
  
    margin-top: 1rem;
    
  }
`;
const Project=styled.div`
 display :flex;
 align-items: center;

 
 margin-bottom:0.3rem;
  
 @media screen and (min-width: 320px) and (max-width: 1080px) {
 
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}
`;

const TitleText = styled.h4`
  height: 10%;
  color: black;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  font-family: Poppins, Medium;

 
`;


const Dropdowns=styled.div`
 display :flex;
 align-items: center;
flex-direction:row;

 @media screen and (min-width:320px) and (max-width:1080px){
  display :flex;
 margin:auto;
  justify-content:center;
  flex-direction:column;
align-items:center;
   }
`;

const Sprintselect=styled.select`
textDecoration :none;
border: none;
borderRadius:none;
width:15em;
 @media screen and (min-width:320px) and (max-width:1080px){
  width:10em;
   }
`;
export default PodMetricsTalentDetailview;