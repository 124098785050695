// en/messages.js
const messagesEn = {
  welcome: 'Welcome to Globl.ai!',
  globalWorkPlatform: 'Global Work platform',
  manageProfile: 'Manage profile',
  getStarted: 'Get Started',
  createGig: 'Create Gig',
  createArt: 'Create ART',
  createPod: 'Create POD',
  findYourBestTalentPod: 'Find your best talent pod',
  findPods: 'Find Pods',
  signUpToGetStarted: '"Sign up" below to Get Started!',
  register: 'Register',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Service',
  copyright: 'Copyright © 2023-present Brilli Global, Inc. All rights reserved.',
   home: 'Home',
  dashboard: 'Dashboard',
  resetPassword: 'Reset Password',
  logOut: 'Log Out',
  podAnalytics: 'POD Analytics',
  latestSprintVelocity: 'Latest Sprint Velocity',
  doraMetrics: 'Dora Metrics',
  stories: 'Stories',
  noDataAvailable: 'No data available',
  defects: 'Defects',
  code: 'Lines of Code',
  jiraTimeDetails: 'Time Log',
  notifications: 'Notifications',
  noNotifications: 'There are no notifications',
  assignedItems: 'Assigned Items',
  taskName: 'Task Name',
  podActivity: 'Pod Activity',
  noPodActivity: 'You Don\'t Have Any Pod Activity',
  done: 'close',
  selectPodTeamMember: 'Select Pod Team Member',
  PodTeamMember:"Pod Team Member",
  submittedDate: 'Submitted Date',
  teamMember: 'Team Member',
  todaysFocus: "Today's Focus",
  accomplishments: 'Accomplishments',
  podAssist: 'Pod Assist',
  enterStoryDescription: 'Enter a Story Description',
  pleaseEnterStoryDescription: 'Please enter a story description and click on submit',
  submit: 'Submit',
  storyDescription: 'Story Description',
  acceptanceCriteria: 'Acceptance Criteria',
  testCases: 'Test Cases',
  loadingStoryDescription: 'Loading Story Description',
  cancel: 'Cancel',
  edit: 'Edit',
  loadingAcceptanceCriteria: 'Loading Acceptance Criteria',
  loadingTestCases: 'Loading Test Cases',
  loading: 'Loading',
  noSubscription: 'You don\'t have any subscription plan',
  findBestPod: 'Find the best pod and get registered or create your own pod',
  prev: 'Prev',
  next: 'Next',
  podName: 'Pod Name',
  email: 'E-Mail',
  portfolio: 'Portfolio',
  spotsLeft: 'Spots Left',
  joinPod: 'Join Pod',
  noMatchingPods: 'There are no matching pods available',
  yourPods: 'Your Pods',
  noPods: 'You don\'t have any Pod',
  status: 'Status',
  podRetro: 'Pod Retro',
  podTask: 'Pod Task',
  timeSubmit: 'Time Submit',
  podBoard: 'Pod Board',
  toDo: 'TO DO',
  inProgress: 'IN PROGRESS',
  Done: 'DONE',
  noData: 'No Data',
  selectPod: 'Select a Pod',
  taskManager: 'Task Manager',
  description: 'Description', 
  dueDate: 'Due Date', 
  all: 'All', 
  markComplete: 'Mark Complete', 
  noPodTasks: 'You don\'t have any Pod Tasks',
  selectPods: 'Select Pods', 
  allReporters: 'All Reporters', // English
  reporter: 'Reporter', // English
  issueSummary: 'Issue Summary', // English
  issueKey: 'Issue Key', // English
  issueStatus: 'Issue Status', // English
  githubSummary: 'GitHub Summary', // English
  githubUser: 'GitHub User', // English
  totalLinesChangedLastWeek: 'Total Lines Changed Last Week', // English
  totalLinesChangedThisWeek: 'Total Lines Changed This Week', 
  allRepoCheckins: 'All Repo Checkins', // English
  currentWeek: 'Current Week', // English
  previousWeek: 'Previous Week', // English
  prCount: 'PR Count', // English
  prSummary: 'PR Summary', // English
  submittedPRCount: 'Submitted PR Count', // English
  approvedPRCount: 'Approved PR Count', // English
  totalPRCount: 'Total PR Count', // English
  ciCdPipeline: 'CICD Pipeline', // English
  pipelineExecutionDetails: 'Pipeline Execution Details', // English
  pipelineName: 'Pipeline Name', // English
  pipelineVersion: 'Pipeline Version', // English
  executionID: 'Execution ID', // English
  revisionSummary: 'Revision Summary', // English
  revisionURL: 'Revision URL', // English
  executionDate: 'Execution Date', // English
  sonarqubeStats: "Sonarqube Stats",
  sonarqubeprojectkey:"Sonarqube Project Key",
  detailsForGitHubRepo: "Details for GitHub Repository",
  projectKey: "Project Key",
  ncloc: "Ncloc",
  bugs: "Bugs",
  vulnerabilities: "Vulnerabilities",
  codeSmells: "Code Smells",
  duplicatedLinesDensity: "Duplicated Lines Density",
  cloudCost: "Cloud Cost",
  services: "Services",
  cost: "Cost",
  costDetails: "Cost Details",
  timeLog: "Time Log",
  selectFilter: "Select Filter",
  candidates: "Candidates",
  linesOfCodeByDay: "Lines of Code By day",
  selectAUser: "Select a User",
  sprintVelocity: "Sprint Velocity",
  reviewAndRating: "Review & Rating",
  pod: "Pod",
  client: "Client",
  candidate: "Candidate",
  noCandidateReviews: "There are no candidate reviews.",
  individual: "Individual",
    manageProfileHere: "Manage your profile here",
    updateProfileHere: "Update your profile here",
    addPortfolio: "Add Portfolio",
    basicInformation: "Basic Information",
    skillsAndExpertise: "Skills & Expertise",
    skills: "Skills",
    techStack: "TechStack",
    educationAndCertifications: "Education & Certifications",
    education: "Education",
    certifications: "Certifications",
    workExperience: "Work Experience",
    podExperience: "Pod Experience",
    noExperience: "No Experience",
    otherInformation: "Other Information",
    github: "Github",
    linkedIn: "LinkedIn",
    preferredWorkHours: "Preferred Work Hours",
    ratePerHour: "Rate /hr",
    calendlyUrl: "Calendly URL",
    currency: "Currency",
    resumeLink: "View Resume",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    country: "Country",
    about: "About",
    primarySkill: "Primary Skill",
    secondarySkill: "Secondary Skill",
    industry: "Industry",
    qualification: "Qualification",
    specialization: "Specialization",
    university: "University",
    startDate: "Start date",
    endDate: "End date",
    add: "Add",
    institute: "Institute",
    certificationUrl: "Certification Url",
    validFrom: "Valid from",
    validTill: "Valid till",
    companyName: "Company Name",
    positionName: "Position Name",
    portfolioUrl: "Portfolio URL",
    linkedInUrl: "LinkedIn URL",
    githubUrl: "Github URL",
    prefferedWorkHours: "Preffered Work Hours",
    ratePerMonth: "Rate /month",
    calendlyurl: "Calendly URL",
    podrate:'Pod Rate',
    talentCode: "Talent Code",
    availability: "Availability",
    hoursPerWeek: "Hours per Week",
    timeZone: "Time Zone",
    currentlyAvailable: "Currently Available",
    availableDate: "Available date",
    resume: "Resume",
    update: "Update",
     postActivity: "Post Activity",
      viewActivity: "View Activity",
      postPodActivity: "Post Pod Activity",
      languageSpoken: "Language Spoken",
      categories: "Categories",
      fileUpload: "File Upload",
      fileNameNoSpaces: "The file name should not include spaces between all elements or characters",
      upload: "Upload",
      userStory: "User Story",
   
      blocker: "Blocker",
      clickHereUploadVideoClip: "Click here to Upload a Video Clip !",
      post: "Post",
      yourPostedPodActivity: "Your Posted Pod Activity",
      postedDate: "Posted Date",
      jiraIssue: "Jira Issue",
      videoUrl: "Video Url",
   
  board: "Board",
  myPods: "My Pods",
  myTasks: "My Tasks",
  podMetrics: "Pod Metrics",
  podTasks: "Pod Tasks",
  podFeedback: "Pod Feedback",
  blockers:"Blockers",
  noBlockers: "There Are No blockers",
  internalPod: "Internal Pod",
  externalPod: "External Pod",
  podType: "Pod Type",
  viewPodDetails: "View Pod Details",
  createYourOwnPod: "Create your own pod and browse for projects",
  enterPodName: "Enter Pod Name",
  selectArtName: "Select ART Name",
  noArtOptionsAvailable: "No ART Options Available",
  enterPodMission: "Enter POD Mission",
  podSize: "Pod Size",
  podTechStack: "Pod Tech Stack",
  create: "Create",
  privatePods: "Private Pods",
  activePods: "Active Pods",
  previousPods: "Previous Pods",
  pendingPods: "Pending Pods",
  podDescription: "Pod Description",
  date: "Date",
  viewDetails: "View Details",
  noCandidatesAddedToPod: "No Candidates Added to Pod!",
  viewPodActivities: "View Pod Activities",
  viewTimeEntries: "View Time Entries",
  select: "Select",
  viewVideo: "View Video",
  approved: "Approved",
  pending: "Pending",
  rowsPerPage: "Rows per page",
  selectItemsPerPage: "Select items per page",
  noReviews: "There are no reviews.",
  approve: "Approve",
  noVideoAvailable: "No video available",
  dateRangeFilter: "Date Range Filter",
  createTask: "Create Task",
  viewTasks: "View Tasks",
  podRequests: "POD REQUESTS",
  sprint: "Sprint",
  startDoing: "Start Doing",
  stopDoing: "Stop Doing",
  keepDoing: "Keep Doing",
  save: "Save",
  phoneNumber: "Phone Number",
  createProfile: "Create Profile",
  createProfileOpportunity: "Create your profile in our talent database to be included for  various remote work opportunities.",
  region: "Region",
  creatingProfile: "Creating profile",
  addStakeholder: "Add Stakeholder",
  manage: "Manage",
  deactivatePod: "Deactivate Pod",
  delete: "Delete",
  invite: "Invite",
  setting: "Setting",
  noStakeholders: "There Are No stakeholders for this pod",
  editPod: "Edit your pod and browse for projects",
  deactivateToUpdate: "De-activate The Pod to Update the Pod!",
  managePod: "Manage Pod",
  taskForm: "Task Form",
  podTaskName: "POD Task Name",
  podTaskDescription: "POD Task Description",
  podTaskDueDate: "POD Task Due Date",
  podTaskOwner: "POD Task Owner",
  podTaskStatus: "POD Task Status",
  podCategories: "POD Categories",
  jiraItem: "Jira Item",
  notificationRecipient: "Notification Recipient",
  checkBoxBlocker: "Check this box if it is a blocker",
  visitFindTalent: "Please visit the 'Find Talent' page and add talented individuals to your pod.",
  name: "Name",
  actions: "Actions",
  gitHubRepo: "GitHub Repo",
  gitHubUsername: "GitHub Username",
  jiraUsername: "Jira Username",
  giveRating: "Give Rating",
  commentHere: "comment here...",
  browseTalent: "Browse Talent",
  findBestFreelancers: "Find the best freelancers or talent pods to get your projects delivered at a faster pace!",
  addingCandidateWait: "Adding Candidate Wait...",
  clear: "Clear",
  search: "Search",
  keyword: "Keyword",
  applyFilters: "Apply Filters",
  enterEmailToInvite: "Enter an E-Mail to Invite",
  talentApprovalRequests: "Talent Approval Requests",
  approvalRequests: "Approval Requests",
  listOfTalents: "List of Talents",
  viewProfile: "View Profile",
  findTalent: 'Find Talent',
  subscription: 'Subscription',
  invoices: 'Invoices',
  login: 'Login',
};

export default messagesEn;
