import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  MenuItem,
  Select,
  Pagination,
  IconButton,
} from "@mui/material";
import axios from "axios";

import { FiDownload } from "react-icons/fi";
import ViewTimeSubmit from "./ViewTimeSubmit";
import moment from "moment";
import { NavHashLink } from "react-router-hash-link";
import styled from "styled-components";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";

import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { GrFormEdit, GrFilter } from "react-icons/gr";
import { jsPDF } from "jspdf";

function PodActivity() {
  const [selectedPodId, setSelectedPodId] = useState("");
  const [editableData, setEditableData] = useState([]);
  const [podDetails, setPodDetails] = useState([]);
  const [podActivities, setPodActivities] = useState([]);
  const [selectedPodDetails, setSelectedPodDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const token = useAuthToken();
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [activeTab, setActiveTab] = useState("post"); // Default to 'post' tab
  const [isOpen, setIsOpen] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().month(2).date(10).toDate(), // March is represented by index 2
      endDate: moment().month(2).date(11).toDate(), // March is represented by index 2
      key: "selection",
    },
  ]);

  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CLIENT_GETCLIENTPOD,
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              clientID: user.data[0].emp_id,
            }),
          }
        );

        const data = await response.json();
        setPodDetails(data.data.podDetails);
        const activities = data.data.activities;
        // Sort activities by createdAt date in descending order (most recent first)
        const sortedActivities = activities.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPodActivities(sortedActivities);
        setFilteredActivities(sortedActivities); // Initialize filtered activities with sorted activities
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handlePodSelect = (event) => {
    const selectedPodId = event.target.value;
    setSelectedPodId(selectedPodId);

    // Find the details of the selected pod
    const podDetail = podDetails.find((pod) => pod.id === selectedPodId);
    setSelectedPodDetails(podDetail);

    // Filter pod activities based on selected pod ID or show all if no pod is selected
    const activities = selectedPodId
      ? podActivities.filter(
          (activity) => activity.pod_id === parseInt(selectedPodId)
        )
      : podActivities;
    setFilteredActivities(activities);
    setCurrentPage(1); // Reset current page when selecting a new pod
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    // Filter activities based on selected date range and team member
    const activities = podActivities.filter((activity) => {
      const isInDateRange = isActivityInRange(activity, ranges.selection);
      const matchesMember =
        !selectedTeamMember || activity.firstName === selectedTeamMember;
      return isInDateRange && matchesMember;
    });
    setFilteredActivities(activities);
  };

  const handleTeamMemberSelect = (event) => {
    const selectedMember = event.target.value;
    setSelectedTeamMember(selectedMember);

    // Filter pod activities based on selected pod ID, team member, and date range
    const activities = podActivities.filter((activity) => {
      const matchesPod =
        !selectedPodId || activity.pod_id === parseInt(selectedPodId);
      const matchesMember =
        !selectedMember || activity.firstName === selectedMember;
      const isInDateRange = isActivityInRange(activity, dateRange[0]); // Use the date range from state

      return matchesPod && matchesMember && isInDateRange;
    });

    setFilteredActivities(activities);
    setCurrentPage(1); // Reset current page when selecting a new team member
  };

  const isActivityInRange = (activity, range) => {
    const activityDate = new Date(activity.createdAt);
    const activityYear = activityDate.getFullYear();
    const rangeStartDateYear = range.startDate.getFullYear();
    const rangeEndDateYear = range.endDate.getFullYear();

    return (
      activityDate >= range.startDate &&
      activityDate <= range.endDate &&
      activityYear >= rangeStartDateYear &&
      activityYear <= rangeEndDateYear
    );
  };

  // Pagination
  const rowsPerPage = 10;
  const totalPages = Math.ceil(filteredActivities.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedActivities = filteredActivities.slice(startIndex, endIndex);

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one data item.");
      return; // Prevent further execution of the function
    }

    const ids = selectedIds.map((id) => id); // Copy selectedIds array

    const payload = {
      poddata: [{ id: ids, manager_id: user.data[0].emp_id }],
      manager_id: user.data[0].emp_id,
    };

    fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        alert("Data Approved successfully.");
        // Optionally, you can perform further actions after successful submission.
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };

  const handleDownload = async (format) => {
    if (format === "csv") {
      // Convert filteredActivities to downloadable format, for example, CSV
      let csvContent =
        "data:text/csv;charset=utf-8," +
        "First Name, Categories, Jira Issue, Today's Focus, Accomplishments, Blocker, Posted Date, Video URL, Status\n";

      filteredActivities.forEach((activity) => {
        // Format the "Today's Focus" field
        const todayFocus = activity.today_focus
          ? activity.today_focus.replace(/,/g, ";")
          : ""; // Check for null value before replacing commas

        // Construct each row of the CSV
        const row = `${activity.firstName},"${activity.categories}","${
          activity.jira_issue
        }","${todayFocus}","${activity.accomplishments}","${
          activity.blockers
        }","${moment(activity.createdAt).format("MM-DD-YYYY") || ""}","${
          activity.loom_url || ""
        }","${activity.posted_to_client == 1 ? "Approved" : "Pending"}"\n`;

        csvContent += row;
      });

      // Create a temporary link element and trigger the download
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "pod_activities.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === "pdf") {
      if (filteredActivities.length > 0) {
        const doc = new jsPDF();
        let yPos = 10; // Initial y position
        filteredActivities.forEach((activity) => {
          // Format the "Today's Focus" field
          const todayFocus = activity.today_focus ? activity.today_focus : "";
          // Construct a string representing the activity
          const activityString = `${activity.firstName}, ${
            activity.categories
          }, ${activity.jira_issue}, ${todayFocus}, ${
            activity.accomplishments
          }, ${activity.blockers}, ${
            moment(activity.createdAt).format("MM-DD-YYYY") || ""
          }, ${activity.loom_url || ""}, ${
            activity.posted_to_client == 1 ? "Approved" : "Pending"
          }`;
          // Add the activity to the PDF
          doc.text(activityString, 10, yPos);
          yPos += 10; // Increment y position for next activity
          if (yPos >= 280) {
            // Check if nearing end of page
            doc.addPage(); // Add a new page if necessary
            yPos = 10; // Reset y position for new page
          }
        });
        doc.save("pod_activities.pdf");
      } else {
        console.error("No activities to generate PDF.");
      }
    } else if (format === "docx") {
      if (filteredActivities.length > 0) {
        let docContent = "<html><body><table border='1'>";
        docContent +=
          "<tr><th>First Name</th><th>Categories</th><th>Jira Issue</th><th>Today's Focus</th><th>Accomplishments</th><th>Blocker</th><th>Posted Date</th><th>Video URL</th><th>Status</th></tr>";

        filteredActivities.forEach((activity) => {
          const todayFocus = activity.today_focus ? activity.today_focus : "";
          const postedStatus =
            activity.posted_to_client == 1 ? "Approved" : "Pending";
          docContent += `<tr><td>${activity.firstName}</td><td>${
            activity.categories
          }</td><td>${activity.jira_issue}</td><td>${todayFocus}</td><td>${
            activity.accomplishments
          }</td><td>${activity.blockers}</td><td>${
            moment(activity.createdAt).format("MM-DD-YYYY") || ""
          }</td><td>${
            activity.loom_url || ""
          }</td><td>${postedStatus}</td></tr>`;
        });

        docContent += "</table></body></html>";

        const blob = new Blob([docContent], { type: "application/msword" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pod_activities.doc");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No activities to generate Word document.");
      }
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelection = (format) => {
    handleDownload(format);
    setIsOpen(false); // Close the dropdown after selection
  };


  const handleViewAndTranscribe = async (loomUrl, id) => {
    try {
      // Split the URL at '?' to extract the filename
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
  
      // Construct the CloudFront URL
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
  
      // Combine the CloudFront URL with the filename
      const fullUrl = cloudFrontUrl + filename;
  
      // Make a request to get the signed URL for transcription
      const response = await axios.post(
       process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );
  
      // Parse the response body as JSON
      const responseBody = JSON.parse(response.data.body);
  
      // Extract the signed URL from the parsed response body
      const signedUrl = responseBody.signedUrl;
  
      // Ensure that the signed URL is found in the transcription response
      if (!signedUrl) {
        console.error('Signed URL not found in transcription response:', responseBody);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }
  
      // Update the corresponding item in editableData with the signed URL
      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
      setEditableData(updatedData);
  
      // Open the video for viewing in a new tab using the full signed URL
      window.open(signedUrl, '_blank');
  
      // console.log('Transcription Data:', signedUrl);
      // alert('Video transcription completed successfully.');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };
  

  return (
    <Div>
      <TitleText>{intl.formatMessage({ id: "podActivity" })}</TitleText>

      <Select
        value={selectedPodId}
        onChange={handlePodSelect}
        displayEmpty
        inputProps={{ "aria-label": "Select Pod" }}
      >
        <MenuItem value="">All Pods</MenuItem>
        {podDetails.map((pod) => (
          <MenuItem key={pod.id} value={pod.id}>
            {pod.podName}
          </MenuItem>
        ))}
      </Select>

      {selectedPodDetails && (
        <TabsContainer>
          <TabButton
            onClick={() => handleTabClick("post")}
            active={activeTab === "post"}
          >
            {intl.formatMessage({ id: "viewPodActivities" })}
          </TabButton>
          <TabButton
            onClick={() => handleTabClick("view")}
            active={activeTab === "view"}
          >
            {intl.formatMessage({ id: "viewTimeEntries" })}
          </TabButton>
        </TabsContainer>
      )}
      {activeTab === "post" ? (
        <>
          {selectedPodDetails && (
            <Select
              value={selectedTeamMember}
              onChange={handleTeamMemberSelect}
              displayEmpty
              inputProps={{ "aria-label": "Select Team Member" }}
              style={{ marginTop: "1rem" }}
            >
              <MenuItem value="">All Team Members</MenuItem>
              {Array.from(
                new Set(
                  podActivities
                    .filter(
                      (activity) => activity.pod_id === parseInt(selectedPodId)
                    )
                    .map((activity) => activity.firstName)
                )
              ).map((member) => (
                <MenuItem key={member} value={member}>
                  {member}
                </MenuItem>
              ))}
            </Select>
          )}

          <div style={{ display: "flex", marginLeft: "auto", width: "15rem" }}>
            <div style={{ position: "relative" }}>
              <FiDownload
                cursor="pointer"
                style={{ width: "25px", height: "25px" }}
                title="Download Activities"
                onClick={toggleDropdown}
              />
              {isOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: "1",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    marginTop: "5px",
                  }}
                >
                  <select
                    style={{
                      width: "15rem",
                      border: "none",
                      textDecoration: "none",
                    }}
                    onChange={(e) => handleSelection(e.target.value)}
                  >
                    <option value="">Download as...</option>
                    <option value="csv">CSV</option>
                    <option value="pdf">PDF</option>
                    <option value="docx">Word</option>
                  </select>
                </div>
              )}
            </div>
          </div>

          <div
            style={{ display: "flex", marginRight: "auto" }}
            onClick={() => setShowDateRangePicker(!showDateRangePicker)}
          >
            <GrFilter
              title="Date Range Filter"
              cursor="pointer"
              style={{ width: "25px", height: "25px" }}
            />
          </div>

          {showDateRangePicker && (
            <DateRangePicker
              onChange={handleDateRangeChange}
              ranges={dateRange}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
            />
          )}
          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{
                width: "100%",
                fontFamily: "Poppins,Medium",
                borderRadius: "10px",
              }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#00B4D8",
                  "& td, & th": {
                    fontFamily: "Poppins,Medium",
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "600",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="right">
                    {intl.formatMessage({ id: "select" })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: "PodTeamMember" })}
                  </TableCell>
                  {/* <TableCell>Categories</TableCell> */}
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "jiraIssue" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "todaysFocus" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "accomplishments" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "blocker" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "postedDate" })}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "videoUrl" })}
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {intl.formatMessage({ id: "status" })}
                    </div>
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "edit" })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedActivities.map((activity) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                      },
                      "&:nth-child(even)": { backgroundColor: "#90E0EF" },
                      "&:nth-child(odd)": {
                        backgroundColor: "#caf0f8",
                      },
                      "& td, & th": {
                        fontFamily: "Poppins,Medium",
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                      },
                    }}
                    key={activity.id}
                  >
                    {activity.posted_to_client == 1 ? (
                      <TableCell>
                        {" "}
                        <input
                          type="checkbox"
                          disabled
                          checked={selectedIds.includes(activity.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, activity.id)
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {" "}
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(activity.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, activity.id)
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell>{activity.firstName}</TableCell>
                    {/* <TableCell>{activity.categories}</TableCell> */}
                    <TableCell>{activity.jira_issue}</TableCell>
                    <TableCell>{activity.today_focus}</TableCell>
                    <TableCell>{activity.accomplishments}</TableCell>
                    <TableCell>{activity.blockers}</TableCell>
                    <TableCell>
                      {moment(activity.createdAt).format("MM-DD-YYYY")}
                    </TableCell>
                    <TableCell>
                      {activity.loom_url ? (
                       <Button onClick={() => handleViewAndTranscribe(activity.loom_url, activity.id)}>
                       {intl.formatMessage({ id: "viewVideo" })}
                       </Button>
                      ) : (
                        <p>{intl.formatMessage({ id: "noVideoAvailable" })}</p>
                      )}
                    </TableCell>
                    {activity.posted_to_client == 1 ? (
                      <TableCell align="right">
                        {intl.formatMessage({ id: "approved" })}
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        {intl.formatMessage({ id: "pending" })}
                      </TableCell>
                    )}
                    {activity.posted_to_client == 1 ? (
                      <TableCell align="right">
                        <ButtonEdit disabled>
                          <GrFormEdit style={{ color: "#00B4D8" }} />{" "}
                        </ButtonEdit>{" "}
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        {" "}
                        <NavHashLink
                          to={{
                            pathname: "/Edittalentpodactivitybyid",
                            state: {
                              Id: activity.id,
                            },
                          }}
                          smooth
                        >
                          {" "}
                          <ButtonEdit>
                            <GrFormEdit style={{ color: "#00B4D8" }} />{" "}
                          </ButtonEdit>{" "}
                        </NavHashLink>{" "}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button1 onClick={handleSubmit}>
              {intl.formatMessage({ id: "approve" })}
            </Button1>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </>
      ) : (
        <ViewTimeSubmit Podid={selectedPodDetails.id} />
      )}
    </Div>
  );
}

export default PodActivity;

const Div = styled.div`
  display: flex;

  jutify-content: center;
  align-items: center;
  width: 80%;
  flex-direction: column;
  margin: auto;
  margin-top: 6rem;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;
const ButtonEdit = styled.button`
  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 2rem;
  }
`;
const Button1 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;
  width: 10rem;
  height: 2rem;
  font-size: 15px;
  font-weight: 600;
  color: white;
`;
const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? "lightblue" : "white")};
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  outline: none;
`;
