import React, { useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";
import axios from "axios";
import { useAuthToken } from '../TokenContext';
import { useSelector } from 'react-redux';
import './time.css';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const TimeViewtalent = ({ podid, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const [timeData, setTimeData] = useState(null);
  const [noData, setNoData] = useState(false); // Separate state for no data condition
  const chartRef = useRef(null);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  useEffect(() => {
    fetchData();
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [podid, token, selectedSprint, sprintStartDate, sprintEndDate]);

  const fetchData = async () => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      let response;
      if (podid) {
        response = await axios.post(
          process.env.REACT_APP_TALENT_JIRASHOWTIMEDASHBOARDTALENT,
          { c_id: user.data[0].c_id, pod_id: podid, sprint_name: selectedSprint || '', sprint_start_date: sprintStartDate || '', sprint_end_date: sprintEndDate || '' },
          { headers: headers }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_TALENT_JIRASHOWTIMEDASHBOARDTALENT,
          { c_id: user.data[0].c_id },
          { headers: headers }
        );
      }
      const responseData = JSON.parse(response.data.body);
      setTimeData(responseData);
      console.log('time', responseData);
    } catch (error) {
      console.error("Error fetching time details:", error);
    }
  };

  useEffect(() => {
    if (timeData) {
      checkForNoData(timeData); // Check for no data whenever timeData changes
    }
  }, [timeData]);

  const checkForNoData = (data) => {
    if (!data || !data.body) {
      setNoData(true);
      return;
    }

    const selectedData = podid
      ? data.body.total_time_by_pods
      : data.body.jira_show_time_by_talent[0];

    if (!selectedData) {
      setNoData(true);
      return;
    }

    const includedCategories = [
      'Bug-Fixing',
      'Deployment',
      'Design',
      'Development',
      'Performance',
      'Security',
    ];
    const filteredData = Object.keys(selectedData)
      .filter((category) => includedCategories.includes(category))
      .reduce((obj, key) => {
        obj[key] = selectedData[key];
        return obj;
      }, {});

    if (
      Object.keys(filteredData).length === 0 ||
      Object.values(filteredData).every((value) => value === '00:00:00')
    ) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  };

  useEffect(() => {
    if (timeData && !noData) {
      renderChart();
    }
  }, [timeData, noData]);

  const renderChart = () => {
    const selectedData = podid
      ? timeData.body.total_time_by_pods
      : timeData.body.jira_show_time_by_talent[0];

    const includedCategories = [
      'Bug-Fixing',
      'Deployment',
      'Design',
      'Development',
      'Performance',
      'Security',
    ];
    const filteredData = Object.keys(selectedData)
      .filter((category) => includedCategories.includes(category))
      .reduce((obj, key) => {
        obj[key] = selectedData[key];
        return obj;
      }, {});

    const labels = Object.keys(filteredData).map((category) => ({
      label: `${category}\n${convertMinutesToTime(filteredData[category])}`,
      value: parseFloat(filteredData[category]),
    }));

    const data = labels.map((labelObj) => labelObj.value);

    const ctx = document.getElementById("timeChart").getContext("2d");

    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#FF5733",
      "#44A7EB",
      "#EFC656",
    ];

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels.map((labelObj) => labelObj.label),
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
          labels: {
            display: false,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index;
              const labelObj = labels[index];
              const formattedTime = convertMinutesToTime(labelObj.value);
              return `${labelObj.label}: ${formattedTime}`;
            },
          },
        },
      },
    });
  };

  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return `${hours}h${minutes}m${seconds}s`;
  };

  const convertMinutesToTime = (timeString) => {
    // Log the time string only once
    if (!convertMinutesToTime.loggedTimeString) {
      console.log('Received time string:', timeString);
      convertMinutesToTime.loggedTimeString = true;
    }
  
    if (!timeString || typeof timeString !== 'string') {
      console.log('Invalid time string:', timeString);
      return 'Invalid Time';
    }
  
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + seconds / 60;
    const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const formattedMinutes = String(Math.floor(totalMinutes % 60)).padStart(2, '0');
  
    return `${formattedHours}h${formattedMinutes}m`;
  };
  

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'jiraTimeDetails' })}</h2>
        <br />
        <div className="chart-wrapper" style={{ marginTop: "20px", width: "135px", height: "135px" }}>
          {noData ? (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          ) : (
            <canvas id="timeChart"></canvas>
          )}
        </div>
      </div>
    </div>
  );
};

TimeViewtalent.propTypes = {
  podid: PropTypes.array.isRequired,
};

export default TimeViewtalent;
