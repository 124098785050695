import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const DetailBitbucketcodeTalent = ({ initialPodIds ,selectedSprint,sprintStartDate,sprintEndDate}) => {
  const [metrics, setMetrics] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const chartId = useRef(uuidv4());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const chartRef = useRef(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const token = useAuthToken();
  const intl = useIntl();

  const openModal = () => {
    console.log("Opening modal");
    setModalIsOpen(true);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
  };

  useEffect(() => {
    Chart.register(...registerables);

      fetchMetrics();
    
  }, [initialPodIds,token,selectedSprint,sprintStartDate,sprintEndDate]);

  

  const fetchMetrics = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      //REACT_APP_TALENT_BITBUCKETCODEMETRICSTALENT
      const apiEndpoint = process.env.REACT_APP_TALENT_CODEMETRICSTALENT;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : token,
        },
        body: JSON.stringify({
            talent_id: user.data[0].c_id ,
          pod_id: initialPodIds||[],
          sprint_name: selectedSprint || '',
    sprint_start_date:sprintStartDate||'',
    sprint_end_date: sprintEndDate ||''
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setMetrics(data.body || {}); // Set metrics to an empty object if data.body is falsy
        displayChart();
      } else {
        console.error('Failed to fetch metrics:', data);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };
  

  useEffect(() => {
    displayChart();
  }, [selectedDropdownValue, metrics, modalIsOpen]); // Include modalIsOpen as well
  

  const displayChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    const ctx = document.getElementById(chartId.current);
    let chartData, chartLabels;
  
    if (!ctx) {
      console.error('Canvas element not found');
      return;
    }
  
    if (selectedDropdownValue) {
      chartData = getChartDataForSelectedUser();
    } else {
      chartData = getChartDataForTotalLines();
    }
  
    if (chartData.length === 0) {
      console.error('No chart data available');
      return;
    }
  
    createChartInstance(ctx, chartData);
  };
  
  const getChartDataForSelectedUser = () => {
    if (!selectedDropdownValue) {
      return getChartDataForTotalLines();
    }
  
    const talentUser = metrics?.talent_users.find(user => user.bitbucket_user === selectedDropdownValue);
    
    if (!talentUser) {
      console.error('Selected user not found:', selectedDropdownValue);
      return [];
    }
  
    if ('total_lines_changed_last_week' in talentUser && 'total_lines_changed_this_week' in talentUser) {
      const { total_lines_changed_last_week, total_lines_changed_this_week } = talentUser;
      return getTotalLinesChartData(total_lines_changed_last_week, total_lines_changed_this_week);
    } else if ('sprint_commit_lines' in talentUser) {
      const { sprint_commit_lines } = talentUser;
      return [sprint_commit_lines];
    }
  
    return [];
  };
  
  const getChartDataForTotalLines = () => {
    const totalLines = metrics?.total_lines;
  
    if (!totalLines) {
      console.error('Metrics or total_lines not found');
      return [];
    }
  
    if (totalLines.total_lines_changed_this_week) {
      // Type 1 response
      return [totalLines.total_lines_changed_last_week, totalLines.total_lines_changed_this_week];
    } else if (totalLines.sprint_commit_lines) {
      // Type 2 response
      return [totalLines.sprint_commit_lines];
    }
  
    console.error('Invalid total_lines data:', totalLines);
    return [];
  };
  
  
  const getTotalLinesChartData = (lastWeek, thisWeek) => {
    const chartData = [lastWeek, thisWeek];
  
    if (chartData.every(value => value === 0)) {
      return [];
    }
  
    return chartData;
  };
  
  const createChartInstance = (ctx, data) => {
    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Lines Changed Last Week', 'Lines Changed This Week'],
        datasets: [
          {
            label: 'Code',
            data: data,
            backgroundColor: ['seagreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        tooltips: {
          enabled: false,
        },
        onClick: (event, elements) => {
          openModal();
        },
        hover: {
          onHover: function (event, elements) {
            const chart = event.target;
            if (chart) {
              chart.options.plugins.annotation.labels = {
                render: 'label',
                position: 'outside',
                fontSize: 12,
                fontStyle: 'bold',
              };
              chart.update();
            }
          },
        },
      },
    });
  };
  
  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
        <br/>
        
        <div>
          <select
            style={{ width: '12rem' }}
            value={selectedDropdownValue}
            onChange={(e) => setSelectedDropdownValue(e.target.value)}
          >
            <option value="">{intl.formatMessage({id:'allReporters'})}</option>
            {metrics?.talent_users?.map((user) => (
              <option key={user.bitbucket_user} value={user.bitbucket_user}>
                {user.bitbucket_user}
              </option>
            ))}
          </select>
        </div>
        <br />
      
        <div className="chart-wrapper" style={{ width: "130px", height: "140px", margin: "auto", textAlign: "center", marginLeft: "2rem" ,marginTop:"10px"}}>
          {metrics ? (
            <>
              {metrics.total_lines &&
                ((metrics.total_lines.total_lines_changed_this_week === 0 && metrics.total_lines.total_lines_changed_last_week === 0) ||
                metrics.total_lines.sprint_commit_lines === 0) ? (
                  <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
                ) : (
                  <canvas id={chartId.current} ></canvas>
                )}
            </>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="GitHub Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>{intl.formatMessage({ id: 'githubSummary' })}</h6>
        <TableContainer component={Paper}>
          <Table component={Paper}
            sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
            aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600'
                },
              }}
            >
              <TableCell align="right">{intl.formatMessage({ id: 'githubUser' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedLastWeek' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedThisWeek' })}</TableCell>
            </TableHead>
            <TableBody>
              {metrics?.talent_users?.map((project, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF'
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: '#caf0f8'
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'black',
                    },
                  }}
                >
                  <TableCell align="right">{project.bitbucket_user}</TableCell>
                  <TableCell align="right">{project.total_lines_changed_last_week}</TableCell>
                  <TableCell align="right">{project.total_lines_changed_this_week}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {metrics?.all_repo_checkins && (
            <>
              <h6 >  {intl.formatMessage({ id: 'allRepoCheckins' })}</h6>
              <p>  {intl.formatMessage({ id: 'currentWeek' })}  : {metrics.all_repo_checkins.current_week}</p>
              <p>   {intl.formatMessage({ id: 'previousWeek' })} : {metrics.all_repo_checkins.previous_week}</p>
            </>)}
        </div>
        <button onClick={closeModal}>  {intl.formatMessage({ id: 'done' })}</button>
        
      </Modal>
    </div>
  );
};

export default DetailBitbucketcodeTalent;
