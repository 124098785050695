
import { green } from "@mui/material/colors";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { postpodproject, postproject } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Select from "react-select";
import profile from './assets/profile_img.png';
import { event } from 'jquery';
import styled from 'styled-components';
import { style } from '@mui/system';
import star from './assets/star.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";
import { Height, Podcasts } from "@mui/icons-material";
import defaultimg from './assets/defaultimge.png';
import './BrowseTalent.css';
import Popup from "./Popup";
import Modal from '@mui/material/Modal';
import {MdSaveAlt} from 'react-icons/md'
import allergens from './allergens';
import OptionsOutsideSelect from "../OptionsOutsideSelect";
import CreatePodSkillspage from "./CreatePodSkillspage";
import PodCreatedSuccessFullyCan from './PodCreatedSuccessFullyCan';
import PersonIcon from '@mui/icons-material/Person';
import AWS from 'aws-sdk';
import S3FileUpload from 'react-s3';
import EditIcon from '@mui/icons-material/Edit';
import { Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IoMdAdd } from 'react-icons/io'
import { AiOutlineMinus } from 'react-icons/ai';
import 'bootstrap/dist/css/bootstrap.css';
import Box from '@mui/material/Box';
import Master from "./Master";
import Mastergithub from "./Mastergithub";
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}


const ClientManagePod = (props) => {

  const token = useAuthToken();
  const intl = useIntl();

  const location = useLocation();
  const { podid } = location.state

  const [userData, setUserData] = useState([]);
  const [userSearchData, setUserSearchData] = useState([]);
  const [name, setName] = useState('');
  const [profession, setProfession] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [job, setJob] = useState('');
  const [data, SetData] = useState([]);
  const [Team, SetTeam] = useState([]);
  const [Candidatetoadd, SetCandidatetoadd] = useState('');
  const [Addcandidatescreen, setAddcandidatescreen] = useState(false);
  const [getid, setGetid] = useState('');
  const [PodStatus, setPodStatus] = useState('');
  const [AuthImage, setAuthImage] = useState("");
  const [Picturepod, setPicturepod] = useState("");
  const [Prevskill, setPrevskill] = useState([]);

  const [s3img, setS3img] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(4);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [value, setValue] = useState('default');
  const [mis, setMis] = useState('');
  const [bdg, setBdg] = useState('');
  const [mr, setMr] = useState('');
  const [pri, setPri] = useState('');
  const [sec, setSec] = useState('');
  const [tz, setTz] = useState('');
  const [Title, setTitle] = useState('');
  const [avail, setAvail] = useState('');
  const [pref, setPref] = useState('');
  const [rep, setRep] = useState([]);
  const [Candidatedata, setCandidatedata] = useState([]);
  const [len, setLen] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);



  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  }
  const available = (val) => {
    setAvail(val.target.value);
  }

  const jobtitle = (val) => {
    setTitle(val.target.value);
  }
  const resetAvailableField = () => {
    setAvail('');
  }
  const resetTitleField = () => {
    setAvail('');
  }
  const preferfield = (val) => {
    setPref(val.target.value);
  }
  const resetPreferField = () => {
    setPref('');
  }
  const handleUserInput = (val) => {
    setMr(val.target.value);
  }
  const resetInputField = () => {
    setMr('');
  }
  const handleJob = (val) => {
    setMis(val.target.value);
  }
  const resetJobField = () => {
    setMis('');
  }
  const budgetfield = (val) => {
    setBdg(val.target.value);
  }
  const resetBudgetField = () => {
    setBdg('');
  }
  const primaryfield = (val) => {
    // alert(val.target.value);
    setPri(val.target.value);
  }
  const resetPrimaryField = () => {
    setPri('');
  }
  const secondaryfield = (val) => {
    setSec(val.target.value);
  }
  const resetSecondaryField = () => {
    setSec('');
  }
  const timezonefield = (val) => {
    setTz(val.target.value);
  }
  const resetTimezoneField = () => {
    setTz('');
  }
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const pages = [];
  var replength = 0;
  var currentItems = []
  if (rep) {
    replength = rep.length

    for (let i = 1; i <= Math.ceil(replength / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentItems = rep.slice(indexOfFirstItem, indexOfLastItem);
  }
  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}</li>
      );
    } else {
      return null;
    }
  });

  const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);

  var scrollTop = () => {
    window.scrollTo(0, 0);
  };


  useEffect(async () => {

    try {
      const data = {
        clientID: user.data[0].emp_id
      }

      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
      // https://sfhr6xe082.execute-api.us-east-1.amazonaws.com/dev/getteammembers
      const response = await axios.post(process.env.REACT_APP_CLIENT_GETTEAMMEMBERS, data,{headers:headers});

      //console.log(response)
      SetTeam(response.data.data);
    } catch (error) {
      console.log(error);
    }



  }, [token])



  function filter(c_id) {

    return getcandidates.map((data) => {

      return data.c_id == c_id;

    });

  };

  function filter32(c_id) {

    return Poddata.candidatesid.map((data) => {

      return data.id == c_id;

    });

  };



  const [podname, setPodName] = useState('');
  const [pod_rate, setPodrate] = useState(''); 
  const [techStack, setTechStack] = useState('');
  const [skills, setSkills] = useState([]);
  const [podtype, setpodtype] = useState('');

  const [podSize, setPodsize] = useState('');

  const [podDescription, setPodDescription] = useState('');
  const [budget1, setBudget1] = useState('');
  const [countrypod, setCountrypod] = useState('');
  const [timezonepod, setTimezonepod] = useState('');
  const [multiskill, setMultiskill] = useState([]);
  const [Image, setImage] = useState("");


  const [selected, setSelected] = useState([]);
  const handleSelectChange = (values) => {
    setSelected(values);
  };
  const [Poddata, setPoddata] = useState({
    podname: '',
    pod_rate:'',
    podDescription: '',
    podsize: '',
    podtype: '',
    techStack: "",
    
    skills: [
      {

      }
    ],
    budget: '',
    country: '',
    timezone: '',
   
    canID: user.data[0].c_id,
    candidatesid: [],
    podID: [],
  });


  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "white", height: '50px', border: "2px solid #B7B7B7", borderRadius: '10px',
      color: 'grey',
      fontWeight: '600',
      paddingLeft: '12px'

    })

  };






  const handleSelectChange3 = (values) => {



    setSelected(values);
    var testskill = [];

    values.map(function (ext) {
      var c = {
        'skill': ext.label
      };
      testskill.push(c)
    })




    setSkills(testskill);





  };


  const onInputChangetechstack = (e) => {
    setTechStack(e.target.value);
  }
  const onInputChangebudget1 = (e) => {
    setBudget1(e.target.value);
  }


  const addCandidate = async (c_id, name) => {

    // console.log(filter32(c_id).includes(true))


    if (filter32(c_id).includes(true)) {

      alert("Already Added");

    } else {

      Poddata.candidatesid.push({ id: c_id, name: name });

      setAddcandidatescreen(false);

    }

  }

  const [emailpopup, setEmailpopup] = useState('');
  const [messege, setMessege] = useState(false);

  const [getpoddetails, setGetpoddetails] = useState({});
  const [candcount, setCandcount] = useState(0);
  const [getcandidates, setGetcandidates] = useState([]);
  const [podskill, setPodskill] = useState([]);
  const email = user.data[0].email

  const portfolioUrl = user.data[0].portfolioUrl

  const firstName = user.data[0].firstName

  const lastName = user.data[0].lastName
//   let path = window.location.pathname;
//   let path1 = path && path.split(',')

//   let c_id = path1 && path1.length > 0 && path1[0].split('/')

//  let c = c_id && c_id.length > 0 && c_id[2]




  const handleSubmitOptions = (e) => {
    e.preventDefault();
    let selectedvalue = []
    selected.map((item) => {

      selectedvalue.push({ "skill": item.label })

    })




    var options = {
      podName: podname,
      pod_rate:pod_rate,
      podtype: podtype,
      techStack: techStack,
      skills: skills,
      podDescription: podDescription,
      budget: budget1,
      timezone: timezonepod,
      country: countrypod,
      canID: user.data[0].c_id,
      
      candidatesid: Poddata.candidatesid,
      id: getid,
      picture: s3img,
      podsize: podSize,

    }


    
    // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-client-update-pod
    fetch(process.env.REACT_APP_CLIENT_PPODCLIENTUPDATEPOD, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','Authorization' : token, },
      body: JSON.stringify(options)
    }).then(() => {
      alert("Pod is Updated Successfully.");
     
      // window.location.href=`/FindPodsDetails`
    });

// Call handleSaveSelected function before making the fetch request
// handleSaveSelected();
//


  };







  useEffect(() => {

   
     


    const body = {
      id: podid
    };

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    //https://w3ten9gqf7.execute-api.us-east-1.amazonaws.com/dev/getpodbyid
    axios.post(process.env.REACT_APP_CLIENT_GETPODID, body,{headers:headers})

      .then((res) => {


        // console.log("this is get data", res.data.data.podDetails.picture);

        // console.log("this is get data2", res.data.data.podDetails.podName);
        const skillsFromAPI = res.data.data.podDetails.skills;
        const preselectedSkills = skillsFromAPI.map((skill) => ({ label: skill.skill }));

        setSelected(preselectedSkills);


        // let filter_candidate = res.data.data.candidates.filter((item)=>item.podID == props.pod_id);

        setGetcandidates(res.data.data.candidates)
       
        setCandcount(res.data.data.candidates.length);
        // let filter_poddetails=res.data.data.podDetails.filter((item)=>item.id == props.pod_id);

        setGetid(res.data.data.podDetails.id)
        // setGetpoddetails(res.data.data.podDetails)
        // setPodStatus(filter_poddetails[0].status)
        setPodsize(res.data.data.podDetails.podsize)
        setNumRows(parseInt(res.data.data.podDetails.podsize));
        setpodtype(res.data.data.podDetails.podtype)
        setTechStack(res.data.data.podDetails.techStack)
        setPodDescription(res.data.data.podDetails.podDescription)
        setPodName(res.data.data.podDetails.podName);
        setPodrate(res.data.data.podDetails.pod_rate)
        setCountrypod(res.data.data.podDetails.location);
        setPicturepod(res.data.data.podDetails.picture);

        setTimezonepod(res.data.data.podDetails.pod_timezone);

        var kks = JSON.parse(res.data.data.podDetails.skills)
        var ab = kks


        var collas = [];
        ab.map(function (item) {
          // console.log("item", item);
          var selectvaluefromallergens = allergens.filter((element) => element.label == item.skill);
          //console.log(selectvaluefromallergens, "--->selectvaluefromallergens");
          if (selectvaluefromallergens.length > 0) {
            collas.push(selectvaluefromallergens[0]);
          }
        });

        setSelected(collas);






       

        setS3img(res.data.data.podDetails.picture);

        const myBucket = new AWS.S3(S3Config);


        const URL_EXPIRATION_TIME = 900;

        const params = {
          Bucket: "globldatauat",
          Key: res.data.data.podDetails.picture,
          Expires: URL_EXPIRATION_TIME,
        }


        let s3url = params.Key


        myBucket.getSignedUrl('getObject', params, (err, url) => {


          setAuthImage(url);

        });






      }


      )
      .catch((err) => {
        console.log("catch", err);
      })


  }, [token]);

  
  const [selectedCandidates, setSelectedCandidates] = useState([]);  
  const [selectedCandidatesNames, setSelectedCandidatesNames] = useState({});

  
  

 
 
 

 


  const [selectedRoles, setSelectedRoles] = useState(Array(podSize).fill(""));
  const [selectedPrimarySkills, setSelectedPrimarySkills] = useState(Array(podSize).fill(""));
  const [selectedSecondarySkills, setSelectedSecondarySkills] = useState(Array(podSize).fill(""));
  const [selectedAvailabilities, setSelectedAvailabilities] = useState(Array(podSize).fill(""));
  const [numRows, setNumRows] = useState(null);
  // Placeholder data for testing
  const [podcandidate, setPodCandidate] = useState([]);
 const initialSelectedCandidate = Array(numRows).fill('');
 const [selectedCandidate, setSelectedCandidate] = useState(initialSelectedCandidate);
 
  useEffect(() => {
    // Fetch candidates when the component mounts
    fetchCandidates(podid);
  }, [podid,token]);

  const fetchCandidates = async (podID) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_FETCHCANDIDATESBASEDONPODS,
        {
          method: 'POST',
          body: JSON.stringify({ pod_id: podID }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token, 
          },
        }
      );
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setPodCandidate(data.data);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };
  
  const handleCandidateChange = (index, selectedOption, candidateID, Candidate_Name) => {
    const updatedSelectedCandidates = [...selectedCandidate];
    updatedSelectedCandidates[index] = selectedOption;
    setSelectedCandidate(updatedSelectedCandidates);
  
    // Now you can pass candidateID and Candidate_Name to your save function
   
  };
  

  const handleRoleChange = (index, value) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles[index] = value;
    setSelectedRoles(updatedRoles);
  };

  const handlePrimarySkillChange = (index, value) => {
    const updatedSkills = [...selectedPrimarySkills];
    updatedSkills[index] = value;
    setSelectedPrimarySkills(updatedSkills);
  };

  const handleSecondarySkillChange = (index, value) => {
    const updatedSkills = [...selectedSecondarySkills];
    updatedSkills[index] = value;
    setSelectedSecondarySkills(updatedSkills);
  };

  const handleAvailabilityChange = (index, value) => {
    const updatedAvailabilities = [...selectedAvailabilities];
    updatedAvailabilities[index] = value;
    setSelectedAvailabilities(updatedAvailabilities);
  };

  const handleCheckboxChange = (index, c_id, firstName) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        // If the index is already in selectedRows, remove it
        return prevSelectedRows.filter((selectedIndex) => selectedIndex !== index);
      } else {
        // If the index is not in selectedRows, add it
        return [...prevSelectedRows, index];
      }
    });
  
    setSelectedCandidates((prevSelectedCandidates) => {
      if (prevSelectedCandidates.includes(c_id)) {
        // If the candidate ID is already in selectedCandidates, remove it
        return prevSelectedCandidates.filter((id) => id !== c_id);
      } else {
        // If the candidate ID is not in selectedCandidates, add it
        return [...prevSelectedCandidates, c_id];
      }
    });
  
    setSelectedCandidatesNames((prevNames) => {
      const newNames = { ...prevNames };
      if (prevNames[c_id]) {
        // If the candidate name is already in selectedCandidatesNames, remove it
        delete newNames[c_id];
      } else {
        // If the candidate name is not in selectedCandidatesNames, add it
        newNames[c_id] = firstName;
      }
      return newNames;
    });
  };
  
 
  

  const handleSaveSelected = (selectedCandidateID, selectedCandidateName, index) => {
   
    console.log('handleSaveSelected function called');
  console.log('Selected Candidate ID:', selectedCandidateID);
  console.log('Selected Candidate Name:', selectedCandidateName);
    selectedCandidate.forEach((selectedCandidateID) => {
      const index = selectedCandidate.indexOf(selectedCandidateID);
  
     

    const payload = {
     podID: parseInt(podid),
    candidateID: selectedCandidateID ||null,
    Candidate_Name: selectedCandidateName || null,
    Pod_Roles: selectedRoles[index] || "",
    Primary_Skill: selectedPrimarySkills[index] || "",
    Secondary_Skill: selectedSecondarySkills[index] || "",
    Availability: selectedAvailabilities[index] || "",
  };
  
      fetch(process.env.REACT_APP_CLIENT_PODROLES, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : token, 
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the API response if needed
          alert('Talent details saved successfully!');
        })
        .catch((error) => {
          // Handle errors
        });
    });
 
  };

  const onImageUpload = e => {

    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
    }
    S3FileUpload.uploadFile(file, config)
      .then(data => {


        setS3img(data.key);


        setPoddata((prevState) => {
          return { ...prevState, picture: data.key };
        });
      })
      .catch(err => {
        console.log(err);
      })

    // setState((prevState) => {
    //   return { ...prevState, [e.target.id]: e.target.value };
    // });
  }


  const renderData = (rep) => {
    return (
      <Pega>
        <Pagination2>
          <div className='paginationcontent'>
            <Num>
              <ol className='pageNumbers' >
                <Num2>
                  <li>
                    <button onClick={handlePrevbtn}
                      disabled={currentPage == pages[0] ? true : false} >
                      Prev
                    </button>
                  </li>
                  <Numbers>{renderPageNumbers} </Numbers>
                  <li>
                    <button onClick={handleNextbtn}
                      disabled={currentPage == pages[pages.length - 1] ? true : false}
                    > Next</button>
                  </li>
                </Num2>
              </ol>
              <Num1>
                <p className='length'> {Candidatedata ? Candidatedata.length : 0} Candidates</p>   <br></br>
              </Num1>
            </Num>
            <ol className='ol'>
            {
  rep && rep.length > 0 ?
    rep.map((dev, index) => (
      <li className='smg' key={index}>
        <div className='name'>
          {dev.profilePicture == "" || dev.profilePicture == null ? (
            <img
              src={defaultimg}
              alt=""
              style={{ width: 50, height: 50, borderRadius: "50px" }}
            ></img>
          ) : (
            <img
              style={{ width: '50px', height: '50px', borderRadius: "50px" }}
              src={'https://globldatauat.s3.amazonaws.com/' + dev.profilePicture}
            ></img>
          )}
          <p className='firstname'>{dev.firstName}</p>
        </div>
        <div className='name2'>
          <img style={{ width: '13px', height: '13px' }} src={star}></img>
          4.5
          <p className='place'>{dev.country}</p>
        </div>
        <div>
          <p className='name1'>{dev.work_position}</p>
        </div>
        <div className='skill'>
          <p className='about'>{dev.about}</p>
        </div>
        <div className='time'>
          <p className='role'>{dev.primaryskill}</p>
          <p className='role'>{dev.secondaryskill}</p>
        </div>
        <Col>
          <p className='time1'>{dev.timezone}</p>
          <p className='role'>{dev.availability}</p>
          <p className='role'>{dev.workhours}</p>
          {dev.currentStatus == "Yes" ? (
            <p className='role' style={{ color: "green" }}>
              Currently Available
            </p>
          ) : (
            <>
              <p className='role' style={{ color: "red" }}>
                Currently Unavailable
              </p>
              <p className='role' style={{ color: "red" }}>
                Available Date : {dev.availabledate}
              </p>
            </>
          )}
          <p className='budget1'>
            {filter(dev.c_id).includes(true) ? (
              <p className='budget1'>Already added to your pod.</p>
            ) : (
              <>
                <button
                  className='sub'
                  onClick={() => {
                    if (countrypod == dev.country.toLowerCase()) {
                      var then = new Date(dev.availabledate);
                      var now = new Date();
                      const msBetweenDates = Math.abs(then.getTime() - now.getTime());
                      const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

                      if (dev.currentStatus == "No" || dev.currentStatus == null) {
                        if (daysBetweenDates < 30) {
                          addCandidate(dev.c_id);
                          handleCheckboxChange(index, dev.c_id,dev.firstName); // Handle checkbox change
                        } else {
                          alert("Sorry, candidate is not available to add to pod!");
                        }
                      } else {
                        addCandidate(dev.c_id, dev.firstName);
                        handleCheckboxChange(index, dev.c_id,dev.firstName); // Handle checkbox change
                      }
                    } else {
                      alert("The candidate you are adding is not in your region!");
                    }
                  }}
                >
                  {selectedCandidates.includes(dev.c_id)
                    ? 'Added to Pod'
                    : 'Add to Pod'}
                </button>
              </>
            )}
          </p>
        </Col>
      </li>
    ))
    : 'No data'
}

            </ol>
          </div>
        </Pagination2>
      </Pega>
    )
  }
  useEffect(() => {
    per();
    setLen(rep.length);


  }, [])
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const per = () => {
    const kk = (mis != 'default') ? mis : '';
    const tk = (mr != 'default') ? mr : '';
    const bd = (bdg != 'default') ? bdg : '';
    const ps = (pri != 'default') ? pri : '';
    const ss = (sec != 'default') ? sec : '';
    const tmz = (tz != 'default') ? tz : '';
    const avb = (avail != 'default') ? avail : '';
    const Prefered = (pref != 'default') ? pref : '';

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization' : token,  },
      body: JSON.stringify({
        "project_location": kk,
        "project_name": Title,
        // "skills" : "",
        "budget": bd,
        "primaryskill": ps,
        "secondaryskill": ss,
        "timezone": tmz,
        "availability": avb,
        "workhours": Prefered,
        "about": mr,
        "client_id": user.data[0].emp_id
      })

    };
    // https://pp0mvip9j1.execute-api.us-east-1.amazonaws.com/dev/browseTalent
    fetch(process.env.REACT_APP_CLIENT_PPODCLIENTFINDTALENT, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setRep(json.data);
        setCandidatedata(json.data)
      });

  }


  function onInputChangesize(val) {
    const selectedValue = val.target.value;

    if (selectedValue !== "") {
      setPodsize(selectedValue);
      setNumRows(parseInt(selectedValue)); // Convert to integer
    } else {
      setPodsize(podSize); // This line might not be necessary
      setNumRows(parseInt(podSize));
    }
  }


  function onInputChangetype(val) {
    setpodtype(val.target.value);

  }
  function onInputChangestack(val) {
    setTechStack(val.target.value);

  }




  const deleteitem = (id, pid) => {

    let confirm = window.confirm("Are you sure you want to remove the candidate!")

    if (confirm == true) {


      let kk = JSON.stringify(id)
      let kk1 = JSON.stringify(pid)
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization' : token, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidateID: kk,
          podID: kk1,
        })
      };
      // console.log(requestOptions)
      //https://dpec67cta3.execute-api.us-east-1.amazonaws.com/dev/deleteCandidatepod_new
      fetch(process.env.REACT_APP_TALENT_DELETECANDIDATEPOD, requestOptions)
        .then(response => {

          response.json();
          // window.location.reload()
        })
        .then(data => {
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!type", data);
        },


        );

    }

  }
  const Addedcandidates = getcandidates.map((data) => {
    if (podSize >= getcandidates.length) {
      return (
        <tr>
          <td style={{ margin: "20px" }}>{data.firstName} </td>
          <td style={{ marginLeft: 'auto', marginTop: '1rem', backgroundColor: "red", borderRadius: "50%", color: 'white', cursor: 'pointer' }} onClick={() => { deleteitem(data.c_id, data.podID) }}   ><CloseIcon /></td>
        </tr>
      )
    }
  });
  
  const podcandidates = Poddata.candidatesid.map((data, i) => {
    return (
      <tr>
        <td style={{ margin: "20px" }}>{data.name}</td>
        <td style={{ marginLeft: 'auto', marginTop: '1rem', backgroundColor: "red", borderRadius: "50%", color: 'white', cursor: 'pointer' }} onClick={() => { Poddata.candidatesid.splice(i, i + 1); setPoddata({ candidatesid: Poddata.candidatesid }) }}><CloseIcon /></td>
      </tr>
    );
  });
  const handleRowClick = (event) => {
    console.log(event.target.rowIndex);
  };
  
  const handleColumnClick = (event) => {
    console.log(event.target.cellIndex);
  };
  const handleCellChange = (event) => {
    console.log(event.target.value);
  };

  const [isCreating, setIsCreating] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const handleCreate = () => {
    setSelectedAction('create');
  };
  
  const handleUpdate = () => {
    setSelectedAction('update');
  };
  

  const [rowCandidates, setRowCandidates] = useState([...Array(numRows)].map(() => ({
    name: "",
    role: "",
    primarySkill: "",
    secondarySkill: "",
    availability: "",
  })));
  const handleRowCandidateChange = (index, field, value) => {
    const updatedRowCandidates = [...rowCandidates];
    updatedRowCandidates[index][field] = value;
    setRowCandidates(updatedRowCandidates);
  };
  
  return (



    <div className="container" style={{ "margin-top": "140px" }}>

      {Addcandidatescreen == false ? (

        <Div>
<Breadcrumbs aria-label="breadcrumb" style={{float:"left"}}>
  <Link to="/FindPodsDetails" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>View Pod</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Manage Pod</span>
</Breadcrumbs>
          <div className="row"style={{display:'flex',flexDirection:'column',justifyContent:"center",alignItems:'center',margin:"auto"}} >
          
          
            <br></br>
           

            <div className="col-lg-11" style={{boxShadow: "0 0 2px 2px #00000029", border: "2px solid #D8D8D8", borderRadius: "16px", opacity: 1 }}>

              <Headingtext1>{intl.formatMessage({ id: 'editPod' })}</Headingtext1>


              <p
                style={{
                  color: "#8EAEBC",
                  marginTop: "2rem",
                  marginBottom: "5rem",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "40px",
                }}
              >
               {intl.formatMessage({ id: 'managePod' })}
              </p>


              {PodStatus == 'active' ? (<>
                <center><p>{intl.formatMessage({ id: 'deactivateToUpdate' })}</p></center>
              </>) : (<>

              

                <center>
                    {Image == "" ? (<div  style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height: "100px", borderRadius: "50px", textAlign: "center" }}>
                    
                        {AuthImage == null || AuthImage == "" ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{ width: '100px', height: '100px', borderRadius: "50px",textAlign: "center" }}
                          ></img>
                        )


                          : (
                          
                            <img src={AuthImage} alt='' style={{ width: '100px', height: '100px',textAlign: "center", borderRadius: "50px" }} id="profilePicture" ></img>

                          )}

<span>
                        <input type="file" style={{ marginLeft: '40px', width: '5rem', height: '3rem', borderRadius: "50px"  }} className="profileclass" onChange={onImageUpload} id="profilePicture" />
                        <EditIcon style={{color: 'white', width:'25px', height: '25px', marginLeft:"-4rem",marginTop:"-1rem", backgroundColor: '#03045e', boxShadow: 'rgb(255, 255, 255) -15px -15px 25px', borderRadius: '50px' }} onChange={onImageUpload}
              />
                      </span>
                      {/* <span style={{marginTop:"-10px",marginLeft:"20px"}}> hhgjh</span> */}

                    </div>) : (<img src={Image} alt='' style={{ width: '100px', height: '100px', marginTop: "30px", borderRadius: "50px" }} id="profilePicture" ></img>)}</center>

                  <br></br>
                  <Postfield>
                    <Input
                      type="text"

                      value={podname}
                      placeholder="Enter Pod Name"
                      onChange={(e) => setPodName(e.target.value)}

                      required
                    />
                  </Postfield>

                  <Postfield>
                    <Input
                      type="text"

                      value={podDescription}
                      placeholder="Enter Pod POD Mission"
                      onChange={(e) => setPodDescription(e.target.value)}

                      required
                    />
                  </Postfield>

                  <Postfield>
                    <Select2
                      onChange={onInputChangetype}
                      id="podtype"
                      placeholder="Pod Type"
                      value={podtype}
                      required
                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Pod Type
                      </option>
                      <option value='SaaS'>SaaS</option>
                        <option value='Web + Mobile App'>Web + Mobile App</option>
                        <option value='Web App'>Web App</option>
                        <option value='Mobile App'>Mobile App</option>
                        <option value='Data & AI'>Data & AI</option>

                    </Select2>
                  </Postfield>
                  <Postfield>
                    <Select2
                      onChange={onInputChangestack}
                      id="techStack"
                      placeholder="Pod Tech Stack"
                      value={techStack}
                      required
                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Pod Tech Stack
                      </option>
                      <option value='MEAN'>MEAN</option>
                      <option value='MERN'>MERN</option>
                      <option value='LAMP'>LAMP</option>
                      <option value='MEVN'>MEVN</option>

                    </Select2>
                  </Postfield>
                  <Postfield>
                    <Select2
                      onChange={onInputChangesize}
                      id="podsize"
                      placeholder="Pod Size"
                      value={podSize}
                      required
                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Pod Size
                      </option>
                      <option value='3'>3</option>
                      <option value='5'>5</option>
                      <option value='7'>7</option>
                      <option value='9'>9</option>
                    </Select2>
                  </Postfield>
                  <Postfield>
                  {user.data[0].manager_type === "PartnerClient" && (

                  <Postfield>
                    <Input
                      type="number"

                      value={pod_rate}
                      placeholder="Enter Pod Rate"
                      onChange={(e) => setPodrate(e.target.value)}

                      required
                    />
                  </Postfield>
                  )}
                  </Postfield>
                  {/* <Postfield>
                    <Select2

                      value={countrypod}
                      placeholder="Country"
                      onChange={(e) => setCountrypod(e.target.value)}

                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Country
                      </option>
                      <option value='usa'>USA</option>
                      <option value='india'>India</option>
                      <option value='canada'>Canada</option>
                    </Select2>
                  </Postfield>
                  <Postfield>
                    <Select2



                      value={timezonepod}
                      placeholder=" Time Zone"
                      onChange={(e) => setTimezonepod(e.target.value)}
                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Time Zone
                      </option>
                      <option style={{ color: "black" }} value='Indian Standard time' >Indian Standard time</option>
                      <option style={{ color: "black" }} value='Eastern standard time' >Eastern standard time</option>
                      <option style={{ color: "black" }} value='Central standard time'>Central standard time</option>
                      <option style={{ color: "black" }} value='Mountain standard time '>Mountain standard time </option>
                      <option style={{ color: "black" }} value='Pacific standard time'>Pacific standard time</option>

                    </Select2>
                  </Postfield> */}


{/* 
                  <Postfield>
                    <CreatePodSkillspage
                      onChange={handleSelectChange3}
                      isMulti
                      options={allergens}
                      placeholder='Skills'
                      value={selected}
                      styles={colourStyles}



                    />
                  </Postfield> */}

                  {/* <Postfield>
          <Select2
            onChange={onInputChangebudget1}
            // value={Poddata.budget}
         
            placeholder="Budget Range"
          >
            <option style={{ color: "black" }} value="" defaultValue>
              Budget Range
            </option>
            <option  value= '100k - 115k' style={{ color: "black" }}>100k - 115k</option>
  <option value='115k - 150k' style={{ color: "black" }}>115k - 150k</option>
          </Select2>
        </Postfield> */}


                
                  {/* <div>
        <Postfield2 onClick={handleCreate}>Create</Postfield2>
        <Postfield2 onClick={handleUpdate}>Update</Postfield2>
      </div> */}
      <div>
  {/* <label htmlFor="actionSelect">Actions:</label> */}
  <Select2
    id="actionSelect"
    value={selectedAction}
    onChange={(e) => setSelectedAction(e.target.value)}
  >
    <option value="">Select an action</option>
    {/* <option value="create">Add Candidates Details</option> */}
    <option value="update">Update POD Team Members </option>
  </Select2>
</div>
<>
{/* {selectedAction === 'create' && (<>

                  <Postfield1>
                    <Canbudtton  >
                      PodCandidates
                    </Canbudtton>




                    {candcount >= podSize ? (

                      <Button2 type="submit" onClick={() => {
                        setAddcandidatescreen(true)

                      }}
                        disabled>

Add Team Member Name

                      </Button2>
                    ) : (<Button2
                      type="submit" onClick={() => {
                        setAddcandidatescreen(true)
                        var candidatelength = candcount + 1
                        setCandcount(candidatelength)
                      }}
                    >
                      Add Team Member Name
                    </Button2>)}

                  </Postfield1>
                  <TableContainer component={Paper}>
  <Table
    component={Paper}
    sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
    aria-label="simple table"
  >
    <TableHead
      sx={{
        backgroundColor: '#00B4D8',
        '& td, & th': {
          fontFamily: 'Poppins,Medium',
          fontSize: '15px',
          color: 'white',
          fontWeight: '600',
        },
      }}
    >
      <TableRow>
      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
        Select
        </TableCell>
        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
        Team Member
        </TableCell>
        
        <TableCell>Role</TableCell>
        <TableCell>Primary Skill</TableCell>
        <TableCell>Secondary Skill</TableCell>
        <TableCell>Availability- Part-time/Full-Time</TableCell>
        <TableCell>Save</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
    {[...Array(numRows)].map((_, index) => (

        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
            '&:nth-child(even)': {
              backgroundColor: '#90E0EF',
            },
            '&:nth-child(odd)': {
              backgroundColor: '#caf0f8',
            },
            '& td, & th': {
              fontFamily: 'Poppins,Medium',
              fontSize: '15px',
              color: 'black',
            },
          }}
        >
          <TableCell>
          <Checkbox
            checked={selectedRows.includes(index)}
            onChange={() => handleCheckboxChange(index)}
          />
        </TableCell>
        <TableCell>
            <SelectField
  id={`candidateDropdown-${index}`}
  value={selectedCandidate[index] || ''}
  onChange={(e) => handleCandidateChange(index, e.target.value)}
>
  <option value="" disabled>
    Select a candidate
  </option>
  {podcandidate && podcandidate.length > 0 ? (
    podcandidate.map((candidate) => (
      <option key={candidate.candidateID} value={candidate.candidateID}>
        {candidate.Candidate_Name}
      </option>
    ))
  ) : (
    <option value="" disabled>
      No candidates available
    </option>
  )}
</SelectField>

        </TableCell>
          




          <TableCell>
             <SelectField 
   value={selectedRoles[index]}
   onChange={(e) => handleRoleChange(index, e.target.value)}   
    >

        <option  value="" defaultValue >Roles</option>
        <option value="Lead" >Lead</option>
        <option  value="Scrum Master">Scrum Master</option>
        <option  value="Product Owner">Product Owner</option>
        <option  value="Developer">Developer</option>
        <option  value="Tester">Tester</option>
        <option  value="DevOps">DevOps</option>
        
        </SelectField> </TableCell>
        <TableCell><SelectField
              
              value={selectedPrimarySkills[index]}
              onChange={(e) => handlePrimarySkillChange(index, e.target.value)}
               
              >

<option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Threejs</option>



              </SelectField> </TableCell>
          <TableCell> 
            <SelectField
             
             value={selectedSecondarySkills[index]}
             onChange={(e) => handleSecondarySkillChange(index, e.target.value)}

              >
              <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>

              </SelectField> </TableCell>
          <TableCell> <SelectField
                 value={selectedAvailabilities[index]}
                 onChange={(e) => handleAvailabilityChange(index, e.target.value)}
          >

                <option style={{ color: 'black' }} value="">Availability</option>
                <option style={{ color: 'black' }} value='full time'>Full Time</option>
                <option style={{ color: 'black' }}  value='part time'>Part Time</option>


              </SelectField></TableCell>
              <TableCell>  
                
                 
              <button onClick={() => {
  let candidateName = "";
  
  if (podcandidate[index] && podcandidate[index].Candidate_Name) {
    candidateName = podcandidate[index].Candidate_Name;
  }

  handleSaveSelected(selectedCandidate[index], candidateName);
}}>

            
<MdSaveAlt/></button>
              </TableCell>
        </TableRow>
      ))}
    </TableBody>

  </Table>
  
</TableContainer> </> )} */}

{selectedAction === 'update' && (<Master podid={podid}  podsize={podSize}/>)}
</>
 {/* {getcandidates.map((data) => {
                    if (podSize >= getcandidates.length) {
                      return (

                        <Postfield>

                          <Select3>

                            <p style={{ margin: "20px" }}>{data.firstName} </p>

                            <p style={{ marginLeft: 'auto', marginTop: '1rem', backgroundColor: "red", borderRadius: "50%", color: 'white', cursor: 'pointer' }} onClick={() => { deleteitem(data.c_id, data.podID) }}   ><CloseIcon /></p>
                          </Select3>

                        </Postfield>

                      )
                    }
                  })}



                  {Poddata.candidatesid.map((data, i) => {

                    return (

                      <Postfield>
                        <Select3>

                          <p style={{ margin: "20px" }}>{data.name}</p>

                          <p style={{ marginLeft: 'auto', marginTop: '1rem', backgroundColor: "red", borderRadius: "50%", color: 'white', cursor: 'pointer' }} onClick={() => { Poddata.candidatesid.splice(i, i + 1); setPoddata({ candidatesid: Poddata.candidatesid }) }}><CloseIcon /></p>
                        </Select3>

                      </Postfield>
                    )
                  })}  */}
                 
 


<br></br>


                  <center>
                    {/* <NavLink to='/PodCreatedSuccessFullyCan'> */}

                    <Button
                      type="submit"
                      onClick={handleSubmitOptions}
                    >
                      {intl.formatMessage({ id: 'update' })}
                    </Button>
                    {/* </NavLink> */}
                  </center>

             
              </>)}

            </div>
          </div>

        </Div>) : (<>



          <Browsecontainer>
            <div  >
              <Heading>
                <h3 style={{ fontSize: '1.625rem', marginLeft: '5rem' }}>Candidate-Pod Lead-Talent Search</h3>
              </Heading>
              <br></br>
              <div  >  <Para> <p style={{ color: '#A2A2A2', fontSize: ' 1.125rem' }}>Find the best freelancers or talent pods to get your projects delivered at a faster pace!</p>
              </Para>
              </div>
              <br></br>
              <br></br>


              <Main>
                {/* <div className='center'> */}
                <Table1>
                  <div className='singlecontainer'>
                    <Singlecontainer>
                      <td className='mis'>
                        <SearchTag>
                          <SearchOutlinedIcon style={{ marginTop: '15px', marginLeft: '20px', margin: 'auto', color: '#7E7E7E' }} />
                          <input className='jobsearch' type='text' placeholder='Keyword' value={mr} onChange={((val) => setMr(val.target.value), handleUserInput)} />
                        </SearchTag>
                      </td>
                      {/* <td className='mis1' >
                  <Selecttag>
                    <select className='select'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                      <option value='default'>Location</option>
                      <option value='usa'>USA</option>
                      <option value='india'>India</option>
                      <option value='canada'>Canada</option>
                    </select>
                    </Selecttag>
                  </td> */}
                      <td >  <Clear><p style={{ marginTop: '20px', width: '6rem', marginLeft: '31rem', cursor: 'pointer', fontWeight: "600", fontSize: '18px', fontFamily: 'Poppins,Regular' }} onClick={() => { resetInputField(); resetJobField(); resetBudgetField(); resetPrimaryField(); resetSecondaryField(); resetTimezoneField(); resetPreferField(); resetAvailableField(); resetTitleField() }}
                      >Clear</p></Clear>  </td>
                      <Search>
                        <td  ><button style={{ marginTop: '10px', width: "10rem", height: '2.6rem', color: 'white', border: '1px solid  #1DBF73', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73', boxShadow: '1px 6px 9px #00000029', fontFamily: 'Poppins,Regular', fontWeight: "500" }} onClick={() => per()}>Search</button></td>
                      </Search>
                    </Singlecontainer>
                  </div>
                  {/* <div className='main'> */}
                  <Row>
                    {/* <tr> */}
                    
                    <Row1>
                      <td><select className='options' value={pri} onChange={((val) => setPri(val.target.value), primaryfield)} >
                      <option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Threejs</option>
                      </select></td>
                      
<td><select
             className='options' 
             value={sec} onChange={((val) => setSec(val.target.value), secondaryfield)} 
              >
              <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>

              </select></td>
                    </Row1>
                    <Row2>
                      <td >
                        <select className='options' value={avail} onChange={((val) => setAvail(val.target.value), available)}>
                          <option value='default'>Availability</option>
                          <option value='parttime'>Part Time</option>
                          <option value='fulltime' >Full Time</option>
                        </select>
                      </td>
                      <td><select className='options' value={tz} onChange={((val) => setTz(val.target.value), timezonefield)}>
                        <option value='default' >Time Zone</option>
                        <option value='Indian Standard time' >Indian Standard time</option>
                        <option value='Eastern standard time' >Eastern standard time</option>
                        <option value='Central standard time'>Central standard time</option>
                        <option value='Mountain standard time '>Mountain standard time </option>
                        <option value='Pacific standard time'>Pacific standard time</option>
                      </select></td>
                    </Row2>
                    <Row2>
                      <td >
                        <select className='options' value={pref} onChange={((val) => setPref(val.target.value), preferfield)}>
                          <option value='default' >Prefered Work Hours</option>
                          <option value='Morning Shift 9am-6pm'>Morning Shift 9am-6pm</option>
                          <option value='Night Shift 10pm - 7am'>Night Shift 10pm - 7am</option>
                        </select>
                      </td>
                      <td >
                        <select className='options' value={Title} onChange={((val) => setTitle(val.target.value), jobtitle)}>

                          <option value='default' >Job title</option>
                          <option value='Software Engineer'>Software Engineer</option>
                          <option value='Full Stack Developer'>Full Stack Developer</option>
                          <option value='Java Developer'>Java Developer</option>

                        </select>
                      </td>

                    </Row2>
                    <tr>
                      <td >
                        <select className='options' value={mis} onChange={((val) => setMis(val.target.value), handleJob)} >
                          <option value='default'>Location</option>
                          <option value='usa'>USA</option>
                          <option value='india'>India</option>
                          <option value='canada'>Canada</option>
                        </select>
                      </td>
                    </tr>
                    <tr></tr>
                    {/* </tr> */}
                    <tr> <td >
                      <button className='optionsaplly' style={{ width: "10rem", height: '2rem', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#1DBF73', boxShadow: '1px 6px 9px #00000029', fontFamily: 'Poppins,Regular', fontWeight: "500" }} onClick={() => per()}>Apply Filters</button>
                    </td></tr>
                  </Row>
                  {/* </div> */}
                </Table1>
              </Main>
              <div>
                {renderData(currentItems)}
              </div>
            </div>
          </Browsecontainer>


        </>)}

    </div>



  )
}

export default ClientManagePod;
const Div = styled.div`

display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin:auto;

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin:auto;
  
 }
`;
const SelectField=styled.select`
margin-top:10px;
border: none;
text-decoration:none;
width:10rem;
height:3.1rem;
font-weight:600;

background:none !important;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
margin:auto;
  
 }
`;
const Divpopup = styled.div`
   display:flex;

  jutify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;



const CardContentpopup = styled.div`
display:flex;

  jutify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0.4rem;
  width: 30rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    display:flex;

  jutify-content: center;
  flex-direction: column;
  margin: auto;
  }
`;

const Buttonpopup = styled.button`

font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:18rem;
border:none;
margin-top:2rem;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const InputFieldpopup = styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:1.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;
const TitleTextpopup = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const CreatePodScreen = styled.div`
  display: flex;
  flex-direction: column;
  font-family:Poppins,Medium;
  margin-left:360px;
  justify-content: center;
  align-items: center;
  // margin: auto;
  margin-top:5rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction: column;
    margin:auto;
    }

`;
const Container1 = styled.div`
font-family:Poppins,Medium;
    width: 60rem;
    height: max-height;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:25rem;
        height:max-height;
        }
`;


const Select2 = styled.select`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:15rem;
        height:max-height;
        }
`;

const Select3 = styled.p`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
display:flex;
// flex-direction:row;
justify-content:center;
align-items:center;

border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:15rem;
        height:max-height;
        }
`;
const Multi = styled.div`
font-family:Poppins,Medium;
// width: 22rem;
// height:50px;
// border-radius: 10px;
// border: 2px solid #B7B7B7;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Button = styled.button`
font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:15rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:15rem;
        height:max-height;
        }
`;


const Button2 = styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:max-width;
        margin-left:2rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;

const Canbudtton = styled.span`
margin-left:-5.5rem;
@media screen and (min-width:320px) and (max-width:1080px){

  margin:auto;
  }

`;
const Postfield = styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px
`;


const Postfield2 = styled.button`
font-family:Poppins,Medium;
    background-color: #90E0EF;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        margin-left:2rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
        `;
const Postfield1 = styled.span`
font-family:Poppins,Medium;
color: #8EAEBC;
font-weight: bold;
 font-size:18px;
display:flex;
justify-content:center;
align-items:center;
margin-left:5rem;
padding:10px
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        display:flex;
        flex-direction:column;
        margin:auto;
        }
`;

const Input = styled.input`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:20px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:15rem;
        height:max-height;
        }
`;
const Headingtext1 = styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-top: 7rem;
    }
`;

const Browsecontainer = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:100%;
display:flex;
align-items:center;
 justify-content:center;
flex-direction:column;
}
`;
const Main = styled.tr`

@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
margin:auto;
width:100%;
}
`;
const Table1 = styled.table`
margin: auto;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
 justify-content:center;
flex-direction:column;
margin:auto;
width:20rem;
height:max-content;
}
`;
const Heading = styled.div`
margin-top: 9rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:70%;
margin-top:10rem;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Para = styled.p`
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
width:20rem;
margin:auto;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Singlecontainer = styled.tr`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
margin:auto;
width:20rem;

}
`;
const Row = styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;

const SearchTag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
width:20rem;
margin:auto;
border-bottom:1px solid #A2A2A2;
}
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  margin:auto;
 width:20rem;
  border-bottom:1px solid #A2A2A2;
}
`;
const Clear = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
 flex-direction:column;
 margin:none;
}
`;
const Search = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
width:100%;
align-items:center;
 flex-direction:column;
 margin-bottom:20px;
}
`;
const Row1 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Row2 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
// const Button = styled.button`
// @media screen and (min-width:320px) and (max-width:1080px){
//   display:flex;
// margin:auto;
//  flex-direction:column;
// }
// `;
const Pagination2 = styled.div`

height: max-content;
margin: auto;
  width:83.5rem;
 
  background-color: white;

margin-top: 3rem;
 border:1px solid #EFEFEF;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:20rem;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
margin-top:3rem;

}
`;
const Pega = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
width:100%;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
}
`;
const Numbers = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
justify-content:center;
width:7rem;
}
`;
const Num = styled.ol`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
//   align-items:center;
// justify-content:center;
flex-direction:column;
marginLeft:-3rem;
width:70%;
}
`;
const Num1 = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  // display:flex;
  margin:auto;
width:100%;
}
`;
const Num2 = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  flex-direction:column;
  align-items:center;
justify-content:center;
width:100%;
}
`;
const Col = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: row;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;

const TextDiv = styled.div`
display:flex;
flex-direction:row;




`;

const Buttonadd = styled.button`
tex-decoration:none;
border:none;
display:flex;
margin-left:10px;
border-radius:2px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  width:2rem;
  height:1rem;
justify-content:center;
align-items:center;

margin-top:0.5rem;
color:white;
background-color:#00B4D8;

`;

const ButtonRemove = styled.button`
display:flex;
align-items:center;
justify-content:center;
text-decoration:none;
border:none;
border-radius:2px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:2rem;
height:1rem;
margin-top:0.5rem;
margin-left:10px;
color:white;
background-color:#00B4D8;

`;
const ButtonRemovepopup = styled.button`
display:flex;
align-items:center;
justify-content:center;
text-decoration:none;
border:none;
border-radius:2px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:2rem;
height:1.5rem;

margin-left:auto;
color:red;

`;
const ManageButton = styled.button`
display:flex;
justify-content:center;
align-items:center;
margin-left:15rem;
padding:5px;
width:10rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  margin-left:5rem;
  width:5rem;
  height:3rem;
  font-size:12px;
 }



`;