import React, { useEffect, useState, useRef } from "react";
import { Chart, registerables } from "chart.js";
import "chartjs-plugin-annotation";
import "./BarGraph.css";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const DetailbitbucketPRcountTalent = ({ initialPodIds }) => {
  const [metrics, setMetrics] = useState(null);
  const [talentUsers, setTalentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const chartId = useRef(uuidv4());
  const chartInstance = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  

  useEffect(() => {
    Chart.register(...registerables);
    
      fetchMetricsPod(initialPodIds);

  }, [token, user.data[0].type, initialPodIds]);

  useEffect(() => {
    if (metrics) {
      displayChart();
    }
  }, [metrics, selectedUser]);

  const fetchMetricsPod = async (selectedPodIds) => {
    try {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      const response = await fetch(
        process.env.REACT_APP_TALENT_PULLREQUESTMETRICSTALENT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            talent_id: user.data[0].c_id,
            pod_id: selectedPodIds || null,
          }),
        }
      );

      const data = await response.json();
      console.log("API Response:", data);

      if (response.status === 500) {
        setErrorMessage("No Data Available");
      } else if (data?.body) {
        const responseJSON = data.body;
        setMetrics(responseJSON);
        setTalentUsers(responseJSON.talent_users); // Set talentUsers from response
      }
    } catch (error) {
      console.log("Error fetching metrics:", error);
    }
  };

  const displayChart = () => {
    if (chartId.current) {
      const ctx = document.getElementById(chartId.current);

      if (metrics) {
        if (selectedUser) {
          // Find the selected user in talentUsers
          const user = talentUsers.find(
            (u) => u.bitbucket_user === selectedUser
          );
          if (user) {
            // User-specific data
            const chartData = {
              labels: ["Submitted PRs", "Approved PRs"],
              datasets: [
                {
                  data: [user.submitted_pr_count, user.approved_pr_count],
                  backgroundColor: ["#D3D3D3", "#32CD32"],
                },
              ],
            };

            if (chartInstance.current) {
              chartInstance.current.destroy();
            }

            chartInstance.current = new Chart(ctx, {
              type: "doughnut",
              data: chartData,
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  annotation: {},
                  legend: {
                    display: false,
                  },
                },
                layout: {
                  padding: {
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 10,
                  },
                },
                tooltips: {
                  enabled: true,
                  mode: "single",
                  callbacks: {
                    label: function (tooltipItem, chartData) {
                      const dataIndex = tooltipItem.dataIndex;
                      const datasetIndex = tooltipItem.datasetIndex;
                      const value =
                        chartData.datasets[datasetIndex].data[dataIndex];
                      const label = chartData.labels[dataIndex];
                      return `${label}: ${value}`;
                    },
                  },
                },
                onClick: () => {
                  openModal();
                },
              },
            });
          }
        } else {
          // Default data based on total_prs
          const totalPrs = metrics.total_prs;

          const chartData = {
            labels: ["Total Submitted PRs", "Total Approved PRs"],
            datasets: [
              {
                data: [
                  totalPrs.total_submitted_prs,
                  totalPrs.total_Approved_prs,
                ],
                backgroundColor: ["#D3D3D3", "#32CD32"],
              },
            ],
          };

          if (chartInstance.current) {
            chartInstance.current.destroy();
          }

          chartInstance.current = new Chart(ctx, {
            type: "doughnut",
            data: chartData,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                annotation: {},
                legend: {
                  display: false,
                },
              },
              layout: {
                padding: {
                  top: 10,
                  bottom: 10,
                  left: 10,
                  right: 10,
                },
              },
              tooltips: {
                enabled: true,
                mode: "single",
                callbacks: {
                  label: function (tooltipItem, chartData) {
                    const dataIndex = tooltipItem.dataIndex;
                    const datasetIndex = tooltipItem.datasetIndex;
                    const value =
                      chartData.datasets[datasetIndex].data[dataIndex];
                    const label = chartData.labels[dataIndex];
                    return `${label}: ${value}`;
                  },
                },
              },
              onClick: () => {
                openModal();
              },
            },
          });
        }
      }
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const hasData =
    metrics &&
    (metrics.total_prs.total_submitted_prs !== 0 ||
      metrics.total_prs.total_Approved_prs !== 0);

  let chartContent;
  if (metrics) {
    if (hasData) {
      chartContent = (
        <canvas id={chartId.current} width="150" height="150"></canvas>
      );
    } else {
      chartContent = <p>{intl.formatMessage({ id: "noDataAvailable" })}</p>;
    }
  } else {
    chartContent = <p>{intl.formatMessage({ id: "noDataAvailable" })}</p>;
  }

  return (
    <div className="chart-container">
     
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 className="chart-heading">
          {intl.formatMessage({ id: "prCount" })}
          </h2>
           {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (<>
          <br />
          {initialPodIds ? (
            <div>
              <select
                style={{ width: "12rem" }}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                  displayChart();
                }}
              >
                <option value="">
                  {intl.formatMessage({ id: "allReporters" })}
                </option>
                {talentUsers?.map((user) => (
                  <option key={user.bitbucket_user} value={user.bitbucket_user}>
                    {user.bitbucket_user}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}

          <div className="chart-wrapper">{chartContent}</div>
          </>  )} </div>
    

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PR Summary Modal"
        style={{
          content: {
            width: "50%",
            maxWidth: "800px",
            margin: "auto",
            marginTop: "5rem",
          },
        }}
      >
        <h6>{intl.formatMessage({ id: "prSummary" })}</h6>
        <TableContainer component={Paper}>
          <Table
            component={Paper}
            sx={{
              maxWidth: 900,
              fontFamily: "Poppins,Medium",
              borderRadius: "10px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#00B4D8",
                "& td, & th": {
                  fontFamily: "Poppins,Medium",
                  fontSize: "15px",
                  color: "white",
                  fontWeight: "600",
                },
              }}
            >
              <TableCell align="right">
                {intl.formatMessage({ id: "githubUser" })}
              </TableCell>
              <TableCell align="right">
                {intl.formatMessage({ id: "submittedPRCount" })}
              </TableCell>
              <TableCell align="right">
                {intl.formatMessage({ id: "approvedPRCount" })}
              </TableCell>
              <TableCell align="right">
                {intl.formatMessage({ id: "totalPRCount" })}
              </TableCell>
            </TableHead>
            <TableBody>
              {talentUsers?.map((project, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontFamily: "Poppins,Medium",
                      fontSize: "15px",
                    },
                    "&:nth-child(even)": {
                      backgroundColor: "#90E0EF",
                    },
                    "&:nth-child(odd)": {
                      backgroundColor: "#caf0f8",
                    },
                    "& td, & th": {
                      fontFamily: "Poppins,Medium",
                      fontSize: "15px",
                      color: "black",
                    },
                  }}
                >
                  <TableCell align="right">{project.bitbucket_user}</TableCell>
                  <TableCell align="right">
                    {project.submitted_pr_count}
                  </TableCell>
                  <TableCell align="right">
                    {project.approved_pr_count}
                  </TableCell>
                  <TableCell align="right">{project.total_pr_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <button onClick={closeModal}>
          {intl.formatMessage({ id: "done" })}
        </button>
      </Modal>
    </div>
  );
};

DetailbitbucketPRcountTalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default DetailbitbucketPRcountTalent;
