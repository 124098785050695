import React, { useEffect, useState, useRef } from "react";
import { Chart, registerables } from "chart.js";
import Modal from "react-modal";
import axios from "axios";
import './Popup.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {  CategoryScale, Title, Tooltip } from 'chart.js';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

Modal.setAppElement("#root");

const ProjectPieCharttalent = ({initialPodIds}) => {
  const [projectData, setProjectData] = useState([]); // Initialize as an empty array
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedPodId, setSelectedPodId] = useState('');
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {

      fetchProjectDetails();
    
  }, [initialPodIds,token]);



  

  const fetchProjectDetails = async () => {
    const headers = {
     
      'Content-Type': 'application/json', 
      'Authorization': token,// You can add more headers as needed
    };
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await axios.post(
        process.env.REACT_APP_TALENT_SONARRUNSMETRICSTALENT,
        {
            c_id: user.data[0].c_id ,
            pod_id: initialPodIds ||[],
        },  {headers:headers}
      );
      const data = JSON.parse(response.data.body);
      setProjectData(data);
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  useEffect(() => {
    if (projectData) {
      renderChart();
    }
  }, [projectData]);

  const renderChart = () => {
    if (!projectData || !Array.isArray(projectData) || projectData.length === 0) {
      console.log('No data available');
      return;
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext("2d");

    const repoLabels = projectData.map((project) => project.github_repo);
    const repoData = projectData.map(() => 1);

    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#FF5733",
      "#44A7EB",
      "#EFC656",
      // Add more colors as needed
    ];

    chartRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels: repoLabels,
        datasets: [
          {
            data: repoData,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // Add the event listener to the canvas element
    canvasRef.current.addEventListener("click", handleChartClick);
  };

  const handleChartClick = (event) => {
    console.log("Clicked on chart"); // Log to check if the click event is firing
    const chart = chartRef.current;

    if (chart) {
      const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
      console.log("Elements:", elements); // Log the elements array
      if (elements.length > 0) {
        const segment = elements[0];
        const datasetIndex = segment.datasetIndex;
        const index = segment.index;
        const validProjects = projectData.filter(project => project.github_repo);
        if (validProjects.length > 0) {
          const selectedRepoName = validProjects[datasetIndex].github_repo;
          console.log("Selected Repo:", selectedRepoName);
          setSelectedRepo(selectedRepoName);
          setModalIsOpen(true);
        } else {
          console.error("No valid project found for the clicked segment.");
        }
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedRepo(null);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'sonarqubeStats' })}</h2>
        <br />  <br/>
        <div className="chart-wrapper" style={{width:"150px",height:"150px",margin:"auto",textAlign:"center",marginLeft:"2rem"}}>
          <canvas ref={canvasRef} width="150" height="150"></canvas>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Details Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem'
          },
        }}
      >
        {selectedRepo && (
          <div>
     <h3>{intl.formatMessage({ id: 'detailsForGitHubRepo' })}{selectedRepo}</h3>
            <TableContainer component={Paper}>
              <Table component={Paper}
                sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
                aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: '#00B4D8',
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'white',
                      fontWeight: '600'
                    },
                  }}
                >
                   <TableCell align="right">{intl.formatMessage({ id: 'projectKey' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'ncloc' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'bugs' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'vulnerabilities' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'codeSmells' })}</TableCell>
            <TableCell align="right">{intl.formatMessage({ id: 'duplicatedLinesDensity' })}</TableCell>
         </TableHead>
                <TableBody>
                  {projectData && (
                    projectData.map((project, index) => (
                   
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                            '&:nth-child(even)': {
                              backgroundColor: '#90E0EF'
                            },
                            '&:nth-child(odd)': {
                              backgroundColor: '#caf0f8'
                            },
                            '& td, & th': {
                              fontFamily: 'Poppins,Medium',
                              fontSize: '15px',
                              color: 'black',
                            },
                          }}
                        >
                          <TableCell align="right">{project.project_key}</TableCell>
                          <TableCell align="right">{project.ncloc}</TableCell>
                          <TableCell align="right">{project.bugs}</TableCell>
                          <TableCell align="right">{project.vulnerabilities}</TableCell>
                          <TableCell align="right">{project.code_smells}</TableCell>
                          <TableCell align="right">{project.duplicated_lines_density}</TableCell>
                        </TableRow>
                      
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <button onClick={closeModal}> {intl.formatMessage({ id: 'done' })}</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ProjectPieCharttalent;