import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
const Popupstakeholdermanager = (props) => {
  const [stakeholderList, setStakeholderList] = useState([]);
  const [selectedStakeholders, setSelectedStakeholders] = useState([]);
  const { user } = useSelector(state => state.auth);

  
  const token = useAuthToken();


  useEffect((token) => {
    fetchStakeholderList();
    fetchPreFetchedStakeholders();
  }, [token]);

  const fetchStakeholderList = () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    axios.post(process.env.REACT_APP_CLIENT_GETSTAKEHOLDERLIST, { "client_code": user.data[0].client_code },{headers:headers})
      .then(response => {
        console.log('Stakeholder List Response:', response.data);
        if (response.data.data === "There Are No stakeholders for this pod") {
          setStakeholderList([]); // Set stakeholderList to an empty array if message received
        } else {
          setStakeholderList(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching stakeholder list:', error);
      });
  };
  

  const fetchPreFetchedStakeholders = () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Fetch pre-fetched stakeholder data
    //https://kghk0o0dv2.execute-api.us-east-1.amazonaws.com/dev/getPodStakeholderslist
    axios.post(process.env.REACT_APP_CLIENT_GETPODSTAKEHOLDERLIST, { "podID": props.pod_id },{headers:headers})
      .then(response => {
        setSelectedStakeholders(response.data.data.map(stakeholder => ({
          value: stakeholder.stakeholderID,
          label: stakeholder.stakeholdername
        })));
      })
      .catch(error => {
        console.error('Error fetching pre-fetched stakeholders:', error);
      });
  };


  const handleRemoveStakeholder = (stakeholder) => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Call the delete API to remove the stakeholder
    //https://mxo58mihgg.execute-api.us-east-1.amazonaws.com/dev/deleteStakeholder
    axios.post(process.env.REACT_APP_CLIENT_DELETESTAKEHOLDER, {
      stakeholder_id: stakeholder.value
    }, {headers:headers})
    .then(() => {
      console.log('Stakeholder deleted successfully');
      // Refresh the stakeholder list
      fetchStakeholderList();
      // Remove the deleted stakeholder from the selectedStakeholders state
      setSelectedStakeholders(selectedStakeholders.filter(item => item.value !== stakeholder.value));
    })
    .catch(error => {
      console.error('Error deleting stakeholder:', error);
    });
  };
  const handleSaveStakeholders = () => {
    const payload = {
      podID: props.pod_id,
      stakeholdersid: selectedStakeholders.map(stakeholder => ({
        stakeholder_id: stakeholder.value,
        label: stakeholder.label
      }))
    };
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Call API to save selected stakeholders
    //https://8rm4qxoool.execute-api.us-east-1.amazonaws.com/dev/addStakeholder
    axios.post(process.env.REACT_APP_CLIENT_ADDSTAKEHOLDER, payload, {headers:headers})
      .then(() => {
        console.log('Stakeholders saved successfully');
        alert('Stakeholders saved successfully')
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(error => {
        console.error('Error saving stakeholders:', error);
      });
  };

  return (
    <div>
    <Selectmulti
      isMulti
      options={stakeholderList.length > 0
        ? stakeholderList.map(stakeholder => ({
          value: stakeholder.stakeholder_id,
          label: stakeholder.stakeholder_firstname
        }))
        : [{ value: 'no-stakeholders', label: 'No  stakeholders available for this pod' }] // Render the pod-specific message
      }
      value={selectedStakeholders}
      onChange={setSelectedStakeholders}
      components={{
        MultiValueRemove: ({ innerProps, data }) => (
          <span onClick={() => handleRemoveStakeholder(data)}>&times;</span>
        )
      }}
      closeMenuOnSelect={false}
    />
    <Button2 onClick={handleSaveStakeholders}>Save</Button2>
  </div>
  );
};

export default Popupstakeholdermanager;
const Selectmulti = styled(Select)`
font-family:Poppins,Medium;
width: 22rem;
.select__control {
  border-radius: 10px;
  border: 2px solid #B7B7B7 !important;
  padding: 8px;
  color:grey;
font-weight:600;
height:50px;
}

.select__option {
  padding: 6px;
  border-radius: 10px;
  border: 2px solid #B7B7B7;
}



    @media screen and (min-width:320px) and (max-width:1080px){
        width:15rem;
        height:max-height;
        }
`;
const Button2 = styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;