import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import {  useSelector } from "react-redux";
import { useAuthToken  } from '../TokenContext';
import { Breadcrumbs } from '@mui/material';
import PortfolioModal from './PortfolioModal';
import { FaImage } from 'react-icons/fa'; // Import the image icon from the Font Awesome library

const Portfolio = () => {
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();

  const [activeTab, setActiveTab] = useState('create'); // 'create' or 'view'
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [portfolioDataFetched, setPortfolioDataFetched] = useState(false);

  const [modalImageUrl, setModalImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };
  

  const closeModal = () => {
    setModalImageUrl(null);
  };
  const [newItem, setNewItem] = useState({
    projectObjective: '',
    projectDescription: '',
    industry: '',
    images: [],
  });
  useEffect(() => {
    if (activeTab === 'view') {
      console.log('Fetching portfolio data...');
      fetchPortfolioData();
    }
  }, [activeTab,token]);

  const fetchPortfolioData = async () => {
    try {
      setLoading(true);
      const portfolioUserId =
        user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
      const response = await fetch(process.env.REACT_APP_TALENT_LISTPORTFOLIO, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolio_user_id: portfolioUserId,
        }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Response Data Body:', responseData.body); // Log responseData.body
        setPortfolioItems(responseData.body);
        setPortfolioDataFetched(true);
        console.log('Portfolio Items:', portfolioItems); // Log portfolioItems after setting
      } else {
        console.error('Error fetching portfolio data:', response.statusText);
        // Handle other errors here
        setPortfolioDataFetched(false);
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      // Handle other errors here
    } finally {
      setLoading(false);
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === portfolioItems.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? portfolioItems.length - 1 : prevIndex - 1));
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prevItem) => ({ ...prevItem, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 3) {
      alert('You can upload up to 3 images only.');
      return;
    }

    setNewItem((prevItem) => ({ ...prevItem, images: files }));
  };

  const handleAddItem = async () => {
    // Check if required fields are not empty
    if (!newItem.projectObjective || !newItem.projectDescription || !newItem.industry) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      // Upload images to S3 and get image URLs
      const imageUrls = await Promise.all(newItem.images.map(uploadImageToS3));
      const portfolioUserId = user.data[0].type === "Provider"
        ? user.data[0].id
        : user.data[0].c_id;
  
      // Prepare payload for API
      const payload = {
        portfolio_user_id: portfolioUserId,
        project_objective: newItem.projectObjective,
        project_description: newItem.projectDescription,
        industry: newItem.industry,
        project_files: imageUrls,
      };
  
      // Make POST request to the API
      const response = await fetch(process.env.REACT_APP_TALENT_CREATEPORTFOLIO, {
        method: 'POST',
        headers: {
          "Authorization": token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.statusCode === 400 && responseData.body === "Already 5 row values exist for this portfolio_user_id.") {
          alert("You can only create a maximum of 5 portfolio items. You have already created the maximum allowed.");
        } else {
          const portfolioItem = {
            ...newItem,
            images: imageUrls,
          };
  
          setPortfolioItems((prevItems) => [...prevItems, portfolioItem]);
          setNewItem({
            projectObjective: '',
            projectDescription: '',
            industry: '',
            images: [],
          });
  
          alert('Portfolio item added successfully.');
          fetchPortfolioData();
        }
      } else {
        // Handle API error
        console.error('Error adding portfolio item:', response.statusText);
        alert('Error adding portfolio item. Please try again.');
      }
    } catch (error) {
      console.error('Error adding portfolio item:', error);
      alert('Error adding portfolio item. Please try again.');
    }
  };
  
  

  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  });

  const s3 = new AWS.S3();
  const uploadImageToS3 = async (imageFile) => {
    const imageKey = `portfolio-images/${uuidv4()}.png`;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKETNAME,
      Key: imageKey,
      Body: imageFile,
      ContentType: 'image/png',
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      return uploadResult.Location;
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      throw error;
    }
  };

  return (
    <Container>
      <BreadcrumbsContainer>
      <Breadcrumbs aria-label="breadcrumb">
  <Link to="/manage-profile" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Manage Profile</Link>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
  <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Portfolio</span>
</Breadcrumbs>


      </BreadcrumbsContainer>
      <Tabs>
        <TabButton onClick={() => setActiveTab('create')} active={activeTab === 'create'}>
          Create Portfolio
        </TabButton>
        <TabButton onClick={() => setActiveTab('view')} active={activeTab === 'view'}>
          View Portfolio
        </TabButton>
      </Tabs>

      {activeTab === 'create' && (
  <AddPortfolioItem>
    <h5>Add Portfolio Item</h5>
    <Input
      type="text"
      name="projectObjective"
      placeholder="Project Objective"
      maxLength={100}
      value={newItem.projectObjective}
      onChange={handleInputChange}
    />
    <Textarea
      name="projectDescription"
      maxLength={300}
      placeholder="Project Description"
      value={newItem.projectDescription}
      onChange={handleInputChange}
    />
    <Selectfield
      name="industry"
      value={newItem.industry}
      onChange={handleInputChange}
    
    >
      <option value="">Select Industry</option>
      <option value="IT & Software">IT & Software</option>
      <option value="healthcare">Healthcare</option>
      <option value="Banking and Financial">Banking and Financial</option>
      <option value="Services">Services</option>
    </Selectfield>
    <ImageInput
      type="file"
      name="images"
      accept="image/*"
      multiple
      onChange={handleImageUpload}
    />
    <Button1 onClick={handleAddItem}>Add Item</Button1>
  </AddPortfolioItem>
)}


   

      
{activeTab === 'view' && (
  <PortfolioList>
     {loading ? (
        <p>Loading...</p>
      ) : !Array.isArray(portfolioItems) || portfolioItems.length === 0 ? (
        <p>No portfolio records found.</p>
      ) : (
      <SlideshowContainer>
        <SlideshowImageContainer>
          {portfolioItems.map((item, index) => (
            <Slide key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
              <div>
                <h3> {item.project_objective}</h3>
                <p>{item.project_description}</p>
                <p>Industry: {item.industry}</p>
              </div>
              <ImageGallery>
                {item.project_files.map((image, imageIndex) => (
                  <Card key={imageIndex}>
                   {image ? (
                      <img
                        src={image}
                        alt={`Image ${imageIndex + 1}`}
                        style={{ width: '100%', height: '100%', borderRadius: '8px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(image)}
                      />
                    ) : (
                      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <FaImage style={{ fontSize: '50px', color: '#ccc' }} />
                    </div>
                    )}
                  </Card>
                ))}
                
              </ImageGallery>
              {/* {modalImageUrl && (
  <PortfolioModal imageUrl={modalImageUrl} onClose={closeModal} />
)} */}

            </Slide>
          ))}
       
        </SlideshowImageContainer>
        <Button prev onClick={prevSlide} disabled={currentIndex === 0}>&#10094;</Button>
          <Button next onClick={nextSlide} disabled={currentIndex === portfolioItems.length - 1}>&#10095;</Button>
      </SlideshowContainer>
    )}
       
  </PortfolioList>
)}

    </Container>
  );
};

// Styled components remain the same

export default Portfolio;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Updated to align items to the start */
  justify-content: center;
  margin: auto;
  margin-top:10rem;
  width:80%;

`;

const Card = styled.div`
border:1px solid rgba(0, 0, 0, 0.07);;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  width: 45%; /* Adjust width to fit in the column */
  height:90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

`;
const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;

`;

const SlideshowImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Slide = styled.div`
  display: none;
  text-align: center;
  padding: 20px;
`;

const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SlideshowImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px; /* Adjust margin as needed */
`;


const Button1 = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
 
  background-color: none;
  text-decoration:none;
  color: black;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  ${(props) => (props.prev ? 'left: 0;' : 'right: 0;')}
`;



const BreadcrumbsContainer = styled.div`
margin:auto;
  position: absolute;
  top: 150px;
  left: 20px;
 
`;


const AddPortfolioItem = styled.div`
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  width: 30rem;

  h2 {
    margin-bottom: 1rem;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.010);
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
 
  }
`;
const Tabs = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.active ? '#00B4D8' : '#90E0EF')};
  color: ${(props) => (props.active ? 'white' : 'white')};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
`;

const Input = styled.input`
width: 28rem;
height:3rem;
padding: 0.5rem;
margin-bottom: 1rem;
border:1px solid #ccc;
border-radius:5px;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width: 18rem;
`;

const Selectfield = styled.select`
  width: 28rem;
  height:3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border:1px solid #ccc;
  border-radius:5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
 
  }
`;

const Textarea = styled.textarea`
width: 28rem;
height:5rem;
padding: 0.5rem;
margin-bottom: 1rem;
border:1px solid #ccc;
border-radius:5px;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width: 18rem;
`;

const ImageInput = styled.input`
  margin-bottom: 1rem;
`;


const PortfolioList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  background-color: rgba(0, 0, 0, 0.03); 
  h2 {
    margin-bottom: 1rem;
  }
  border:1px solid rgba(0, 0, 0, 0.08);
  border-radius:5px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  `;

const PortfolioItem = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 150px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const Details = styled.div`
  padding: 1rem;
  flex-grow: 1;

  h3 {
    color: #4caf50;
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 16px;
    color: #555;
  }

  .description {
    color: #777;
    font-weight: bold;
  }

  .industry {
    color: #999;
    font-style: italic;
  }
`;

