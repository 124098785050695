import styled from "styled-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {React, useState,useEffect } from "react";
import {  useSelector } from "react-redux";
import axios from "axios";
import { NavHashLink } from 'react-router-hash-link';
import { MdAssignment } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import { useAuthToken } from "../TokenContext";
import { FaClock } from "react-icons/fa";
import { useIntl } from 'react-intl';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}



const Podstalent = () => {

  const intl = useIntl();

  const token = useAuthToken();




  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [getpoddetails, setGetpoddetails] = useState([]);

  const [id, setId] = useState('');

 

  useEffect(() => {


    const body = {
      "candidateID": user.data[0].c_id
    };
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };

    axios.post(process.env.REACT_APP_TALENT_PODLISTTALENT, body, {headers:headers})

      .then((res) => {


        setGetpoddetails(res.data.data)





      }

      ).catch((err) => {
        console.log("catch", err);
      })


  }, [token]);



  


  return (
   
    <Div>
     
    
      <TitleText>{intl.formatMessage({ id: 'yourPods' })} </TitleText>




      <CardContent>




        {getpoddetails.length == 0 ? (<div>  {intl.formatMessage({ id: 'noPods' })} </div>) : 
        (<TableContainer component={Paper}>
            <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>

                <TableRow>

                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podName' })}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'status' })}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podActivity' })}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podRetro' })}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podTask' })}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'timeSubmit' })}</TableCell>
                 
                </TableRow>
              </TableHead>
              {
                getpoddetails.map((x, i) => (
                  <TableBody>


                    <TableRow

                      key={""}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                        '&:nth-child(even)': {
                          backgroundColor: '#90E0EF'
                        },
                        '&:nth-child(odd)': {
                          backgroundColor: '#caf0f8'
                        },
                        '& td, & th': {
                          fontFamily: 'Poppins,Medium',
                          fontSize: '15px',
                          color: 'black',
                        },
                      }}

                    >

                      <TableCell style={{fontWeight:'bold'}} align="right" >{x.podName}</TableCell>

                      <TableCell align="right" >{x.status}</TableCell>



                      {x.status == 'Active' ? <TableCell align="right" >
                        <NavHashLink to={{
                          pathname: '/Podactivitytalent',
                          state: {

                            podid: x.podID,
                            clientid:x.clientID
                          }
                        }} smooth>

                          <ButtonEdit><MdAssignment />  </ButtonEdit>    </NavHashLink>


                      </TableCell> : <TableCell align="right" >


                        <ButtonEdit disabled><MdAssignment />  </ButtonEdit>


                      </TableCell>}
                      {x.status == 'Active' ? <TableCell align="right" >
                        <NavHashLink

                          to={{
                            pathname: '/PodactivitytalentRetro',
                            state: {

                              podid: x.podID
                            }
                          }}
                          smooth>



                          <ButtonEdit><MdAssignment /> </ButtonEdit>    </NavHashLink>


                      </TableCell> : <TableCell align="right" >


                        <ButtonEdit disabled><MdAssignment /> </ButtonEdit>


                      </TableCell>}

                      {x.status == 'Active' ? <TableCell align="right" >
                        <NavHashLink
                          to={{
                            pathname: '/Createtasktalent',
                            state: {
                              podname: x.podName,
                              podid: x.podID
                            }
                          }}
                          smooth
                        >

                          <ButtonEdit><FaTasks /></ButtonEdit>    </NavHashLink>


                      </TableCell> : <TableCell align="right" >


                        <ButtonEdit disabled><FaTasks /> </ButtonEdit>


                      </TableCell>}
                      {x.status == 'Active' ? <TableCell align="right" >
                        <NavHashLink
                          to={{
                            pathname: '/TimeSubmit',
                            state: {

                              podName: x.podName,
                              podid: x.podID
                            }
                          }}
                          smooth
                        >

                          <ButtonEdit><FaClock /></ButtonEdit>    </NavHashLink>


                      </TableCell> :<TableCell><ButtonEdit disabled><FaClock /></ButtonEdit></TableCell>}

                       {/* <TableCell align="right" >
                        <NavHashLink
                          to={{
                            pathname: '/Viewpodretrotalent',
                            state: {

                              podid: x.podID
                            }
                          }}
                          smooth
                        >

                          <ButtonEdit><MdList /></ButtonEdit>    </NavHashLink>


                      </TableCell> 

                      
                      <TableCell align="right" >
                        <NavHashLink
                          to={{
                            pathname: '/Podactivityviewalltalent',
                            state: {

                              podid: x.podID
                            }
                          }}
                          smooth
                        >

                          <ButtonEdit><MdList /></ButtonEdit>    </NavHashLink>


                      </TableCell>  */}

                      
                    </TableRow>

                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        )}




      </CardContent>








    </Div>
  );
  
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  // margin-top: 8rem;
  align-items: center;
margin:auto;
  // margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;


const ButtonEdit = styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:#eef4f6;
  width: 2rem;
  height: 2rem;
  color: #00B4D8;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    width: 5rem;
    height: 3rem;
    margin: auto;
    margin-top:2rem;
  }

`;

const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;



const CardContent = styled.div`
  margin: 0.4rem;
  // width: 40rem;
  padding:1rem;
  margin-top:4rem;
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;



















export default Podstalent
