import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const Dashboarddefectsmetricstalent = ({podid,selectedSprint}) => {
  const [metrics, setMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const chartRef = useRef(null);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    Chart.register(...registerables);

    const fetchDataAndCreateChart = async () => {
      try {
        if (!token) {
          return; // Exit early if there is no token
        }
        const response = await fetch(
          process.env.REACT_APP_TALENT_JIRADEFECTSDASHBOARDTALENT,
          {
            method: 'POST',
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ c_id: user.data[0].c_id , pod_id: podid,
              sprint_name: selectedSprint || '',}),
          }
        );

        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const responseData = await response.json();
        const parsedData = JSON.parse(responseData.body);

        if (parsedData && parsedData.Reporter) {
          setMetrics([parsedData]);
        } else {
          console.log('No data found in the API response.');
        }
      } catch (error) {
        console.error('Error fetching metrics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataAndCreateChart();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [token, user.data,selectedSprint]);

  useEffect(() => {
    if (!isLoading && metrics.length > 0) {
      const ctx = document.getElementById(`completedChart-${podid}`); // Unique ID based on podid

      if (ctx) {
        if (chartRef.current) {
          chartRef.current.destroy();
        }

        const chartInstance = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: ['Resolved Defects', 'Open Defects'],
            datasets: [{
              label: 'Defects',
              data: [metrics[0].resolved_defects, metrics[0].open_defects],
              backgroundColor: ['lightgreen', 'red'],
              borderWidth: 1,
            }],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              annotation: {},
              legend: {
                display: false,
              },
            },
            tooltips: {
              enabled: true,
              mode: 'single',
            },
          },
        });

        chartRef.current = chartInstance;
      }
    }
  }, [isLoading, metrics, podid]);

  let chartContent;
  if (isLoading) {
    chartContent = <p>Loading...</p>;
  } else {
    if (metrics.length > 0 && (metrics[0].open_defects !== 0 || metrics[0].resolved_defects !== 0)) {
      chartContent = (
        <canvas id={`completedChart-${podid}`} width="150" height="150"></canvas>
      );
    } else {
      chartContent = <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>;
    }
  }
  
  
  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'defects' })}</h2>
       <br/>
        <div className="chart-wrapper" style={{marginTop:"20px", width:"135px", height:"135px"}}>
          {chartContent}
        </div>
      </div>
    </div>
  );
};

Dashboarddefectsmetricstalent.propTypes = {
  podid: PropTypes.array.isRequired,
};

export default Dashboarddefectsmetricstalent;
