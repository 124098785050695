import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_PROFILE,
  SUCCESS,
  PARTNER_SUCCESS,
  PARTNER_FAIL,
  FAIL
} from "./types";
import AuthService from "../helper/queries";


export const register = (usertype, email,code) => (dispatch) => {
  return AuthService.register(usertype, email,code).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const partner = (usertype, email) => (dispatch) => {
  return AuthService.partner(usertype, email).then(
    (response) => {
      dispatch({
        type: PARTNER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: PARTNER_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const postproject = (data,headers) => (dispatch) => {
  return AuthService.postproject(data,headers).then(
    (response) => {
      dispatch({
        type: SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const postpodproject = (data) => (dispatch) => {
  return AuthService.postpodproject(data).then(
    (response) => {
      dispatch({
        type: SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateProfilecandidate = (data,headers) => (dispatch) => {
  return AuthService.updateProfile(data,headers).then(
    (response) => {

      localStorage.setItem("user", JSON.stringify({ data : response.data.result }));

       dispatch({
        type: UPDATE_PROFILE,
        payload: { user : { data : response.data.result } },
        
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateProfileclient = (data,headers) => (dispatch) => {
  return AuthService.updateclientProfile(data,headers).then(
    (response) => {
      console.log(response);

      localStorage.setItem("user", JSON.stringify(response.data));

       dispatch({
        type: UPDATE_PROFILE,
        payload: { user : response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const StakeholderupdateProfile = (data,headers) => (dispatch) => {
  return AuthService.StakeholderupdateProfileapi(data,headers).then(
    (response) => {
      console.log(response);

      localStorage.setItem("user", JSON.stringify(response.data));

       dispatch({
        type: UPDATE_PROFILE,
        payload: { user : response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const CostomerupdateProfile = (data,headers) => (dispatch) => {
  return AuthService.CostomerupdateProfileapi(data,headers).then(
    (response) => {
      console.log(response);

      localStorage.setItem("user", JSON.stringify(response.data));

       dispatch({
        type: UPDATE_PROFILE,
        payload: { user : response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const podprovideredit = (providerdata,headers) => (dispatch) => {
  return AuthService.podproviderupdate(providerdata,headers).then(
    (response) => {
      console.log(response);

      localStorage.setItem("user", JSON.stringify(response.data));

       dispatch({
        type: UPDATE_PROFILE,
        payload: { user : response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const login = (email, table) => (dispatch) => {
  return AuthService.login(email, table).then(
    (data) => {
      if(data.data != 'Not Found User'){
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve(data.data);
    }else{
      return Promise.reject();
    }
    } 
  ).catch((error) => {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });
    return Promise.reject();
  });
};

export const adminlogin = (email, table) => (dispatch) => {
  return AuthService.login(email, table).then(
    (data) => {

      console.log(data);

      if(data.data != 'Not Found User'){
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve(data.data);
    }else{
      return Promise.reject();
    }
    } 
  ).catch((error) => {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });
    return Promise.reject();
  });
};




export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
  console.log("User logged out. Dispatching LOGOUT action.");
};