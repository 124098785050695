import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {IoIosNotificationsOutline} from 'react-icons/io'
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';

import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';


function InfoDummy() {
const[details,setDetails]=useState([])
const { isLoggedIn, user } = useSelector(state => state.auth);
const [tasks, setTasks] = useState([]);
const [authorizationDetails, setAuthorizationDetails] = useState({});

const [errorState, setErrorState] = useState(false);
const intl = useIntl();

const token = useAuthToken();

const notificationdetails = async () => {
  if (!token) {
    return; // Exit early if there is no token
  }
  const headers = {
    'Authorization': token, // Ensure token is in the correct format
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.post(
      process.env.REACT_APP_NOTIFICATIONLIMIT,
      { user_id: user.data[0].c_id },
      { headers: headers }
    );
    console.log('Details:', response.data); // Debugging statement
    setDetails(response.data.data);
  } catch (error) {
    console.error('Error:', error); // Debugging statement
  }
};

useEffect(() => {
  notificationdetails();
}, [token]);


  useEffect(() => {
    const fetchData = async () => {
      // Fetch authorization tokens
      if (!token) {
        return; // Exit early if there is no token
      }
      const authorizationPayload = {
        Organizational_Code: user.data[0].talent_code,
      };
  
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
  
      try {
        const authorizationResponse = await axios.post(
          process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          authorizationPayload, { headers: headers }
        );
  
        const authorizationData = authorizationResponse.data.body.data[0];
  
        // Store authorization details in state
        setAuthorizationDetails(authorizationData);
  
        // Extract the necessary tokens and URLs from the authorization response
        const jiraUrl = authorizationData.Jira_URL;
        const jiraAdminEmail = authorizationData.Jira_admin_login_email_address;
        const jiraAdminToken = authorizationData.Jira_admin_API_Token;
        const githubUsername = authorizationData.Github_username;
        const githubApiToken = authorizationData.Github_API_Token;
        const projectKey = authorizationData.Jira_Kanben_Project_name;
  
        // Construct the dynamic payload
        const dynamicPayload = {
          "candidate_id": user.data[0].c_id,
          "username": jiraAdminEmail,
          "password": jiraAdminToken,
          "projectKey": projectKey,
          "jira_url": jiraUrl,
        };
  
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_TALENTDASHBOARDPODTASKSJIRAISSUES,
          dynamicPayload,
          { headers: headers }
        );
  
        if (response.status === 200) {
          // Update state with the response data
          setTasks(response.data.data.mergedData);
        } else {
          console.error('Error fetching data. Unexpected status:', response.status);
          // Handle the error, for example, by setting an error state
          setErrorState(true);
        }
      } catch (error) {
        // Handle different types of errors, log to console, and set error state
        console.error('Error fetching data:', error);

        // Check if it's a 400 Bad Request response from the Jira API
        if (error.response && error.response.status === 400) {
          console.error('Bad Request. Check your request payload or Jira configuration.');
          // Handle 400 Bad Request, for example, by setting an error state with a specific message
          setErrorState(true);
          // setErrorMessage('Bad Request. Check your request payload or Jira configuration.');
        } else {
          // Handle other types of errors
          setErrorState(true);
          // setErrorMessage('An unexpected error occurred. Please try again later.');
        }
      }
    };

  
  
    fetchData();
  }, [token, user.data]);
  

  return <InfoCard>


<SPAN>
     {/* <TitleText>Notifications</TitleText> */}
     <TitleText> {intl.formatMessage({ id: 'notifications' })}</TitleText>
     
                </SPAN>
                <Notcard>
 
 {details &&  details.length >0?details.map((data)=>

<Card  >
  <CardContent>
  
    <Row>
   
    
      <InfoContainer>
        {/* <Title>David Triggin </Title> */}
        <SubTitle><IoIosNotificationsOutline  style={{width:"1.5rem",height:"2rem"}}/> {data.message}  </SubTitle>
      </InfoContainer>
      
    </Row>
    
  </CardContent>
</Card>):(<Card ><CardContent>  <Row>
   
    
   <InfoContainer>

     <SubTitle> <IoIosNotificationsOutline  style={{width:"1.5rem",height:"2rem"}}/> {intl.formatMessage({ id: 'noNotifications' })}</SubTitle></InfoContainer></Row></CardContent></Card>)
}


</Notcard> 
<RecommendProject>
  <TitleText1>{intl.formatMessage({ id: 'assignedItems' })}</TitleText1>

  {tasks && tasks.length === 0 ? (
    <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
  ) : (
    <>
    {tasks?.map((task) => (
      <CardContent1 key={task.pod_task_id}>
        <ProjectInfo1>
          <span style={{ fontWeight: "bolder", fontSize: "16px", paddingRight: "0.3rem", color: 'black' }}>
            {intl.formatMessage({ id: 'taskName' })} :
          </span>
          {task.summary}
        </ProjectInfo1>
        <PriceContainer1>
          <Price1>{task.status}</Price1>
          <Price1>{task.key}</Price1>
        </PriceContainer1>
      </CardContent1>
    ))}
  </>
  
  )}

  {/* Display an error message if there is an error */}
 
</RecommendProject>

  </InfoCard>
}


const Viewall=styled.div`
text-align:center;
color: black;
float:right;
cursor:pointer;

border:2px solid green;
width:75px;
height:25px;
font-size:15px;
align-self:center;
`;
const InfoCard = styled.div`
height:340%!important;
width:21%;
margin-left:35px;
border-radius:1rem;
background-color:#F2F4F5;
flex-direction: column;
align-items: center;
gap: 1rem;
box-shadow: 0px 4px 6px #00000029;
transition:0.4s ease-in-out;
&:hover{
  box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
 margin-right: 300px
 display: flex;

margin:auto;
align-item:center;
justify-content:center;
 }
`;

const TitleText=styled.h6`
margin-top:10px;
margin-left:10px;
font-size:18px;
font-weight:600;
font-family:Poppins,Medium;
padding-bottom:3px;
color:#90E0EF;
`;
const Card = styled.div`
display:flex;

flex-direction:column;
padding:1px;
padding:2px;
height:30%;
border-bottom: 1px solid #00000029;
width:41.5%;
margin-bottom:2%;
  margin-top:-10px;
  @media screen and (min-width:320px) and (max-width:1080px){
    width:19rem;
 
   display: flex;
  
  margin:auto;
  align-item:center;
  justify-content:center;
   }
`;
const CardContent = styled.div`

margin-left:10px;
// padding: 0.7rem 1rem 0.3rem 1rem;
`;
const Row = styled.div`
display: flex;
flex-direction:column;
margin:auto;
align-item:center;
justify-content:center;
overflow:auto;
${({justify})=>
justify &&

`justify-content:space-around;
width:90%;
margin-left:6rem;
margin-bottom:0rem;

`}
`;

const Notcard=styled.div`
justify-content:center;
align-items:center;
flex-direction:column;

height:40%;
width:45rem;
margin-top:10px;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width:19rem;
 
 display: flex;

margin:auto;
align-item:center;
justify-content:center;
 }

`;

const InfoContainer = styled.div`
display:flex;

margin-right:auto;

overflow:hidden;
`;
const Title = styled.h6`
color:black;
`;
const SubTitle = styled.p`

align-item:center;
justify-content:center;
margin:auto;
color:black;
font-size:12px;
font-weight:600;
font-family:Poppins;

font-weight:Poppins;
`;

const RecommendProject=styled.div`
border-bottom-right-radius:1rem;
border-bottom-left-radius:1rem;
overflow-y:auto;
height:50%;
width:100%;
// margin-top:2rem;
background-color: #F8F8F8;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:40%;
   }
`;

const Row1 = styled.div`
display: flex;

align-item:center;
margin-bottom:0.2rem;
${({justify})=>
justify &&

`justify-content:space-around;
width:90%;

margin-left:6rem;
margin-bottom:0rem;
`}
`;
const TitleText1=styled.h6`
margin-left:10px;
font-size:18px;
font-family:Poppins,Medium;
font-weight:600;
color:#90E0EF;
`;
const CardContent1=styled.div`
margin:0.4rem;

`;
const Detail1=styled.div`
display:flex;
justify-content:space-around;
 aligh-items:center;
margin-left:5px;
@media screen and (min-width:320px) and (max-width:1080px){
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    gap:0.2rem;
    margin-bottom:1rem;
   }
`;
const InfoContainer1=styled.div`
display:flex;
aligh-items:center;

`;

const Info1=styled.div`
margin-left:1rem;
`;
const InfoName1=styled.h6`
font-weight:500;
font-size:18px;

`;
const InfoUpdate1=styled.h6`
font-weight:300;

`;
const PriceContainer1=styled.div`
display: flex;
align-items:center;
 justify-content:space-between;

 padding:0.1rem;
 

height:85%;
width:95%;
background-color:${themeColor};

@media screen and (min-width:320px) and (max-width:1080px){
    flex-dirction:column;
    gap:0.4rem;
    
   }
`;
const Price1=styled.p`
display:flex;
flex-direction:row;
margin:auto;
justify-content:center;
align-items:center;
color:white;
font-size:14px;
font-weight:600;
font-family:Poppins;

font-weight:Poppins;
`;
const Title1=styled.h6`
font-weight:00;

`;
const SPAN=styled.div`
padding:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
 
 }
`;
const ProjectInfo1=styled.p`
padding-left:0.1rem;
margin:0;
color:#17a2b8;
font-size:16px;
font-weight:600;
font-family:Poppins;

font-weight:Poppins;
margin-bottom:0.5rem
`;

export default InfoDummy