import { green } from '@mui/material/colors';
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import './PostRequirement.css';
import { useEffect, useState } from 'react'
import {VscChevronLeft} from 'react-icons/vsc';
import axios from 'axios'
import { postpodproject, postproject } from './action/auth';
import { useDispatch, useSelector } from "react-redux";
// import { styled } from '@material-ui/core';

import styled ,{ css }from 'styled-components'
import { style } from '@mui/system';

import { useAuthToken } from "../TokenContext";
const PostRequirementPod = props => {
  const token = useAuthToken();


  const [industrydata, setindustryData] = useState([]);
  const [getindustry, setIndustry] = useState({
    industry: "IT"
  });

  const industry = [
    {
      value: ' Healthcare', label: ' Healthcare'
    },
    {
      value: ' Banking and Financial', label: ' Banking and Financial'
    },
    {
      value: 'Services', label: 'Services'
    }
  ]

  const { isLoggedIn, user } = useSelector(state => state.auth);

  console.log(user)

  const dispatch = useDispatch();

  const history = useHistory();


  const [loading, setLoading] = useState(false);
  const { message } = useSelector(state => state.message);


  const [Postdata, setPostdata] = useState({
    project_name: "",
    project_description: "",
    budget: "",
    industry: "",
    devlopement_pod: "",
    pod_size: "",
    tech_stack: "",
    work_hours: "",
    type: "",
    timezone: "",
    client_ID: user.data[0].emp_id,
    errors: {
      cognito: null,
      blankfield: false
    }
  })



  const clearErrorState = () => {
    setPostdata({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  const PostRequirementdata = async event => {

    event.preventDefault();
    setLoading(true)

    const data = {
      project_name: Postdata.project_name,
      project_description: Postdata.project_description,
      budget: Postdata.budget,
      industry: Postdata.industry,
      devlopement_pod: Postdata.devlopement_pod,
      pod_size: Postdata.pod_size,
      tech_stack: Postdata.tech_stack,
      work_hours: Postdata.work_hours,
      timezone: Postdata.timezone,
      client_ID: user.data[0].emp_id,
    }

    try {
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };


      dispatch(postpodproject(data,headers)).then(() => {
        alert("Project Details Successfully added");
        history.push('/client-dashboard')
      })
    }

    //   this.props.auth.setAuthStatus(true);

    //   console.log(this.state.username);

    //   axios.post('https://xvc2hr1788.execute-api.us-east-1.amazonaws.com/dev/getUser', { table : this.state.table, email: this.state.username }).then(res => {
    //     console.log(res);
    //     console.log(res.data);

    //     localStorage.setItem("user", JSON.stringify(res.data.data[0]));

    //     console.log(this.props.auth);

    //     if(res.data.data.lenght > 0){

    //     if(this.state.table == "candidate"){

    //     if(res.data.data[0].profile == 0){
    //       this.props.history.push("/addcandidate");
    //     }else{

    //       this.props.history.push("/");
    //     }

    //   }else{
    //     this.props.history.push("/employerhome");

    //   }

    // }else{

    //   let err = "User Not Found!";
    //   this.setState({
    //     errors: {
    //       ...this.state.errors,
    //       cognito: err
    //     }
    //   });

    // }

    // }, (error) => {
    //     console.log(error);
    // });





    catch (error) {
      setLoading(false)
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      setPostdata({
        ...Postdata,
        errors: {
          cognito: err
        }
      });
    }
  };



  const onInputChange = e => {
    setPostdata((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  // const industry = [...new Set(industrydata.map(item=> item.industry))]
  return (
    <div className="container" style={{ "margin-top": "140px" }}>


    <div className="row" >
<br></br>
      <div className="col-lg-2" >      </div>

    
     
   
     <div className="col-lg-8" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1 }}>



      <Headingtext2 style={{ textAlign: "center" }} >
Post Requirement
 </Headingtext2>

        <form onSubmit={PostRequirementdata}>

          <Navdiv>

            <Cast to='/project-requirment'   >Individual </Cast>
            <span> <Cast2 to='/PostRequirementPod'  >Pod</Cast2></span>

          </Navdiv>

          <Postfield>
            <Textarea  type='text'  placeholder='Project Objective*' onChange={onInputChange} required />



          </Postfield>
          <Postfield  >

            <Dropdown  name='industry' list='industry' onChange={onInputChange}  placeholder='Industry*' required >
              <option value="" defaultValue>Industry</option>
              {industry.map(items => <option key={items} value={items.value} >{items.value}</option>)}

            </Dropdown>

          </Postfield>
         

          <Postfield>


            <Dropdown onChange={onInputChange} placeholder='Development Pod' >
              <option value="" defaultValue>Pod Type</option>
              <option style={{ color: 'black' }} >Mobile App</option>
              <option style={{ color: 'black' }} > Web App</option>
              <option style={{ color: 'black' }} >Web App + Mobile App</option>
              <option style={{ color: 'black' }} > SAAS </option>



            </Dropdown>

          </Postfield>
          <Postfield>
            <Dropdown onChange={onInputChange} placeholder='Pod Size' >
              <option style={{ color: 'black' }} value="" defaultValue>Pod Size</option>
              <option style={{ color: 'black' }} >Small (1 Front-end & 1 Back-end)</option>
              <option style={{ color: 'black' }} >Medium (2 Front-end & 1 Back-end)</option>
              <option style={{ color: 'black' }} >Large (2 Front-end & 2 Back-end)</option>

            </Dropdown>

          </Postfield>
          <Postfield>
            <Dropdown
            onChange={onInputChange} placeholder='Tech Stack' >
              <option style={{ color: 'black' }} value="" defaultValue>Tech Stack</option>
              <option style={{ color: 'black' }} >MEAN</option>
              <option style={{ color: 'black' }} >MERN</option>
              <option style={{ color: 'black' }} >LAMP</option>
              <option style={{ color: 'black' }} >MEVN</option>
            </Dropdown>


          </Postfield>

          <Postfield>
            <Dropdown onChange={onInputChange} placeholder='Preferred Work Hours'>
              <option style={{ color: 'black' }} value="" defaultValue>Preferred Work Hours</option>
              <option style={{ color: 'black' }} >Morning Shift</option>
              <option style={{ color: 'black' }} >Evening Shift</option>

            </Dropdown>
          
          </Postfield>

         
          <Postfield>
            <Dropdown onChange={onInputChange} placeholder='Timezone' >
              <option style={{ color: 'black' }} value="" defaultValu>Timezone</option>
              <option style={{ color: 'black' }} >Eastern standard time</option>
              <option style={{ color: 'black' }} >Central standard time</option>
              <option style={{ color: 'black' }} >Mountain standard time</option>
              <option style={{ color: 'black' }} >Pacific standard time</option>



            </Dropdown>
          </Postfield>
          <Postfield>
            <Dropdown onChange={onInputChange} placeholder='Budget Range'>
              <option style={{ color: 'black' }} value="" defaultValue>Budget Range</option>
              <option  style={{ color: 'black' }}> $5000 / month </option>
              <option style={{ color: 'black' }} > 
               $10000 / month
              </option>
              <option style={{ color: 'black' }} >$15,000/month</option>


            </Dropdown>


          </Postfield>
          <Postfield>

            <Textarea1  type='text' onChange={onInputChange}  placeholder='Project Description' />
          </Postfield>
         
          <center>

            <Button  type='submit' >Post</Button>

          </center>
          <br></br>
      <br></br>
      <br></br>
      
          </form>
          </div>
          <div className="col-lg-2" >      </div>

    </div>
     </div>
  )
}





const PostPodDiv=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

    @media screen and (min-width:320px) and (max-width:1080px){
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }
`;

const Postreqcontainer=styled.div`

  font-family: Poppins,Medium;
  font-weight: 600;
    width: 60rem;
    height: 1100px;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
margin-left: 23rem;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
    @media screen and (min-width:320px) and (max-width:1080px){
   margin:auto;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
overflow-x : hidden;
      width: 24rem;
      height: 1100px;   
    }
`;


const Postfield=styled.div`
font-family: Poppins,Medium;
font-weight: 600;
  padding: 10px;

  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    margin:auto; 
    }
 
`;
 


const Textarea=styled.textarea`
height: 60px; 
padding: 10px;
padding-left: 1.5rem;
 border: 1px solid #B7B7B7;
width: 407px;
 box-sizing: border-box;

resize: none;
 border-radius: 10px;
margin-top: 5rem;
font-family: Poppins,Medium;
color:grey;
  font-weight: 600;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    width: 20rem;
    height: 60px; 
    margin:auto;
    margin-top: 5rem;

  }
`;

const Textarea1=styled.textarea`
height: 60px; 
padding: 10px;


padding-left: 1.5rem;
 border: 1px solid #B7B7B7;
width: 407px;
 box-sizing: border-box;

resize: none;
 border-radius: 10px;

font-family: Poppins,Medium;
color:grey;
  font-weight: 600;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    width: 20rem;
    height: 60px; 
    margin:auto;
     
  }
`;
 
const StyleIndustry=styled.select`

width: 407px;
margin-left: 19.6rem;
margin-bottom: -1rem;
padding-left: 1.5rem;
 border: 1px solid #B7B7B7;
height: 54px;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;

  width: 20rem;
  height: 60px; 
  margin:auto;
   
}
`;


 
const Dropdown = styled.select`

width: 25rem;
font-family: Poppins,Medium;
font-weight: 600;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;

  width: 20rem;
  height: 60px; 
  margin:auto;
   
}
`;


 const Navdiv=styled.div`
 
@media screen and (min-width:320px) and (max-width:1080px){
 

}
 `;




 const CSSstyle=css`
 color: black;
 background-color: #E4E4E4;
 &:hover 
  {
    background-color:#E4E4E4;;
  }
 text-decoration: none !important;
  border-radius: 2rem; 
 padding: 0.5rem;

 padding-left: 2rem;
 padding-right: 3.5rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  
  margin-left: 3rem; 
  padding-left: 2rem;
  padding-right: none;
 }
 `;


 const CSSstyle1=css`

 color: white;
  box-shadow: 1px  3px 6px  #00000029; 
 background-color: #00B4D8; 
 text-decoration: none !important;
  border-radius: 2rem;
 padding: 0.5rem;
 pointer-events: none;
  margin-left: -3rem;
 padding-left: 3.5rem;
  padding-right: 3.5rem;

  @media screen and (min-width:320px) and (max-width:1080px){
   
   }
   `;



   const Cast2=styled(NavLink)`
   ${CSSstyle1}
   `;
  

 const Cast=styled(NavLink)`
 ${CSSstyle}
 `;

 const Headingtext1=styled.div`
 font-family: Poppins,Medium;
  font-weight: 600;
    text-align: center;
    margin-top:40rem ;
    margin-left: 23rem;
  
font-size: 1.5rem;

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  width:20rem;
  font-size: 1.5rem;
 margin:auto;
 margin-top:10rem ;
 }
 `;

 const Headingtext2=styled.p`

 color: #8EAEBC;
  margin-top: 2rem;
 margin-bottom: 5rem;
  text-align: center;
 font-weight: 600;
 
 font-size: 40px;
 @media screen and (min-width:320px) and (max-width:1080px){
  font-size: 25px;

 }
 `;

 const Button =styled.button`
 height: 50px;
  width: 200px;
  box-shadow: 1px 3px 6px #00000029;
 border:none;
 font-size:16px;
 font-weight: 600;
 
 background-color: #1DBF73;
 color: white;
 
 border-radius: 10px;
 height: 44px;
   
    font-family: Poppins,Medium;
    @media screen and (min-width:320px) and (max-width:1080px){
 
      width:20rem;
      margin:auto;
      margin-top:2rem
    }
 `;
 

export default PostRequirementPod

