import styled from "styled-components";
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskForm from "./TaskForm";
import axios from "axios";

import { NavHashLink } from 'react-router-hash-link';

import { useParams, useHistory } from 'react-router-dom';

import AWS from 'aws-sdk';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ViewPodTasksManagerbyid from "./ViewPodTasksManagerbyid";
import { useAuthToken } from "../TokenContext";
import { FaEye, FaTasks, FaPlus } from 'react-icons/fa';
import { useIntl } from 'react-intl';

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong',process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}



const ViewPodTasksManager = () => {

  const token = useAuthToken();
  const intl = useIntl();


  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [getpoddetails, setGetpoddetails] = useState({});
  const [getcandidates, setGetcandidates] = useState([]);
  const [getpoddetailsdata, setGetpoddetailsdata] = useState([]);
  const [podskill, setPodskill] = useState([]);
  const [id, setId] = useState('');
  const params = useParams();
  const [AuthImage, setAuthImage] = useState(""); 
  const [isExpanded, setIsExpanded] = useState(false);


  const myBucket = new AWS.S3(S3Config);




  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {

    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    const body = {
      "clientID": user.data[0].emp_id
    };

    // https://xujexobo25.execute-api.us-east-1.amazonaws.com/dev/getcandidatepods
    axios.post(process.env.REACT_APP_CLIENT_GETMULTIPLECLIENTPODS, body,{headers:headers})

      .then((res) => {
        console.log('skillssss',res)
      
        setGetpoddetailsdata(res.data.data.podDetails)
        

        const URL_EXPIRATION_TIME = 900;
  
        const params = {
          Bucket: process.env.REACT_APP_BUCKETNAME,
          Key: res.data.data.podDetails.picture,
          Expires: URL_EXPIRATION_TIME,
        }

        console.log(params)
       
     myBucket.getSignedUrl('getObject', params, (err, url) => {

      console.log(err, url)
      setAuthImage(url);

    });


      }

      ).catch((err) => {
        console.log("catch", err);
      })


  }, [token]);








  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;


  }



  return (
    <Div>

    <TitleText>{intl.formatMessage({ id: 'podTasks' })} </TitleText>




    <CardContent>




      {getpoddetailsdata.length == 0 ? (<>  {intl.formatMessage({ id: 'noPods' })} </>) : (

        <Table component={Paper} sx={{ maxWidth: 1050 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}} aria-label="simple table">
          <TableHead    sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}}>
            <TableRow     >

              <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podName' })}</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'viewTasks' })}</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'createTask' })}</TableCell>

            </TableRow>
          </TableHead>
         
              <TableBody>

              {
            getpoddetailsdata.map((x, i) => (
                
                <TableRow

key={""}
sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
 '&:nth-child(even)': {
  backgroundColor: '#90E0EF'
},
'&:nth-child(odd)': {
  backgroundColor: '#caf0f8'
},
'& td, & th': {
  fontFamily: 'Poppins,Medium',
  fontSize: '15px',
  color: 'black',
},
}}
                >

                  <TableCell align="right" >{x.podName}</TableCell>

              





                  <TableCell align="right" >
                    <NavHashLink 
                    
                    
                    
                    to={{
                      pathname: '/ViewPodTasksManagerbyid',
                      state: {
                        
                          Id: x.id,
                          podname:x.podName,
                      }
                  }}
                  smooth
                    >

                      <ButtonEdit><FaEye />  </ButtonEdit>    </NavHashLink>


                  </TableCell>
                  <TableCell align="right" >
                  <NavHashLink
            to={{
                pathname: '/TaskForm',
                state: {
                   
                    podid: x.id,
                    podname:x.podName
                }
            }}
            smooth
        >
               

                      <ButtonEdit><FaTasks/>  </ButtonEdit>    </NavHashLink>


                  </TableCell>
                </TableRow>
 

  ))}

              </TableBody>
          
        </Table>

       

    )}


      
    </CardContent>









  </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin:auto;
  margin-top: 8rem;
  align-items: center;

  // margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;


const ButtonEdit = styled.button`

  text-decoration: none  !important;
  border-radius: 10px;

  width: 3rem;
  height: 2rem;
  color: #00B4D8;;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    width: 3rem;
    height: 3rem;
    margin: auto;
    margin-top:2rem;
  }

`;

const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  margin-top:4rem;
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;

export default ViewPodTasksManager
