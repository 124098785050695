import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Tab, Tabs, Drop, InputLabel, Select } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
// import Button from 'react-bootstrap/Button';
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { logout } from "./action/auth";
import { NotificationsNone, PersonAddAlt } from "@mui/icons-material";
import logo from "./assets/Globllogogrey.png";
import globllogo from "./assets/Globlai-Logo-Clear.png";
import axios from "axios";
import login from "./auth/LogIn";
import FindPodsClient from "./FindPodsClient";
import NotificationDropdown from "./Notificationtalent";
import Notificationmanagericon from "./Notificationmanagericon";
import styled from "styled-components";
import { DiAptana } from "react-icons/di";
import { useIntl } from 'react-intl';

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [User, setUser] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const intl = useIntl();

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    setUser(user.username);
  }, []);

  let path =
    window.location.pathname == "/login" ? "login" : window.location.pathname;

  // console.log(path);

  const handleLogOut = async (event) => {
    event.preventDefault();

    Auth.signOut()
      .then((data) => {
        dispatch(logout()).then((data) => {
          sessionStorage.setItem("cprofile", "");
          history.push("/login");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogOutAdmin = async (event) => {
    event.preventDefault();

    Auth.signOut()
      .then((data) => {
        dispatch(logout()).then((data) => {
          history.push("/admin-login");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  // function timeSince(date) {

  //   console.log(mydate)
  //   const seconds = Math.floor((Date.now() - mydate.getTime()) / 1000);
  //   const interval = intervals.find(i => i.seconds < seconds);
  //   const count = Math.floor(seconds / interval.seconds);
  //   return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  // }

  return (
    <AppBar style={{ backgroundColor: "#f3f3f3", boxShadow: "none" }}>
      <Toolbar>
        <Typography
          variant="h6"
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "black",
            textDecoration: "none",
          }}
        >
          <Img src={globllogo} alt="Globl.ai logo" />
        </Typography>

        <Box
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          style={{}}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            style={{
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Img
            src={globllogo}
            alt="Globl.ai logo"
            style={{
              flex: 1,
              float: "left",
              marginLeft: "30px",
              width: window.width,
              height: window.height,
            }}
          />

<div>
  {isLoggedIn === true && (
    <Dropdown style={{ marginLeft: "auto", borderColor: "transparent" }}>
      <Dropdown.Toggle variant="Secondary">
        <strong>Hi, {User}</strong>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ padding: "20px" }}>
        <Dropdown.Item
          href="/forgotpassword"
          style={{ color: "black", fontWeight: "bold" }}
        >
          {intl.formatMessage({ id: 'resetPassword' })}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleLogOut}
          to="/login"
          style={{ color: "black", fontWeight: "bold" }}
        >
          {intl.formatMessage({ id: 'logOut' })}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )}
</div>


          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
            style={{ width: "500" }}
          >
            <MenuItem
              onClick={handleCloseNavMenu}
              style={{
                width: "300px",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
              }}
            >
              {isLoggedIn == true ? (
                user.data[0].type == "Candidate" ? (
                  <>
                    {path == "Home" ? (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'home' })}
                        
                      </a>
                    ) : (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{ color: "black" }}
                      >
                       {intl.formatMessage({ id: 'home' })}
                      </a>
                    )}

                    {path == "/candidate-dashboard" ? (
                      <a
                        href="/candidate-dashboard"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                       {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    ) : (
                      <a
                        href="/candidate-dashboard"
                        className="navbar-item"
                        style={{ color: "black" }}
                      >
                      {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    )}

                    {path == "/FindPods" ? (
                      <a
                        href="/FindPods"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                      {intl.formatMessage({ id: 'findPods' })}
                      </a>
                    ) : (
                      <a
                        href="/FindPods"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                       {intl.formatMessage({ id: 'findPods' })}
                      </a>
                    )}

{path == "/EditTalentSettingsForm" ? (
                  <a
                    href="/EditTalentSettingsForm"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    <DiAptana />
                  </a>
                ) : (
                  <a
                    href="/EditTalentSettingsForm"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    <DiAptana />
                  </a>
                )}
                    <div style={{ marginTop: "10px" }}>
                      <NotificationDropdown />
                    </div>
                  </>
                ) : user.data[0].type == "stakeholder" ? (
                  <>
                    {path == "Home" ? (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                       {intl.formatMessage({ id: 'home' })}
                      </a>
                    ) : (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{ color: "black" }}
                      >
                    {intl.formatMessage({ id: 'home' })}
                      </a>
                    )}

                    {path == "StakeholderDashboard" ? (
                      <a
                        href="/StakeholderDashboard"
                        className="navbar-item"
                        style={{
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                       {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    ) : (
                      <a
                        href="/StakeholderDashboard"
                        className="navbar-item"
                        style={{ color: "black" }}
                      >
                     {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    )}
                  </>
                ) : user.data[0].type == "Client" ? (
                  <>
                    {path == "Home" ? (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'home' })}
                      </a>
                    ) : (
                      <a
                        href="/"
                        className="navbar-item"
                        style={{ color: "black" }}
                      >
                       {intl.formatMessage({ id: 'home' })}
                      </a>
                    )}

                    {path == "/client-dashboard" ? (
                      <a
                        href="/client-dashboard"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                       {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    ) : (
                      <a
                        href="/client-dashboard"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                      {intl.formatMessage({ id: 'dashboard' })}
                      </a>
                    )}

                    {path == "/browse-talent" ? (
                      <a
                        href="/browse-talent"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'findTalent' })}
                      </a>
                    ) : (
                      <a
                        href="/browse-talent"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                       {intl.formatMessage({ id: 'findTalent' })}
                      </a>
                    )}
                    {path == "/Popup" ? (
                      <a
                        href="/Popup"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    ) : (
                      <a
                        href="/Popup"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    )}
                    {path == "/FindPodsClient" ? (
                      <a
                        href="/FindPodsClient"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'findPods' })}
                      </a>
                    ) : (
                      <a
                        href="/FindPodsClient"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'findPods' })}
                      </a>
                    )}

                    {/* {path == "/subscriptions" ? (

                    <a href="/subscriptions" className="navbar-item" style={{ marginLeft: "20px", color: "black", color: "#1DBF73", borderBottom: "1px solid #1DBF73" }} >
                      MyPods
                    </a>) : (

                    <a href="/subscriptions" className="navbar-item" style={{ marginLeft: "20px", color: "black" }} >
                      MyPods
                    </a>)} */}

                    {path == "/Team" ? (
                      <a
                        href="/Team"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                       {intl.formatMessage({ id: 'myPods' })}
                      </a>
                    ) : (
                      <a
                        href="/Team"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                     {intl.formatMessage({ id: 'myPods' })}
                      </a>
                    )}
                     {path == "/Setting" ? (
                  <a
                    href="/Setting"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    <DiAptana />
                  </a>
                ) : (
                  <a
                    href="/Setting"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    <DiAptana />
                  </a>
                )}

                    <div style={{ marginTop: "10px" }}>
                      <Notificationmanagericon style={{ color: "black" }} />
                    </div>
                  </>
                ) : user.data[0].type == "ClientAdmin" ? (
                  <>
                    {path == "/subscriptions" ? (
                      <a
                        href="/subscriptions"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'subscription' })}
                      </a>
                    ) : (
                      <a
                        href="/subscriptions"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'subscription' })}
                      </a>
                    )}
                    {path == "/Clientinvite" ? (
                      <a
                        href="/Clientinvite"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    ) : (
                      <a
                        href="/Clientinvite"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    )}

                    {path == "/InvoicesClient" ? (
                      <a
                        href="/InvoicesClient"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'invoices' })}
                      </a>
                    ) : (
                      <a
                        href="/InvoicesClient"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'invoices' })}
                      </a>
                    )}

                    
                  </>
                ) : user.data[0].type == "Provider" ? (
                  <>
                    {path == "/PartnerSubscription" ? (
                      <a
                        href="/PartnerSubscription"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                          {intl.formatMessage({ id: 'subscription' })}
                      </a>
                    ) : (
                      <a
                        href="/PartnerSubscription"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                          {intl.formatMessage({ id: 'subscription' })}
                      </a>
                    )}
                    {path == "/PartnerInvite" ? (
                      <a
                        href="/PartnerInvite"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    ) : (
                      <a
                        href="/PartnerInvite"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'invite' })}
                      </a>
                    )}

                    {path == "/PartnerInvoices" ? (
                      <a
                        href="/PartnerInvoices"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        {intl.formatMessage({ id: 'invoices' })}
                      </a>
                    ) : (
                      <a
                        href="/PartnerInvoices"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        {intl.formatMessage({ id: 'invoices' })}
                      </a>
                    )}

                    {path == "/PartnerSettings" ? (
                      <a
                        href="/PartnerSettings"
                        className="navbar-item"
                        style={{
                          marginLeft: "20px",
                          color: "black",
                          color: "#1DBF73",
                          borderBottom: "1px solid #1DBF73",
                        }}
                      >
                        <DiAptana />
                      </a>
                    ) : (
                      <a
                        href="/PartnerSettings"
                        className="navbar-item"
                        style={{ marginLeft: "20px", color: "black" }}
                      >
                        <DiAptana />
                      </a>
                    )}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  {path == "Home" ? (
                    <a
                      href="/"
                      className="navbar-item"
                      style={{
                        color: "#1DBF73",
                        borderBottom: "2px solid #1DBF73",
                      }}
                    >
                     {intl.formatMessage({ id: 'home' })}
                    </a>
                  ) : (
                    <a
                      href="/"
                      className="navbar-item"
                      style={{ color: "black" }}
                    >
                     {intl.formatMessage({ id: 'home' })}
                    </a>
                  )}

                  {path == "/login" ? (
                    <a
                      href="/login"
                      className="navbar-item"
                      style={{
                        color: "black",
                        color: "#1DBF73",
                        borderBottom: "2px solid #1DBF73",
                      }}
                    >
                        {intl.formatMessage({ id: 'login' })}
                    </a>
                  ) : (
                    <a
                      href="/login"
                      className="navbar-item"
                      style={{ color: "black" }}
                    >
                        {intl.formatMessage({ id: 'login' })}
                    </a>
                  )}

                  {path == "/register" ? (
                    <a
                      href="/register"
                      className="navbar-item"
                      style={{
                        color: "black",
                        color: "#1DBF73",
                        borderBottom: "2px solid #1DBF73",
                      }}
                    >
                        {intl.formatMessage({ id: 'register' })}
                    </a>
                  ) : (
                    <a
                      href="/register"
                      className="navbar-item"
                      style={{ color: "black" }}
                    >
                        {intl.formatMessage({ id: 'register' })}
                    </a>
                  )}
                </>
              )}
            </MenuItem>
          </Menu>
        </Box>

        <Typography
          variant="h6"
          component="div"
          href=""
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        ></Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {isLoggedIn == true ? (
            user.data[0].type == "Candidate" ? (
              <>
                {path == "Home" ? (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                      marginLeft: "550px",
                    }}
                  >
                   {intl.formatMessage({ id: 'home' })}
                  </a>
                ) : (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{ color: "black", marginLeft: "550px" }}
                  >
                    {intl.formatMessage({ id: 'home' })}
                  </a>
                )}

                {path == "/candidate-dashboard" ? (
                  <a
                    href="/candidate-dashboard"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                   {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                ) : (
                  <a
                    href="/candidate-dashboard"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                )}
               

                {path == "/FindPods" ? (
                  <a
                    href="/FindPods"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    {intl.formatMessage({ id: 'findPods' })}
                  </a>
                ) : (
                  <a
                    href="/FindPods"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                {intl.formatMessage({ id: 'findPods' })}
                  </a>
                )}
                {path == "/EditTalentSettingsForm" ? (
                  <a
                    href="/EditTalentSettingsForm"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    <DiAptana />
                  </a>
                ) : (
                  <a
                    href="/EditTalentSettingsForm"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    <DiAptana />
                  </a>
                )}
 <div style={{ marginTop: "10px" }}>
                  <NotificationDropdown />
                </div>
                <Dropdown
                  style={{
                    marginLeft: "20px",
                    color: "black",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      href="/manage-profile"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      {intl.formatMessage({ id: 'manageProfile' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="/forgotpassword"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                     {intl.formatMessage({ id: 'resetPassword' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={handleLogOut}
                      to="/login"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      
                      {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : user.data[0].type == "Client" ? (
              <>
                {path == "Home" ? (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                      marginLeft: "300px",
                    }}
                  >
                    {intl.formatMessage({ id: 'home' })}
                  </a>
                ) : (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{ color: "black", marginLeft: "300px" }}
                  >
                   {intl.formatMessage({ id: 'home' })}
                  </a>
                )}

                {path == "/client-dashboard" ? (
                  <a
                    href="/client-dashboard"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                   {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                ) : (
                  <a
                    href="/client-dashboard"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                  {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                )}

                {path == "/browse-talent" ? (
                  <a
                    href="/browse-talent"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                {intl.formatMessage({ id: 'findTalent' })}
                  </a>
                ) : (
                  <a
                    href="/browse-talent"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                {intl.formatMessage({ id: 'findTalent' })}
                  </a>
                )}

                {path == "/Popup" ? (
                  <a
                    href="/Popup"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                ) : (
                  <a
                    href="/Popup"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                )}

                {path == "/FindPodsClient" ? (
                  <a
                    href="/FindPodsClient"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                   {intl.formatMessage({ id: 'findPods' })}
                  </a>
                ) : (
                  <a
                    href="/FindPodsClient"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                   {intl.formatMessage({ id: 'findPods' })}
                  </a>
                )}

                {path == "/Team" ? (
                  <a
                    href="/Team"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                     {intl.formatMessage({ id: 'myPods' })}
                  </a>
                ) : (
                  <a
                    href="/Team"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                     {intl.formatMessage({ id: 'myPods' })}
                  </a>
                )}

{path == "/Setting" ? (
                  <a
                    href="/Setting"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    <DiAptana />
                  </a>
                ) : (
                  <a
                    href="/Setting"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    <DiAptana />
                  </a>
                )}
                <div style={{ marginTop: "10px" }}>
                  <Notificationmanagericon style={{ color: "black" }} />
                </div>
                <Dropdown
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      href="/client-profile"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                  {intl.formatMessage({ id: 'manageProfile' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="/forgotpassword"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      {intl.formatMessage({ id: 'resetPassword' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={handleLogOut}
                      to="/login"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                 {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : user.data[0].type == "stakeholder" ? (
              <>
                {path == "Home" ? (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                      marginLeft: "300px",
                    }}
                  >
                   {intl.formatMessage({ id: 'home' })}
                  </a>
                ) : (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{ color: "black", marginLeft: "300px" }}
                  >
                  {intl.formatMessage({ id: 'home' })}
                  </a>
                )}

                {path == "StakeholderDashboard" ? (
                  <a
                    href="/StakeholderDashboard"
                    className="navbar-item"
                    style={{
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                   {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                ) : (
                  <a
                    href="/StakeholderDashboard"
                    className="navbar-item"
                    style={{ color: "black" }}
                  >
                 {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                )}

                <Dropdown
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      href="/stakeholder-profile"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                  {intl.formatMessage({ id: 'manageProfile' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="/forgotpassword"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      {intl.formatMessage({ id: 'resetPassword' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={handleLogOut}
                      to="/login"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                     {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : user.data[0].type == "ClientAdmin" ? (
              <>
                {path == "/subscriptions" ? (
                  <a
                    href="/subscriptions"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    Subscription
                  </a>
                ) : (
                  <a
                    href="/subscriptions"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Subscription
                  </a>
                )}
                {path == "/Clientinvite" ? (
                  <a
                    href="/Clientinvite"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                ) : (
                  <a
                    href="/Clientinvite"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                )}
                {path == "/InvoicesClient" ? (
                  <a
                    href="/InvoicesClient"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    Invoices
                  </a>
                ) : (
                  <a
                    href="/InvoicesClient"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Invoices
                  </a>
                )}

               

                <Dropdown
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      href="/costomer-profile"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                     {intl.formatMessage({ id: 'manageProfile' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="/forgotpassword"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      {intl.formatMessage({ id: 'resetPassword' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={handleLogOutAdmin}
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                   {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : user.data[0].type == "CandidateAdmin" ? (
              <>
                {path == "/candidateadmin-dashboard" ? (
                  <a
                    href="/login"
                    className="navbar-item"
                    style={{
                      marginLeft: "850px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "2px solid #1DBF73",
                    }}
                  >
                   {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                ) : (
                  <a
                    href="/candidateadmin-dashboard"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                   {intl.formatMessage({ id: 'dashboard' })}
                  </a>
                )}

                <Dropdown
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      onClick={handleLogOutAdmin}
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                   {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : user.data[0].type == "Provider" ? (
              <>
                {path == "/PartnerSubscription" ? (
                  <a
                    href="/PartnerSubscription"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    Subscription
                  </a>
                ) : (
                  <a
                    href="/PartnerSubscription"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Subscription
                  </a>
                )}
                {path == "/PartnerInvite" ? (
                  <a
                    href="/PartnerInvite"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                ) : (
                  <a
                    href="/PartnerInvite"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                      {intl.formatMessage({ id: 'invite' })}
                  </a>
                )}
                {path == "/PartnerInvoices" ? (
                  <a
                    href="/PartnerInvoices"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    Invoices
                  </a>
                ) : (
                  <a
                    href="/PartnerInvoices"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Invoices
                  </a>
                )}

                {path == "/PartnerSettings" ? (
                  <a
                    href="/PartnerSettings"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "1px solid #1DBF73",
                    }}
                  >
                    <DiAptana />
                  </a>
                ) : (
                  <a
                    href="/PartnerSettings"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    <DiAptana />
                  </a>
                )}

                <Dropdown
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    borderColor: "transparent",
                  }}
                >
                  <Dropdown.Toggle variant="Secondary">
                    <strong>Hi, {User} </strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: "20px" }}>
                    <Dropdown.Item
                      href="/provider-profile"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                     {intl.formatMessage({ id: 'manageProfile' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="/forgotpassword"
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                   {intl.formatMessage({ id: 'resetPassword' })}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={handleLogOutAdmin}
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                     {intl.formatMessage({ id: 'logOut' })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                {path == "Home" ? (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{
                      color: "#1DBF73",
                      borderBottom: "2px solid #1DBF73",
                      marginLeft: "950px",
                    }}
                  >
                    {intl.formatMessage({ id: 'home' })}
                  </a>
                ) : (
                  <a
                    href="/"
                    className="navbar-item"
                    style={{ color: "black", marginLeft: "930px" }}
                  >
                   {intl.formatMessage({ id: 'home' })}
                  </a>
                )}

                {path == "/login" ? (
                  <a
                    href="/login"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "2px solid #1DBF73",
                    }}
                  >
                    Login
                  </a>
                ) : (
                  <a
                    href="/login"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Login
                  </a>
                )}

                {path == "/register" ? (
                  <a
                    href="/register"
                    className="navbar-item"
                    style={{
                      marginLeft: "20px",
                      color: "black",
                      color: "#1DBF73",
                      borderBottom: "2px solid #1DBF73",
                    }}
                  >
                    Register
                  </a>
                ) : (
                  <a
                    href="/register"
                    className="navbar-item"
                    style={{ marginLeft: "20px", color: "black" }}
                  >
                    Register
                  </a>
                )}
              </>
            )
          ) : (
            <>
              {path == "Home" ? (
                <a
                  href="/"
                  className="navbar-item"
                  style={{
                    color: "#1DBF73",
                    borderBottom: "2px solid #1DBF73",
                    marginLeft: "950px",
                  }}
                >
                {intl.formatMessage({ id: 'home' })}
                </a>
              ) : (
                <a
                  href="/"
                  className="navbar-item"
                  style={{ color: "black", marginLeft: "930px" }}
                >
               {intl.formatMessage({ id: 'home' })}
                </a>
              )}

              {path == "/login" ? (
                <a
                  href="/login"
                  className="navbar-item"
                  style={{
                    marginLeft: "20px",
                    color: "black",
                    color: "#1DBF73",
                    borderBottom: "2px solid #1DBF73",
                  }}
                >
                  Login
                </a>
              ) : (
                <a
                  href="/login"
                  className="navbar-item"
                  style={{ marginLeft: "20px", color: "black" }}
                >
                  Login
                </a>
              )}

              {path == "/register" ? (
                <a
                  href="/register"
                  className="navbar-item"
                  style={{
                    marginLeft: "20px",
                    color: "black",
                    color: "#1DBF73",
                    borderBottom: "2px solid #1DBF73",
                  }}
                >
                  Register
                </a>
              ) : (
                <a
                  href="/register"
                  className="navbar-item"
                  style={{ marginLeft: "20px", color: "black" }}
                >
                  Register
                </a>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

const Img = styled.img`
 flex:1,
 float: left;
margin-left: 30px;
width:15rem;
 height:5rem ;
 @media screen and (min-width:320px) and (max-width:1080px){
  width:8rem;
  height:3rem ;
 }
`;
