import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
// import IconButton from "@material-ui/core/IconButton";
// import InputLabel from "@material-ui/core/InputLabel";
// import Visibility from "@material-ui/icons/Visibility";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Input from "@material-ui/core/Input";
import { Auth } from "aws-amplify";
import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, Link } from "react-router-dom";
import { login, adminlogin, logout } from "../action/auth";
import axios from "axios";
import partner from '../assets/partner.png'

import clientlabel from "../assets/clientlabel.png";
import candidatelabel from "../assets/candidatelabel.png";

const Adminlogin = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [User, setUser] = useState({
    username: "",
    password: "",
    table: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  });

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  console.log(User);

  const clearErrorState = () => {
    setUser({
      ...User,
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation

    setLoading(true);

    if (User.table == "") {
      alert("Please Select User!");
      setLoading(false);
    } else if (User.username == "") {
      alert("Please Enter Email!");
      setLoading(false);
    } else if (
      !User.username
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      alert("Please Enter Valid Email Address!");
      setLoading(false);
    } else if (User.password == "") {
      alert("Please Enter Password!");
      setLoading(false);
    } else {
      try {
        const userss = await Auth.signIn(User.username, User.password);

        console.log(userss);

        if (User.table == "candidate") {
          dispatch(adminlogin(User.username, User.table)).then((data) => {

            console.log(data);

            if(data[0].type == "CandidateAdmin" ){

                // window.location.href = "/candidateadmin-dashboard";


            }else{

                alert("Please Enter Valid Admin Login Details!");
                dispatch(logout());

                setLoading(false);



            }



          }).catch((errr) => {
            setLoading(false);
            let err = {message : "User not found as Candidate Admin." }
            setUser({
              ...User,
              errors: {
                cognito: err,
              },
            });          })

        } 
       else if(User.table == "podprovider") {
          dispatch(adminlogin(User.username, User.table)).then((data) => {

            console.log(data);

            if(data[0].type == "ProviderAdmin"){

                window.location.href = "/PartnerAdminDashboard";


            }else{

                alert("Please Enter Valid Admin Login Details!");
                dispatch(logout());

                setLoading(false);



            }



          }).catch((errr) => {
            setLoading(false);
            let err = {message : "User not found as partner Admin." }
            setUser({
              ...User,
              errors: {
                cognito: err,
              },
            });          })

        } 
        else {
          dispatch(adminlogin(User.username, User.table)).then((data) => {

            if(data[0].type == "ClientAdmin" ){

              window.location.href = "/clientadmin-dashboard";


          }else{

              alert("Please Enter Valid Admin Login Details!")
              dispatch(logout());
              setLoading(false);


          }

            
        }).catch((errr) => {
          setLoading(false);
            let err = {message : "User not found as client." }
          setUser({
            ...User,
            errors: {
              cognito: err,
            },
          });        })
        }

        //   this.props.auth.setAuthStatus(true);

        //   console.log(this.state.username);

        //   axios.post('https://xvc2hr1788.execute-api.us-east-1.amazonaws.com/dev/getUser', { table : this.state.table, email: this.state.username }).then(res => {
        //     console.log(res);
        //     console.log(res.data);

        //     localStorage.setItem("user", JSON.stringify(res.data.data[0]));

        //     console.log(this.props.auth);

        //     if(res.data.data.lenght > 0){

        //     if(this.state.table == "candidate"){

        //     if(res.data.data[0].profile == 0){
        //       this.props.history.push("/addcandidate");
        //     }else{

        //       this.props.history.push("/");
        //     }

        //   }else{
        //     this.props.history.push("/employerhome");

        //   }

        // }else{

        //   let err = "User Not Found!";
        //   this.setState({
        //     errors: {
        //       ...this.state.errors,
        //       cognito: err
        //     }
        //   });

        // }

        // }, (error) => {
        //     console.log(error);
        // });
      } catch (error) {
        setLoading(false);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setUser({
          ...User,
          errors: {
            cognito: err,
          },
        });
      }
    }
  };


  const handleChangetable = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  const onInputChange = (e) => {
clearErrorState();
    setUser((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });

    document.getElementById(e.target.id).classList.remove("is-danger");
  };

  function showPassword() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <div className="container " >
      <div className="row">
        <div className="col-sm-2"></div>

        <div
          className="col-lg-7"
          style={{
            boxShadow: "1px 3px 6px #00000029",
            border: "1px solid #D8D8D8",
            borderRadius: "16px",
            opacity: 1,
            padding: "30px",
            width: "500px"
          }}
        >
          <br></br>

          <center>
          <FormErrors formerrors={User.errors} />
</center>
          {loading == true ? <p>Login... Please wait</p> : <p></p>}

          <form onSubmit={handleSubmit}>
            <center>
              {" "}
              <h1 className="register-header">Admin Login</h1>
            </center>

            <br></br>

            <Field1>
                <br></br>
              
                <img
                  className="loginSelect1"
                  value="candidate"
                  name="table"
                  id="table"
                  onClick={() => {
                    setUser((prevState) => {
                      return { ...prevState, table: "candidate" };
                    });
                  }}
                  style={{
                   
                    border:
                      User.table == "candidate"
                        ? "4px solid #1DBF73"
                        : "1px solid grey",
                   
                  }}
                  src={candidatelabel} 
                ></img>


<img
                   value="podprovider"
                   name="table"
                   className="loginSelect"
                  id="table"
                  onClick={() => {
                    setUser((prevState) => {
                      return { ...prevState, table: "podprovider" };
                    });
                  }}
                  style={{
                   
                    border:
                      User.table == "podprovider"
                        ? "4px solid #1DBF73"
                        : "1px solid grey",
                     
                  }}
                  src={partner} 
                ></img>
            
                <img
                  className="loginSelect"
                  onClick={() => {
                    setUser((prevState) => {
                      return { ...prevState, table: "employer" };
                    });
                  }}
                  style={{
                    
                    border:
                      User.table == "employer"
                        ? "4px solid #1DBF73"
                        : "1px solid grey",
                  
                  }}
                  src={clientlabel}
                ></img>
            
            </Field1>
              
            

            <div className="field">
              <p className="control">
                <input
                  className="input2"
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter E-mail"
                  value={User.username}
                  onChange={onInputChange}
                  style={{
                    width: "408px",
                    height: "54px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "600",
                    marginTop:"30px",
                    border: " 1px solid #B7B7B7",
                    "border-radius": "10px",
                    opacity: 1,
                  }}
                />
              </p>
            </div>

            <div className="field">
              <p className="control">
                <input
                  className="input2"
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  value={User.password}
                  onChange={onInputChange}
                  style={{
                    width: "408px",
                    height: "54px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "600",

                    border: " 1px solid #B7B7B7",
                    "border-radius": "10px",
                    opacity: 1,
                  }}
                />
              </p>
            </div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-4">
                <div className="field" style={{marginTop:'-2rem'}}>
                  <p className="control" id="showPass">
                    <input
                      type="checkbox"
                      onClick={showPassword}
                      style={{ marginLeft: "-25px" }}
                    />
                    <span
                      style={{
                        fontFamily: "Poppins, Regular",
                        paddingLeft: "5px",
                        fontWeight: "600",
                       
                      }}
                    >
                      Show Password
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field" style={{marginTop:'-2rem'}}>
                  <p className="control" id="showPass">
                    <Link
                      to="/forgotpassword"
                      style={{
                        color: "black",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "600",
                      
                      }}
                    >
                      Forgot password?
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>

            <div className="field">
              <p className="control">
                <button
                  className="login"
                  style={{
                    width: "400px",
                    height: "50px",
                    marginTop: "40px",
                    fontFamily: "Poppins, Medium",
                    fontWeight: "600",
                  }}
                >
                  Login
                </button>
              </p>
            </div>

            <div className="field">
              <p
                className="control"
                style={{ fontFamily: "Poppins, Regular", fontWeight: "600" }}
              >
                Don't have an account?{" "}
                <Link to="/register">
                  <b
                    className="login-label"
                    style={{
                      fontFamily: "Poppins, Regular",
                      fontWeight: "600",
                    }}
                  >
                    Register
                  </b>
                </Link>
              </p>
            </div>
          </form>
        </div>
        <div className="col-sm-2"></div>
        <div className="col-sm-1"></div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default Adminlogin;
const Field1 =styled.div`

 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
margin-bottom: 3rem;
@media screen and (min-width:320px) and (max-width:1080px){

  display: flex;
  flex-direction: column;
  margin  margin:auto;
  justify-content: center;
  align-items: center;
 
 }
 `;