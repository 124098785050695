import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';

const Dashboardstoriesmetricstalent = ({ podid, selectedSprint }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  const [metrics, setMetrics] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchMetrics();
  }, [podid, token, selectedSprint]);

  const fetchMetrics = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(process.env.REACT_APP_TALENT_JIRASTORIESDASHBOARDTALENT, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          c_id: user.data[0].c_id,
          pod_id: podid,
          sprint_name: selectedSprint || '',
        }),
      });

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);

      if (parsedData && parsedData.Reporter) {
        setMetrics([parsedData]);
      } else {
        setMetrics([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
    }
  };

  useEffect(() => {
    // Create or update the pie chart when metrics or selectedSprint changes
    if (metrics.length > 0) {
      const chartData = [metrics[0].completed_user_stories, metrics[0].open_user_stories];
      displayChart(chartData);
    }
  }, [metrics]);

  const displayChart = (chartData) => {
    const ctx = document.getElementById('completedChart');

    if (!ctx) {
      return;
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Completed Stories', 'Open Stories'], // No labels for segments
        datasets: [
          {
            data: chartData,
            backgroundColor: ['lightgreen', 'red'], // Use the specified colors
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            annotation: {},
            legend: {
                display: false,
            },
        },
        tooltips: {
            enabled: true,
            mode: 'single',
        },
    },
    
    });
    
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'stories' })}</h2>
        {/* Dropdown menu for selecting sprint names */}
        <br/>
        <div className="chart-wrapper"  style={{marginTop:"20px", width:"135px", height:"135px"}}>
          {/* Display the pie chart or "No data available" message */}
          {metrics.length > 0 && (metrics[0].open_user_stories !== 0 || metrics[0].completed_user_stories !== 0) ? (
            <canvas
              key="completedChart"
              id="completedChart"
            ></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>
    </div>
  );
};



export default Dashboardstoriesmetricstalent;
