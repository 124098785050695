import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { useSelector } from 'react-redux';
import Modal from "react-modal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

Modal.setAppElement('#root'); // '#root' should be the id of your root HTML element


const CloudCosttalent = ({ initialPodIds}) => {
  const [chartData, setChartData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const chartRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const token = useAuthToken();
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchData();
  }, [initialPodIds,token]);

  useEffect(() => {
    if (chartData) {
      renderPieChart();
    }
  }, [chartData]);

 
  const fetchData = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(
        process.env.REACT_APP_TALENT_AWSCLOUDCOSTMETRICSTALENT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({
            c_id: user.data[0].c_id,
            pod_id: initialPodIds ||[],
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('API Response Data:', responseData);
  
      // Parse the JSON string in the body
      const parsedData = JSON.parse(responseData.body);
  
      // Access the nested properties correctly
      const lastMonthName = parsedData[0]?.last_month?.last_month_name;
      const thisMonthName = parsedData[0]?.this_month?.this_month_name;
  
      if (lastMonthName && thisMonthName) {
        setChartData(parsedData[0]);
      } else {
        console.error('Missing month names in API response:', parsedData);
        setChartData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setChartData(null);
    }
  };
  
  
  

  const renderPieChart = () => {
    if (!chartData?.last_month || !chartData?.this_month) {
      return;
    }
  
    const ctx = chartRef.current.getContext('2d');
  
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }
  
    const lastMonthCost = calculateTotalCost(chartData.last_month.last_month_costs);
    const thisMonthCost = calculateTotalCost(chartData.this_month.this_month_costs);
  
    const newChartInstance = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [chartData.last_month.last_month_name, chartData.this_month.this_month_name],
        datasets: [
          {
            data: [lastMonthCost, thisMonthCost],
            backgroundColor: ['#FF6384', '#36A2EB'],
          },
        ],
      },
      options: {
        cutoutPercentage: 50,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          annotation: {},
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.labels[tooltipItem.index];
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return `${label}: $${value.toFixed(2)}`;
            },
          },
        },
      },
    });
  
    chartRef.current.chart = newChartInstance;
  };
  
  const calculateTotalCost = (costs) => {
    return costs.reduce((total, cost) => total + parseFloat(cost.TotalCost.replace('$', '')), 0);
  };
  








  
  
  
  
  
  
  
 
 
  



  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading"> {intl.formatMessage({ id: 'cloudCost' })}</h2>
        <br />
        <br/>
        
          <div className="chart-wrapper"style={{width:"150px",height:"150px",margin:"auto",textAlign:"center",marginLeft:"2rem"}}>
          {chartData ? (
  <canvas
    id="cloud_cost_chart"
    ref={chartRef}
    style={{ width: '200px', height: '300px' }}
  ></canvas>
) : (
  <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
)}

          </div>
     

       
      </div>
   
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}>
  <h6> {intl.formatMessage({ id: 'costDetails' })}</h6>
  {modalData && (
     <TableContainer component={Paper}>
     <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
       <TableHead
         sx={{
           backgroundColor: '#00B4D8',
           '& td, & th': {
             fontFamily: 'Poppins,Medium',
             fontSize: '15px',
             color: 'white',
             fontWeight: '600',
           },
         }}
       >
          <TableRow>
            <TableCell> {intl.formatMessage({ id: 'services' })}</TableCell>
            <TableCell align="right"> {intl.formatMessage({ id: 'cost' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(modalData).map(([key, value]) => (
            <TableRow key={key} sx={{
              '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
              '&:nth-child(even)': {
                backgroundColor: '#90E0EF',
              },
              '&:nth-child(odd)': {
                backgroundColor: '#caf0f8',
              },
              '& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'black',
              },
            }}>
              <TableCell>{key}</TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}
  <button onClick={() => {
    console.log('Close Modal Clicked');
    setModalOpen(false);
  }}> {intl.formatMessage({ id: 'done' })}</button>
</Modal>



    </div>
  );
};

export default CloudCosttalent;