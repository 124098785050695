import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const BarGraph = ({ initialPodIds,selectedSprint}) => {
  const [metrics, setMetrics] = useState(null);
  const chartRefs = useRef({});
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();
  const token = useAuthToken();
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        if (!token) {
          return; // Exit early if there is no token
        }
        if (user.data[0].type === "stakeholder") {
          await fetchStakeholderMetrics();
        } else {
          Chart.register(...registerables);
          if (initialPodIds && initialPodIds.length > 0) {
            console.log('Fetching metrics for selected pods:', initialPodIds);
            await fetchMetricspod(initialPodIds);
          } else {
            await fetchMetrics();
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error if needed
      } finally {
        console.error('Error fetching data:'); // Set loading state to false once data fetching is done
      }
    };

    fetchData();
  }, [token, user.data[0].type, initialPodIds,selectedSprint]);

const fetchStakeholderMetrics = async () => {
    const payload = {};
    
        payload.pod_id = initialPodIds || [];
  
        payload.org_code = user.data[0].client_code;
    
    payload.sprint_name = selectedSprint || '';
    try {
        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_JIRASTATSFORSTAKEHOLDER, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        const data = await response.json();
       
      if (data && data.body && data.body.length > 0) {
        const metricsData = JSON.parse(data.body);
        console.log('metricsData', metricsData);
        setMetrics(metricsData);
      } else {
            // Handle the case where data.body is empty
        }
    } catch (error) {
        console.error('Error fetching Jira stats for stakeholder:', error);
        // Handle the error if needed
    }
};

  const fetchMetricspod = async (selectedPodIds) => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_JIRASTATSMANAGERPODID, {
        method: 'POST',
        headers: {
          Authorization: token, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pod_id: selectedPodIds || null ,
          sprint_name:selectedSprint ||''})
      });
      const data = await response.json();
     
      if (data && data.body && data.body.length > 0) {
        const metricsData = JSON.parse(data.body);
        console.log('metricsData', metricsData);
        setMetrics(metricsData);
      } else {
        // Handle empty data.body
      }
    } catch (error) {
      // Handle the error
    }
  };

  const fetchMetrics = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_JIRASTATS, {
        method: 'POST',
        headers: {
          Authorization: token, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          manager_id: user.data[0].emp_id,
          sprint_name: selectedSprint ||'',
        }),
      });
      const data = await response.json();
      console.log('data', data);
  
      if (data && data.body && data.body.length > 0) {
        const metricsData = JSON.parse(data.body);
        console.log('metricsData', metricsData);
        setMetrics(metricsData);
      } else {
        // Handle empty data.body
      }
    } catch (error) {
      // Handle the error
    }
  };
  
  
  useEffect(() => {
    if (metrics && Object.keys(metrics).length > 0) {
      setTimeout(() => {
        displayChart('completedChart', 'Stories');
        displayChart('defectsChart', 'Defects');
      }, 0);
    }
  }, [metrics]);
 
  const displayChart = (chartId, chartLabel) => {
    const ctx = document.getElementById(chartId);
    if (!ctx) {
      return;
    }
    if (chartRefs.current[chartId]) {
      chartRefs.current[chartId].destroy();
    }
    const chartData = calculateChartData(chartLabel);
    chartRefs.current[chartId] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: chartLabel === 'Defects' ? ['Open Defects', 'Resolved Defects'] : ['Open User Stories', 'Completed User Stories'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: chartLabel === 'Defects' ? ['red', 'lightgreen'] : ['red', 'lightgreen'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
      },
    });
  };


  

  const calculateChartData = (chartLabel) => {
    if (chartLabel === 'Defects') {
      return [metrics.open_defects, metrics.resolved_defects];
    } else {
      return [metrics.open_user_stories, metrics.completed_user_stories];
    }
  };


  return (
    <div style={{ display: 'flex' }} className="chart-container">
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h2 className="chart-heading">{intl.formatMessage({ id: 'stories' })}</h2>
     
      <div className="chart-wrapper"  style={{ width: "150px", height: "160px",  margin: "auto"}}>
        {metrics && Object.keys(metrics).length > 0 ? (
          <canvas key="completedChart" id="completedChart"></canvas>
        ) : (
          <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        )}
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h2 className="chart-heading">{intl.formatMessage({ id: 'defects' })}</h2>
     
      
      <div className="chart-wrapper" style={{ width: "160px", height: "160px", margin: "auto"}}>
        {metrics && Object.keys(metrics).length > 0 ? (
          <canvas key="defectsChart" id="defectsChart" ></canvas>
        ) : (
          <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        )}
      </div>
    </div>
  </div>
  
  );
};
BarGraph.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default BarGraph;
