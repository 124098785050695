import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ user: null, token: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const { idToken } = currentSession;
        const token = idToken.jwtToken;

        setAuthState({ user: currentSession, token });
      } catch (error) {
        console.error('Token fetch error:', error);
        setAuthState({ user: null, token: null });
      } finally {
        setLoading(false);
      }
    };

    if (!authState.token && loading) {
      initializeAuth();
    }
  }, [authState.token, loading]);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const { idToken } = currentSession;
        const token = idToken.jwtToken;

        setAuthState({ user: currentSession, token });
      } catch (error) {
        console.error('Token refresh error:', error);
        // Handle token refresh error, e.g., log out user or display message
      }
    };

    const tokenExpirationTime = authState.user?.getIdToken().getExpiration() || 0;
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if token has expired within a tolerance of 1 minute
    if (tokenExpirationTime - currentTime < 60 && authState.token) {
      refreshToken();
    }
  }, [authState.token, authState.user]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};

export const useAuthToken = () => {
  const authState = useContext(AuthContext);
  const token = authState.token;

  return token;
};
