import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import PopupDefectsdetailviewchart from './PopupDefectsdetailviewchart';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const Defectsdetailviewchart = ({ initialPodIds,selectedSprint }) => {
  const [metrics, setMetrics] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState('');
  const [sprints, setSprints] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const completedChartRef = useRef(null);
  const [data, setData] = useState(null); 
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const chartIdCounter = useRef(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const intl = useIntl();

  const token = useAuthToken();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchDataForStakeholder = async () => {
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        const payload = {};
       
          payload.pod_id = initialPodIds || [];
   
          payload.org_code = user.data[0].client_code;
       
        payload.sprint_name = selectedSprint || '';

        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_JIRADEFETCSMERICSSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const responseData = await response.json();
        const parsedData = JSON.parse(responseData.body);

        if (parsedData?.reporters?.length > 0) {
          setMetrics(parsedData.reporters);
          setData(parsedData);
        } else {
          setMetrics([]);
        }
      } catch (error) {
        console.error('Error fetching metrics:', error);
        setMetrics([]);
      }
    };

    const fetchDataForUser = async () => {
      Chart.register(...registerables);
      if (initialPodIds && initialPodIds.length > 0) {
        fetchMetricsPod(initialPodIds);
      } else {
        fetchMetrics();
      }
    };

    if (user.data[0].type === "stakeholder") {
      fetchDataForStakeholder();
    } else {
      fetchDataForUser();
      return () => {
        if (completedChartRef.current) {
          completedChartRef.current.destroy();
        }
      };
    }
  }, [token, user.data[0].type, initialPodIds,selectedSprint]);

  const fetchMetricsPod = async (selectedPodIds) => {
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await fetch(
        process.env.REACT_APP_CLIENT_JIRAMETRICSMANAGERPODID,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ pod_id: selectedPodIds || null, sprint_name: selectedSprint, }),
        }
      );

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);

      if (parsedData && parsedData.reporters && parsedData.reporters.length > 0) {
        setMetrics(parsedData.reporters);
        setData(parsedData);
      } else {
        setMetrics([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
    }
  };

  const fetchMetrics = async () => {
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GETJIRAUSERDEFECTS,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            sprint_name: selectedSprint,
          }),
        }
      );

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);
      if (parsedData?.reporters?.length > 0) {
        setMetrics(parsedData.reporters);
        setData(parsedData);
      } else {
        setMetrics([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
    }
  };

  const createChart = (chartId, chartLabel, chartData) => {
    console.log('Creating chart with data:', chartData);
    const ctx = document.getElementById(chartId);
  
    if (!ctx) {
      console.error('Canvas element not found');
      return;
    }
  
    // Destroy previous chart instance if it exists
    if (completedChartRef.current) {
      completedChartRef.current.destroy();
    }
  
    const chartInstance = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: chartLabel === 'Defects' ? ['Resolved Defects', 'Open Defects'] : ['Completed Stories', 'Open Stories'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: chartLabel === 'Defects' ? ['#FFCE56', 'red'] : ['#36A2EB', '#FF6384'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
      },
    });
  
    completedChartRef.current = chartInstance;
  };
  
  

  const handlePieChartClick = (event) => {
    const chartInstance = completedChartRef.current;
  
    if (chartInstance) {
      const clickedDataset = chartInstance.getElementsAtEventForMode(event, 'point', chartInstance.options);
      if (clickedDataset && clickedDataset.length > 0) {
        const datasetIndex = clickedDataset[0].datasetIndex;
        const selectedLabel = chartInstance.data.labels[datasetIndex];
  
        if (selectedLabel === 'Resolved Defects' || selectedLabel === 'Open Defects') {
          // Open the modal when either 'Resolved Defects' or 'Open Defects' is clicked
          setModalIsOpen(true);
        }
      }
    }
  };
  
  
  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setSelectedDefects([]);
    chartIdCounter.current++;
  };
  useEffect(() => {
    if (!data || !data.reporters) {
      return;
    }
  
    let chartData = [];
  
    if (!selectedReporter || selectedReporter === '') {
      // Display pie chart with total user defect count
      chartData = [data.total_user_defect_count.resolved_defects, data.total_user_defect_count.open_defects];
    } else {
      // Filter the metrics data based on the selected reporter
      const selectedReporterData = data.reporters.find(reporter => reporter.Reporter === selectedReporter);
      if (selectedReporterData) {
        // Check if both resolved_defects and open_defects are 0
        if (selectedReporterData.resolved_defects === 0 && selectedReporterData.open_defects === 0) {
          // If both are 0, show "No data available" message
          chartData = null;
        } else {
          chartData = [selectedReporterData.resolved_defects, selectedReporterData.open_defects];
        }
      } else {
        // If selected reporter is not found, show "No data available" message
        chartData = null;
      }
    }
  
    if (chartData !== null) {
      createChart(`completedChart-${chartIdCounter.current}`, 'Defects', chartData);
    } else {
      // Show "No data available" message
      console.log('No data available');
    }
  
  }, [selectedReporter, data]);
  
    return (
      <div className="chart-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2 className="chart-heading">{intl.formatMessage({ id: 'defects' })}</h2>
          <br />
          
           {data && data.reporters && data.reporters.length > 0 ? (
    <select
      value={selectedReporter}
      onChange={(e) => setSelectedReporter(e.target.value)}
      style={{ width: '12rem',  }}
    >
      <option value="">{intl.formatMessage({ id: 'allReporters' })}</option>
      {data.reporters.map((reporter) => (
        <option key={reporter.Reporter} value={reporter.Reporter}>
          {reporter.Reporter}
        </option>
      ))}
    </select>
  ) : (
    <p>No reporters available</p>
  )}

  

          

          <div className="chart-wrapper" style={{ width: "170px", height: "170px", margin: "auto",marginTop:"15px"}}>
            {metrics.length > 0 ? (
              <canvas key={`completedChart-${chartIdCounter.current}`} id={`completedChart-${chartIdCounter.current}`}  onClick={handlePieChartClick}></canvas>
            ) : (
              <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
            )}
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Defects Modal"
          style={{
            content: {
              width: '50%',
              maxWidth: '800px',
              margin: 'auto',
              marginTop: '5rem',
            },
          }}
        >
          <h6>Defects</h6>
          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{ maxWidth: 900, fontFamily: 'Poppins, Medium', borderRadius: '10px' }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  backgroundColor: '#00B4D8',
                  '& td, & th': {
                    fontFamily: 'Poppins, Medium',
                    fontSize: '15px',
                    color: 'white',
                    fontWeight: '600',
                  },
                }}
              >
                <TableCell align="right">{intl.formatMessage({ id: 'reporter' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'issueKey' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'issueSummary' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'issueStatus' })}</TableCell>
              </TableHead>
              <TableBody>
                {data && data.open_defects && data.open_defects.map((defect, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins, Medium', fontSize: '15px' },
                      '&:nth-child(even)': {
                        backgroundColor: '#90E0EF',
                      },
                      '&:nth-child(odd)': {
                        backgroundColor: 'rgb(166, 194, 206, 30%)',
                      },
                      '& td, & th': {
                        fontFamily: 'Poppins, Medium',
                        fontSize: '15px',
                      },
                    }}
                  >
                    <TableCell align="right">{defect.Reporter}</TableCell>
                    <TableCell align="right">{defect.issue_key}</TableCell>
                    <TableCell align="right">{defect.issue_summary}</TableCell>
                    <TableCell align="right">{defect.issue_status}</TableCell>
                  </TableRow>
                ))}
                {data && data.resolved_defects && data.resolved_defects.map((defect, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins, Medium', fontSize: '15px' },
                      '&:nth-child(even)': {
                        backgroundColor: '#90E0EF',
                      },
                      '&:nth-child(odd)': {
                        backgroundColor: 'rgb(166, 194, 206, 30%)',
                      },
                      '& td, & th': {
                        fontFamily: 'Poppins, Medium',
                        fontSize: '15px',
                      },
                    }}
                  >
                    <TableCell align="right">{defect.Reporter}</TableCell>
                    <TableCell align="right">{defect.issue_key}</TableCell>
                    <TableCell align="right">{defect.issue_summary}</TableCell>
                    <TableCell align="right">{defect.issue_status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>

       
      </div>
    );
  };
  
  Defectsdetailviewchart.propTypes = {
    initialPodIds: PropTypes.array,
  };
  
  export default Defectsdetailviewchart;
